import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useStores } from "stores/utils";
import { Input } from "components/Form/Input";
import "./index.scss";

const ChangeEmailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export const ProfilePage = () => {
  const { userStore } = useStores();

  return (
    <div className="profile-page">
      <Formik
        initialValues={{
          email: userStore?.userInfo?.email,
        }}
        validationSchema={ChangeEmailSchema}
        onSubmit={(values) => {
          userStore.changeEmail(values.email);
        }}
      >
        {({ isValid }) => (
          <Form>
            <h1 className="title is-1">Profile</h1>
            <div className="box">
              <h3 className="is-size-5 change-email-title">
                Change your email
              </h3>
              <Field name="email" component={Input} placeholder="Email" />
              <div className="profile-page__actions">
                <button
                  className="button is-sblue button-submit"
                  disabled={!isValid}
                  type="submit"
                >
                  Change email
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
