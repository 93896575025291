import { observer } from "mobx-react-lite";
import { Redirect } from "@reach/router";
import { useStores } from "stores/utils";
import LoadingPage from "../LoadingPage/LoadingPage";

export function PrivateRoute(props) {
  const { userStore } = useStores();

  const { as: Comp, ...restOfTheProps } = props;

  let to = "";

  if (!userStore.isAuthenticated) {
    userStore.setLoginDestination(restOfTheProps.uri);
  }
  if (restOfTheProps.path === "/host/*") {
    to = "/login?to=host";
  } else {
    to = "/login";
  }

  if (userStore.isInitializing) {
    return <LoadingPage />;
  }

  return userStore.isAuthenticated ? (
    <Comp {...restOfTheProps} />
  ) : (
    <Redirect to={to} noThrow />
  );
}

export default observer(PrivateRoute);
