import { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import "./ForgotPassword.scss";
import * as Amplitude from "../../utilities/amplitude";
import api from "../../api";

const SetNewPassword = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [resetLoading, setResetLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetError, setResetError] = useState(false);

  const focusRef = useCallback(
    (inputElement) => {
      register(inputElement, { required: true });
      if (inputElement) {
        inputElement.focus();
      }
    },
    [register]
  );

  const onSubmit = useCallback(
    async (credentials) => {
      setResetError(false);
      setResetLoading(true);
      try {
        await api.setNewPassword(props.resetToken, credentials.password);
      } catch (error) {
        setResetLoading(false);
        if (error.response && error.response.status === 404) {
          setResetError(true);
          return;
        } else {
          throw error;
        }
      }
      Amplitude.track("Forgot password successfully requested", {
        email: credentials.email,
      });
      await new Promise((r) => setTimeout(r, 700));
      setResetLoading(false);
      setResetSuccess(true);
    },
    [props.resetToken]
  );

  return (
    <div className="forgot-password-form">
      {resetSuccess ? (
        <>
          <h1 className="title is-3 is-size-3-mobile">Password Changed</h1>

          <>
            <div className="text-form-padding">
              <div className="field">
                <div className="message-row">
                  <p className="reset-message">
                    Your Sidebar password has been changed.
                  </p>
                </div>
              </div>
            </div>

            <div className="field">
              <div className="control">
                <button className="button is-link" onClick={props.goToLogin}>
                  Login
                </button>
                {resetLoading && <span className="reset-loading" />}
              </div>
            </div>
          </>
        </>
      ) : (
        <>
          <h1 className="title is-3 is-size-3-mobile">New Password</h1>

          <>
            {resetError ? (
              <div className="has-text-danger has-text-weight-bold">
                This reset link has already been used.
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <div className="message-row">Enter a new password.</div>
                </div>

                <div className="field">
                  <div className="control">
                    <input
                      name="password"
                      className="input"
                      type="password"
                      placeholder="New password"
                      ref={focusRef}
                    />
                    {errors.password && (
                      <div className="message-row has-text-danger">
                        This field is required
                      </div>
                    )}
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <button className="button is-link">Continue</button>
                    {resetLoading && <span className="reset-loading" />}
                  </div>
                </div>
              </form>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default observer(SetNewPassword);
