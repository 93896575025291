import axios from "axios";
import axiosBetterStacktrace from "axios-better-stacktrace";

const publicFetch = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    post: {
      Accept: "application/json",
    },
  },
});
axiosBetterStacktrace(publicFetch);

const createAuthOptionalAxios = (authToken) => {
  const fetcher = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
  });
  axiosBetterStacktrace(fetcher);
  fetcher.interceptors.request.use((config) => {
    config.headers["Content-Type"] = "application/vnd.api+json";
    config.headers["Accept"] = "application/vnd.api+json";

    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }

    const participantToken = localStorage.getItem("participant-token");
    if (participantToken) {
      config.headers["X-Participant-Token"] = participantToken;
    }

    return config;
  });

  return fetcher;
};

export { publicFetch, createAuthOptionalAxios };
