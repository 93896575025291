/* eslint-disable jsx-a11y/anchor-is-valid */
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import { FiExternalLink } from "react-icons/fi";
import { useIntercom } from "react-use-intercom";
import "./HelpMenu.scss";

const HelpMenu = ({ userStore, isHelpMenuOpen, hideHelpMenu }) => {
  const { show, hide } = useIntercom();

  const bootWithProps = useCallback(() => {
    show();

    hideHelpMenu();
  }, [show, hideHelpMenu]);

  useEffect(() => {
    // cleanup
    return () => hide();
  }, [hide]);

  return (
    <div className="help-menu">
      <div className="help-menu__wrap">
        <div className="help-menu__section">
          <a
            href="https://grand-coneflower-ab5.notion.site/Sidebar-Troubleshooting-64d912aa86124931b785d7bfa9083d4e"
            target="_blank"
            rel="noopener noreferrer"
            className="help-menu__section-help-link"
          >
            Troubleshooting guide <FiExternalLink />
          </a>
        </div>

        <hr />

        <div className="help-menu__section">
          <a
            className="button is-small is-rounded invite-button help-menu__help-button is-fullwidth"
            onClick={bootWithProps}
          >
            Chat with support
          </a>
        </div>
      </div>
    </div>
  );
};

export default observer(HelpMenu);
