import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import { useQueryClient } from "react-query";
import OrganizationLogo from "./OrganizationLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import { pwaProbablySupported } from "../../utilities/pwa";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "./SideMenuHeader.scss";
import TinyProfilePhoto from "../ProfilePhoto/TinyProfilePhoto";
import { faDoNotEnter } from "@fortawesome/pro-solid-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
// eslint-disable-next-line
import { faDoorOpen } from "@fortawesome/pro-solid-svg-icons";
import { navigate } from "@reach/router";

function SideMenuHeader({ officeSummary }) {
  const rootStores = useStores();
  const { api, appStore, officeStore, userStore, viewStore } = rootStores;
  const queryClient = useQueryClient();
  const [logoUrl, setLogoUrl] = useState();

  const [menuOptions, setMenuOptions] = useState(false);
  const showMenuOptions = () => setMenuOptions(true);
  const hideMenuOptions = () => setMenuOptions(false);

  const signOut = () => {
    userStore.logout({ redirect: true });
  };

  const availabilityStatus =
    userStore.userInfo?.availability_status || "Online";
  const online = true;
  const tinyPhoto = userStore?.userInfo?.tiny_photo_url;
  const myName = userStore?.userInfo?.display_name;
  const myColor = userStore?.userInfo?.color;

  const changeName = async () => {
    hideMenuOptions();
    const currentName = userStore?.userInfo?.display_name || "";
    var newDisplayName = prompt("Enter a new display name", currentName);

    if (newDisplayName != null) {
      await api.updateUser({
        id: userStore.userInfo.id,
        display_name: newDisplayName,
      });
      await queryClient.invalidateQueries("office/officeSummary");
      await userStore.refetchUser();
    }
  };

  useEffect(() => {
    const included = officeSummary?.data?.data?.included;
    const organizationData = included?.find((x) => x.type === "organizations");
    setLogoUrl(organizationData?.attributes?.["tiny-logo-url"]);
  }, [officeSummary?.data]);

  const changeStatus = async (newStatus) => {
    if (availabilityStatus !== newStatus) {
      await api.updateUser({
        id: userStore.userInfo.id,
        availability_status: newStatus,
      });
      await userStore.refetchUser();
    }
  };

  const showUserConnectSlack =
    userStore?.userInfo?.slack_team_id && !userStore?.userInfo?.slack_user_id;

  const clearRightPane = () => {
    officeStore.setSpaceInRightPaneId(undefined);
    navigate("/office");
  };

  return (
    <div className="side-menu__header">
      <div className="office-menu__head-title">
        <div className="head-left" onClick={clearRightPane}>
          {" "}
          <OrganizationLogo imageSrc={logoUrl} />
          <h1 className="head-title">
            {userStore?.userInfo?.organization_name}
          </h1>
        </div>

        <div className="head-right">
          <div className="is-pulled-right is-flex is-align-content-center">
            <Tippy
              content={
                <aside className="menu settings-menu">
                  <ul className="menu-list availability-status">
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        onClick={() => {
                          changeStatus("Online");
                          hideMenuOptions();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="in-dropdown-menu-icons"
                          color="rgb(0 209 178)"
                        />
                        Online
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        onClick={() => {
                          changeStatus("Door is open");
                          hideMenuOptions();
                        }}
                        className="door-is-open-action"
                      >
                        <FontAwesomeIcon
                          icon={faDoorOpen}
                          className="in-dropdown-menu-icons "
                        />
                        Door is open
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        onClick={() => {
                          changeStatus("Do not disturb");
                          hideMenuOptions();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faDoNotEnter}
                          className="in-dropdown-menu-icons"
                          color="rgb(240 14 3)"
                        />
                        Do not disturb
                      </a>
                    </li>
                  </ul>
                  <ul className="menu-list my-settings">
                    <p className="menu-label">{userStore.userInfo.email}</p>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        onClick={() => {
                          hideMenuOptions();
                          changeName();
                        }}
                      >
                        Change name
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        onClick={() => {
                          hideMenuOptions();
                          officeStore.showChangePhoto();
                        }}
                      >
                        Change photo
                      </a>
                    </li>
                    {showUserConnectSlack && (
                      <li>
                        <a href="/office/setup/slack?next=office">
                          Connect with Slack
                        </a>
                      </li>
                    )}
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a onClick={() => signOut()}>Sign out</a>
                    </li>
                  </ul>
                  {userStore.isAdmin && (
                    <>
                      <ul className="menu-list my-settings">
                        <p className="menu-label">
                          {userStore?.userInfo?.organization_name}
                        </p>
                        <li>
                          <a
                            href="/office/admin/team"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Admin
                          </a>
                        </li>
                      </ul>
                    </>
                  )}
                  {pwaProbablySupported ? (
                    appStore.showingInstallPromotion && (
                      <>
                        <hr />
                        <div className="install-button-container">
                          <div
                            className="button is-sblue is-rounded install-button"
                            onClick={() => appStore.installPWA()}
                          >
                            Install the app
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      <hr />
                      <div className="install-button-container">
                        <div
                          className="button is-sblue is-rounded install-button"
                          onClick={() =>
                            officeStore.setShowingInstallUnsupportedModal(true)
                          }
                        >
                          Install the app
                        </div>
                      </div>
                    </>
                  )}
                </aside>
              }
              theme="light"
              interactive={true}
              offset={1}
              arrow={false}
              placement="bottom-end"
              visible={menuOptions}
              onClickOutside={hideMenuOptions}
              className="user-menu-tippy"
            >
              <div
                className="photo-with-activity"
                onClick={() =>
                  menuOptions ? hideMenuOptions() : showMenuOptions()
                }
                style={{ cursor: "pointer" }}
              >
                <TinyProfilePhoto
                  photoUrl={tinyPhoto}
                  personName={myName}
                  placeholderColor={myColor}
                />

                {online && (
                  <>
                    <div className="css-activity-dot activity--online"></div>
                  </>
                )}
                {online && availabilityStatus === "Do not disturb" && (
                  <>
                    <div className="css-activity-dot activity--do-not-disturb"></div>
                  </>
                )}
                {online && availabilityStatus === "Door is open" && (
                  <>
                    <div className="css-activity-dot activity--door-is-open"></div>
                  </>
                )}
              </div>
            </Tippy>
          </div>
          <div
            className="collapse-sidebar"
            onClick={() => viewStore.toggleSideMenu()}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(SideMenuHeader);
