/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import "./ForgotPassword.scss";
import * as Amplitude from "../../utilities/amplitude";
import api from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";

const HostLoginForm = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [resetLoading, setResetLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const focusRef = useCallback(
    (inputElement) => {
      register(inputElement, { required: true });
      if (inputElement) {
        inputElement.focus();
      }
    },
    [register]
  );

  const onSubmit = useCallback(async (credentials) => {
    setNotFound(false);
    setResetLoading(true);
    try {
      await api.resetPassword(credentials.email);
    } catch (error) {
      setResetLoading(false);
      if (error.response && error.response.status === 404) {
        setNotFound(true);
        return;
      } else {
        throw error;
      }
    }
    Amplitude.track("Forgot password successfully requested", {
      email: credentials.email,
    });
    await new Promise((r) => setTimeout(r, 700));
    setResetLoading(false);
    setResetSuccess(true);
  }, []);

  return (
    <div className="forgot-password-form">
      <h1 className="title is-3 is-size-3-mobile">Forgot Password</h1>

      {resetSuccess ? (
        <>
          <div className="text-form-padding">
            <div className="reset-title">
              Password Reset Email Sent <FontAwesomeIcon icon={faCheckCircle} />
            </div>

            <p className="reset-message">
              An email has been sent to your email address. Follow the
              directions in the email to reset your password.
            </p>
          </div>

          <div className="field">
            <div className="control">
              <button className="button is-link" onClick={props.goToLogin}>
                Log in
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            {notFound && (
              <div className="field">
                <div className="message-row has-text-danger has-text-weight-bold">
                  We couldn't find an account with that email address.
                </div>
              </div>
            )}

            <div className="field">
              <div className="message-row">
                Enter your email address to get started.
              </div>
            </div>

            <div className="field">
              <div className="control">
                <input
                  name="email"
                  className="input"
                  type="email"
                  placeholder="Email"
                  ref={focusRef}
                />
                {errors.email && (
                  <div className="message-row has-text-danger">
                    Email is required
                  </div>
                )}
              </div>
            </div>

            <div className="field">
              <div className="control">
                <button className="button is-link">Continue</button>
                {resetLoading && <span className="reset-loading" />}
              </div>
            </div>
            <a className="is-size-75" onClick={props.goToLogin}>
              Log in
            </a>
          </form>
        </>
      )}
    </div>
  );
};

export default observer(HostLoginForm);
