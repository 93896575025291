export const calculateLocalTime = (timezone) => {
  const options = {
    timeZone: timezone,
    hour: "numeric",
    minute: "numeric",
  };

  const today = new Date();
  let localTime = today.toLocaleTimeString("en-US", options);
  const AMPM = localTime.slice(localTime.length - 2);
  const ampm = AMPM.toLowerCase();
  localTime = localTime.replace(AMPM, ampm);

  return localTime;
};
