import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import classnames from "classnames";
import ExtraMenu from "./ExtraMenu.js";
import "tippy.js/animations/shift-away.css";
import "tippy.js/themes/light.css";

const ExtraMenuButton = ({ disabled, theme }) => {
  const { extraMenuStore: store } = useStores();

  if (!store.showButton) return null;

  return (
    <Tippy
      animation="shift-away"
      content={<ExtraMenu store={store} />}
      interactive={true}
      theme="light"
      onClickOutside={store.hideMenu}
      visible={store.isMenuOpen}
      arrow={false}
    >
      <Tippy content="End event" disabled={store.isMenuOpen} theme={theme}>
        <button
          className={classnames(
            "tray-button",
            "custom-tray-item",
            "more-button",
            "extra-menu-button"
          )}
          onClick={store.toggleMenu}
          disabled={disabled}
        >
          <FontAwesomeIcon color="#fb5554" icon={faEllipsisV} />
        </button>
      </Tippy>
    </Tippy>
  );
};

export default observer(ExtraMenuButton);
