import { useCallback } from "react";
import { observer } from "mobx-react-lite";

const ConfirmMeetingDelete = ({
  setShowingConfirmMeetingDelete,
  deleteMeeting,
}) => {
  const cancel = useCallback(() => {
    setShowingConfirmMeetingDelete(false);
  }, [setShowingConfirmMeetingDelete]);

  const confirmDelete = useCallback(async () => {
    await deleteMeeting();
    setShowingConfirmMeetingDelete(false);
  }, [deleteMeeting, setShowingConfirmMeetingDelete]);

  return (
    <div className="modal-padding content">
      <h1>Delete meeting</h1>
      <p className="mb-5">
        Permanently delete this meeting for everyone? You can't undo this.
      </p>
      <div className="buttons">
        <div className="button is-light" onClick={cancel}>
          Cancel
        </div>
        <div className="button  is-sred" onClick={confirmDelete}>
          Delete
        </div>
      </div>
    </div>
  );
};

export default observer(ConfirmMeetingDelete);
