import { useState } from "react";
import { observer } from "mobx-react-lite";
import ReactModal from "react-modal";
import { MdClose } from "react-icons/md";
import ChangePhoto from "../ChangePhoto/ChangePhoto";
import { useStores } from "stores/utils";
import { useQueryClient } from "react-query";
import "./ChangePhotoModal.scss";

function ChangePhotoModal({ handleCloseModal, showingModal }) {
  const { api, userStore } = useStores();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const queryClient = useQueryClient();

  const uploadPhoto = async (croppedImage) => {
    await api.uploadProfilePhoto(croppedImage);
    await queryClient.invalidateQueries("office/officeSummary");
    await queryClient.invalidateQueries("office/allUsers");
    await userStore.refetchUser();
    setUpdateSuccess(true);
  };

  const closeModal = () => {
    setUpdateSuccess(false);
    handleCloseModal();
  };

  return (
    <ReactModal
      isOpen={showingModal}
      overlayClassName="ReactModal__Overlay__disabled"
      className="ReactModal__Content__modal"
      onRequestClose={() => closeModal()}
    >
      <div className="modal-wrapper">
        <div className="modal-close-wrap" onClick={handleCloseModal}>
          <MdClose size={20} />
        </div>

        <div className="modal-content content">
          <div className="change-photo-modal">
            {updateSuccess ? (
              <>
                <h2 className="modal-title">Success!</h2>
                <div className="change-photo-container">
                  <img src={userStore.userInfo.big_photo_url} alt="Uploaded" />
                </div>
              </>
            ) : (
              <>
                <h2 className="modal-title">Change profile picture</h2>
                <div className="change-photo-container">
                  <ChangePhoto
                    doUpload={uploadPhoto}
                    oldPhotoUrl={userStore.userInfo.big_photo_url}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default observer(ChangePhotoModal);
