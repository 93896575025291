import { observer } from "mobx-react-lite";
import { Link } from "@reach/router";
import { useStores } from "stores/utils";
import { useEffect } from "react";

function LaunchTheAppStep() {
  const { appStore, userStore } = useStores();

  useEffect(() => {
    userStore.refetchUser();
  }, [userStore]);

  return (
    <div className="office-onboarding-launch-the-app">
      <div className="content">
        <h2 className="modal-title">You're ready to go!</h2>
        <div className="actions">
          {appStore.showingInstallPromotion ? (
            <>
              <div
                className="button is-link with-pwa-support onboarding-button"
                onClick={() => appStore.installPWA()}
              >
                Launch the app
              </div>
              <div>
                <Link to="/office" className="with-pwa-support">
                  Use in my browser for now
                </Link>
              </div>
            </>
          ) : (
            <>
              {/* to do: need to open the app */}
              <Link to="/office" className="button is-link onboarding-button">
                Done
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(LaunchTheAppStep);
