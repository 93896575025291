/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import "./HostLoginModal.scss";
import * as Amplitude from "../../utilities/amplitude";
import { useStores } from "stores/utils";

const HostLoginForm = (props) => {
  const { userStore } = useStores();
  const { register, handleSubmit, errors } = useForm();
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const focusRef = useCallback(
    (inputElement) => {
      register(inputElement, { required: true });
      if (inputElement) {
        setTimeout(() => {
          inputElement.focus();
        }, 250);
      }
    },
    [register]
  );

  const failLogin = useCallback((errorMessage) => {
    console.log("login failure");
    setLoginLoading(false);
    setLoginError(errorMessage);
    setLoginSuccess(null);
  }, []);

  const onLogin = props.onLogin;
  const onSubmit = useCallback(
    async (credentials) => {
      setLoginLoading(true);
      let data = {};
      try {
        data = await userStore.login(credentials);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          Amplitude.track("Host log in failure, wrong email or password (?).", {
            email: credentials.email,
          });
          return failLogin("Email or password incorrect.");
        } else if (error.response && error.response.data) {
          Amplitude.track("Host log in failure.", error.response.data);
          return failLogin(error.response.data);
        } else if (error.response) {
          Amplitude.track("Host log in failure.", error.response);
          return failLogin("Error logging in: " + error.response);
        } else {
          throw error;
        }
      }

      setLoginSuccess(data);
      setLoginError(null);
      Amplitude.track("Host successfully logged in.", {
        email: credentials.email,
      });

      await new Promise((r) => setTimeout(r, 700));

      if (onLogin) {
        onLogin();
      }
    },
    [failLogin, userStore, onLogin]
  );

  return (
    <>
      <h1 className="title is-3 is-size-3-mobile">
        {props.hideHostTitle ? "" : "Host"} Login
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        {props.loginMessage && (
          <div className="field">
            <div className="message-row has-text-danger has-text-weight-bold">
              {props.loginMessage}
            </div>
          </div>
        )}
        {loginError && (
          <div className="field">
            <div className="message-row has-text-danger has-text-weight-bold">
              {loginError.error ? loginError.error : loginError}
            </div>
          </div>
        )}
        <div className="field">
          <div className="control">
            <input
              name="email"
              className="input"
              type="email"
              placeholder="Email"
              ref={focusRef}
            />
            {errors.email && (
              <div className="message-row has-text-danger">
                Email is required
              </div>
            )}
          </div>
        </div>
        <div className="field">
          <div className="control">
            <input
              name="password"
              className="input"
              type="password"
              placeholder="Password"
              ref={register({ required: true })}
            />

            {errors.password && (
              <div className="message-row has-text-danger">
                Password is required
              </div>
            )}
          </div>
        </div>

        {loginSuccess && (
          <div className="field">
            <div className="message-row has-text-weight-bold login-success">
              Login Success!
            </div>
          </div>
        )}

        <div className="field">
          <div className="control">
            <button className="button is-link">Log in</button>
            {loginLoading && <span className="login-loading" />}
          </div>
        </div>

        <a className="is-size-75" onClick={props.goToForgot}>
          Forgot password?
        </a>
      </form>
    </>
  );
};

export default observer(HostLoginForm);
