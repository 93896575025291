import { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStores } from "stores/utils";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Moment from "react-moment";
import "./CallRow.scss";

export default observer(function CallRow(props) {
  const { permissionsStore } = useStores();
  const [showCopiedTooltip, setShowCopiedTootip] = useState(false);

  const triggerCopiedTooltip = useCallback(() => {
    setShowCopiedTootip(true);
    setTimeout(() => {
      setShowCopiedTootip(false);
    }, 2000);
  }, []);

  const sessionUrl = props.session.attributes["session-url"];
  let envFriendlyUrl = sessionUrl;
  const stagingHost = "https://staging.sidebar.vc";
  if (sessionUrl.startsWith(stagingHost)) {
    envFriendlyUrl = sessionUrl.replace(stagingHost, window.location.origin);
  }

  return (
    <tr className="call-row">
      <td>{props.session.attributes.name}</td>
      <td>
        <div className="field has-addons">
          <div className="control is-expanded">
            <div className="input is-small">
              <a
                href={envFriendlyUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {envFriendlyUrl}
              </a>
            </div>
          </div>
          <div className="control">
            <CopyToClipboard
              text={envFriendlyUrl}
              onCopy={triggerCopiedTooltip}
            >
              <div>
                <Tippy
                  content="Copied!"
                  visible={showCopiedTooltip}
                  className="blue-tippy"
                  arrow={false}
                >
                  <Tippy content="Copy URL to clipboard">
                    <button className="button is-small">Copy</button>
                  </Tippy>
                </Tippy>
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </td>
      {/*<td>93 min</td>*/}
      <td>
        <Tippy
          content={
            <div>
              <Moment format="llll">
                {props.session.attributes["created-at"]}
              </Moment>
            </div>
          }
        >
          <div className="created-time-ago">
            <Moment fromNow>{props.session.attributes["created-at"]}</Moment>{" "}
          </div>
        </Tippy>
      </td>
      <td>{props.session.attributes["created-by"]}</td>
      {permissionsStore.canManageEvent(props.session) && (
        <td>
          {/* eslint-disable-next-line */}
          <a onClick={() => props.deleteCall(props.session.id)}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </a>
        </td>
      )}
    </tr>
  );
});
