import { observer } from "mobx-react-lite";
import ActionMenuItem from "../ParticipantCaret/ActionMenuItem";

const MoreMenu = ({ store }) => {
  return (
    <div className="more-actions-menu participant-actions-menu">
      <ul>
        <li>
          <ActionMenuItem
            label="Mute all"
            onClick={() => {
              store.muteEveryone();
            }}
            hideSending
          />
        </li>
      </ul>
    </div>
  );
};

export default observer(MoreMenu);
