import { observer } from "mobx-react-lite";
import Tippy from "@tippyjs/react";
import { useStores } from "../../stores/utils";
import { ReactComponent as Plus } from "../../images/SVG/plus-light.svg";

const AddRoomButton = () => {
  const { callStore } = useStores();
  const disabled = callStore.appButtonsDisabled;

  return (
    <div className="add-room-button">
      <Tippy content="Create new room">
        <button
          className="button is-link is-outlined is-small is-rounded "
          disabled={disabled}
          onClick={() => callStore.addRoom()}
        >
          <span className="icon">
            <Plus />
          </span>
          &nbsp;&nbsp; {callStore.creatingRoom ? "Creating..." : "Room"}
        </button>
      </Tippy>
    </div>
  );
};

export default observer(AddRoomButton);
