/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";

import ReadReciept from "../ReadReciept";

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageFileWrapper,
  messageInfoWrapperStyle,
} from "./style";

import blueFile from "./resources/file-blue.svg";

const senderfilebubble = (props) => {
  return (
    <div css={messageContainerStyle()}>
      <div css={messageWrapperStyle()}>
        <div css={messageFileWrapper(props)}>
          <a
            href={props.message.data.attachments[0].url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.message.data.attachments[0].name}{" "}
            <img src={blueFile} alt="file" />
          </a>
        </div>
        <div css={messageInfoWrapperStyle()}>
          <ReadReciept {...props} />
        </div>
      </div>
    </div>
  );
};

export default senderfilebubble;
