import { observer } from "mobx-react-lite";
import TinyProfilePhoto from "../ProfilePhoto/TinyProfilePhoto";
import UserParticipantActions from "./UserParticipantActions";
import "./PersonRow.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoNotEnter } from "@fortawesome/pro-solid-svg-icons";
import { faDoorOpen } from "@fortawesome/pro-solid-svg-icons";
import { useStores } from "../../stores/utils";
import LimitedParticipantsList from "../LimitedParticipantsList/LimitedParticipantsList";
import RestBlock from "../RestParticipantsList/RestBlock";
import RestBlock5 from "../RestParticipantsList/RestBlock5";
import classnames from "classnames";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { useCallback, useEffect, useRef } from "react";
import ChatMessages from "../ChatMessages/ChatMessages";
import { offscreenMsgObserver } from "utilities/dom";
import { useOffscreen } from "hooks/useOffscreen";

function PersonRow({ person, idx }) {
  const [curIdx, setNewIdx] = useOffscreen({ p: person });

  const rootStores = useStores();
  const { callStore, userStore, userChatStore } = rootStores;

  const online = person.attributes["online-status"] === "online";
  const offline =
    person.attributes["online-status"] == null ||
    person.attributes["online-status"] === "offline";

  const myUserId = userStore?.userInfo?.id;
  const availabilityStatus =
    person.attributes["availability-status"] || "Online";
  const isDoNotDisturb = availabilityStatus === "Do not disturb";
  const isDoorOpen = availabilityStatus === "Door is open";

  const thisIsntYou = person.id !== myUserId;
  const thisIsYou = !thisIsntYou;
  const inThisUserOffice = callStore.currentRoomId === person?.officeGroup?.id;

  const isChatOpen = userChatStore.currentOpenedChatId === person.id;
  const newOffscreenMessageRef = useRef(null);
  const newMessages = userChatStore.newMessages[person.id];
  const isAnyUnreadMessages = newMessages?.length > 0;

  const closeChat = useCallback(() => {
    userChatStore.setCurrentOpenedChatId(undefined);
    userChatStore.closeChat();
  }, [userChatStore]);

  useEffect(() => {
    if (idx === undefined) return;
    setNewIdx(idx);
  }, [idx, setNewIdx]);

  useEffect(() => {
    if (curIdx === undefined) return;
    const observer = offscreenMsgObserver(
      curIdx,
      newOffscreenMessageRef,
      userChatStore
    );

    if (newOffscreenMessageRef.current) {
      observer.observe(newOffscreenMessageRef.current);
    }
    return () => observer && observer.disconnect();
  }, [userChatStore, newOffscreenMessageRef, curIdx, isAnyUnreadMessages]);

  return (
    <Tippy
      content={
        isChatOpen ? (
          <ChatMessages person={person} closeUserChat={closeChat} />
        ) : (
          <></>
        )
      }
      placement="right-start"
      arrow={false}
      theme="light"
      interactive={true}
      visible={isChatOpen}
      onClickOutside={closeChat}
      duration={[275, 0]}
    >
      <div
        className={classnames("person-row-container", {
          "in-this-office": inThisUserOffice,
          hovarable: !inThisUserOffice,
        })}
        onClick={() =>
          isChatOpen ? closeChat() : userChatStore.openChat(person.id)
        }
      >
        {" "}
        <div
          className={classnames("people-row", { "status-offline": offline })}
        >
          <div className="people-left">
            <div className="photo-with-activity">
              <TinyProfilePhoto
                photoUrl={person.attributes["tiny-photo-url"]}
                personName={person.attributes["name"]}
                placeholderColor={person.attributes["color"]}
              />

              {online && (
                <>
                  <div className="css-activity-dot activity--online"></div>
                </>
              )}
              {online && isDoNotDisturb && (
                <>
                  <div className="css-activity-dot activity--do-not-disturb"></div>
                </>
              )}
              {offline && (
                <div className="css-activity-dot activity--offline"></div>
              )}
              {online && isDoorOpen && (
                <>
                  <div className="css-activity-dot activity--door-is-open"></div>
                </>
              )}
            </div>

            <div className="person-name">{person.attributes["name"]}</div>

            {online && isDoNotDisturb && (
              <Tippy content="Do not disturb">
                <div className="is-flex">
                  <FontAwesomeIcon
                    icon={faDoNotEnter}
                    color="rgb(240 14 3)"
                    className="person-row-icons"
                  />
                </div>
              </Tippy>
            )}

            {online && isDoorOpen && (
              <Tippy content={thisIsYou ? "Your door is open" : "Door is open"}>
                <div className="is-flex">
                  <FontAwesomeIcon
                    icon={faDoorOpen}
                    className="person-row-icons door-is-open-icon"
                  />
                </div>
              </Tippy>
            )}
          </div>
          {newMessages && newMessages.length > 0 && (
            <div className="user-chat-new-message-count">
              {newMessages.length}
              <div ref={newOffscreenMessageRef}></div>
            </div>
          )}
          <div className="people-right">
            <UserParticipantActions
              userId={person.id}
              availabilityStatus={availabilityStatus}
              officeId={person?.officeGroup?.id}
              sayStart={!person.officeGroup}
            />
          </div>
        </div>
        {person.officeGroup && (
          <div className="user-office-participants">
            <LimitedParticipantsList
              room={person.officeGroup}
              differentSession={!inThisUserOffice}
              disableUserActions={true}
            />

            {inThisUserOffice ? (
              <>
                {callStore.currentRoom && (
                  <RestBlock
                    room={callStore.currentRoom}
                    differentSession={!inThisUserOffice}
                  />
                )}
              </>
            ) : (
              <>
                <RestBlock5
                  room={person.officeGroup}
                  differentSession={!inThisUserOffice}
                />
              </>
            )}
          </div>
        )}
      </div>
    </Tippy>
  );
}

export default observer(PersonRow);
