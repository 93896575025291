import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../stores/utils";
import { useQuery } from "react-query";
import ParticipantsList from "../../ParticipantsList";
import { ParticipantModelLite } from "../../../stores/ParticipantModelLite";

function ParticipantListModal({ groupId }) {
  const [participants, setParticipants] = useState([]);
  const rootStores = useStores();
  const { api } = rootStores;
  const { data, isFetching, isSuccess } = useQuery(
    ["groups", groupId],
    (params) => api.getGroup(groupId)
  );

  useEffect(() => {
    if (isSuccess) {
      const participantModels = data.data.included
        .filter((x) => x.type === "participants")
        .map((p) => {
          return new ParticipantModelLite({
            data: p,
            rootStores: rootStores,
          });
        });
      setParticipants(participantModels);
    }
  }, [data, isSuccess, rootStores]);

  return (
    <>
      {isFetching ? (
        "Loading..."
      ) : (
        <div className="foyer-more-popup">
          <ParticipantsList
            participants={participants}
            alreadyInRoom={false}
            differentSession={true}
          />
        </div>
      )}
    </>
  );
}

export default observer(ParticipantListModal);
