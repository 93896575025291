import { action, computed, makeObservable, observable } from "mobx";

export class MoreMenuStore {
  @observable isMenuOpen = false;

  constructor(rootStores) {
    this.rootStores = rootStores;
    this.api = rootStores.api;
    makeObservable(this);
  }

  @action.bound toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  @action hideMenu() {
    this.isMenuOpen = false;
  }

  @computed
  get showButton() {
    return this.rootStores.callStore.isEventHost;
  }

  muteEveryone = async () => {
    const groupId = this.rootStores.callStore.currentRoomId;
    const myParticipantId = this.rootStores.callStore.participantId;
    try {
      await this.api.muteEveryone(groupId, myParticipantId);
    } catch (err) {
      console.log(err);
      return this.rootStores.errorStore.handleApiError(err);
    } finally {
      this.hideMenu();
    }
  };
}
