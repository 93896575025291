export function setConsumerLayer(dailyId, layer) {
  try {
    let consumer = window.rtcpeers.sfu.consumers[dailyId + "/cam-video"];
    window.rtcpeers.sfu.setConsumerLayer(consumer, layer).then((x) => {
      console.log(`Set ${dailyId + "/cam-video"} to simulcast layer ${layer}`);
    });
  } catch (e) {
    console.log(e);
  }
}

export function setVideoQuality(dailyId, layer) {
  if (!window.rtcpeers || !window.rtcpeers.sfu) {
    console.log("Tried to setConsumerLayer, but no window.rtcpeers or .sfu");
    return;
  }

  setConsumerLayer(dailyId, layer);
}
