import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import OfficeRoomRow from "./OfficeRoomRow";
import classnames from "classnames";

function RoomsList({ groups, sessionId, hideGroupName, sessionType }) {
  const rootStores = useStores();
  const { officeStore } = rootStores;

  return (
    <>
      <div
        className={classnames("space-outline space-rooms-list", {
          "collapsed-single-room": hideGroupName,
        })}
      >
        <ul className="rooms-list">
          {groups.map((g) => {
            return (
              <li key={g.id}>
                <OfficeRoomRow
                  key={g.id}
                  room={g}
                  onClickRoom={() =>
                    officeStore.joinRoomFromFoyer(g, sessionId, sessionType)
                  }
                  differentSession={true}
                  hideGroupName={hideGroupName}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default observer(RoomsList);
