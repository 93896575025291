import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import DocPane from "components/DocPane/DocPane";
import { useStores } from "stores/utils";
import PlainGreyLayout from "components/Layouts/PlainGreyLayout";
import Modal from "components/Office/Modal";
import DocumentSettings from "components/Office/DocumentSettings";

const DocPage = ({ groupDocId }) => {
  const { cableSocketStore, docStore } = useStores();
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    docStore.setAllowedFromDocPage(groupDocId);
    docStore.fetchDoc(groupDocId).then((docExists) => {
      if (docExists) {
        docStore.selectDoc(groupDocId);
        setNotFound(false);
      } else {
        setNotFound(true);
      }
    });
  }, [docStore, groupDocId]);

  useEffect(() => {
    cableSocketStore.subscribeToGroupDocsMetadataChannel(groupDocId);
    return () => {
      cableSocketStore.unsubscribeFromGroupDocsMetadataChannel();
    };
  }, [cableSocketStore, groupDocId]);

  const reload = () => {
    window.location.reload();
  };

  return (
    <PlainGreyLayout blueLogo={true} hideLogoMobile={true} tall={true}>
      <div className="content-column high tall">
        <div className="doc-page">
          {notFound && (
            <div className="content">
              <h1 className="has-text-centered mt-6">Document not found</h1>
            </div>
          )}
          <DocPane />
        </div>
      </div>
      <Modal
        onRequestClose={() => docStore.showDocumentSettingsDocId(undefined)}
        showingModal={docStore.showingDocumentSettingsDocId}
      >
        <DocumentSettings
          docId={docStore.showingDocumentSettingsDocId}
          modalWrapper={true}
          afterDelete={reload}
        />
      </Modal>
    </PlainGreyLayout>
  );
};

export default observer(DocPage);
