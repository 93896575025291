import { action, observable, computed, makeObservable } from "mobx";
import { toast } from "react-toastify";
import { NotVerifiedModal } from "components/NotVerifiedModal";
import { StartSubscriptionModal } from "components/StartSubscriptionModal";
import { FaExclamationCircle } from "react-icons/fa";
import * as Amplitude from "../utilities/amplitude";

toast.configure({
  autoClose: 20000,
  draggable: false,
  position: "top-center",
});

export class ViewStore {
  @observable showNameForm = false;
  @observable showEditNameForm = false;
  @observable nameModalJoin = null;
  @observable showHostLogin = false;
  @observable forceShowFoyer = false;
  @observable hideSideMenu = false;
  @observable errorPage = null;
  @observable generalNotification = null;
  @observable showBanModal = false;
  @observable banModalContent = null;
  @observable sideMenuCollapsedByFullScreen = false;
  @observable isFullScreening = false;
  @observable callContentSize = { width: undefined, height: undefined };
  @observable showingDocumentsList = false;

  constructor(rootStores) {
    this.rootStores = rootStores;
    makeObservable(this);
  }

  @action
  setHideSideMenu(value) {
    this.hideSideMenu = value;
  }

  @action
  toggleIsFullScreening() {
    this.isFullScreening = !this.isFullScreening;
  }

  @action
  setErrorPage(value) {
    this.errorPage = value;
  }

  @action
  setForceShowFoyer(value) {
    this.forceShowFoyer = value;
  }

  @action
  setShowNameForm(value) {
    this.showNameForm = value;
  }

  @action
  setShowEditNameForm(value) {
    this.showEditNameForm = value;
  }

  @action
  setGeneralNotification(value) {
    this.generalNotification = value;
  }

  toggleShowNameForm = (joinAction = null) => {
    const participantName = localStorage.getItem(
      `participantName-session-${this.rootStores.callStore.sessionId}`
    );

    if (participantName && joinAction) {
      return joinAction(participantName);
    }

    this.setNameModalJoin(joinAction);
    this.setShowNameForm(!this.showNameForm);
  };

  toggleShowEditNameForm = () => {
    this.setShowEditNameForm(!this.showEditNameForm);
  };

  @action
  setNameModalJoin(value) {
    this.nameModalJoin = value;
  }

  toggleShowHostLogin = () => {
    this.setShowHostLogin(!this.showHostLogin);
    Amplitude.track("clicked 'host log in' link on foyer");
  };

  @action
  setShowHostLogin(value) {
    this.showHostLogin = value;
  }

  clickJoinInNameModal = (...args) => {
    const participantName = args[0];
    if (participantName) {
      localStorage.setItem(
        `participantName-session-${this.rootStores.callStore.sessionId}`,
        participantName
      );
    }

    if (this.nameModalJoin) {
      this.nameModalJoin(...args);
      this.toggleShowNameForm();
    }
  };

  clickLeaveCall = () => {
    Amplitude.track("Clicked Leave");
    this.rootStores.callStore.clickExit();
  };

  toggleSideMenu = () => {
    const { participantName } = this.rootStores.callStore;

    if (this.hideSideMenu) {
      this.setHideSideMenu(false);
      Amplitude.track("Show side menu", {
        display_name: participantName,
        url: window.location.href,
      });
    } else {
      this.setHideSideMenu(true);
      Amplitude.track("Hide side menu", {
        display_name: participantName,
        url: window.location.href,
      });
    }
    this.recalculateTiles();
  };

  forceHideSideMenu = () => {
    this.setHideSideMenu(true);
    this.hideDocumentsList();
    this.recalculateTiles();
  };

  forceHideSideMenuIfInCall = () => {
    if (this.rootStores.officeStore.inACall) {
      this.forceHideSideMenu();
    }
  };

  forceShowSideMenu = () => {
    this.setHideSideMenu(false);
    this.recalculateTiles();
  };

  recalculateTiles = () => {
    if (this.rootStores.tilesStore.recalculateTileSizes) {
      this.rootStores.tilesStore.recalculateTileSizes();
    }
  };

  toggleSideMenuCollapsedByFullScreen = () => {
    this.sideMenuCollapsedByFullScreen = !this.sideMenuCollapsedByFullScreen;
  };

  showErrorToast = (errorMessage, leaveCall = false) => {
    toast(
      ({ closeToast }) => (
        <div className="has-text-centered">
          <FaExclamationCircle size={50} />
          <h1>Oops.</h1>
          <p>
            <strong>Something went wrong.</strong> We've been notified. Feel
            free to retry.
          </p>
          <br />
          <p>
            <small>
              <em>{`${errorMessage}`}</em>
            </small>
          </p>
        </div>
      ),
      { autoClose: 120000 }
    );
  };

  showMessageToast = (message, toastConfig = {}) => {
    toast(
      () => (
        <div>
          <h1 className="title is-6">Oops</h1>
          <p>{message}</p>
        </div>
      ),
      toastConfig
    );
  };

  showOfflineToast = (e) => {
    toast(
      ({ closeToast }) => (
        <div className="has-text-centered">
          <FaExclamationCircle size={50} />
          <h1>Offline</h1>
          <p>
            Can't reach the internet. Please check your connection and try
            again.
          </p>
        </div>
      ),
      { autoClose: 120000 }
    );
  };

  showNotVerifiedModal = () => {
    this.setGeneralNotification({
      bodyText: <NotVerifiedModal />,
    });
  };

  showStartSubscriptionModal = () => {
    this.setGeneralNotification({
      bodyText: <StartSubscriptionModal />,
    });
  };

  @computed
  get skipAnimation() {
    return this.rootStores.appStore.isIpad || this.rootStores.appStore.isSafari;
  }

  @action
  setShowBanModal = (value) => {
    this.showBanModal = value;
  };

  @action
  hideBanModal = () => {
    this.showBanModal = false;
  };

  @action
  setBanModalContent = (content) => {
    this.banModalContent = content;
  };

  @action
  setCallContentSize = (size) => {
    this.callContentSize = size;
  };

  @action
  showDocumentsList = () => {
    this.showingDocumentsList = true;
  };

  @action
  hideDocumentsList = () => {
    this.showingDocumentsList = false;
  };

  @action
  toggleDocumentsList = () => {
    this.showingDocumentsList = !this.showingDocumentsList;
  };
}
