import { CometChat } from "@cometchat-pro/chat";

import * as enums from '../../util/enums.js';

export class MessageListManager {

  item = {};
  type = "";
  parentMessageId = null;
  messageRequest = null;
  msgListenerId = "message_" + new Date().getTime();
  groupListenerId = "group_" + new Date().getTime();
  callListenerId = "call_" + new Date().getTime();

  constructor(item, type, parentMessageId) {

    this.item = item;
    this.type = type;
    this.parentMessageId = parentMessageId;

    const caterogies = "message";
    const limit = 10;

    if (type === "user") {

      if (this.parentMessageId) {
        this.messageRequest = new CometChat.MessagesRequestBuilder()
          .setUID(item.uid)
          .setParentMessageId(this.parentMessageId)
          .setCategory(caterogies)
          .setLimit(limit)
          .build();
      } else {
        this.messageRequest = new CometChat.MessagesRequestBuilder()
          .setUID(item.uid)
          .hideReplies(true)
          .setCategory(caterogies)
          .setLimit(limit)
          .build();
      }
    } else if (type === "group") {
      if (this.parentMessageId) {
        this.messageRequest = new CometChat.MessagesRequestBuilder()
          .setGUID(item.guid)
          .setParentMessageId(this.parentMessageId)
          .setCategory(caterogies)
          .setLimit(limit)
          .build();
      } else {
        this.messageRequest = new CometChat.MessagesRequestBuilder()
          .setGUID(item.guid)
          .hideReplies(true)
          .setCategory(caterogies)
          .setLimit(limit)
          .build();
      }
    }
  }

  fetchPreviousMessages() {
    return this.messageRequest.fetchPrevious();
  }

  attachListeners(callback) {

    CometChat.addMessageListener(
      this.msgListenerId,
      new CometChat.MessageListener({
        onTextMessageReceived: textMessage => {
          callback(enums.TEXT_MESSAGE_RECEIVED, textMessage);
        },
        onMediaMessageReceived: mediaMessage => {
          callback(enums.MEDIA_MESSAGE_RECEIVED, mediaMessage);
        },
        onCustomMessageReceived: customMessage => {
          callback(enums.CUSTOM_MESSAGE_RECEIVED, customMessage);
        },
        onMessagesDelivered: messageReceipt => {
          callback(enums.MESSAGE_DELIVERED, messageReceipt);
        },
        onMessagesRead: messageReceipt => {
          callback(enums.MESSAGE_READ, messageReceipt);
        },
        onMessageDeleted: deletedMessage => {
          callback(enums.MESSAGE_DELETED, deletedMessage);
        },
        onMessageEdited: editedMessage => {
          callback(enums.MESSAGE_EDITED, editedMessage);
        }
      })
    );

    CometChat.addCallListener(
      this.callListenerId,
      new CometChat.CallListener({
        onIncomingCallReceived: call => {
          callback(enums.INCOMING_CALL_RECEIVED, call);
        },
        onOutgoingCallRejected: call => {
          callback(enums.OUTGOING_CALL_REJECTED, call);
        },
        onIncomingCallCancelled: call => {
          callback(enums.INCOMING_CALL_CANCELLED, call);
        }
      })
    );
  }

  removeListeners() {

    CometChat.removeMessageListener(this.msgListenerId);
    CometChat.removeGroupListener(this.groupListenerId);
    CometChat.removeCallListener(this.callListenerId);
  }
}