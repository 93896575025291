import { useState } from "react";
import { observer } from "mobx-react-lite";
import ChangePhoto from "../../ChangePhoto/ChangePhoto";
import { useStores } from "stores/utils";
import { useQueryClient } from "react-query";
import { Link } from "@reach/router";
import { pwaProbablySupported } from "../../../utilities/pwa";

function PhotoUploadStep({ skip }) {
  const { api, userStore, appStore } = useStores();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [nowCropping, setNowCropping] = useState(false);
  const queryClient = useQueryClient();

  const uploadPhoto = async (croppedImage) => {
    await api.uploadProfilePhoto(croppedImage);
    await userStore.refetchUser();
    await queryClient.invalidateQueries("office/officeSummary");
    setUpdateSuccess(true);
  };

  return (
    <div className="office-onboarding-profile-photo">
      <div className="content">
        {updateSuccess ? (
          <>
            <h4 className="modal-title">Here's your new profile photo!</h4>
            <div className="change-photo-container">
              <img src={userStore.userInfo.big_photo_url} alt="Uploaded" />
            </div>
            <div className="actions">
              {pwaProbablySupported ? (
                appStore.showingInstallPromotion ? (
                  <>
                    <h3>You're ready to go!</h3>
                    <div
                      className="button is-link with-pwa-support onboarding-button"
                      onClick={() => appStore.installPWA()}
                    >
                      Launch the app
                    </div>
                    <div>
                      <Link to="/office" className="with-pwa-support">
                        Use in my browser for now
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    {/* to do: need to open the app */}
                    <h3>You're ready to go!</h3>
                    <Link
                      to="/office"
                      className="button is-link onboarding-button"
                    >
                      Done
                    </Link>
                  </>
                )
              ) : (
                <>
                  <h3>You're ready to go!</h3>
                  <Link
                    to="/office"
                    className="button is-link onboarding-button"
                  >
                    Explore Sidebar
                  </Link>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <h2 className="modal-title">
              {nowCropping ? "Crop your photo" : "Add a profile picture"}
            </h2>
            <div className="change-photo-container">
              <ChangePhoto
                doUpload={uploadPhoto}
                oldPhotoUrl={userStore.userInfo.big_photo_url}
                setNowCropping={setNowCropping}
              />
            </div>
          </>
        )}
      </div>
      {!updateSuccess ? (
        !pwaProbablySupported ? (
          <div className="skip-button">
            <Link to="/office" className="button is-ghost">
              Skip
            </Link>
          </div>
        ) : (
          <div className="skip-button">
            <div className="button is-ghost" onClick={() => skip()}>
              Skip
            </div>
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
}

export default observer(PhotoUploadStep);
