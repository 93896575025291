import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStores } from "stores/utils";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import "tippy.js/dist/tippy.css";
import "./SpaceRow.scss";

export default observer(function SpaceRow(props) {
  const { permissionsStore } = useStores();

  return (
    <tr className="space-row">
      <td>{props.session.attributes.name}</td>
      <td>{props.session.attributes["created-by"]}</td>
      {permissionsStore.canManageEvent(props.session) && (
        <td>
          {/*
          <button
            onClick={() => {
              openMembersModal(props.session);
            }}
            className="members-button button button-primary"
          >
            Members
          </button>
          */}
          {/* eslint-disable-next-line */}
          <a onClick={() => props.deleteCall(props.session.id)}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </a>
        </td>
      )}
    </tr>
  );
});
