import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import ParticipantCaret from "../ParticipantCaret/ParticipantCaret";

const MicParticipantList = () => {
  const { callStore, appStore } = useStores();

  return (
    <div>
      <ul>
        {callStore.audioCallItems.map((callItem) => {
          let sidebarParticipant = callItem.sidebarParticipant;
          if (sidebarParticipant) {
            let sidebarParticipantName = sidebarParticipant.name;
            return (
              <li key={sidebarParticipant.id}>
                <ParticipantCaret
                  participant={callItem.sidebarParticipant}
                  officeMode={appStore.officeMode}
                >
                  {(toggleOpen, isOpen) => {
                    return (
                      <button
                        className="button is-text-clear-bg is-text"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu7"
                        onClick={() => toggleOpen()}
                      >
                        <span>{sidebarParticipantName}</span>
                      </button>
                    );
                  }}
                </ParticipantCaret>
              </li>
            );
          } else {
            return "";
          }
        })}
      </ul>
    </div>
  );
};

export default observer(MicParticipantList);
