import { useCallback } from "react";
import { useStores } from "../stores/utils";
import * as Amplitude from "utilities/amplitude";
import { isAModifierKey, isTyping } from "utilities/dom";
import { useEventListener } from "./useEventListener";

export function useFullscreen({ fullscreenRef, disabled }) {
  const { viewStore, tilesStore } = useStores();

  const adjustSideMenu = useCallback(() => {
    viewStore.toggleSideMenu();
    viewStore.toggleSideMenuCollapsedByFullScreen();
  }, [viewStore]);

  const restoreUserView = useCallback(() => {
    if (viewStore.sideMenuCollapsedByFullScreen) {
      adjustSideMenu();
    }
    if (tilesStore.hideTiles) tilesStore.toggleHideTiles();
    if (viewStore.isFullScreening) viewStore.toggleIsFullScreening();
  }, [viewStore, tilesStore, adjustSideMenu]);

  const enterFullscreen = useCallback(() => {
    if (!fullscreenRef.current) return;
    const elementToExpand = fullscreenRef.current;
    if (elementToExpand.requestFullscreen) {
      if (!viewStore.hideSideMenu) {
        adjustSideMenu();
      }
      tilesStore.toggleHideTiles();
      elementToExpand.requestFullscreen();
    }
  }, [tilesStore, viewStore, fullscreenRef, adjustSideMenu]);

  const exitFullscreen = useCallback(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      if (viewStore.sideMenuCollapsedByFullScreen) {
        adjustSideMenu();
      }
      tilesStore.toggleHideTiles();
    }
  }, [viewStore, tilesStore, adjustSideMenu]);

  const exitFullscreenOnEsc = useCallback(() => {
    const fullscreenElement =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullscreenElement ||
      document.msFullscreenElement;

    !fullscreenElement && restoreUserView();
  }, [restoreUserView]);

  const toggleFullscreen = useCallback(() => {
    if (viewStore.isFullScreening) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
    viewStore.toggleIsFullScreening();
    Amplitude.track("Toggled Fullscreen");
  }, [viewStore, exitFullscreen, enterFullscreen]);

  const toggleFullscreenOnF = useCallback(
    (e) => {
      e.stopPropagation();
      if (disabled) return;
      const notF = isTyping(e) || isAModifierKey(e) || e.keyCode !== 70;
      if (notF) return;

      toggleFullscreen();
    },
    [toggleFullscreen, disabled]
  );

  useEventListener("keydown", toggleFullscreenOnF, document);
  useEventListener("fullscreenchange", exitFullscreenOnEsc, document);
  useEventListener("webkitfullscreenchange", exitFullscreenOnEsc, document);
  useEventListener("mozfullscreenchange", exitFullscreenOnEsc, document);
  useEventListener("MSFullscreenChange", exitFullscreenOnEsc, document);

  return toggleFullscreen;
}
