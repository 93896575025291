import { useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useInfiniteQuery } from "react-query";
import { useStores } from "../../stores/utils";
import { useVirtual } from "react-virtual";
import PersonRow from "./PersonRow";
import "./Modal.scss";

function SpaceMembersInfinite({ sessionId }) {
  const rootStores = useStores();
  const { api } = rootStores;

  const getSessionMembers = ({ queryKey, pageParam = 1 }) => {
    return api.getSessionMembers(queryKey[1], pageParam);
  };

  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(["sessionMembers", sessionId], getSessionMembers, {
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.links?.next;
      if (nextPage) {
        const regex = /page%5Bnumber%5D=(\d+)&/;
        const match = nextPage.match(regex);
        return match[1];
      }
      return false;
    },
  });

  const flatPosts = data ? data.pages.map((p) => p.included).flat() : [];

  const parentRef = useRef();

  const rowVirtualizer = useVirtual({
    size: hasNextPage ? flatPosts.length + 1 : flatPosts.length,
    parentRef,
    estimateSize: useCallback(() => 30, []),
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index === flatPosts.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    flatPosts.length,
    isFetchingNextPage,
    rowVirtualizer.virtualItems,
  ]);

  return (
    <div className="modal-padding space-members-modal">
      <h4>Members</h4>
      {status === "loading" ? (
        <p>Loading...</p>
      ) : status === "error" ? (
        <span>Error: {error.message}</span>
      ) : (
        <div
          ref={parentRef}
          className="List"
          style={{
            height: `300px`,
            width: `300px`,
            overflow: "auto",
          }}
        >
          <div
            className="ListInner"
            style={{
              height: `${rowVirtualizer.totalSize}px`,
              width: "100%",
              position: "relative",
            }}
          >
            {rowVirtualizer.virtualItems.map((virtualRow) => {
              const isLoaderRow = virtualRow.index > flatPosts.length - 1;
              const person = flatPosts[virtualRow.index];
              return (
                <div
                  key={virtualRow.index}
                  className={
                    virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven"
                  }
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${
                      virtualRow.start + virtualRow.index * 4
                    }px)`,
                  }}
                >
                  {isLoaderRow
                    ? hasNextPage
                      ? "Loading more..."
                      : "Nothing more to load"
                    : person && <PersonRow person={person} />}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default observer(SpaceMembersInfinite);
