import { observer } from "mobx-react-lite";
import ReactModal from "react-modal";
import { MdClose } from "react-icons/md";
import JoinSpaces from "../JoinSpaces/JoinSpaces";
import "./JoinSpacesModal.scss";

function JoinSpacesModal({ handleCloseModal, showingModal }) {
  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ReactModal
      isOpen={showingModal}
      overlayClassName="ReactModal__Overlay__disabled"
      className="ReactModal__Content__modal "
      onRequestClose={() => closeModal()}
    >
      <div className="modal-wrapper">
        <div className="modal-close-wrap" onClick={handleCloseModal}>
          <MdClose size={20} />
        </div>

        <div className="content">
          <div className="join-spaces-modal">
            <>
              <h2 className="modal-title">Manage spaces</h2>
              <p>
                Spaces are where teammates gather. Join to see when colleagues
                are talking and be able to jump in.
              </p>
              <div className="join-spaces-container">
                <JoinSpaces />
              </div>
              <div className="join-spaces-actions">
                <button
                  className="button is-buttongrey"
                  onClick={() => closeModal()}
                >
                  Done
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default observer(JoinSpacesModal);
