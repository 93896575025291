export const isOffscreen = (el) => {
  const rect = el.getBoundingClientRect();
  // if one of these true => then the element is offscreen;
  return (
    rect.x + rect.width < 0 ||
    rect.y + rect.height < 0 ||
    rect.x > window.innerWidth ||
    rect.y > window.innerHeight
  );
};

export const offscreenMsgObserver = (i, ref, store) => {
  if (!ref.current) return null;

  const observer = new IntersectionObserver((entries) => {
    const {
      lastOffscreenReference,
      firstOffscreenReference,
      setOffscreenNotifRef,
      setShowingOffscreenNotif,
      offscreenReferenceLength,
    } = store;

    // helper function
    const setRef = (entry, pos) => {
      setOffscreenNotifRef(ref, pos);
      if (entry.isIntersecting) {
        setShowingOffscreenNotif(false, pos);
      } else {
        setShowingOffscreenNotif(true, pos);
      }
    };

    // helper function
    const killRef = (pos) => {
      setOffscreenNotifRef(undefined, pos);
      setShowingOffscreenNotif(false, pos);
    };

    // helper function
    const manageSingleRef = (isPosTop, entry) => {
      if (isPosTop) {
        killRef("bottom");
        setRef(entry, "top");
      } else {
        killRef("top");
        setRef(entry, "bottom");
      }
    };

    // helper function
    const manageMultipleRefs = (isPosTop, entry) => {
      if (i === firstOffscreenReference) {
        if (isPosTop) {
          setRef(entry, "top");
        } else {
          killRef("top");
        }
      } else if (i === lastOffscreenReference) {
        if (isPosTop) {
          killRef("bottom");
        } else {
          setRef(entry, "bottom");
        }
      }
    };

    // this is the main thing
    entries.forEach((entry) => {
      const isPosTop = entry.boundingClientRect.top < window.innerHeight;
      if (offscreenReferenceLength === 1) {
        manageSingleRef(isPosTop, entry);
      } else if (offscreenReferenceLength > 1) {
        manageMultipleRefs(isPosTop, entry);
      }
    });
  });

  return observer;
};

export const isAModifierKey = (e) => {
  const isAModifierKey =
    e.altKey ||
    e.ctrlKey ||
    e.metaKey ||
    e.shiftKey ||
    e.key === "Meta" ||
    e.key === "Shift" ||
    e.key === "Control" ||
    e.key === "Alt";

  return isAModifierKey;
};

export const isTyping = (e) => {
  const focusingInputField = e.target.tagName === "INPUT";
  const focusingContentEditable = e.target.contentEditable === "true";
  return focusingContentEditable || focusingInputField;
};

export const isValidUrl = (url) => {
  try {
    url = new URL(url);
  } catch (err) {
    return undefined;
  }
  return url;
};
