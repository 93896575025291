import { computed, observable, makeObservable } from "mobx";

export class TileModel {
  @observable.ref callItem;

  constructor({ rootStores, callItem }) {
    this.rootStores = rootStores;
    this.callItem = callItem;

    makeObservable(this);
  }

  @computed
  get isLoading() {
    return (
      this.callItem.isLoading &&
      (!this.callItem.sidebarParticipant ||
        this.callItem.sidebarParticipant.videoOn)
    );
  }

  @computed
  get isLarge() {
    const screenMode = this.rootStores.callStore.clusterContainsScreen;

    return this.isScreen || !screenMode;
  }

  @computed
  get isScreen() {
    return this.callItem.isScreen;
  }

  @computed
  get key() {
    return this.callItem.listKey;
  }
}
