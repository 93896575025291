import { useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import ReactModal from "react-modal";
import { MdClose } from "react-icons/md";
import { useStores, observe } from "stores/utils";

import "components/SignupForm/CapModal.scss";

export const ChangeCapModal = ({ handleCloseModal }) => {
  const { subscriptionsStore, userSubscriptionStore } = useStores();

  useEffect(() => {
    subscriptionsStore.changeSubscription(
      userSubscriptionStore.plan?.attributes?.name
    );
    subscriptionsStore.changeInterval(
      userSubscriptionStore.plan?.attributes?.interval
    );
    subscriptionsStore.initializeCap();
  }, [subscriptionsStore, userSubscriptionStore]);

  const generateOptions = () => {
    const includedHours =
      subscriptionsStore.currentSubscription?.attributes?.included_hours;

    let options = Array.apply(null, Array(20)).map((_, index) => ({
      value: includedHours + index * 50,
      label: `${includedHours} + ${
        index *
        subscriptionsStore.currentSubscription?.attributes
          ?.overage_hours_lot_size
      } Hours`,
    }));

    options.unshift({ value: null, label: "No cap" });
    return options;
  };

  const upgradeSubscription = () => {
    userSubscriptionStore.updateCap().then(() => handleCloseModal());
  };

  return (
    <ReactModal
      isOpen
      overlayClassName="ReactModal__Overlay__disabled"
      className="ReactModal__Content__modal"
    >
      {observe(subscriptionsStore.isFetching, () =>
        subscriptionsStore.isFetching ? null : (
          <div className="modal-wrapper">
            <div className="modal-close-wrap" onClick={handleCloseModal}>
              <MdClose size={20} />
            </div>
            <div className="modal-header">Change cap</div>
            <div className="modal-content">
              <div
                className="subscription-container"
                style={{ marginBottom: 10 }}
              >
                <div>
                  <div className="subscription-item">
                    <div className="subscription-item__title">Set a cap</div>
                    <div className="subscription-item__content">
                      {observe(
                        subscriptionsStore.selectedCap,
                        () => {
                          return (
                            <Select
                              value={{
                                value: subscriptionsStore.selectedCap,
                                label:
                                  subscriptionsStore.selectedCap === null
                                    ? "No cap"
                                    : `${subscriptionsStore.selectedCap} Hours`,
                              }}
                              label="Single select"
                              options={generateOptions()}
                              onChange={(option) =>
                                subscriptionsStore.setCap(option.value)
                              }
                            />
                          );
                        },
                        true
                      )}
                    </div>
                    <div className="subscription-item__subtitle">
                      You can cap your hours at a preselected amount, or be
                      billed automatically for additional usage. If capped, your
                      events will end when the limit is reached. We'll alert you
                      when you’re nearing your cap.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {observe(subscriptionsStore.isPlanChanged, () =>
              subscriptionsStore.isPlanChanged ? (
                <div className="subscription-message">
                  Your plan changes will take effect in the next period.
                </div>
              ) : null
            )}

            <div className="modal-actions">
              {observe(userSubscriptionStore.isUpdatingSubscription, () => (
                <button
                  onClick={upgradeSubscription}
                  className="subscription__submit-button"
                  disabled={userSubscriptionStore.isUpdatingSubscription}
                >
                  Confirm
                </button>
              ))}
            </div>
          </div>
        )
      )}
    </ReactModal>
  );
};

ChangeCapModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};
