import { action, computed, makeAutoObservable, observable } from "mobx";
import { httpService } from "utilities/HttpService";

const SUBSCRIPTIONS_ORDER = ["standard", "plus", "pro", "personal"];

export class SubscriptionsStore {
  @observable subscriptions = [];
  @observable selectedPlan = null;
  @observable selectedInterval = "month";
  @observable selectedCap = null;
  @observable isFetching = false;
  @observable coupon = null;
  @observable isCouponValid = null;

  constructor(rootStores) {
    this.rootStores = rootStores;
    this.selectedPlan =
      localStorage.getItem("subscription-selected-plan") || null;
    makeAutoObservable(this);
  }

  sortSubscriptions = (subscriptions) => {
    return SUBSCRIPTIONS_ORDER.reduce((result, subscriptionName) => {
      const subscrs = subscriptions.filter(
        (subscription) =>
          subscription.attributes?.name?.toLowerCase() === subscriptionName
      );
      return result.concat(subscrs);
    }, []);
  };

  @action
  getSubscriptions = async () => {
    this.isFetching = true;
    try {
      const { data } = await httpService.get("/api/v1/subscription_plans");

      this.subscriptions = this.sortSubscriptions(data.data);
      this.initializeCap();

      return data.data;
    } catch (err) {
      console.log(err, "error while getting subscriptions");
    } finally {
      this.isFetching = false;
    }
  };

  @action
  checkCoupon = async (coupon) => {
    try {
      const { data } = await httpService.get(
        `/api/v1/subscription_promo_codes/${coupon}?plan_name=${this.currentSubscription?.attributes?.name}&interval=${this.selectedInterval}`
      );

      this.coupon = data;
      this.isCouponValid = true;
    } catch (err) {
      this.coupon = null;
      this.isCouponValid = false;
    }
  };

  @action
  setInitialCap = () => {
    this.selectedCap = null;
  };

  @action
  setCap = (cap) => {
    console.log("setting cap to: ", cap);
    this.selectedCap = cap;
  };

  initializeCap = () => {
    const currentCap = this.rootStores.userSubscriptionStore.subscription?.data
      ?.attributes?.hours_cap;
    if (currentCap) {
      console.log("setting selected cap to: ", currentCap);
      this.setCap(currentCap);
    } else {
      console.log("Setting initial cap (none)");
      this.setInitialCap();
    }
  };

  @action
  changePlan = (planId) => {
    const plan = this.getPlanById(planId);
    localStorage.setItem(
      "subscription-selected-plan",
      plan?.attributes?.name?.toLowerCase()
    );
    this.selectedInterval = plan?.attributes?.interval;
    this.selectedPlan = plan?.attributes?.name?.toLowerCase();
    this.setInitialCap();
  };

  @action
  changeSubscription = (subscriptionName) => {
    this.selectPlan(subscriptionName.toLowerCase());

    this.setInitialCap();
  };

  @action
  changeInterval = (interval) => {
    this.selectedInterval = interval;

    this.setInitialCap();
  };

  @action
  selectPlan = (planName) => {
    this.selectedPlan = planName;
    localStorage.setItem("subscription-selected-plan", this.selectedPlan);
  };

  @computed
  get currentSubscription() {
    return this.subscriptions.find(
      (subscription) =>
        subscription.attributes.name.toLowerCase() === this.selectedPlan &&
        subscription.attributes.interval === this.selectedInterval
    );
  }

  @computed
  get currentYearSubscription() {
    return this.subscriptions.find(
      (subscription) =>
        subscription.attributes.name.toLowerCase() === this.selectedPlan &&
        subscription.attributes.interval === "year"
    );
  }

  @computed
  get currentMonthSubscription() {
    return this.subscriptions.find(
      (subscription) =>
        subscription.attributes.name.toLowerCase() === this.selectedPlan &&
        subscription.attributes.interval === "month"
    );
  }

  @computed
  get annualSaving() {
    const annualPrice = this.currentYearSubscription?.attributes?.price;
    const monthPrice = this.currentMonthSubscription?.attributes?.price;
    const annualMonthPrice = monthPrice * 12;

    return annualMonthPrice - annualPrice;
  }

  @computed
  get currentPrice() {
    const price = this.currentSubscription?.attributes?.price;

    if (this.coupon && this.isCouponValid) {
      return price * ((100 - this.coupon.percent_off) / 100);
    }

    return price;
  }

  @computed
  get subscriptionPrice() {
    return this.currentSubscription?.attributes?.price;
  }

  @computed
  get isPlanChanged() {
    return (
      this.currentSubscription?.attributes?.name !==
        this.rootStores.userSubscriptionStore.plan?.attributes?.name ||
      this.currentSubscription?.attributes?.interval !==
        this.rootStores.userSubscriptionStore.plan?.attributes?.interval
    );
  }

  getPlanById = (id) => {
    return this.subscriptions.find((subscription) => subscription.id === id);
  };
}
