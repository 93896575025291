import { useState } from "react";
import { useStores } from "stores/utils";
import "./SubscriptionForm.scss";
import "./Cards.scss";
import { ChangePlanModal } from "./ChangePlanModal";

export const SubscriptionForm = ({ onSubmit }) => {
  const { subscriptionsStore } = useStores();
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);

  return (
    <div className="subscription-container subscription-card">
      <h1 className="title">Your plan</h1>
      <div className="subscription-row">
        <div className="subscription-column">
          <div className="subscription-column-title">
            {subscriptionsStore.currentSubscription?.attributes?.name} -{" "}
            {subscriptionsStore.currentSubscription?.attributes?.included_hours}{" "}
            included hours
          </div>
          <div className="subscription-column-info">
            $50 for 50 additional hours -{" "}
            {subscriptionsStore.selectedCap === null
              ? "no cap"
              : `capped at ${subscriptionsStore.selectedCap} hours`}
          </div>
        </div>
        <div className="subscription-column">
          <div
            className="subscription-link-button"
            onClick={() => setShowChangePlanModal(true)}
          >
            Change plan
          </div>
        </div>
      </div>
      {showChangePlanModal && (
        <ChangePlanModal
          handleCloseModal={() => setShowChangePlanModal(false)}
        />
      )}
    </div>
  );
};
