import { useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { observer } from "mobx-react-lite";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { FaMicrophone } from "react-icons/fa";
import { useStores } from "stores/utils";
import RedButton from "components/RedButton/RedButton";

import chromeMacInstructions from "images/help/enable-cam-chrome-mac.png";
import safariMacInstructions from "images/help/enable-cam-safari-mac.png";
import edgePcInstructions from "images/help/enable-cam-edge-pc.png";
import firefoxInstructions1 from "images/help/enable-cam-ff-pc.png";
import permissionCheckboxFF from "images/help/enable-cam-ff-pc-2.png";
import enableCamMacSystem from "images/help/enable-cam-mac-system.png";
import safariIosInstructions from "images/help/enable-cam-safari-ios.png";
import safariIos2Instructions from "images/help/enable-cam-safari-ios-2.png";
import iosSettings from "images/help/iOS-Settings-icon.png";

const customSelectStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  control: (styles) => ({
    ...styles,
    border: "none",
    backgroundColor: "#fceff1",
    padding: "0px 15px",
    borderRadius: "8px",
    outline: "none",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#bdb6b7",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "#363434",
  }),
  container: (styles) => ({
    ...styles,
    outline: "none",
    width: "90%",
  }),
};

const CallPreview = ({ onJoin, videoOffMessage }) => {
  const { callPreviewStore, appStore } = useStores();

  const onRoomJoin = () => {
    callPreviewStore.stopAndCleanup();
    onJoin();
  };

  useEffect(() => {
    if (!callPreviewStore.hasGetUserMedia()) {
      return;
    }

    if (!callPreviewStore.hasUserMedia) {
      callPreviewStore.requestUserMedia();
    }
  }, [callPreviewStore]);

  useEffect(() => {
    return () => {
      callPreviewStore.stopAndCleanup();
    };
  }, [callPreviewStore]);

  const renderError = () => {
    if (callPreviewStore.deniedBySystemError && appStore.isMac) {
      return (
        <div className="preview-modal__permission-error">
          <p className="preview-modal__action-row">
            Open{" "}
            <a href="x-apple.systempreferences:com.apple.preference">
              System Preferences
            </a>{" "}
            —{">"} Security {"&"} Privacy and follow these instructions:
          </p>
          <img src={enableCamMacSystem} alt="ff1" />
        </div>
      );
    }

    if (appStore.isChrome) {
      return (
        <div className="preview-modal__permission-error">
          <img src={chromeMacInstructions} alt="chrome" />
          <p className="preview-modal__action-row">
            Then you'll be prompted to <a href={window.location.href}>Reload</a>
          </p>
        </div>
      );
    } else if (appStore.isIOS) {
      return (
        <div className="preview-modal__permission-error">
          <p className="preview-modal__action-row">
            Try <a href={window.location.href}>refreshing</a> Sidebar. If
            prompted, allow Sidebar to use your camera and microphone.
          </p>
          <p className="preview-modal__action-row">
            If there’s no prompt, open your iPad's{" "}
            <img
              className="ios-settings"
              src={iosSettings}
              alt="ios-settings"
            />{" "}
            <strong>Settings</strong> app and click <strong>Safari</strong>:
          </p>
          <img src={safariIosInstructions} alt="chrome" />
          <p className="preview-modal__action-row">
            Click <strong>Allow</strong> for both the Camera and Microphone.
          </p>
          <img src={safariIos2Instructions} alt="chrome" />
          <p className="preview-modal__action-row">
            Then <a href={window.location.href}>Refresh </a>
            Sidebar
          </p>
        </div>
      );
    } else if (appStore.isSafari) {
      return (
        <div className="preview-modal__permission-error">
          <p className="preview-modal__action-row">
            Try <a href={window.location.href}>refreshing</a> Sidebar. If
            prompted, allow Sidebar to use your camera and microphone.
          </p>
          <p className="preview-modal__action-row">
            If there’s no prompt, try the following:
          </p>
          <img src={safariMacInstructions} alt="safari" />
          <p className="preview-modal__action-row">
            Then <a href={window.location.href}>Refresh </a>
            the page
          </p>
        </div>
      );
    } else if (appStore.isFirefox) {
      return (
        <div className="preview-modal__permission-error">
          <img src={firefoxInstructions1} alt="ff1" />
          <p className="preview-modal__action-row">
            Then <a href={window.location.href}>Reload the page</a>.
          </p>
          {appStore.isMac && (
            <p className="preview-modal__action-row">
              Also, make sure your camera and microphone are connected and that
              Firefox has access to your camera in{" "}
              <a href="x-apple.systempreferences:com.apple.preference">
                System Preferences
              </a>
              .{" "}
              <a
                className="modal-actions__troubleshoot"
                href="https://www.notion.so/Troubleshooting-7cfde550f53441f1ac59c91d9828ad15"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </p>
          )}
        </div>
      );
    } else if (appStore.isEdge) {
      return (
        <div className="preview-modal__permission-error">
          <img src={edgePcInstructions} alt="edge" />
          <p className="preview-modal__action-row">
            Then you'll be prompted to <a href={window.location.href}>Reload</a>
          </p>
        </div>
      );
    }
  };

  const renderAllowMessage = () => {
    if (appStore.isFirefox) {
      return (
        <div className="preview-modal__permission-error">
          <img src={permissionCheckboxFF} alt="ff1" />
        </div>
      );
    } else {
      return (
        <div className="preview-modal__pending-permissions">
          Allow Sidebar to access your camera and microphone
        </div>
      );
    }
  };

  const renderContent = () => {
    return (
      <>
        {!callPreviewStore.src && (
          <div className="preview-modal__pending-permissions">
            <div className="preview-modal__spinner"></div>
          </div>
        )}
        <div className="preview-modal__camera-container">
          <video
            autoPlay
            src={callPreviewStore.src}
            muted={true}
            playsInline
            ref={(ref) => callPreviewStore.setVideoRef(ref)}
            style={{
              display: callPreviewStore.src ? "block" : "none",
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div className="preview-modal">
      <div className="preview-modal__title">
        {callPreviewStore.deniedBySystemError ||
        callPreviewStore.isPermissionError ? (
          "Can’t find your camera and microphone"
        ) : (
          <div>Get your camera ready</div>
        )}
      </div>
      {callPreviewStore.couldNotStartSource ? (
        <div className="preview-modal__permission-error__simple">
          <p className="preview-modal__action-row">
            The webcam is in use by another application. Please make sure no
            other applications (like Skype) are currently accessing it, then
            refresh the page.
          </p>
          <p className="preview-modal__action-row">
            If the problem continues, you can clear all access to your webcams
            by restarting your computer.
          </p>
        </div>
      ) : (
        <div className="preview-modal__content">
          {callPreviewStore.pendingPermission
            ? renderAllowMessage()
            : callPreviewStore.isPermissionError
            ? renderError()
            : renderContent()}
        </div>
      )}
      {!callPreviewStore.isPermissionError && (
        <div className="preview-modal__selects">
          <div className="preview-modal__selects__select-wrapper">
            <BsFillCameraVideoFill />
            <Select
              styles={customSelectStyles}
              options={callPreviewStore.cameraDevicesOptions}
              placeholder="Select a camera"
              value={callPreviewStore.selectedCameraOption}
              onChange={(option) =>
                callPreviewStore.changeVideoDevice(option.value)
              }
            />
          </div>
          <div className="preview-modal__selects__select-wrapper">
            <FaMicrophone />
            <Select
              styles={customSelectStyles}
              options={callPreviewStore.audioDevicesOptions}
              placeholder="Select a microphone"
              value={callPreviewStore.selectedAudioOption}
              onChange={(option) =>
                callPreviewStore.changeAudioDevice(option.value)
              }
            />
          </div>
        </div>
      )}
      <div className="preview-modal__actions">
        <RedButton handleClick={onRoomJoin} label="Join the chat" />
      </div>
      {videoOffMessage && (
        <p>
          You will join with your camera <strong>OFF</strong>.
        </p>
      )}
    </div>
  );
};

CallPreview.propTypes = {
  onJoin: PropTypes.func.isRequired,
};

export default observer(CallPreview);
