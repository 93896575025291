import { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import UserMeetingRow from "./UserMeetingRow";
import { useMutation } from "react-query";
import { useStores } from "../../stores/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus } from "@fortawesome/free-regular-svg-icons";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import RoomsListCurrentCall from "./RoomsListCurrentCall";
import { partition } from "lodash";

const hasActiveParticipants = (userMeeting) => {
  return userMeeting.meeting.groups.find(
    (group) => group.participants.length > 0
  );
};

const UserMeetingsMenuSection = ({
  privateSummaryQuery,
  scrollableContainer,
}) => {
  const { api, callStore, officeStore, userStore } = useStores();
  const [userMeetings, setUserMeetings] = useState([]);
  const moreMeetingsHeader = useRef();

  const privateSummaryData = privateSummaryQuery?.data?.data;

  const toggleMoreMeetings = () => {
    officeStore.toggleShowMoreMeetings((x) => !x);
  };

  const scrollToMoreHeader = () => {
    const yPos = moreMeetingsHeader.current.offsetTop - 55;
    scrollableContainer.current.scrollTo({ top: yPos, behavior: "smooth" });
  };

  const newMeetingMutation = useMutation(api.createMeeting, {
    onSuccess: async (data) => {
      await privateSummaryQuery.refetch();
      const newId = data?.data?.data?.id;
      setTimeout(() => {
        officeStore.scrollToNewMeetingPopup(newId, scrollToMoreHeader);
      }, 100);
    },
  });

  const createMeeting = async () => {
    try {
      const roomName = `${userStore.firstishName}'s Meeting`;
      await newMeetingMutation.mutate(roomName);
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    if (!privateSummaryData) {
      return;
    }

    const meetingsList = privateSummaryData.included.filter((x) => {
      return x.type === "sessions";
    });

    const stuffedMeetingsList = officeStore.buildSpacesList(
      meetingsList,
      privateSummaryData?.included
    );

    const meetingsMap = stuffedMeetingsList.reduce((prev, cur) => {
      cur.session.groups = cur.groups;
      prev.set(cur.session.id, cur.session);
      return prev;
    }, new Map());

    const userMeetings = privateSummaryData.included
      .filter((x) => {
        return x.type === "user-meetings";
      })
      .map((userMeeting) => {
        userMeeting.meeting = meetingsMap.get(
          userMeeting.relationships.session.data.id
        );
        return userMeeting;
      });

    setUserMeetings(userMeetings);
  }, [officeStore, privateSummaryData]);

  const userMeetingsWithoutCurrent = useMemo(() => {
    return userMeetings.filter(
      (userMeeting) => userMeeting.meeting.id !== callStore.sessionId
    );
  }, [userMeetings, callStore.sessionId]);

  useEffect(() => {
    const [activeMeetings, inactiveMeetings] = partition(
      userMeetingsWithoutCurrent,
      hasActiveParticipants
    );

    const fillerNeeded = 2 - activeMeetings.length;
    let listTop = [];
    let listTopFiller = [];
    let listRest = [];
    if (fillerNeeded > 0) {
      listTopFiller = inactiveMeetings.slice(0, fillerNeeded);
      listTop = activeMeetings.concat(listTopFiller);
      listRest = inactiveMeetings.slice(fillerNeeded);
    } else {
      listTop = activeMeetings;
      listRest = inactiveMeetings;
    }

    officeStore.setTopUserMeetings(listTop);
    officeStore.setBottomUserMeetings(listRest);
  }, [officeStore, userMeetingsWithoutCurrent]);

  return (
    <div className="menu-section">
      <div className="meetings-list">
        <div className="section-header">
          <div className="header-title">Meetings</div>
          <div className="header-right">
            <Tippy
              content={"New Meeting"}
              hideOnClick={true}
              trigger="mouseenter"
            >
              <button
                className="button is-white is-small"
                onClick={() => createMeeting()}
              >
                <span className="icon is-small">
                  <FontAwesomeIcon
                    icon={faCalendarPlus}
                    className="new-meeting-icon"
                  />
                </span>
              </button>
            </Tippy>
          </div>
        </div>
        <div>
          {userMeetings.length ? (
            <div>
              {callStore.sessionId && officeStore.sessionType === "meeting" && (
                <RoomsListCurrentCall />
              )}
              <div className="office-rooms-menu">
                <div className="top-meetings">
                  {officeStore.topUserMeetings &&
                    officeStore.topUserMeetings?.map((userMeeting) => {
                      return (
                        <UserMeetingRow
                          key={userMeeting.id}
                          userMeeting={userMeeting}
                          privateSummaryQuery={privateSummaryQuery}
                        />
                      );
                    })}
                </div>
                {officeStore.bottomUserMeetings &&
                  officeStore.bottomUserMeetings.length > 0 && (
                    <>
                      <div
                        className="more-meetings-toggle"
                        onClick={toggleMoreMeetings}
                        ref={moreMeetingsHeader}
                      >
                        <FontAwesomeIcon
                          icon={
                            officeStore.showMoreMeetings
                              ? faCaretDown
                              : faCaretRight
                          }
                        />{" "}
                        More meetings
                      </div>
                      {officeStore.showMoreMeetings && (
                        <div className="bottom-meetings" id="bottom-meetings">
                          {officeStore.bottomUserMeetings &&
                            officeStore.bottomUserMeetings?.map(
                              (userMeeting) => {
                                return (
                                  <UserMeetingRow
                                    key={userMeeting.id}
                                    userMeeting={userMeeting}
                                    privateSummaryQuery={privateSummaryQuery}
                                  />
                                );
                              }
                            )}
                        </div>
                      )}
                    </>
                  )}
              </div>
            </div>
          ) : (
            <div>
              <p className="no-meetings-placeholder">No meetings yet</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(UserMeetingsMenuSection);
