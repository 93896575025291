/* eslint-disable import/no-anonymous-default-export */
import { createAuthOptionalAxios } from "./utilities/fetch";
import { httpService } from "utilities/HttpService";
import Honeybadger from "honeybadger-js";

async function getSession(
  sessionId,
  participantId = null,
  silentlyFail = false
) {
  console.log(" !!!! API CALL !!!! - getSession ");

  let requestPath = `/api/v1/sessions/${sessionId}?include=groups,participants,participants.group`;
  if (participantId) {
    requestPath += `&participant_id=${participantId}`;
  }

  const axios = createAuthOptionalAxios();

  return axios
    .get(requestPath)
    .then((response) => {
      return parseSessionData(response.data);
    })
    .catch((error) => {
      console.log("getSession ", error);
      if (error.response && error.response.status === 404) {
        return { url: null };
      } else if (error.response && error.response.status === 403) {
        return { url: 403 };
      } else {
        console.log("Error in api getSession...");
        console.log(error.response);
        if (silentlyFail) {
          Honeybadger.notify(error);
          return;
        }
        throw error;
      }
    });
}

function parseSessionData(data) {
  let lobbyUrl = null;
  let sortedGroups = [];
  let participants = [];
  let clusters = [];

  if (data.type === "session_history") return data;

  if (data.included) {
    let groups = data.included.filter((x) => x.type === "groups");
    sortedGroups = groups.sort((a, b) =>
      a.attributes["broadcast-mode"] < b.attributes["broadcast-mode"]
        ? 1
        : a.attributes["broadcast-mode"] === b.attributes["broadcast-mode"]
        ? a.attributes.lobby < b.attributes.lobby
          ? 1
          : a.attributes.lobby === b.attributes.lobby
          ? a.attributes["created-at"] > b.attributes["created-at"]
            ? 1
            : -1
          : -1
        : -1
    );
    let lobby = sortedGroups[0];
    if (lobby) {
      lobbyUrl = lobby.attributes["daily-url"];
    }

    participants = data.included.filter((x) => x.type === "participants");
    clusters = data.included.filter((x) => x.type === "clusters");
  }

  let organizationId = null;
  if (data.data.relationships.organization.data) {
    organizationId = data.data.relationships.organization.data.id;
  }
  return {
    url: lobbyUrl,
    sessionId: data.data.id,
    groups: sortedGroups,
    participants: participants,
    clusters: clusters,
    cometChatGuid: data.data.attributes["comet-chat-guid"],
    sessionVersion: data.data.meta.version,
    organizationId: organizationId,
    data: data,
  };
}

async function createParticipant(
  sessionId,
  name,
  dailyParticipantId,
  groupId,
  joinedAt,
  videoOn,
  authToken
) {
  console.log(" !!!! API CALL !!!! - createParticipant ");
  // const csrfToken = document.querySelector("meta[name=csrf-token]").content;
  let postData = {
    data: {
      type: "participants",
      attributes: {
        name: name,
        "daily-participant-id": dailyParticipantId,
        "joined-at": joinedAt,
        "video-on": videoOn,
      },
      relationships: {
        session: {
          data: {
            id: sessionId,
            type: "sessions",
          },
        },
      },
    },
  };
  if (groupId) {
    postData.data.relationships["group"] = {
      data: {
        id: groupId,
        type: "groups",
      },
    };
  }

  const axios = createAuthOptionalAxios(authToken);
  // const axios = createAuthOptionalAxios();

  let newParticipant = axios.post(
    `/api/v1/participants?include=group`,
    postData
  );

  return newParticipant.then((response) => {
    // TODO: this needs to also set group data!
    // and parseSession shouldn't do too much!!
    const participantToken = response.data?.participant_auth_token;
    if (participantToken) {
      localStorage.setItem("participant-token", participantToken);
      axios.defaults.headers.common["X-Participant-Token"] = participantToken;
    }

    return response.data;
  });
}

async function changeRoom(
  groupId,
  dailyParticipant,
  clusterId = null,
  authToken = null
) {
  let newDailyId = "";
  if (dailyParticipant && dailyParticipant.local) {
    newDailyId = dailyParticipant.local.user_id;
  }

  console.log(
    "API API API - - - changeRoom - Setting new daily id to: ",
    newDailyId
  );

  let postData = {
    "new-daily-participant-id": newDailyId,
  };

  if (clusterId != null) {
    postData["cluster-id"] = clusterId;
  }

  const axios = createAuthOptionalAxios(authToken);

  let request = axios.post(`/api/v1/groups/${groupId}/join`, postData);

  return request.then((response) => {
    return { session: parseSessionData(response.data.session), response };
  });
}

async function updateParticipant(participantId, properties, authToken = null) {
  console.log(" !!!! API CALL !!!! - updateParticipant");

  let patchData = {
    data: {
      id: participantId,
      type: "participants",
      attributes: properties,
    },
  };

  const axios = createAuthOptionalAxios(authToken);

  let request = axios.patch(`/api/v1/participants/${participantId}`, patchData);

  return request.then((response) => {
    return response.data;
  });
}

async function getParticipant(participantId) {
  const axios = createAuthOptionalAxios();
  let request = await axios.get(`/api/v1/participants/${participantId}`);

  return request;
}

async function destroyParticipant(participantId) {
  console.log(" !!!! API CALL !!!! - destroyParticipant");
  const axios = createAuthOptionalAxios();
  return axios
    .delete(`api/v1/participants/${participantId}`)
    .then((response) => {
      return parseSessionData(response.data);
    });
}

async function createRoom({
  sessionId,
  roomName,
  invitedUserId = null,
  broadcastRoom = false,
  authToken = null,
  thriftyRoom = false,
  locked = false,
  sidebar = false,
}) {
  console.log(" !!!! API CALL !!!! - createRoom ");

  const axios = createAuthOptionalAxios(authToken);

  let groupParams = {
    data: {
      type: "groups",
      attributes: {
        name: roomName,
        "invited-user-id": invitedUserId,
      },
      relationships: {
        session: {
          data: {
            id: sessionId,
            type: "sessions",
          },
        },
      },
    },
  };

  if (broadcastRoom) {
    groupParams.data.attributes["broadcast-mode"] = true;
  }
  if (thriftyRoom) {
    groupParams.data.attributes["thrifty-mode"] = true;
  }
  if (locked) {
    groupParams.data.attributes["locked"] = true;
  }
  if (sidebar) {
    groupParams.data.attributes["sidebar"] = true;
  }

  let newGroup = await axios.post(`/api/v1/groups`, groupParams);

  let session = parseSessionData(newGroup.data.session);
  session.newRoomId = newGroup.data.new_room_id;
  session.newRoomDailyUrl = newGroup.data.new_room_daily_url;
  return session;
}

async function updateRoomName(groupId, newName, authToken = null) {
  console.log(" !!!! API CALL !!!! - updateRoomName ");
  let patchData = {
    data: {
      id: groupId,
      type: "groups",
      attributes: { name: newName },
    },
  };

  const axios = createAuthOptionalAxios(authToken);

  let request = axios.patch(`/api/v1/groups/${groupId}`, patchData);

  return request;
}

async function updateRoomPermanent(groupId, permanent, authToken = null) {
  console.log(" !!!! API CALL !!!! - updateRoomPermanent ");
  let patchData = {
    data: {
      id: groupId,
      type: "groups",
      attributes: { permanent: permanent },
    },
  };

  const axios = createAuthOptionalAxios(authToken);

  let request = axios.patch(`/api/v1/groups/${groupId}`, patchData);

  return request;
}

async function deleteRoom(groupId, authToken = null) {
  console.log(" !!!! API CALL !!!! - deleteRoom");
  const axios = createAuthOptionalAxios(authToken);
  return axios.delete(`/api/v1/groups/${groupId}`).then((response) => {
    return parseSessionData(response.data);
  });
}

async function createParticipantPermission(
  participantId,
  groupId,
  authToken = null
) {
  let patchData = {
    data: {
      "group-id": groupId,
      "participant-id": participantId,
    },
  };

  const axios = createAuthOptionalAxios(authToken);
  let request = axios.post(`/api/v1/participant_permissions`, patchData);

  return request;
}

async function removeParticipantPermission(
  participantId,
  groupId,
  authToken = null
) {
  let patchData = {
    data: {
      "group-id": groupId,
      "participant-id": participantId,
    },
  };

  const axios = createAuthOptionalAxios(authToken);
  let request = axios.delete(
    `/api/v1/participant_permissions/doesnt-matter`,
    patchData
  );

  return request;
}

async function ping(participantId) {
  const axios = createAuthOptionalAxios();
  return axios.post(`/api/v1/participants/${participantId}/ping`, null, {
    timeout: 2000,
  });
}

async function banParticipant(id, authToken = null) {
  const axios = createAuthOptionalAxios(authToken);
  return axios.post(`/api/v1/participants/${id}/host_act`, {
    hostAction: "ban",
  });
}

async function resetPassword(email) {
  return await httpService.post("/api/v1/password_resets", {
    email: email,
  });
}

async function setNewPassword(resetToken, newPassword) {
  const url = `/api/v1/password_resets/${resetToken}/perform`;
  return await httpService.post(url, {
    password: newPassword,
  });
}

async function acceptInviteSetPassword(inviteToken, password) {
  const url = `/api/v1/invitations/${inviteToken}/accept`;
  return await httpService.post(url, {
    password: password,
  });
}

async function acceptOfficeInvitation(inviteToken, fullName, password) {
  const url = `/api/v1/invitations/${inviteToken}/accept_office`;
  return await httpService.post(url, {
    full_name: fullName,
    password: password,
  });
}

async function acceptSharedInvitation(inviteToken, fullName, email, password) {
  const url = `/api/v1/shared_invitations/${inviteToken}/accept`;
  return await httpService.post(url, {
    full_name: fullName,
    email: email,
    password: password,
  });
}

async function getInvitationInfo(inviteToken) {
  return await httpService.get(`/api/v1/invitations/${inviteToken}`);
}

async function lockRoom(roomId, authToken = null) {
  const axios = createAuthOptionalAxios(authToken);
  return axios.post(`/api/v1/groups/${roomId}/lock`);
}

async function unlockRoom(roomId, authToken = null) {
  const axios = createAuthOptionalAxios(authToken);
  return axios.delete(`/api/v1/groups/${roomId}/lock`);
}

async function pullAside(groupId, participantIds = []) {
  const url = `/api/v1/groups/${groupId}/clusters`;
  let params = {};
  if (participantIds) {
    params = {
      participant_ids: participantIds,
    };
  }
  return await httpService.post(url, params);
}

async function getGroup(groupId) {
  const url = `/api/v1/groups/${groupId}`;
  return await httpService.get(url);
}

async function joinCluster(clusterId, participantIds = []) {
  console.log("joining cluster: ", clusterId);
  const url = `/api/v1/clusters/${clusterId}/join`;
  let params = {};
  if (participantIds) {
    params = {
      participant_ids: participantIds,
    };
  }
  return await httpService.post(url, params);
}

async function leaveCluster(clusterId) {
  const url = `/api/v1/clusters/${clusterId}/leave`;
  return await httpService.post(url);
}

async function getRestParticipantsInGroup(groupId) {
  const url = `/api/v1/groups/${groupId}/rest`;
  return await httpService.get(url);
}

async function uploadImage(sessionId, base64Image) {
  const formData = new FormData();
  formData.append("banner_image", base64Image);

  const url = `/api/v1/sessions/${sessionId}/upload_image`;
  return await httpService.post(url, formData);
}

async function deleteImage(sessionId) {
  const url = `/api/v1/sessions/${sessionId}/delete_image`;
  return await httpService.delete(url);
}

async function joinMeInvite(roomId, clusterId, invitedParticipantId) {
  const url = `/api/v1/groups/${roomId}/join_me`;
  let params = {};
  params = {
    cluster_id: clusterId,
    invited_participant_id: invitedParticipantId,
  };
  return await httpService.post(url, params);
}

async function knockRoom(roomId, participantId, userId) {
  const url = `api/v1/groups/${roomId}/knock`;
  let params = {};
  if (userId) {
    params.knocker_type = "User";
    params.knocker_id = userId;
  } else {
    params.knocker_type = "Participant";
    params.knocker_id = participantId;
  }
  return await httpService.post(url, params);
}

async function acceptRoomKnock(groupRequestId, notificationId) {
  const url = `api/v1/group_requests/${groupRequestId}/accept`;
  let params = {
    notification_id: notificationId,
  };
  return await httpService.post(url, params);
}

async function cancelRoomKnock(groupRequestId) {
  const url = `api/v1/group_requests/${groupRequestId}/cancel`;
  return await httpService.post(url);
}

async function addUnreadMessage(receiverId) {
  const url = `/api/v1/users/${receiverId}/increment_unread_message_count`;
  return await httpService.post(url);
}

async function clearUnreadMessages(receiverId, senderId) {
  const url = `/api/v1/users/${receiverId}/clear_unread_messages`;
  let params = {
    sender_id: senderId,
  };
  return await httpService.post(url, params);
}

async function getSharedInvitation(inviteToken) {
  const url = `api/v1/shared_invitations/${inviteToken}`;
  return await httpService.get(url);
}

const getSessionBySlug = async (slug) => {
  const url = `api/v1/sessions/by_slug/${slug}`;
  return await httpService.get(url);
};

// -------------------------------------------------- Links --------------------------------------------------

const createLinkByParticipant = async (payload, token) => {
  const url = "api/v1/group-links";
  const customAxios = createAuthOptionalAxios(token);
  return await customAxios.post(url, payload);
};

const getLinksForParticipant = async (groupId, token) => {
  const url = `api/v1/groups/${groupId}/group-links`;
  const customAxios = createAuthOptionalAxios(token);
  return await customAxios.get(url);
};

const getLinkForParticipant = async (linkId, token) => {
  const url = `api/v1/group-links/${linkId}`;
  const customAxios = createAuthOptionalAxios(token);
  return await customAxios.get(url);
};

const updateLinkByParticipant = async (linkId, payload, token) => {
  const url = `api/v1/group-links/${linkId}`;
  const customAxios = createAuthOptionalAxios(token);
  return await customAxios.put(url, payload);
};

const deleteLinkByParticipant = async (linkId, token) => {
  const url = `api/v1/group-links/${linkId}`;
  const customAxios = createAuthOptionalAxios(token);
  return await customAxios.delete(url);
};

const getOtherRoomLinksForParticipant = async (groupId, token) => {
  const url = `api/v1/groups/${groupId}/other-group-links?sort=-updated_at`;
  const customAxios = createAuthOptionalAxios(token);
  return await customAxios.get(url);
};

export default {
  getSession,
  createRoom,
  createParticipant,
  changeRoom,
  updateRoomName,
  updateRoomPermanent,
  parseSessionData,
  deleteRoom,
  updateParticipant,
  getParticipant,
  destroyParticipant,
  createParticipantPermission,
  removeParticipantPermission,
  ping,
  banParticipant,
  resetPassword,
  setNewPassword,
  getInvitationInfo,
  acceptInviteSetPassword,
  acceptOfficeInvitation,
  acceptSharedInvitation,
  lockRoom,
  unlockRoom,
  pullAside,
  getGroup,
  joinCluster,
  leaveCluster,
  getRestParticipantsInGroup,
  uploadImage,
  deleteImage,
  joinMeInvite,
  knockRoom,
  acceptRoomKnock,
  cancelRoomKnock,
  addUnreadMessage,
  clearUnreadMessages,
  getSharedInvitation,
  getSessionBySlug,
  getLinksForParticipant,
  createLinkByParticipant,
  updateLinkByParticipant,
  getLinkForParticipant,
  deleteLinkByParticipant,
  getOtherRoomLinksForParticipant,
};
