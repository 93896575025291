import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { navigate } from "@reach/router";
import SetNewPassword from "../components/Forgot/SetNewPassword";
import * as Amplitude from "../utilities/amplitude";
import PlainGreyLayout from "../components/Layouts/PlainGreyLayout";

const PasswordResets = (props) => {
  useEffect(() => {
    Amplitude.track("Visited forgot password page.");
  }, []);

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <PlainGreyLayout>
      <div className=" content-column narrow">
        <SetNewPassword
          navigate={navigate}
          goToLogin={goToLogin}
          resetToken={props.resetToken}
        />
      </div>
    </PlainGreyLayout>
  );
};

export default observer(PasswordResets);
