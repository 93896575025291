/* eslint-disable jsx-a11y/anchor-is-valid */
import { observer } from "mobx-react-lite";
import "../Tray/HelpMenu.scss";
import "./ReactionsMenu.scss";
import { useStores } from "../../stores/utils";

const ReactionsMenu = ({ isReactionsMenuOpen, hideReactionsMenu }) => {
  const rootStores = useStores();
  const { roomReactionStore } = rootStores;

  const expressMyReaction = (itemId) => {
    roomReactionStore.displayReaction(itemId);
    hideReactionsMenu();
  };

  return (
    <div className="reactions-menu">
      <div className="reactions-menu__wrap">
        <div className="reactions-items-row">
          <div
            className="reactions-item"
            onClick={() => expressMyReaction("0x1f91a")}
          >
            ✋
          </div>
          <div
            className="reactions-item"
            onClick={() => expressMyReaction("0x1f44d")}
          >
            👍
          </div>
          <div
            className="reactions-item"
            onClick={() => expressMyReaction("0x1f44e")}
          >
            👎
          </div>
          <div
            className="reactions-item"
            onClick={() => expressMyReaction("0x1f44f")}
          >
            👏
          </div>
          <div
            className="reactions-item"
            onClick={() => expressMyReaction("0x1f44b")}
          >
            👋
          </div>
        </div>
        <div className="reactions-items-row">
          <div
            className="reactions-item"
            onClick={() => expressMyReaction("0x1f389")}
          >
            🎉
          </div>
          <div
            className="reactions-item"
            onClick={() => expressMyReaction("❤️")}
          >
            ❤️
          </div>
          <div
            className="reactions-item"
            onClick={() => expressMyReaction("0x1f602")}
          >
            😂
          </div>
          <div
            className="reactions-item"
            onClick={() => expressMyReaction("0x1f62e")}
          >
            😮
          </div>
          <div
            className="reactions-item"
            onClick={() => expressMyReaction("0x1f914")}
          >
            🤔
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ReactionsMenu);
