import { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { Tooltip } from "react-tippy";
import "../../StatusDropdown/StatusDropdown.scss";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import StatusDropdownItem from "../../StatusDropdown/StatusDropdownItem";
import { adminStatus, memberStatus } from "../../../stores/TeamStore";
import { getStatusLabel } from "../../../stores/TeamMemberModel";

const StatusDropdown = ({
  sessionsStore,
  onRoleChange,
  role,
  permittedEvents,
  togglePermittedEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen((x) => !x);
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleAdminRoleSelect = useCallback(() => {
    onRoleChange("admin");
  }, [onRoleChange]);

  const handleMemberRoleSelect = useCallback(() => {
    onRoleChange("member");
  }, [onRoleChange]);

  return (
    <Tooltip
      open={isOpen}
      onRequestClose={closeMenu}
      trigger="click"
      theme="light"
      className="borderless-tooltip"
      position="bottom"
      animateFill={false}
      interactive
      appendTo="parent"
      duration={0}
      hideDelay={0}
      html={
        <div className="StatusDropdown-menu">
          <ul>
            <li>
              <StatusDropdownItem
                onClick={handleAdminRoleSelect}
                isSelected={role === adminStatus}
                label={getStatusLabel(adminStatus)}
                description={
                  "Can change all account settings, invite new members to the organization, and has moderator actions to mute or kick users from rooms."
                }
              />
            </li>

            <li>
              <StatusDropdownItem
                onClick={handleMemberRoleSelect}
                isSelected={role === memberStatus}
                label={getStatusLabel(memberStatus)}
                description={"A standard user. Has no special privileges."}
              />
            </li>
          </ul>
        </div>
      }
    >
      <div
        className="button is-text"
        aria-haspopup="true"
        aria-controls="dropdown-menu7"
        onClick={toggleOpen}
      >
        <div className="teammember-role-content">
          <span>{getStatusLabel(role)}</span>
          <div>{isOpen ? <BsChevronUp /> : <BsChevronDown />}</div>
        </div>
      </div>
    </Tooltip>
  );
};

export default observer(StatusDropdown);
