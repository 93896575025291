import { ReactComponent as SidebarLogo } from "../../images/SVG/sidebar-logo.svg";
import { ReactComponent as BlueSidebarLogo } from "../../images/SVG/blue_logo.svg";
import classnames from "classnames";
import "./Layouts.scss";

const PlainGreyLayout = ({ children, blueLogo, hideLogoMobile, tall }) => {
  return (
    <div className="plain-grey-page">
      <div
        className={classnames("title-row", {
          "hide-logo-mobile": hideLogoMobile,
        })}
      >
        <div className={classnames("title-image", { "blue-logo": blueLogo })}>
          {blueLogo ? <BlueSidebarLogo /> : <SidebarLogo />}
        </div>
      </div>
      <div className={classnames("flex-container", { tall })}>{children}</div>
    </div>
  );
};

export default PlainGreyLayout;
