import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/utils";
import TotalUsage from "./TotalUsage";
import ParticipantsTime from "./ParticipantsTime";
import EventsTime from "./EventsTime";
import "./UsageSummary.scss";

const UsageSummary = () => {
  const { usageSummaryStore } = useStores();

  useEffect(() => {
    usageSummaryStore.init();
    return () => usageSummaryStore.dispose();
  }, [usageSummaryStore]);

  return (
    <div className="usage-summary">
      <h1 className="title is-1">Usage</h1>
      <TotalUsage store={usageSummaryStore} />
      <ParticipantsTime store={usageSummaryStore} />
      <EventsTime store={usageSummaryStore} />
    </div>
  );
};

export default observer(UsageSummary);
