import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import "./EventsSelectItem.scss";

const EventsSelectItem = ({ value, onClick, isSelected }) => {
  return (
    <div
      className="EventsSelectItem"
      onClick={() => {
        onClick(value.id);
      }}
    >
      <div className="EventsSelectItem__content">
        <div className="EventsSelectItem__label">{value.attributes.name}</div>
        {/* <div className="EventsSelectItem__description">
          {value.attributes["session-url"]}
        </div> */}
      </div>

      {isSelected ? (
        <div className="EventsSelectItem__icon">
          <FontAwesomeIcon icon={faCheck} />
        </div>
      ) : (
        <div className="EventsSelectItem__icon"></div>
      )}
    </div>
  );
};

export default observer(EventsSelectItem);
