import { action, computed, makeObservable, observable } from "mobx";
import { ParticipantModelLite } from "./ParticipantModelLite";

export class ParticipantModel extends ParticipantModelLite {
  id;
  name;
  groupId;
  @observable dailyId;
  @observable videoOn;
  @observable.ref attributes = {};
  @observable.ref relationships = {};
  type;

  constructor({ data, rootStores }) {
    super({ data, rootStores });
    this.mergeData(data);

    makeObservable(this);
  }

  @action
  mergeData(data) {
    this.id = data.id;
    this.name = data.attributes.name;
    this.groupId = data.relationships?.group?.data?.id;
    this.dailyId = data.attributes["daily-participant-id"];
    this.videoOn = data.attributes["video-on"];
    this.attributes = data.attributes;
    this.relationships = data.relationships;
  }

  @computed
  get authorized() {
    return this.attributes["can-broadcast"];
  }

  @computed
  get colorClass() {
    return this.rootStores.callStore.isMe(this.id)
      ? "is-lightblue"
      : this.authorized
      ? "is-lighterblue"
      : this.audioOnly
      ? "audio-only-tag"
      : "is-lighterblue";
  }

  @computed
  get inSameCluster() {
    return this.rootStores.callStore.clusterParticipantIds.includes(this.id);
  }

  @computed
  get audioParticipant() {
    return !this.rootStores.callStore.audioCallItems
      ? false
      : this.rootStores.callStore.audioCallItems.some((callItem) => {
          return (
            callItem.sidebarParticipant &&
            callItem.sidebarParticipant.id === this.id
          );
        });
  }

  @computed
  get audioOnly() {
    return this.audioParticipant;
  }

  @computed
  get showMicIcon() {
    return (
      this.audioParticipant &&
      !this.rootStores.callStore.currentRoom?.isAuditorium
    );
  }

  @computed
  get callItem() {
    return Object.values(this.rootStores.callStateStore.callItems).find(
      (callItem) => {
        return callItem.dailyId === this.dailyId;
      }
    );
  }

  @computed
  get micMuted() {
    return !this.callItem.audioTrack;
  }

  get inSameRoom() {
    return true;
  }
}
