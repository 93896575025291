import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/utils";
import Tippy from "@tippyjs/react";
import NewMeetingPopup from "./NewMeetingPopup";
import RoomsList from "./RoomsList";
import classnames from "classnames";
import JoinButton from "../JoinButton/JoinButton";
import SpaceNameHeader from "./SpaceNameHeader";

const UserMeetingRow = ({ userMeeting, privateSummaryQuery }) => {
  const { callStore, officeStore } = useStores();
  const [mainRoomId, setMainRoomId] = useState(undefined);
  const [mainRoom, setMainRoom] = useState(undefined);

  const meetingId = userMeeting.meeting.id;
  const meetingSlug = userMeeting.meeting?.["attributes"]?.slug;

  const clickRow = (e) => {
    if (officeStore.inACall) {
      officeStore.setSpaceInTippyPopup(meetingId);
    } else {
      officeStore.setSpaceInRightPaneId(meetingId, "meeting", meetingSlug);
    }
  };

  const showNewMeeting =
    officeStore.editingMeetingId === userMeeting.meeting.id;

  useEffect(() => {
    const mainRoomId = userMeeting?.meeting?.attributes?.["lobby-group-id"];
    setMainRoomId(mainRoomId);
    setMainRoom(userMeeting?.meeting?.groups.find((x) => x.id === mainRoomId));
  }, [userMeeting]);

  const isFull = mainRoom?.isFull;

  const joinMeeting = useCallback(
    async (e) => {
      e.stopPropagation();
      const lobbyDailyUrl =
        userMeeting?.meeting?.attributes?.["lobby-daily-url"];

      if (mainRoomId && lobbyDailyUrl) {
        const sessionId = userMeeting?.meeting?.id;
        await officeStore.joinGroupFromFoyer(
          mainRoomId,
          sessionId,
          lobbyDailyUrl,
          "meeting"
        );
      }
    },
    [mainRoomId, officeStore, userMeeting]
  );

  const meetingUrl = userMeeting?.meeting?.attributes?.["meeting-url"];

  const isCurrentRoom =
    callStore.currentRoomId && callStore.currentRoomId === mainRoomId;

  const joinButton = (
    <JoinButton
      join={joinMeeting}
      joining={callStore.joiningRoomId === mainRoomId}
      disabled={callStore.joinButtonsDisabled}
      isCurrentRoom={isCurrentRoom}
      isFull={isFull}
    />
  );

  const collapseToSingleRoom = userMeeting?.meeting?.groups?.length < 2;

  return (
    <div className="user-meeting-row">
      <div>
        <Tippy
          theme="light"
          className="new-meeting-tippy"
          content={
            showNewMeeting && (
              <NewMeetingPopup
                session={userMeeting.meeting}
                privateSummaryQuery={privateSummaryQuery}
              />
            )
          }
          visible={showNewMeeting}
          placement="right"
          interactive={true}
          onClickOutside={() => officeStore.setEditingMeetingId(undefined)}
        >
          <div
            onClick={clickRow}
            className={classnames({ "editing-meeting": showNewMeeting })}
          >
            <SpaceNameHeader
              withContent={true}
              name={userMeeting.meeting.attributes.name}
              inCall={false}
              sessionType="meeting"
              joinButton={collapseToSingleRoom && joinButton}
              collapseSingleRoom={collapseToSingleRoom}
              sessionUrl={meetingUrl}
              inRightPane={officeStore.spaceInRightPaneId === meetingId}
            />

            {userMeeting.meeting.groups &&
              userMeeting.meeting.groups.length > 0 && (
                <RoomsList
                  groups={userMeeting.meeting.groups}
                  sessionId={userMeeting.meeting.id}
                  hideRoomNameIfJustOneRoom={true}
                  hideGroupName={collapseToSingleRoom}
                  sessionType="meeting"
                />
              )}
          </div>
        </Tippy>
      </div>
    </div>
  );
};

export default observer(UserMeetingRow);
