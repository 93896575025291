import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import classnames from "classnames";
import MoreMenu from "./MoreMenu.js";
import "tippy.js/animations/shift-away.css";
import "tippy.js/themes/light.css";

const MoreButton = ({ disabled, theme }) => {
  const { moreMenuStore: store } = useStores();

  if (!store.showButton) return null;

  return (
    <Tippy
      animation="shift-away"
      content={<MoreMenu store={store} />}
      interactive={true}
      theme="light"
      onClickOutside={() => {
        store.hideMenu();
      }}
      visible={store.isMenuOpen}
      arrow={false}
    >
      <Tippy
        content="More ..."
        disabled={disabled || store.isMenuOpen}
        appendTo="parent"
        theme={theme}
      >
        <button
          className={classnames(
            "tray-button",
            "custom-tray-item",
            "more-button"
          )}
          onClick={store.toggleMenu}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
      </Tippy>
    </Tippy>
  );
};

export default observer(MoreButton);
