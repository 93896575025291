import { action, observable, makeObservable } from "mobx";

export class CallSettingsStore {
  @observable.ref currentCam;
  @observable.ref currentMic;
  @observable docId = undefined;

  constructor(rootStores) {
    this.rootStore = rootStores;
    makeObservable(this);
  }

  @action
  setCurrentCam(cam) {
    this.currentCam = cam;
  }

  @action
  setCurrentMic(mic) {
    // console.log("!!! setting mic to: ", mic);
    this.currentMic = mic;
  }

  @action.bound
  clearDevices() {
    this.currentCam = null;
    this.currentMic = null;
  }

  @action
  setDocId(docId) {
    this.docId = docId;
  }
}
