import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import { useStores } from "../../../stores/utils";
import Loader from "../../Loader";
import "./SlackUser.scss";

const SlackUser = ({ skip }) => {
  const { ably, api, userStore } = useStores();
  const [requestSent, setRequestSent] = useState(false);
  const [requestedSlackUser, setRequestedSlackUser] = useState(undefined);
  const [linkRejected, setLinkRejected] = useState(false);

  const slackUserLookup = async () => {
    return await api.slackUserLookup(userStore.userId);
  };

  const query = useQuery("slack-user-lookup", slackUserLookup, {
    staleTime: 60000,
  });

  const linkAccount = async (slackUserId, slackuser) => {
    setRequestedSlackUser(slackuser);
    await api.slackTryLinkAccount(userStore.userId, slackUserId, linkRejected);
    setRequestSent(true);
  };

  useEffect(() => {
    const refreshUser = async () => {
      await userStore.refetchUser();
      const currentSlackUserId = userStore.userInfo["slack_user_id"];
      if (currentSlackUserId) {
        skip();
      }
    };
    refreshUser();
  }, [skip, userStore]);

  useEffect(() => {
    const userId = userStore.userInfo.id;
    const channel = ably.channels.get(`user/${userId}`);

    const listener = async (message) => {
      if (message.name === "user_update") {
        userStore.updateUser(message.data);
      } else if (message.name === "slack_link_rejected") {
        setLinkRejected(true);
        setRequestSent(false);
      } else if (message.name === "slack_link_rejected_second_attempt") {
        skip();
      }
    };
    channel.subscribe(listener);

    return () => {
      channel.unsubscribe(listener);
    };
  }, [ably.channels, skip, userStore]);

  return (
    <div className="content office-onboarding-slack-user">
      {requestSent ? (
        <div className="slack-sent-request-message">
          <h4>
            We just sent you a direct message in Slack{" "}
            <div className="slack-style-mention">@{requestedSlackUser[1]}</div>!
          </h4>
          <p>Please click "Confirm" in Slack.</p>
        </div>
      ) : (
        <>
          <h2>We can't find you in Slack.</h2>
          <p>
            We'll forward calls and invites to Slack when you're not on Sidebar.
          </p>

          {linkRejected && (
            <div className="notification is-danger">
              Slack account link was rejected.{" "}
              <p>
                <strong>You have 1 attempt remaining.</strong>
              </p>
            </div>
          )}
          <p>Link your Slack account:</p>
          <ul className="slack-user-list">
            {query?.isLoading && (
              <div className="slack-loader">
                <div className="loader-container">
                  <Loader />
                  <div>Fetching users from your workspace...</div>
                </div>
              </div>
            )}
            {query?.data?.data &&
              query.data.data.map((slackUser) => {
                return (
                  <li
                    onClick={() => linkAccount(slackUser[0], slackUser)}
                    key={slackUser[0]}
                  >
                    <div className="is-flex">
                      <button className="button is-small is-sblue">Link</button>
                    </div>
                    <div className="is-flex">
                      <img src={slackUser[3]} alt="Slack profile" />{" "}
                      {slackUser[1]}{" "}
                    </div>
                  </li>
                );
              })}
          </ul>
          <div className="skip-button">
            <div className="button is-ghost" onClick={skip}>
              Skip
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(SlackUser);
