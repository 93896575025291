import { observer } from "mobx-react-lite";
import "../RoomRow/RoomRow.scss";
import RoomName from "../RoomName/RoomName";
import RestBlock from "../RestParticipantsList/RestBlock";
import RestBlock5 from "../RestParticipantsList/RestBlock5";
import LimitedParticipantsList from "../LimitedParticipantsList/LimitedParticipantsList";
import classnames from "classnames";

function OfficeRoomRow({
  room,
  joinButtonsDisabled,
  focusedEditRoom,
  setFocusedEditRoom,
  noActions,
  isFoyer,
  onClickRoom,
  differentSession,
  hideGroupName,
}) {
  const isCurrentRoom = room.isCurrentRoom && !hideGroupName;

  return (
    <div
      className={classnames("room-row-container", {
        "room-row-container__current": isCurrentRoom,
      })}
    >
      {!hideGroupName && (
        <RoomName
          room={room}
          focusedEditRoom={focusedEditRoom}
          setFocusedEditRoom={setFocusedEditRoom}
          noActions={noActions}
          isFoyer={isFoyer}
          onClickRoom={onClickRoom}
        />
      )}
      <>
        <LimitedParticipantsList
          room={room}
          differentSession={differentSession}
          clickToDM={true}
        />
        {differentSession ? (
          <RestBlock5 room={room} differentSession={differentSession} />
        ) : (
          <RestBlock room={room} differentSession={differentSession} />
        )}
      </>
    </div>
  );
}

export default observer(OfficeRoomRow);
