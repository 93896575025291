import { useEffect, useState, useCallback, useRef } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentEditable from "react-contenteditable";
import { faBell, faBellSlash } from "@fortawesome/free-regular-svg-icons";
import {
  faCog,
  faHashtag,
  faPen,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import Tippy from "@tippyjs/react";
import { useQuery, useQueryClient } from "react-query";
import classNames from "classnames";
import "./Foyer.scss";
import api from "../../api";
import { useStores } from "../../stores/utils";
import { ParticipantModelLite } from "../../stores/ParticipantModelLite";
import GroupModel from "../../stores/GroupModel";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from "../Office/Modal";
import SpaceMembersInfinite from "../Office/SpaceMembersInfinite";
import TopMembers from "../Office/Foyer/TopMembers";
import ChangeSpacePhotoModal from "../Office/ChangeSpacePhotoModal";
import classnames from "classnames";
import MeetingSettingsMenu from "../Office/MeetingSettingsMenu";
import ConfirmMeetingDelete from "components/Office/ConfirmMeetingDelete";
import FoyerCardList from "./FoyerCardList";
import MeetingFoyer from "./MeetingFoyer";
import FoyerDocumentList from "./FoyerDocumentList";

const placeCaretAtEnd = (el) => {
  el.focus();
  if (
    typeof window.getSelection != "undefined" &&
    typeof document.createRange != "undefined"
  ) {
    var range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange != "undefined") {
    var textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
};

const pressEnter = (e) => {
  if (e.keyCode === 13) {
    e.preventDefault();
    e.target.blur();
  }
};

const addRoom = () => {};

function Body({ sessionId }) {
  const rootStores = useStores();
  const [manageLogoVisible, setManageLogo] = useState(false);
  const { userStore, api: api2, officeStore } = rootStores;
  const contentEditableRef = useRef(null);
  const descriptionContentEditableRef = useRef(null);
  const [isTitleUpdate, setTitleUpdate] = useState(false);
  const [isDescriptionUpdate, setDescriptionUpdate] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [showCopiedTooltip, setShowCopiedTootip] = useState(false);
  const [descriptionText, setDescriptionText] = useState("");
  const [sessionNameText, setSessionNameText] = useState("");
  const [editingDescription, setEditingDescription] = useState(false);
  const [showingMembersModal, setShowingMembersModal] = useState(false);
  const [
    showingConfirmMeetingDelete,
    setShowingConfirmMeetingDelete,
  ] = useState(false);
  const [sessionType, setSessionType] = useState();
  const queryClient = useQueryClient();

  const triggerCopiedTooltip = useCallback(() => {
    setShowCopiedTootip(true);
    setTimeout(() => {
      setShowCopiedTootip(false);
    }, 2000);
  }, []);

  const limitExceeded = false;

  const getSession = (params) => {
    return api.getSession(params.queryKey[1]);
  };

  const query = useQuery(["sessions", sessionId], getSession, {
    refetchOnWindowFocus: false,
  });

  const sessionMembershipSummaryQuery = useQuery(
    ["sessionMembershipSummary", sessionId],
    (params) => api2.getSessionMembershipSummary(params.queryKey[1]),
    {
      refetchOnWindowFocus: false,
    }
  );

  const memberCount =
    sessionMembershipSummaryQuery?.data?.data?.data?.attributes?.[
      "user-count"
    ] || 0;

  const handleBlur = useCallback(async () => {
    setTitleUpdate(true);
    await officeStore.updateSpace({
      name: contentEditableRef.current.innerText.trim(),
      id: sessionId,
    });
    setTitleUpdate(false);
  }, [contentEditableRef, officeStore, sessionId]);

  const handleDescriptionBlur = useCallback(async () => {
    setDescriptionUpdate(true);
    await officeStore.updateSpace({
      description: descriptionContentEditableRef.current.innerText.trim(),
      id: sessionId,
    });
    setDescriptionUpdate(false);
  }, [descriptionContentEditableRef, officeStore, sessionId]);

  const handleDescriptionChange = useCallback((evt) => {
    setDescriptionText(evt.target.value.trim());
  }, []);

  const handleSessionNameChange = useCallback((evt) => {
    setSessionNameText(evt.target.value.trim());
  }, []);

  useEffect(() => {
    const session = query.data;
    if (!session) return;

    const participants = session.participants.map((p) => {
      return new ParticipantModelLite({
        data: p,
        rootStores: rootStores,
      });
    });

    const groupList = session.groups.map((g) => {
      const groupParticipants = participants.filter((x) => {
        return x.groupId === g.id;
      });

      return new GroupModel({
        data: g,
        participants: groupParticipants,
        rootStores: rootStores,
      });
    });
    setGroupList(groupList);
    setSessionNameText(session.data.data.attributes.name);
    setDescriptionText(session.data.data.attributes.description);
    setSessionType(session.data.data.attributes?.["session-type"]);
  }, [query.data, rootStores]);

  const subscribeToSpace = useCallback(
    (sessionId) => {
      // create space subscription for session / user id
      api2.subscribeToSpace(sessionId, userStore.userId);
    },
    [api2, userStore.userId]
  );

  const unsubscribeFromSpace = useCallback(
    (sessionId) => {
      api2.unsubscribeFromSpace(sessionId, userStore.userId);
    },
    [api2, userStore.userId]
  );

  const subscribedToSpace = useCallback(
    (sessionId) => {
      return userStore.subscribedSpaces.includes(sessionId);
    },
    [userStore.subscribedSpaces]
  );

  const onClickRoom = useCallback(
    (event, roomId, dailyUrl, appState) => {
      event.stopPropagation();
      const room = groupList.find((x) => x.id === roomId);
      if (room.attributes["locked"]) return;
      return officeStore.joinGroupFromFoyer(
        room.id,
        sessionId,
        room.attributes["daily-url"],
        sessionType
      );
    },
    [groupList, sessionId, officeStore, sessionType]
  );

  const createRoom = () => {
    return officeStore.createRoomFromFoyer(sessionId);
  };

  const createAuditorium = () => {
    return officeStore.createAuditoriumFromFoyer(sessionId);
  };

  const showMembersModal = () => {
    setShowingMembersModal(true);
  };

  const removeLogo = async () => {
    await api2.removeSessionLogo(sessionId);
    await queryClient.invalidateQueries(["sessions", sessionId]);
  };

  const logoUrl = query?.data?.data?.data?.attributes?.["logo-url"];

  const isMeeting =
    query?.data?.data?.data?.attributes?.["session-type"] === "meeting";

  let envFriendlyUrl;
  if (isMeeting) {
    envFriendlyUrl = query?.data?.data?.data?.attributes?.["meeting-url"];
  } else {
    envFriendlyUrl = `${window.location.origin}/office/s/${sessionId}`;
  }

  const homeAndRefresh = async () => {
    officeStore.setSpaceInRightPaneId(undefined);
    await queryClient.refetchQueries("office/privateSummary");
  };

  const deleteMeeting = async () => {
    await api2.deleteSession(sessionId);
    await homeAndRefresh();
  };

  return (
    <div className="foyer-body-padding office-foyer">
      <div className="centered-office-foyer">
        {query.data ? (
          sessionType === "meeting" ? (
            <MeetingFoyer
              session={query?.data}
              envFriendlyUrl={envFriendlyUrl}
              triggerCopiedTooltip={triggerCopiedTooltip}
              showCopiedTooltip={showCopiedTooltip}
              addRoom={addRoom}
              createAuditorium={createAuditorium}
              createRoom={createRoom}
              groupList={groupList}
              onClickRoom={onClickRoom}
              sessionId={sessionId}
              sessionNameText={sessionNameText}
              contentEditableRef={contentEditableRef}
              handleBlur={handleBlur}
              pressEnter={pressEnter}
              handleSessionNameChange={handleSessionNameChange}
              placeCaretAtEnd={placeCaretAtEnd}
              isTitleUpdate={isTitleUpdate}
              query={query}
              setShowingConfirmMeetingDelete={setShowingConfirmMeetingDelete}
              homeAndRefresh={homeAndRefresh}
            />
          ) : (
            <>
              {/*<Banner sessionId={sessionId} bannerUrl={null} />*/}
              <div className="office-foyer-header">
                <div
                  className="office-foyer-header__space-image__wrapper"
                  onClick={() => {
                    if (!logoUrl) {
                      officeStore.showSpaceChangePhoto();
                    }
                  }}
                >
                  <Tippy
                    arrow={false}
                    content={
                      <div className="menu manage-spaces-menu">
                        <ul className="menu-list">
                          <li>
                            {/* eslint-disable-next-line */}
                            <a
                              onClick={() => {
                                officeStore.showSpaceChangePhoto();
                                setManageLogo(false);
                              }}
                            >
                              Upload new image
                            </a>
                          </li>
                          <li>
                            {/* eslint-disable-next-line */}
                            <a
                              onClick={() => {
                                removeLogo();
                                setManageLogo(false);
                              }}
                            >
                              Remove image
                            </a>
                          </li>
                        </ul>
                      </div>
                    }
                    placement="bottom-start"
                    interactive={true}
                    theme="light"
                    visible={logoUrl && manageLogoVisible}
                    onClickOutside={() => setManageLogo(false)}
                  >
                    <div
                      className={classnames("logo-wrapper", {
                        "with-logo": logoUrl,
                      })}
                      onClick={() =>
                        logoUrl
                          ? setManageLogo(true)
                          : officeStore.showSpaceChangePhoto()
                      }
                    >
                      {logoUrl ? (
                        <>
                          <FontAwesomeIcon icon={faCamera} />
                          <img src={logoUrl} alt="space-logo" />
                        </>
                      ) : (
                        <FontAwesomeIcon icon={faCamera} />
                      )}
                    </div>
                  </Tippy>
                </div>
                <div className="office-foyer-title__wrapper">
                  <h1 className="title is-3 is-size-3-mobile is-spaced">
                    <div className="space-title-container">
                      <div className="space-icon">
                        <FontAwesomeIcon icon={faHashtag} />
                      </div>{" "}
                      <div className="hover-for-actions">
                        <ContentEditable
                          innerRef={contentEditableRef}
                          html={sessionNameText}
                          onBlur={() => {
                            handleBlur();
                          }}
                          onKeyDown={pressEnter}
                          onChange={handleSessionNameChange}
                          className="editable-name"
                        />
                        <div className="floating-actions">
                          <div
                            className="pen-icon"
                            onClick={() =>
                              placeCaretAtEnd(contentEditableRef.current)
                            }
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </div>
                          <div
                            className={classNames("room-action-loader", {
                              active: isTitleUpdate,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="subtitle is-6 is-size-5-mobile">
                      <div className="hover-for-actions">
                        {!descriptionText && !editingDescription && (
                          <div
                            className="description-placeholder"
                            onClick={() =>
                              placeCaretAtEnd(
                                descriptionContentEditableRef.current
                              )
                            }
                          >
                            Add a description
                          </div>
                        )}
                        <ContentEditable
                          innerRef={descriptionContentEditableRef}
                          html={descriptionText || ""}
                          onChange={handleDescriptionChange}
                          onBlur={() => {
                            handleDescriptionBlur();
                            setEditingDescription(false);
                          }}
                          onKeyDown={pressEnter}
                          onFocus={() => setEditingDescription(true)}
                          className="editable-name"
                        />
                        <div className="floating-actions">
                          <div
                            className="pen-icon"
                            onClick={() =>
                              placeCaretAtEnd(
                                descriptionContentEditableRef.current
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </div>
                          <div
                            className={classNames("room-action-loader", {
                              active: isDescriptionUpdate,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </h1>
                  <div className="foyer-actions">
                    <CopyToClipboard
                      text={envFriendlyUrl}
                      onCopy={triggerCopiedTooltip}
                    >
                      <div>
                        <Tippy
                          content="Link copied to clipboard!"
                          visible={showCopiedTooltip}
                          className="blue-tippy"
                          arrow={false}
                        >
                          <Tippy content="Get link">
                            <button className="link-button button">
                              <div className="icon ">
                                <FontAwesomeIcon icon={faLink} />
                              </div>
                            </button>
                          </Tippy>
                        </Tippy>
                      </div>
                    </CopyToClipboard>
                    {isMeeting && (
                      <Tippy content="Meeting settings">
                        <div>
                          <Tippy
                            interactive={true}
                            theme="light"
                            arrow={false}
                            placement="bottom-end"
                            className="tippy-menu-padding"
                            trigger="click"
                            content={
                              <MeetingSettingsMenu
                                currentUserId={userStore.userId}
                                session={query?.data}
                                setShowingConfirmMeetingDelete={
                                  setShowingConfirmMeetingDelete
                                }
                                homeAndRefresh={homeAndRefresh}
                              />
                            }
                          >
                            <button className="link-button button">
                              <div className="icon ">
                                <FontAwesomeIcon icon={faCog} />
                              </div>
                            </button>
                          </Tippy>
                        </div>
                      </Tippy>
                    )}
                    {!isMeeting && (
                      <>
                        {subscribedToSpace(sessionId) ? (
                          <>
                            <Tippy content="Notifications on">
                              <button
                                className="button"
                                onClick={() => unsubscribeFromSpace(sessionId)}
                              >
                                <div className="subscribed-bell icon ">
                                  <FontAwesomeIcon icon={faBell} />
                                </div>
                              </button>
                            </Tippy>
                          </>
                        ) : (
                          <Tippy content="Notifications off">
                            <button
                              className="button"
                              onClick={() => subscribeToSpace(sessionId)}
                            >
                              <div className="subscribed-bell unsubscribed icon ">
                                <FontAwesomeIcon icon={faBellSlash} />
                              </div>
                            </button>
                          </Tippy>
                        )}
                        <Tippy content={`View all ${memberCount} members`}>
                          <div
                            className="button space-membership-population"
                            onClick={() => showMembersModal()}
                          >
                            <TopMembers
                              data={sessionMembershipSummaryQuery.data}
                            />
                            <div className="member-count">{memberCount}</div>
                          </div>
                        </Tippy>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {limitExceeded && (
                <div className="notification is-warning">
                  Usage limit exceeded.
                </div>
              )}

              <FoyerCardList
                addRoom={addRoom}
                createAuditorium={createAuditorium}
                createRoom={createRoom}
                groupList={groupList}
                onClickRoom={onClickRoom}
                sessionId={sessionId}
              />
              <FoyerDocumentList sessionId={sessionId} groupList={groupList} />
            </>
          )
        ) : (
          <></>
        )}
      </div>
      <Modal
        onRequestClose={() => setShowingMembersModal(false)}
        showingModal={showingMembersModal}
      >
        <SpaceMembersInfinite sessionId={sessionId} />
      </Modal>
      <Modal
        showingModal={officeStore.showingSpaceChangePhoto}
        onRequestClose={() => officeStore.hideSpaceChangePhoto()}
      >
        <ChangeSpacePhotoModal />
      </Modal>
      <Modal
        showingModal={showingConfirmMeetingDelete}
        onRequestClose={() => setShowingConfirmMeetingDelete(false)}
      >
        <ConfirmMeetingDelete
          setShowingConfirmMeetingDelete={setShowingConfirmMeetingDelete}
          deleteMeeting={deleteMeeting}
        />
      </Modal>
    </div>
  );
}
export default observer(Body);
