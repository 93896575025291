import { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/utils";
import "./CallManagement.scss";
import CallRow from "../CallRow/CallRow";
import CreateCallModal from "../CreateCallModal/CreateCallModal";
import * as Amplitude from "../../../utilities/amplitude";
import Loader from "../../Loader";

const CallManagement = () => {
  const { viewStore, sessionsStore, userStore, permissionsStore } = useStores();
  const [showCreateCall, setShowCreateCall] = useState(false);
  const [creatingCall, setCreatingCall] = useState(false);

  const toggleShowCreateCall = useCallback(() => {
    if (!userStore.isVerified) {
      return viewStore.showNotVerifiedModal();
    }

    Amplitude.track("Clicked create call in host dashboard");
    setShowCreateCall((x) => !x);
  }, [userStore.isVerified, viewStore]);

  const deleteCall = useCallback(
    async (sidebarSessionId) => {
      Amplitude.track("Clicked delete call in host dashboard", {
        call_id: sidebarSessionId,
      });
      const sure = window.confirm(
        "Are you sure you want to DELETE this call and all its rooms? This cannot be undone!"
      );
      if (sure) {
        await sessionsStore.deleteSession(sidebarSessionId);
      }
    },
    [sessionsStore]
  );

  const createCall = useCallback(
    async (data) => {
      setCreatingCall(true);
      try {
        await sessionsStore.createSession(data);
        Amplitude.track("Created call in host dashboard", {
          new_call_name: data.call_name,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setCreatingCall(false);
        setShowCreateCall(false);
      }
    },
    [setShowCreateCall, sessionsStore]
  );

  return (
    <div className="call-management">
      <h1 className="title is-1">Event Links</h1>

      {permissionsStore.canCreateEvent && (
        <div
          className="button create-call-button is-sblue"
          onClick={() => {
            toggleShowCreateCall();
          }}
        >
          Create Event Link
        </div>
      )}

      {creatingCall && <div className="create-call-loading"></div>}
      <div className="box">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>URL</th>
              {/*<th>Used this month</th>*/}
              <th>Created</th>
              <th>Created by</th>
              <th>Delete</th>
            </tr>
          </thead>

          <tbody>
            {sessionsStore.sessions.length > 0 ? (
              sessionsStore.sessions.map((session) => (
                <CallRow
                  session={session}
                  key={session.id}
                  deleteCall={deleteCall}
                />
              ))
            ) : (
              <tr>
                <td colSpan="5">
                  <div className="no-sessions">
                    {sessionsStore.isFetching ? (
                      <Loader />
                    ) : (
                      <>
                        No event links yet. {/* eslint-disable-next-line */}
                        <a onClick={() => toggleShowCreateCall()}>
                          Create event link
                        </a>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <CreateCallModal
        showCreateCall={showCreateCall}
        setShowCreateCall={setShowCreateCall}
        toggleShowCreateCall={toggleShowCreateCall}
        refetchSessions={() => null}
        createCall={createCall}
        creatingCall={creatingCall}
      />
    </div>
  );
};

export default observer(CallManagement);
