import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { MdClose } from "react-icons/md";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as Yup from "yup";
import { Formik } from "formik";
import { ChangePaymentForm } from "./ChangePaymentForm";
import "components/SignupForm/CapModal.scss";
import "components/SignupForm/Cards.scss";
import "components/SignupForm/CreditCardForm.scss";

const BillingAddressSchema = Yup.object().shape({
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  postal_code: Yup.number("Postal code must be number").required(
    "Postal code is required"
  ),
  line1: Yup.string().required("Address is required"),
  line2: Yup.string(),
});

export const ChangePaymentModal = ({ handleCloseModal }) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const strPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    setStripePromise(strPromise);
  }, []);

  if (!stripePromise) {
    return null;
  }

  return (
    <ReactModal
      isOpen
      overlayClassName="ReactModal__Overlay__disabled"
      className="ReactModal__Content__modal"
    >
      <Elements stripe={stripePromise}>
        <div className="modal-wrapper">
          <div className="modal-close-wrap" onClick={handleCloseModal}>
            <MdClose size={20} />
          </div>
          <div className="modal-header">Change payment details</div>
          <div className="modal-content">
            <div className="payment-container">
              <Formik
                initialValues={{
                  city: "",
                  country: "US",
                  state: "",
                  postal_code: "",
                  line1: "",
                  line2: "",
                }}
                validationSchema={BillingAddressSchema}
              >
                <ChangePaymentForm handleCloseModal={handleCloseModal} />
              </Formik>
            </div>
          </div>
        </div>
      </Elements>
    </ReactModal>
  );
};

ChangePaymentModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
