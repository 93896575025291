import { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import DocEditorsPopup from "components/Office/DocEditorsPopup";
import ContentEditable from "react-contenteditable";
import { debounce } from "lodash";

const DocTitle = ({ docId, docName, editing, startEditing, stopEditing }) => {
  const { docStore } = useStores();

  const [cachedDocName, setCachedDocName] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const contentEditableRef = useRef();

  useEffect(() => {
    if (docName) {
      setCachedDocName(docName);
    }
  }, [docName]);

  const saveDocName = useCallback(
    async (textValue) => {
      await docStore.updateName(docId, textValue);
    },
    [docStore, docId]
  );

  // eslint-disable-next-line
  const debouncedSaveDocName = useCallback(debounce(saveDocName, 1000), [
    saveDocName,
  ]);

  const handleChange = useCallback(
    (evt) => {
      const nameFieldValue = evt.target.value.trim();
      setCachedDocName(nameFieldValue);
      debouncedSaveDocName(nameFieldValue);
    },
    [debouncedSaveDocName]
  );

  const handleBlur = useCallback(async () => {
    const textValue = contentEditableRef.current.innerText.trim();
    if (cachedDocName !== textValue) {
      setShowLoader(true);
      await saveDocName(textValue);
      setShowLoader(false);
    }
  }, [cachedDocName, saveDocName]);

  const pressEnter = useCallback((e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }, []);

  // const clickDocName = useCallback(() => {
  //   if (setFocusedEditRoom) {
  //     setFocusedEditRoom(roomId);
  //   }
  // }, [name, roomId, setFocusedEditRoom]);

  const openSettings = () => {
    docStore.showDocumentSettingsDocId(docId);
  };

  let editButton;
  let settingsButton = (
    <div className="doc-pane-settings-button" onClick={openSettings}>
      <FontAwesomeIcon icon={faCog} />
    </div>
  );
  if (editing) {
    editButton = (
      <button
        onClick={stopEditing}
        className="button doc-edit-button doc-stop-button"
        id="button1"
      >
        Stop editing
      </button>
    );
  } else {
    editButton = (
      <Tippy
        content={<DocEditorsPopup docId={docId} />}
        disabled={!docStore.editorListFull}
        interactive={true}
        placement="bottom-end"
      >
        <div className="is-inline-block">
          <button
            onClick={startEditing}
            className={classNames("button doc-edit-button", {})}
            id="button2"
            disabled={docStore.editorListFull}
          >
            Edit
          </button>
        </div>
      </Tippy>
    );
  }

  return (
    <h1 className="doc-title">
      <span>
        <ContentEditable
          innerRef={contentEditableRef}
          html={cachedDocName}
          onBlur={handleBlur}
          onChange={handleChange}
          className="editable-name"
          onKeyDown={pressEnter}
          placeholder={"Document name"}
          spellCheck={false}
        />
      </span>

      <nobr>
        {editButton} {settingsButton}
      </nobr>
    </h1>
  );
};

export default observer(DocTitle);
