import { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-away.css";
import "./SideMenu.scss";

import { ReactComponent as ReactLogo } from "../../images/SVG/sidebar-logo.svg";
import { ReactComponent as LeftArrow } from "../../images/SVG/chevron-left-solid.svg";
import { ReactComponent as RightArrow } from "../../images/SVG/chevron-right-solid.svg";
import ReactModal from "react-modal";
import { useStores } from "../../stores/utils";
import { STATE_IDLE, STATE_LURKING } from "../../stores/AppStore";
import * as Amplitude from "../../utilities/amplitude";

import RoomsMenu from "./RoomsMenu";
import BottomMenu from "./BottomMenu";
import InviteModal from "./InviteModal";
import DebugMenu from "../DebugMenu/DebugMenu";

import "./SideMenu.scss";

function SideMenu(props) {
  const { userStore, viewStore, appStore } = useStores();
  const [showInvite, setShowInvite] = useState(false);
  const [focusedEditRoom, setFocusedEditRoom] = useState(null);

  const logout = useCallback(() => {
    Amplitude.track("Clicked log out from call");
    return userStore.logout({ redirect: false, refresh: false });
  }, [userStore]);

  useEffect(() => {
    ReactModal.setAppElement("#root");
  }, []);

  const toggleShowInvite = () => {
    Amplitude.track("clicked Show Invite URL button");
    setShowInvite((si) => !si);
  };

  let showOrHideMenuClass = viewStore.hideSideMenu ? "hide-side-menu" : "";

  const darkArrows = [STATE_IDLE, STATE_LURKING].includes(appStore.appState);

  return (
    <>
      <div className={`${showOrHideMenuClass || ""} side-menu`}>
        <div className="padding">
          <div className="title-image">
            <ReactLogo />
          </div>
          <div className="two-sections">
            <RoomsMenu
              focusedEditRoom={focusedEditRoom}
              setFocusedEditRoom={setFocusedEditRoom}
            />

            {appStore.isDebug && <DebugMenu />}
            <BottomMenu
              logout={logout}
              toggleShowHostLogin={viewStore.toggleShowHostLogin}
              toggleShowInvite={toggleShowInvite}
              {...props}
            />
          </div>
        </div>
        <div
          className={`${darkArrows ? "dark-expand " : ""} collapse-button`}
          onClick={() => viewStore.toggleSideMenu()}
        >
          <LeftArrow />
        </div>
      </div>
      {viewStore.hideSideMenu && (
        <div
          className={`${darkArrows ? "dark-expand " : ""} show-button`}
          onClick={() => viewStore.toggleSideMenu()}
        >
          <div className="show-icon">
            <RightArrow />
          </div>
        </div>
      )}
      <InviteModal
        showInvite={showInvite}
        toggleShowInvite={toggleShowInvite}
      />
    </>
  );
}

export default observer(SideMenu);
