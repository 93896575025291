import { useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import * as Amplitude from "../../utilities/amplitude";
import { useStores } from "stores/utils";
import api from "../../api";
import "./AcceptInviteForm.scss";

const AcceptInviteForm = (props) => {
  const { userStore, viewStore } = useStores();
  const { register, handleSubmit, errors } = useForm();
  const [resetLoading, setResetLoading] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [invitationData, setInvitationData] = useState({});

  const navigate = props.navigate;

  const focusRef = useCallback(
    (inputElement) => {
      register(inputElement, { required: true });
      if (inputElement) {
        inputElement.focus();
      }
    },
    [register]
  );

  useEffect(() => {
    const func = async () => {
      try {
        const info = await api.getInvitationInfo(props.inviteToken);
        console.log("invite info: ", info);
        setInvitationData(info.data);
      } catch (e) {
        console.log("Oops, this invitation has already been accepted.");
        window.location.href = "/login/accepted";
        return;
      }
    };
    func();
  }, [props.inviteToken]);

  const onSubmit = useCallback(
    async (credentials) => {
      setResetError(false);
      setResetLoading(true);
      try {
        await api.acceptInviteSetPassword(
          props.inviteToken,
          credentials.password
        );
      } catch (error) {
        setResetLoading(false);
        if (error.response && error.response.status === 404) {
          setResetError(true);
          return;
        } else {
          throw error;
        }
      }
      Amplitude.track(
        "Accepted Invite to organization, submitted create account form with new password.",
        {
          email: credentials.email,
        }
      );

      const loginCredentials = {
        email: invitationData.email,
        password: credentials.password,
      };
      await userStore.login(loginCredentials);

      await new Promise((r) => setTimeout(r, 700));
      setResetLoading(false);
      viewStore.setGeneralNotification({
        bodyText: `Welcome to Sidebar! You can now get started creating and managing event links for ${invitationData.organization.name}.`,
        buttonText: "Got it!",
      });
      navigate("/host");
    },
    [userStore, invitationData, viewStore, navigate, props.inviteToken]
  );

  return (
    <div className="accept-invite-form">
      <>
        <h1 className="title is-3 is-size-3-mobile">Set a password</h1>

        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            {resetError && (
              <div className="has-text-danger has-text-weight-bold">
                This reset link is invalid.
              </div>
            )}

            <div className="field">
              <div className="control">
                <input
                  name="email"
                  className="input"
                  type="email"
                  defaultValue={invitationData.email}
                  disabled
                  ref={focusRef}
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <input
                  name="password"
                  className="input"
                  type="password"
                  placeholder="Password"
                  ref={focusRef}
                />
                {errors.password && (
                  <div className="message-row has-text-danger">
                    This field is required
                  </div>
                )}
              </div>
            </div>

            <div className="field">
              <div className="control">
                <button className="button is-link">Create Account</button>
                {resetLoading && <span className="reset-loading" />}
              </div>
            </div>
          </form>
        </>
      </>
    </div>
  );
};

export default observer(AcceptInviteForm);
