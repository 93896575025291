import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";

function checkNotificationPromise() {
  try {
    Notification.requestPermission().then();
  } catch (e) {
    return false;
  }

  return true;
}

function RequestNotifications({ skip }) {
  const [checking, setChecking] = useState(false);
  const [status, setStatus] = useState("default");
  const [showingTippy, setShowingTippy] = useState(false);

  useEffect(() => {
    setStatus(Notification.permission);
  }, []);

  const requestPermission = () => {
    setShowingTippy(false);
    setChecking(true);
    if (checkNotificationPromise()) {
      Notification.requestPermission().then((permission) => {
        setStatus(Notification.permission);
      });
    } else {
      Notification.requestPermission(function (permission) {
        setStatus(Notification.permission);
      });
    }
  };

  useEffect(() => {
    if (status === "granted") {
      setTimeout(() => {
        skip();
      }, 750);
    }
  }, [skip, status]);

  return (
    <div className="office-onboarding-request-notifications">
      <div className="content">
        <div className="request-description">
          <h2>Sidebar needs your permission to enable desktop notifications</h2>
          <p>We'll notify you when teammates want to talk.</p>
        </div>
        <div className="actions is-flex is-justify-content-center">
          {status === "denied" ? (
            <div>
              <div className="notification">
                <p>
                  Your browser is set to <strong>deny</strong> desktop
                  notifications from Sidebar.{" "}
                  <em>You may miss calls or activity in Sidebar.</em>
                </p>
                <p>
                  To enable notifications, follow these instructions for{" "}
                  <a
                    href="https://support.google.com/chrome/answer/3220216?co=GENIE.Platform%3DDesktop&hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Chrome
                  </a>
                  ,{" "}
                  <a
                    href="https://support.apple.com/guide/safari/websites-ibrwe2159f50/14.0/mac/11.0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Safari
                  </a>
                  , or{" "}
                  <a
                    href="https://support.mozilla.org/en-US/kb/push-notifications-firefox"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Firefox
                  </a>
                  .
                </p>
                <p>
                  <small>
                    Note: Incognito mode does not support notifications.
                  </small>
                </p>
              </div>
            </div>
          ) : status === "granted" ? (
            <div className="notifications-success">
              <FontAwesomeIcon icon={faCheckCircle} />
            </div>
          ) : checking ? (
            <div className="checking-loader loader"></div>
          ) : (
            <div
              className="button is-link onboarding-button"
              onClick={() => requestPermission()}
            >
              Ok
            </div>
          )}
        </div>
      </div>
      <Tippy
        content={
          <>
            <div>
              <div>
                We strongly recommend enabling desktop notifications if you’ll
                be using Sidebar on this computer. Otherwise, you’ll miss
                colleagues trying to contact you.
              </div>
              <div className="tippy-actions">
                <div
                  className="button is-small is-link"
                  onClick={() => requestPermission()}
                >
                  Enable
                </div>{" "}
                <div
                  className="button is-small is-white"
                  onClick={() => skip()}
                >
                  Skip
                </div>
              </div>
            </div>
          </>
        }
        interactive={true}
        className="skip-tippy"
        visible={showingTippy}
        onClickOutside={() => setShowingTippy(false)}
      >
        <div className="skip-button">
          <div
            className="button is-ghost"
            onClick={() => setShowingTippy(true)}
          >
            Skip
          </div>
        </div>
      </Tippy>
    </div>
  );
}

export default observer(RequestNotifications);
