/* eslint-disable jsx-a11y/anchor-is-valid */
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "../../../stores/utils";
import StatusCell from "./StatusCell";

const TeamMemberRow = ({ teamMember }) => {
  const { permissionsStore, userStore } = useStores();

  return (
    <tr>
      <td>{teamMember.name}</td>
      <td>{teamMember.email}</td>
      <td>{teamMember.invitedBy}</td>
      <td>
        <StatusCell teamMember={teamMember} />
      </td>

      <td>
        {permissionsStore.canDeleteUser &&
          userStore.userInfo.id !== teamMember.id && (
            <a onClick={teamMember.delete}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </a>
          )}
      </td>
    </tr>
  );
};

export default observer(TeamMemberRow);
