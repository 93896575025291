import { observer } from "mobx-react-lite";
import { navigate } from "@reach/router";
import { useStores } from "stores/utils";
import HostLoginModal from "../../components/HostLoginModal/HostLoginModal";
import "./Login.scss";
import PlainGreyLayout from "../../components/Layouts/PlainGreyLayout";
import { useEffect } from "react";

export function Login(props) {
  const { userStore } = useStores();

  const urlParams = new URLSearchParams(window.location.search);
  const redirectDestination = urlParams.get("to");
  const redirectToHost = redirectDestination === "host";
  const isAuthenticated = userStore.isAuthenticated;

  useEffect(() => {
    if (isAuthenticated) {
      if (userStore.loginDestination) {
        navigate(userStore.loginDestination);
      } else {
        if (redirectDestination === "host") {
          navigate("/host");
        } else {
          if (userStore?.userInfo?.office_enabled) {
            navigate("/office");
          } else {
            navigate("/host");
          }
        }
      }
    }
  }, [isAuthenticated, userStore, redirectDestination]);

  let message, notification;
  if (props["*"]) {
    if (props["*"].includes("expired")) {
      message = "Your session expired. Please log in again.";
    }
    if (props["*"].includes("signedout")) {
      message = "Signed out successfully!";
    }
    if (props["*"].includes("accepted")) {
      notification = (
        <div className="login-modal-padding">
          <div className="notification is-primary">
            Invitation already accepted (or might have been deleted). Sign in
            below!
          </div>
        </div>
      );
    }
    if (props["*"].includes("welcome")) {
      notification = (
        <div className="login-modal-padding">
          <div className="notification is-primary">
            Success! You may now sign in to your new account below.
          </div>
        </div>
      );
    }
  }

  if (userStore.isInitializing) {
    return (
      <div className={"app white-app-bg"}>
        <h1>Loading</h1>
      </div>
    );
  }

  return (
    <PlainGreyLayout blueLogo={!redirectToHost}>
      <div className=" content-column narrow">
        {notification}
        <HostLoginModal
          navigate={navigate}
          loginMessage={message}
          goToForgot={() => navigate("forgot")}
          redirectDestination={redirectDestination}
          hideHostTitle={true}
        />
      </div>
    </PlainGreyLayout>
  );
}

export default observer(Login);
