import { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-light-svg-icons";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import Sliders from "./Sliders";
import { useStores } from "../../stores/utils";
import AnimatedButton from "./AnimatedButton";
import "./TileSettings.scss";

function TileSettings({ myId }) {
  const [isOpen, setIsOpen] = useState(false);
  const { tileSettingsStore } = useStores();

  const mySettings = tileSettingsStore.changedTiles[myId];

  const showSlider = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const hideSlider = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const goRoundMode = useCallback(() => {
    showSlider();
    tileSettingsStore.shareTileSetting({
      "background-color": "#222222",
      "tile-dimensions": "50",
    });
  }, [showSlider, tileSettingsStore]);

  const goDefaultMode = useCallback(() => {
    hideSlider();
    tileSettingsStore.shareTileSetting({
      "background-color": "default",
      "tile-dimensions": "default",
    });
  }, [hideSlider, tileSettingsStore]);

  return (
    <>
      <div className="tile-settings-container">
        <Tippy
          content={<Sliders myId={myId} />}
          theme="light"
          animation="fade"
          visible={isOpen}
          onClickOutside={hideSlider}
          interactive
          appendTo="parent"
        >
          {mySettings ? (
            <div>
              <AnimatedButton
                rotation={25}
                timing={200}
                scale={1.1}
                goDefaultMode={goDefaultMode}
                showSlider={showSlider}
              />
            </div>
          ) : (
            <Tippy content="Round video" appendTo="parent">
              <button className="button" onClick={goRoundMode}>
                <FontAwesomeIcon icon={faCircle} />
              </button>
            </Tippy>
          )}
        </Tippy>
      </div>
    </>
  );
}

export default observer(TileSettings);
