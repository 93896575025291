import { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import ReactModal from "react-modal";
import { useForm } from "react-hook-form";
import { useStores } from "../../../stores/utils";
import StatusDropdown from "../../StatusDropdown";

function CreateInvitationModal() {
  const { teamStore, sessionsStore } = useStores();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("manager");
  const [permittedEvents, setPermittedEvents] = useState([]);

  const { register, handleSubmit /* watch, errors, reset*/ } = useForm();

  const callbackRef = useCallback(
    (inputElement) => {
      register(inputElement);
      if (inputElement && inputElement.name === "teammate_name") {
        inputElement.focus();
      }
    },
    [register]
  );

  const togglePermittedEvent = useCallback(
    (eventId) => {
      if (permittedEvents.some((x) => x === eventId)) {
        setPermittedEvents(permittedEvents.filter((x) => x !== eventId));
      } else {
        setPermittedEvents([...permittedEvents, eventId]);
      }
    },
    [permittedEvents, setPermittedEvents]
  );

  return (
    <ReactModal
      isOpen={teamStore.isShowCreateModal}
      onRequestClose={teamStore.toggleShowCreateModal}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      className="custom-modal"
      overlayClassName="modal-background"
    >
      <div className="modal-content create-call-modal">
        <div className="box is-info">
          <h1 className="title is-3 is-size-3-mobile">Invite Teammate</h1>
          <form onSubmit={handleSubmit(teamStore.inviteNewMember)}>
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  name="teammate_name"
                  type="text"
                  placeholder="Teammate's name"
                  ref={callbackRef}
                  onChange={(event) => setName(event.target.value)}
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <input
                  className="input"
                  name="teammate_email"
                  type="text"
                  placeholder="Teammate's email"
                  ref={callbackRef}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>

              {teamStore.createInvitationError && (
                <div className="field">
                  <div className="message-row has-text-danger has-text-weight-bold">
                    {teamStore.createInvitationError}
                  </div>
                </div>
              )}
            </div>

            <div className="field">
              <div className="label">Teammate's status</div>
              <div className="control">
                <StatusDropdown
                  onRoleChange={setRole}
                  role={role}
                  sessionsStore={sessionsStore}
                  permittedEvents={permittedEvents}
                  togglePermittedEvent={togglePermittedEvent}
                />

                <input
                  type="hidden"
                  name="teammate_role"
                  value={role}
                  ref={callbackRef}
                />
              </div>
            </div>

            {role === "host" && (
              <input
                type="hidden"
                name="permitted_events"
                value={permittedEvents.join(",")}
                ref={callbackRef}
              />
            )}

            <div className="field is-grouped">
              <div className="control">
                <input
                  disabled={
                    !name.length || !email.length || teamStore.isLoading
                  }
                  type="submit"
                  className="button is-link"
                  value="Invite"
                />
              </div>
              <div className="control">
                {/* eslint-disable-next-line */}
                <a
                  className="button is-link is-light"
                  onClick={teamStore.toggleShowCreateModal}
                >
                  Cancel
                </a>
              </div>
              {teamStore.isLoading && (
                <div className="create-call-loading"></div>
              )}
            </div>
          </form>
        </div>
      </div>
    </ReactModal>
  );
}

export default observer(CreateInvitationModal);
