import { ReactComponent as SidebarLogo } from "../../images/SVG/sidebar-logo.svg";
import { ReactComponent as BlueSidebarLogo } from "../../images/SVG/blue_logo.svg";
import classnames from "classnames";
import "./Layouts.scss";

const PlainPhotoLayout = ({ children, blueLogo, className }) => {
  return (
    <div className={"plain-photo-page " + className}>
      <div className="title-row">
        <div className={classnames("title-image", { "blue-logo": blueLogo })}>
          {blueLogo ? <BlueSidebarLogo /> : <SidebarLogo />}
        </div>
      </div>
      <div className="flex-container">{children}</div>
    </div>
  );
};

export default PlainPhotoLayout;
