import { computed } from "mobx";

/*
    permissions:
    - An admin can invite or delete teammates,
    can delete and change setting on teammates' links, and modify billing.
    - A manager can't delete teammates, can't invite new teammates,
    and can't delete or edit settings on other peoples' event links.
    They can only create and delete their own event links. They can't see the billing tab.
    They still have Host powers on any call for their org when they are logged in.
  */

export class PermissionsStore {
  constructor(rootStores) {
    this.rootStores = rootStores;
  }

  @computed
  get isAdmin() {
    return this.rootStores.userStore.isAdmin;
  }

  @computed
  get isManager() {
    return this.rootStores.userStore.isManager;
  }

  @computed
  get canDeleteUser() {
    return this.isAdmin;
  }

  @computed
  get canInvite() {
    return this.isAdmin;
  }

  @computed
  get canEditUser() {
    return this.isAdmin;
  }

  @computed
  get canManageAllEvents() {
    return this.isAdmin;
  }

  canManageEvent = (session) => {
    if (this.canManageAllEvents) return true;

    return (
      session.relationships?.creator?.data?.id ===
      this.rootStores.userStore.userInfo.id
    );
  };

  @computed
  get canSeeBillingPage() {
    return this.isAdmin;
  }

  @computed
  get canSeeTeamPage() {
    return this.isAdmin || this.isManager;
  }

  @computed
  get canCreateEvent() {
    return this.isAdmin || this.isManager;
  }
}
