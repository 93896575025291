import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretRight,
  faArrowDown,
  faArrowUp,
} from "@fortawesome/pro-solid-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faSlack } from "@fortawesome/free-brands-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { ParticipantModelLite } from "../../stores/ParticipantModelLite";
import GroupModel from "../../stores/GroupModel";
import RoomsListCurrentCall from "./RoomsListCurrentCall";
import SpaceListRow from "./SpaceListRow";
import PersonRow from "./PersonRow";
import PrivateCall from "./PrivateCall";
import DebugMenu from "../DebugMenu/DebugMenu";
import _ from "lodash";
import { Offline } from "react-detect-offline";
import { IoCloudOfflineOutline } from "react-icons/io5";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "./SideMenuContent.scss";
import classnames from "classnames";
import Honeybadger from "honeybadger-js";
import UserMeetingsMenuSection from "./UserMeetingsMenuSection";

function SideMenuContent({
  people,
  includedDataForPeople,
  fetchingPreferencesCompleted,
  privateSummaryQuery,
}) {
  const rootStores = useStores();
  const {
    api,
    appStore,
    callStore,
    officeStore,
    userStore,
    sessionsStore,
    userChatStore,
  } = rootStores;
  const userId = userStore.userId;
  const availabilityStatus = userStore.userInfo?.availability_status;

  const [spacesList, setSpacesList] = useState([]);
  const [manageSpacesVisible, setManageSpacesVisible] = useState(false);
  const [memberSpaces, setMemberSpaces] = useState([]);
  const [nonMemberSpaces, setNonMemberSpaces] = useState([]);
  const [showingOtherSpaces, setShowingOtherSpaces] = useState(false);

  const [sortedPeopleList, setSortedPeopleList] = useState([]);
  const [yourPeopleEntry, setYourPeopleEntry] = useState(null);

  const [forceHideInviteTeammates, setForceHideInviteTeammates] = useState(
    false
  );

  const [forceHideConnectSlack, setForceHideConnectSlack] = useState(false);

  const hideManageSpaces = () => setManageSpacesVisible(false);
  const toggleManageSpaces = () => setManageSpacesVisible((x) => !x);
  const toggleOtherSpaces = () => setShowingOtherSpaces((x) => !x);

  const spacesData = officeStore.spacesData;
  const includedData = officeStore.includedData;
  const withOtherSpacesActivity = sessionsStore.otherSpacesActivityCount > 0;

  const toggleInviteTeammates = () => {
    officeStore.setShowingInviteTeammates(true);
  };

  useEffect(() => {
    if (!nonMemberSpaces) return;
    let totalActivity = 0;

    for (const space of nonMemberSpaces) {
      const spaceId = space.session.id;
      const included = sessionsStore.viewedSpaces.includes(spaceId);

      if (space.groups.length && !included) {
        totalActivity++;
      } else if (!space.groups.length && included) {
        //resets the activity state if space goes to 0 participants.
        sessionsStore.removeViewedSpace(spaceId);
      }
    }
    sessionsStore.setOtherSpacesActivityCount(totalActivity);
  }, [nonMemberSpaces, sessionsStore]);

  useEffect(() => {
    if (people) {
      const meData = people.find((x) => x.id === userId);

      const officeParticipants = includedDataForPeople
        .filter((x) => x.type === "participants")
        .map((p) => {
          return new ParticipantModelLite({
            data: p,
            rootStores: rootStores,
          });
        });

      const officeGroupMap = new Map();
      includedDataForPeople
        .filter((x) => x.type === "groups")
        .forEach((group) => {
          const groupParticipants = officeParticipants.filter((x) => {
            return x.groupId === group.id;
          });

          const model = new GroupModel({
            data: group,
            participants: groupParticipants,
            rootStores: rootStores,
          });
          officeGroupMap.set(group.attributes["office-owner-id"], model);
        });

      meData.officeGroup = officeGroupMap.get(userId);

      let sorted = _.sortBy(
        people.filter((x) => x.id !== userId),
        [
          (x) => (x.attributes["online-status"] === "online" ? "a" : "b"),
          (x) =>
            x.attributes["availability-status"] === "Door is open" ? "a" : "b",
          (x) => x.attributes.name,
        ]
      );

      sorted.forEach((person) => {
        person.officeGroup = officeGroupMap.get(person.id);
      });
      setYourPeopleEntry(meData);
      setSortedPeopleList(sorted);
    }
  }, [people, includedDataForPeople, userId, rootStores]);

  useEffect(() => {
    if (!availabilityStatus) return;
    if (people) {
      if (
        yourPeopleEntry &&
        yourPeopleEntry.attributes["availability-status"] !== availabilityStatus
      ) {
        setYourPeopleEntry((prevState) => {
          prevState.attributes["availability-status"] = availabilityStatus;
          return {
            ...prevState,
          };
        });
      }
    }
  }, [availabilityStatus, people, yourPeopleEntry]);

  useEffect(() => {
    const memberOfTheseSpaceIds = userStore.memberSpaces;

    const spacesNotCurrentlyIn = spacesList.filter(
      (obj) => obj.session.id !== callStore.sessionId
    );

    const memberOfTheseSpaces = spacesNotCurrentlyIn.filter((space) =>
      memberOfTheseSpaceIds.includes(space.session.id)
    );
    const notMemberOfTheseSpaces = spacesNotCurrentlyIn.filter(
      (space) => !memberOfTheseSpaceIds.includes(space.session.id)
    );

    setMemberSpaces(
      _.sortBy(memberOfTheseSpaces, [(x) => x.session.attributes.name])
    );
    setNonMemberSpaces(
      _.sortBy(notMemberOfTheseSpaces, [(x) => x.session.attributes.name])
    );
  }, [spacesList, callStore.sessionId, userStore.memberSpaces]);

  useEffect(() => {
    const spacesList = officeStore.buildSpacesList(spacesData, includedData);
    setSpacesList(spacesList);
  }, [officeStore, spacesData, includedData]);

  const scrollableContainer = useRef();
  const isOffscreenNotifTop = userChatStore.isShowingOffscreenNotifTop;
  const isOffscreenNotifBottom = userChatStore.isShowingOffscreenNotifBottom;

  const scrollTo = (pos) => {
    if (pos === "top") {
      if (userChatStore.offscreenNotifRefTop?.current) {
        const target = userChatStore.offscreenNotifRefTop?.current;
        const targetOffsetTop =
          target.parentNode.parentNode.parentNode.offsetTop;
        scrollableContainer?.current?.scroll({
          top: targetOffsetTop - 50,
          behavior: "smooth",
        });
      }
    } else {
      userChatStore.offscreenNotifRefBottom?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  const dismissConnectSlack = (e) => {
    e.preventDefault();
    try {
      api.setUserPreferenceValue("dismissed_connect_slack", true);
      setForceHideConnectSlack(true);
    } catch (e) {
      Honeybadger.notify(e);
    }
  };

  const dismissInviteTeammates = (e) => {
    e.stopPropagation();
    try {
      api.setUserPreferenceValue("dismissed_invite_teammates", true);
      setForceHideInviteTeammates(true);
    } catch (e) {
      Honeybadger.notify(e);
    }
  };

  const hideConnectSlack =
    userStore?.userInfo?.slack_team_id ||
    !!userStore?.basicPreferences?.dismissed_connect_slack ||
    forceHideConnectSlack;
  const hideInviteTeammates =
    !!userStore?.basicPreferences?.dismissed_invite_teammates ||
    forceHideInviteTeammates;

  const showAdminSuggestions =
    userStore?.isAdmin &&
    fetchingPreferencesCompleted &&
    (!hideConnectSlack || !hideInviteTeammates);

  const showUserConnectSlack =
    userStore?.userInfo?.slack_team_id &&
    !userStore?.userInfo?.slack_user_id &&
    !userStore?.basicPreferences?.dismissed_connect_slack &&
    !forceHideConnectSlack;

  return (
    <div className="office-side-menu-content" ref={scrollableContainer}>
      <div className="office-side-menu-padding">
        <Offline>
          <div className="menu-section">
            <div className="offline-alert notification is-sred is-light">
              <h2>
                <IoCloudOfflineOutline size={22} /> <strong>Offline</strong>
              </h2>
              <p>Your network is unavailable.</p>
            </div>
          </div>
        </Offline>
        {appStore.applyAppUpdate && (
          <div className="menu-section">
            <div className="update-available notification is-sblue is-light">
              <div className="level">
                <div className="level-left">New version available.</div>
                <div className="level-right">
                  <div
                    className="button is-sblue"
                    onClick={appStore.applyAppUpdate}
                  >
                    Refresh
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {(showAdminSuggestions || showUserConnectSlack) && (
          <div className="menu-section">
            <div className="admin-member-onboarding">
              {!hideConnectSlack && (
                <a
                  className="button is-fullwidth is-lightblue3 reminder-item"
                  href="/office/admin/slack"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="icon-wrapper slack-icon">
                    <FontAwesomeIcon icon={faSlack} />
                  </div>
                  Link your Slack workspace
                  <div
                    className="dismiss-reminder"
                    onClick={dismissConnectSlack}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </a>
              )}

              {!hideInviteTeammates && (
                <div
                  className="button is-fullwidth is-lightblue3 reminder-item"
                  onClick={() => toggleInviteTeammates()}
                >
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={faUserPlus} />
                  </div>{" "}
                  Invite teammates
                  <div
                    className="dismiss-reminder"
                    onClick={dismissInviteTeammates}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              )}

              {showUserConnectSlack && (
                <a
                  className="button is-fullwidth is-lightblue3 reminder-item"
                  href="/office/setup/slack?next=office"
                >
                  <div className="icon-wrapper slack-icon">
                    <FontAwesomeIcon icon={faSlack} />
                  </div>
                  Connect with Slack
                  <div
                    className="dismiss-reminder"
                    onClick={dismissConnectSlack}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </a>
              )}
            </div>
          </div>
        )}

        <UserMeetingsMenuSection
          privateSummaryQuery={privateSummaryQuery}
          scrollableContainer={scrollableContainer}
        />

        <div className="menu-section">
          <div className="spaces-list">
            <div className="section-header">
              <div className="header-title">Your Spaces</div>
              <div className="header-right">
                <Tippy
                  content={"Join Spaces"}
                  hideOnClick={true}
                  trigger="mouseenter"
                >
                  <Tippy
                    content={
                      <>
                        <div className="menu manage-spaces-menu">
                          <ul className="menu-list">
                            <li>
                              {/* eslint-disable-next-line */}
                              <a
                                onClick={() => {
                                  officeStore.setShowCreateSpace(true);
                                  hideManageSpaces();
                                }}
                              >
                                Create a space
                              </a>
                            </li>
                            <li>
                              {/* eslint-disable-next-line */}
                              <a
                                onClick={() => {
                                  officeStore.showJoinSpaces();
                                  hideManageSpaces();
                                }}
                              >
                                Join spaces
                              </a>
                            </li>
                          </ul>
                        </div>
                      </>
                    }
                    placement="bottom-start"
                    interactive={true}
                    theme="light"
                    visible={manageSpacesVisible}
                    onClickOutside={hideManageSpaces}
                  >
                    <button
                      className="button is-white is-small"
                      onClick={() => {
                        toggleManageSpaces();
                      }}
                    >
                      <span className="icon is-small">
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="add-space-icon"
                        />
                      </span>
                    </button>
                  </Tippy>
                </Tippy>
              </div>
            </div>
            {callStore.sessionId &&
              !officeStore.currentCall &&
              !officeStore.inOfficeSessionId &&
              officeStore.sessionType !== "meeting" && <RoomsListCurrentCall />}
            <ul>
              {memberSpaces &&
                memberSpaces.map((space) => {
                  return (
                    <SpaceListRow
                      key={space.session.id}
                      space={space}
                      subscribed={true}
                    />
                  );
                })}
            </ul>
          </div>
          {nonMemberSpaces.length > 0 && (
            <div className="other-spaces-list">
              <div
                className="section-header other-spaces-section"
                onClick={() => toggleOtherSpaces()}
              >
                {/* eslint-disable-next-line */}
                <a
                  className={classnames(
                    withOtherSpacesActivity &&
                      !showingOtherSpaces &&
                      "with-activity"
                  )}
                >
                  {showingOtherSpaces ? (
                    <FontAwesomeIcon icon={faCaretDown} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretRight} />
                  )}{" "}
                  <span className="link-text">Other spaces</span>
                </a>
                {sessionsStore.otherSpacesActivityCount > 0 &&
                  !showingOtherSpaces && (
                    <div className="other-spaces-activity-count">
                      {sessionsStore.otherSpacesActivityCount}
                    </div>
                  )}
              </div>
              {showingOtherSpaces && (
                <ul>
                  {nonMemberSpaces &&
                    nonMemberSpaces.map((space) => {
                      return (
                        <SpaceListRow
                          key={space.session.id}
                          space={space}
                          subscribed={false}
                        />
                      );
                    })}
                </ul>
              )}
            </div>
          )}
        </div>
        {callStore.sessionId &&
          officeStore.currentCall &&
          callStore.groupList.length > 0 && (
            <>
              <div className="conversation-section menu-section">
                <div className="section-header">
                  <div className="header-title">Private Call</div>
                  <div className="header-right"></div>
                </div>
                <PrivateCall />
              </div>
            </>
          )}
        <div className="people-list menu-section">
          <div className="section-header">
            <div className="header-title">People</div>
            <div className="header-right">
              {userStore?.isAdmin && (
                <Tippy
                  content={"Invite Teammates"}
                  hideOnClick={true}
                  trigger="mouseenter"
                >
                  <button
                    className="button is-white is-small"
                    onClick={() => {
                      toggleInviteTeammates();
                    }}
                  >
                    <span className="icon is-small">
                      <FontAwesomeIcon
                        icon={faUserPlus}
                        className="add-user-icon"
                      />
                    </span>
                  </button>
                </Tippy>
              )}
            </div>
          </div>

          <ul className="people-row-list">
            {yourPeopleEntry && (
              <li key={yourPeopleEntry.id}>
                <PersonRow person={yourPeopleEntry} />
              </li>
            )}
            {sortedPeopleList.map((p, i) => {
              return (
                <li key={p.id} className="">
                  <PersonRow person={p} idx={i} />
                </li>
              );
            })}
          </ul>
        </div>
        {appStore.isDebug && <DebugMenu />}
      </div>
      <div
        className={classnames("new-offscreen-messages top", {
          notify: isOffscreenNotifTop,
        })}
        onClick={() => scrollTo("top")}
      >
        <FontAwesomeIcon icon={faArrowUp} />
        <div>Unread Messages</div>
      </div>
      <div
        className={classnames("new-offscreen-messages bottom", {
          notify: isOffscreenNotifBottom,
        })}
        onClick={() => scrollTo("bottom")}
      >
        <FontAwesomeIcon icon={faArrowDown} />
        <div>Unread Messages</div>
      </div>
    </div>
  );
}

export default observer(SideMenuContent);
