/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { useStores, observe } from "stores/utils";
import "./TrialBanner.scss";

export const TrialBanner = () => {
  const { userSubscriptionStore, viewStore } = useStores();

  return observe(
    [
      userSubscriptionStore.isTrialSubscription,
      userSubscriptionStore.isActiveSubscription,
    ],
    () => {
      if (!userSubscriptionStore.isTrialSubscription) {
        return null;
      }

      return (
        <div className="notification is-primary trial-banner ">
          {userSubscriptionStore.isActiveSubscription ? (
            <>
              <span className="post-padded">
                You have{" "}
                {moment(
                  userSubscriptionStore.subscription?.data?.attributes
                    ?.trial_end
                ).diff(moment(), "days")}{" "}
                days left in your free trial. Cancel any time before then to
                avoid being charged.
              </span>
              <a
                href="#"
                className="button is-darkcarribean"
                onClick={viewStore.showStartSubscriptionModal}
              >
                Start subscription
              </a>
            </>
          ) : (
            <>
              <span className="post-padded">Trial cancelled.</span>
              <a
                href="#"
                className="button is-darkcarribean"
                onClick={() =>
                  userSubscriptionStore.setShowCancelSubscriptionModal(true)
                }
              >
                Resume trial
              </a>{" "}
              or{" "}
              <a
                className="button is-darkcarribean"
                href="#"
                onClick={viewStore.showStartSubscriptionModal}
              >
                Start subscription
              </a>
            </>
          )}
        </div>
      );
    }
  );
};
