import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useStores } from "../../stores/utils";
import classnames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tippy from "@tippyjs/react";
import Honeybadger from "honeybadger-js";
import "./InviteTeammates.scss";

function InviteTeammates({ modalWrapper = false }) {
  const { api, userStore } = useStores();
  const [invitationUrl, setInvitationUrl] = useState("Generating...");
  const [showCopiedTooltip, setShowCopiedTootip] = useState(false);

  useEffect(() => {
    const generateInvitation = async () => {
      try {
        const sharedInvitation = await api.generateSharedInvitation(
          userStore.organizationId
        );
        if (sharedInvitation?.data?.url) {
          setInvitationUrl(sharedInvitation?.data?.url);
        }
      } catch (e) {
        Honeybadger.notify(e);
      }
    };

    if (userStore.organizationId) {
      generateInvitation();
    }
  }, [api, userStore.organizationId, setInvitationUrl]);

  const triggerCopiedTooltip = useCallback(() => {
    setShowCopiedTootip(true);
    setTimeout(() => {
      setShowCopiedTootip(false);
    }, 8000);
  }, []);

  return (
    <div
      className={classnames("invite-teammates", "content", {
        "modal-padding": modalWrapper,
      })}
    >
      <h4>Share this link to invite teammates</h4>
      <div className="field has-addons">
        <div className="control">
          <input
            className="input shared-invitation-url"
            type="text"
            value={invitationUrl}
            readOnly
          />
        </div>
        <div className="control">
          <Tippy
            content="Link copied - expires in 30 days"
            visible={showCopiedTooltip}
          >
            <div>
              <CopyToClipboard
                text={invitationUrl}
                onCopy={triggerCopiedTooltip}
              >
                <button className="button is-sblue">Copy</button>
              </CopyToClipboard>
            </div>
          </Tippy>
        </div>
      </div>
      <p className="alternative-invite">
        Or,{" "}
        <a href="/office/admin/team" target="_blank" rel="noopener noreferrer">
          invite people by email
        </a>
      </p>
    </div>
  );
}

export default observer(InviteTeammates);
