import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import "./MeetingSettingsMenu.scss";

const MeetingSettingsMenu = ({
  currentUserId,
  session,
  setShowingConfirmMeetingDelete,
  homeAndRefresh,
}) => {
  const { api } = useStores();

  const creatorId = session?.data?.data?.relationships?.creator?.data?.id;
  const isOwner = creatorId === currentUserId;
  const sessionId = session?.data?.data?.id;

  const askDeleteMeeting = () => {
    setShowingConfirmMeetingDelete(true);
  };

  const forgetMeeting = async () => {
    await api.forgetMeeting(sessionId);
    homeAndRefresh();
  };

  return (
    <div className="meeting-settings-menu">
      <aside className="menu settings-menu">
        <ul className="menu-list">
          {isOwner ? (
            <li>
              {/* eslint-disable-next-line */}
              <a onClick={askDeleteMeeting}>
                <FontAwesomeIcon icon={faExclamationTriangle} /> Delete meeting
              </a>
            </li>
          ) : (
            <li>
              {/* eslint-disable-next-line */}
              <a onClick={forgetMeeting}>Remove from your meetings</a>
            </li>
          )}
        </ul>
      </aside>
    </div>
  );
};

export default observer(MeetingSettingsMenu);
