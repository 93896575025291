import classNames from "classnames";

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";

import Avatar from "../Avatar";
import { SvgAvatar } from "../../util/svgavatar";

import clearIcon from "./resources/clear.svg";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TinyProfilePhoto from "../../../ProfilePhoto/TinyProfilePhoto";
import "./UserChat.scss";
import { useStores } from "../../../../stores/utils";
import UserParticipantActions from "../../../Office/UserParticipantActions";
import LocalTime from "./LocalTime";
import React, { useEffect } from "react";
import { calculateLocalTime } from "./utils";
import api from "../../../../api";
import Honeybadger from "honeybadger-js";

import {
  chatHeaderStyle,
  chatDetailStyle,
  chatUserStyle,
  modalCloseStyle,
  userNameStyle,
  cssActivityDot,
} from "./style";

const MessageHeader = ({
  closeChat,
  updateTab,
  tab,
  tabs,
  item,
  type,
  person,
  ...props
}) => {
  const rootStores = useStores();
  const { userStore, callStore } = rootStores;
  const inUserOffice =
    callStore.sessionAttributes["session-type"] === "user-office";

  useEffect(() => {
    if (person) {
      const onUnmount = () => {
        const unreadMessages = userStore.userInfo.unread_messages;
        if (unreadMessages) {
          const um = unreadMessages.find((msg) => msg.sender_id === person.id);
          if (um) {
            try {
              api.clearUnreadMessages(userStore.userId, person.id);
            } catch (error) {
              Honeybadger.notify(error);
            }
          }
        }
      };

      window.addEventListener("beforeunload", onUnmount, false);

      return () => {
        onUnmount();
        window.removeEventListener("beforeunload", onUnmount, false);
      };
    }
  }, [userStore, person]);

  const getUserBlock = () => {
    const char = item.name.charAt(0).toUpperCase();
    const avatar = SvgAvatar.getAvatar(item.uid, char);

    return (
      <div css={userNameStyle()}>
        <div
          style={{
            width: "36px",
            height: "36px",
          }}
        >
          <Avatar
            cornerRadius="50%"
            borderColor={props.theme.color.secondary}
            borderWidth="1px"
            image={avatar}
          />
        </div>
        <div>{item.name}</div>
      </div>
    );
  };

  const getUserChatBlock = () => {
    const isMe = userStore.userId === person.id;

    const availabilityStatus =
      person.attributes["availability-status"] || "Online";
    const isDoNotDisturb = availabilityStatus === "Do not disturb";
    const isDoorOpen = availabilityStatus === "Door is open";

    const online = person.attributes["online-status"] === "online";
    const offline =
      person.attributes["online-status"] == null ||
      person.attributes["online-status"] === "offline";

    const timeZone = person.attributes["time-zone"] || "America/Los_Angeles";
    const prefetchedLocalTime = calculateLocalTime(timeZone);

    return (
      <div css={userNameStyle()}>
        <div>
          <TinyProfilePhoto
            photoUrl={person.attributes["tiny-photo-url"]}
            personName={person.attributes["name"]}
            placeholderColor={person.attributes["color"]}
          />
        </div>
        <div className="user-chat-brief-info-container">
          <div className="user-chat-title">
            {isMe ? (
              <React.Fragment>
                <div>{item.name}</div>
                <span className="you-label">you</span>
              </React.Fragment>
            ) : (
              <div className="is-not-my-name">{item.name}</div>
            )}
          </div>
          <div className="status-or-local-time-container">
            <div css={cssActivityDot(online, isDoNotDisturb, isDoorOpen)}></div>
            <div className="status-or-local-time-text">
              {offline ? "Offline" : availabilityStatus}
            </div>
          </div>
          <div className="status-or-local-time-container">
            <FontAwesomeIcon icon={faClock} color="#C4C4C4" />
            <div className="status-or-local-time-text">
              <div>
                Local time&nbsp;{" "}
                <LocalTime
                  prefetchedLocalTime={prefetchedLocalTime}
                  timezone={timeZone}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div css={chatHeaderStyle({ person, type, ...props })}>
      <div css={chatDetailStyle({ person })}>
        <div css={chatUserStyle({ type })}>
          {person ? (
            getUserChatBlock()
          ) : type === "user" ? (
            getUserBlock()
          ) : inUserOffice ? (
            <div className="in-office-chat">Chat</div>
          ) : (
            <div className="chat-tabs">
              {tabs.map((itemTab) => (
                <button
                  className={classNames("chat-tab", {
                    "tab-active": tab === itemTab.key,
                  })}
                  key={itemTab.key}
                  onClick={() => updateTab(itemTab.key)}
                >
                  <div className="chat-tabs-label">{itemTab.label}</div>
                  {itemTab.newMessages > 0 && (
                    <div className="new-message-count">
                      {itemTab.newMessages}
                    </div>
                  )}
                </button>
              ))}
            </div>
          )}
          <span
            css={modalCloseStyle(person, clearIcon)}
            onClick={closeChat}
            title="Close"
          />
        </div>
        {person && (
          <div className="user-chat-actions">
            <UserParticipantActions
              userId={person.id}
              availabilityStatus={
                person.attributes["availability-status"] || "Online"
              }
              officeId={person?.officeGroup?.id}
              userChat
              sayStart={!person?.officeGroup}
              hideHere={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageHeader;
