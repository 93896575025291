import { observer } from "mobx-react-lite";
import { useStores } from "stores/utils";
import ChangePhoto from "../ChangePhoto/ChangePhoto";
import { useQueryClient } from "react-query";
import { useQuery } from "react-query";
import "./ChangeLogo.scss";

function ChangeLogo() {
  const { api, userStore } = useStores();
  const queryClient = useQueryClient();
  const organizationId = userStore.organizationId;
  const organizationData = useQuery(["organization", organizationId], () =>
    api.getOrganization()
  );

  const uploadPhoto = async (croppedImage) => {
    await api.uploadOrganizationLogo(croppedImage, organizationId);
    await queryClient.refetchQueries();
  };

  const deletePhoto = async () => {
    await api.deleteOrganizationLogo(organizationId);
    await queryClient.refetchQueries();
  };

  const logoUrl =
    organizationData?.data?.data?.data?.attributes?.["tiny-logo-url"];

  return (
    <div className="change-logo content">
      <h1>Logo</h1>
      <div className="change-logo-container">
        <ChangePhoto
          doUpload={uploadPhoto}
          oldPhotoUrl={logoUrl}
          doDelete={deletePhoto}
        />
      </div>
    </div>
  );
}

export default observer(ChangeLogo);
