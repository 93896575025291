import { observer } from "mobx-react-lite";
import moment from "moment";
import ProgressLine from "./ProgressLine";
import Loader from "../../Loader";

const ParticipantsTime = ({ store }) => {
  return (
    <div className="info-block box mb10 info-block--full-width">
      <div className="info-block__header">
        <span className="info-block__title">Included participant minutes</span>
        {store.monthlyUsagePeriodEnd ? (
          <span className="info-block__label">
            Resets {moment(store.monthlyUsagePeriodEnd).format("MMM. DD")}
          </span>
        ) : null}
      </div>
      <div>
        <ProgressLine
          value={store.progress}
          isLoading={store.isWaitingPushData}
        />
      </div>
      <div className="info-block__footer">
        <span className="info-block__footer-title">
          <span>
            {store.totalUsage.hours}{" "}
            <span className="info-block__footer-title__title">hours</span>
          </span>{" "}
          <span>
            {store.totalUsage.minutes}{" "}
            <span className="info-block__footer-title__title">minutes</span>
          </span>
          <span>used</span> {store.isWaitingPushData && <Loader />}
        </span>
        <span className="info-block__label">
          {store.monthlyUsageLimit ? (
            <div>
              <strong>Usage cap:&nbsp;</strong>
              {store.monthlyUsageLimit.hours}{" "}
              <span className="info-block__footer-title__title">hours</span>{" "}
              {store.monthlyUsageLimit.minutes}{" "}
              <span className="info-block__footer-title__title">minutes</span>
            </div>
          ) : (
            <div>
              {store.monthlyTimePurchased.hours}{" "}
              <span className="info-block__footer-title__title">hours</span>{" "}
              {store.monthlyTimePurchased.minutes}{" "}
              <span className="info-block__footer-title__title">minutes</span>
            </div>
          )}
        </span>
      </div>
    </div>
  );
};

export default observer(ParticipantsTime);
