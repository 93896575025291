import classnames from "classnames";
import PropTypes from "prop-types";
import { Link } from "@reach/router";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "components/Form/Input";
import "./index.scss";

const PASSWORD_MIN_LENGTH = 8;

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(
      PASSWORD_MIN_LENGTH,
      `Min ${PASSWORD_MIN_LENGTH} letters for password!`
    )
    .matches(/(?=.*[a-z])/, "Password requires lowercase letters")
    .matches(/(?=.*[A-Z])/, "Password requires uppercase letters")
    .matches(/(?=.*[0-9])/, "Password requires numbers")
    .matches(/\S/, "Whitespaces not allowed in password")
    .matches(
      /[$&+,:;=?@#|'<>.^*()%!-]/,
      "Password requires at least one special character"
    ),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  organization_name: Yup.string(),
});

export const SignupForm = ({ onSubmit }) => {
  const isLowercased = (value) => {
    return /(?=.*[a-z])/.test(value);
  };

  const isUppercased = (value) => {
    return /(?=.*[A-Z])/.test(value);
  };

  const isMatchLength = (value) => {
    return value.length >= PASSWORD_MIN_LENGTH;
  };

  const isWithNumber = (value) => {
    return /(?=.*[0-9])/.test(value);
  };

  const isSpecialCharacter = (value) => {
    return /[$&+,:;=?@#|'<>.^*()%!-]/.test(value);
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        organization_name: "",
      }}
      validationSchema={SignupSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ isValid, values }) => (
        <Form className="register-form">
          <h1 className="title is-3 is-size-3-mobile is-spaced">
            Welcome to Sidebar
          </h1>
          <div className="subtitle">
            Already have an account? <Link to="/login">Sign in</Link>
          </div>
          <div className="form__two-columns">
            <Field
              name="first_name"
              component={Input}
              placeholder="First Name"
            />
            <Field name="last_name" component={Input} placeholder="Last Name" />
          </div>
          <Field
            name="organization_name"
            component={Input}
            placeholder="Organization (optional)"
          />
          <Field name="email" component={Input} placeholder="Email" />
          <Field
            name="password"
            component={Input}
            type="password"
            placeholder="Password"
          />
          <div className="password-requirements">
            <div
              className={classnames("password-requirement", {
                "password-requirement__valid": isLowercased(values.password),
              })}
            >
              <div className="circle" />
              <div className="password-requirement-text">
                One lowercase character
              </div>
            </div>

            <div
              className={classnames("password-requirement", {
                "password-requirement__valid": isSpecialCharacter(
                  values.password
                ),
              })}
            >
              <div className="circle" />
              <div className="password-requirement-text">
                One special character
              </div>
            </div>

            <div
              className={classnames("password-requirement", {
                "password-requirement__valid": isUppercased(values.password),
              })}
            >
              <div className="circle" />
              <div className="password-requirement-text">
                One uppercase character
              </div>
            </div>

            <div
              className={classnames("password-requirement", {
                "password-requirement__valid": isMatchLength(values.password),
              })}
            >
              <div className="circle" />
              <div className="password-requirement-text">
                {PASSWORD_MIN_LENGTH} characters minimum
              </div>
            </div>

            <div
              className={classnames("password-requirement", {
                "password-requirement__valid": isWithNumber(values.password),
              })}
            >
              <div className="circle" />
              <div className="password-requirement-text">One number</div>
            </div>
          </div>
          <button
            className="button is-link button-submit"
            disabled={!isValid}
            type="submit"
          >
            Create Account
          </button>
          <div className="terms">
            By clicking "Create Account", you are creating a Sidebar account,
            and you agree to Sidebar's{" "}
            <a
              href="https://get.sidebar.vc/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              href="https://get.sidebar.vc/privacy-policy"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
