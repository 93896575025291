import { observer } from "mobx-react-lite";
import ParticipantNametag from "../ParticipantNametag/ParticipantNametag";
import ParticipantNameDifferentSession from "../ParticipantNametag/ParticipantNameDifferentSession";
import { useStores } from "../../stores/utils";
import classnames from "classnames";

function LimitedParticipantsList({
  room,
  differentSession,
  disableUserActions,
  clickToDM,
}) {
  const { appStore, callStore } = useStores();

  let limitedParticipants;
  if (room.isCurrentRoom) {
    limitedParticipants = callStore.participantList.slice(0, 15);
  } else {
    limitedParticipants = room.participants;
  }

  return (
    <>
      {limitedParticipants && limitedParticipants.length > 0 && (
        <div
          className={classnames("participant-nametags-list", {
            "non-inline-list": appStore.officeMode,
          })}
        >
          {limitedParticipants.map((participant) => {
            return differentSession ? (
              <ParticipantNameDifferentSession
                key={participant.id}
                participant={participant}
                disableUserActions={disableUserActions}
                clickToDM={clickToDM}
              />
            ) : (
              <ParticipantNametag
                key={participant.id}
                participant={participant}
                alreadyInRoom={room.alreadyInRoom}
                officeMode={appStore.officeMode}
              />
            );
          })}
        </div>
      )}
    </>
  );
}

export default observer(LimitedParticipantsList);
