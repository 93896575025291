import { observer } from "mobx-react-lite";
import RefreshButton from "./RefreshButton";
import NetworkStatsButton from "../NetworkStats/Button/Button";
import HelpButton from "./HelpButton";
import ExtraMenuButton from "./ExtraMenuButton";
import FullScreenButton from "./FullScreenButton";

function RightTrayActions({
  disabled,
  toggleRefresh,
  store,
  isFullScreen,
  officeMode,
  toggleFullscreen,
  theme,
}) {
  return (
    <>
      <RefreshButton
        disabled={disabled}
        toggleRefresh={toggleRefresh}
        theme={theme}
      />
      <NetworkStatsButton store={store} disabled={disabled} theme={theme} />
      <HelpButton isFullScreen={isFullScreen} theme={theme} />
      {!officeMode && <ExtraMenuButton theme={theme} />}
      <FullScreenButton
        isFullScreen={isFullScreen}
        toggleFullscreen={toggleFullscreen}
        disabled={disabled}
        theme={theme}
      />
    </>
  );
}

export default observer(RightTrayActions);
