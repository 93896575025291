import { useEffect, useRef, useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import { debounce, throttle } from "lodash";
import { useEventListener } from "hooks/useEventListener";
import { useMountedRef } from "hooks/useMountedRef";

function SharedCursors({ videoTrack, myScreen }) {
  const {
    callObjectStore,
    userStore,
    sharedCursorsStore,
    callStore,
    viewStore,
    docStore,
  } = useStores();

  const [isDrawing, setIsDrawing] = useState(false);
  const [isResize, setIsResize] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const canvasContainer = useRef(null);
  const mounted = useMountedRef();

  const participantsNumber = callStore.participantList.length;
  const aspectRatio = sharedCursorsStore.aspectRatio;
  const participantId = callStore.participantId;
  const localPen = sharedCursorsStore.localPen;
  const color = `#${userStore?.userInfo?.color}` || "#d67500";
  const userName = userStore.userInfo.display_name;

  const resizeOnSideMenuToggle = viewStore.hideSideMenu;
  const resizeOnDocToggle = docStore.openDoc?.id;

  // broadcast my data
  const sendMyData = useCallback(
    (data) => {
      console.log("Shared cursors - data sent");
      const callObject = callObjectStore.callObject;
      if (callObject) {
        try {
          callObject.sendAppMessage(
            {
              ...data,
              participantId,
            },
            "*"
          );
        } catch (error) {
          console.log("Shared cursors - data sent", error);
        }
      }
    },
    [callObjectStore.callObject, participantId]
  );

  // my screen && get my AR (needs a timeout to get the correct AR)
  useEffect(() => {
    if (!myScreen) return;
    const timer = setTimeout(() => {
      const { aspectRatio, width, height } = videoTrack.getSettings();
      const ar = aspectRatio || width / height;
      sharedCursorsStore.receiveAspectRatio({ ar });
    }, 700);
    return () => clearTimeout(timer);
  }, [videoTrack, myScreen, sharedCursorsStore]);

  // my screen && on participant number change && share AR with others
  useEffect(() => {
    if (!myScreen || participantsNumber === 1 || !aspectRatio) return;
    const message = "aspectRatio";
    const ar = aspectRatio;
    sendMyData({
      message,
      ar,
    });
  }, [participantsNumber, sendMyData, aspectRatio, myScreen]);

  // calculate canvas width and height, recalculate on resize
  useEffect(() => {
    if (!aspectRatio) return;
    canvasContainer.current &&
      sharedCursorsStore.setRef(canvasContainer.current, "canvasContainer");
    const { width, height } = sharedCursorsStore.calculateCanvasSize();
    setWidth(width);
    setHeight(height);
  }, [
    sharedCursorsStore,
    aspectRatio,
    isResize,
    resizeOnSideMenuToggle,
    resizeOnDocToggle,
  ]);

  // is used to recalculate the canvas size
  useEffect(() => {
    const timer = setTimeout(() => setIsResize(true), 600);
    return () => clearTimeout(timer);
  }, [setIsResize]);

  // set canvas width & height && create context
  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = width * 2;
    canvas.height = height * 2;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;

    const context = canvas.getContext("2d");
    context.scale(2, 2);
    context.lineCap = "round";
    contextRef.current = context;

    sharedCursorsStore.setRef(context, "context");
  }, [width, height, sharedCursorsStore]);

  // clear canvas if no new activity
  useEffect(() => {
    sharedCursorsStore.noNewDrawings && sharedCursorsStore.clearCanvas();
  }, [sharedCursorsStore.noNewDrawings, sharedCursorsStore]);

  const startDrawing = ({ nativeEvent }) => {
    nativeEvent.preventDefault();
    nativeEvent.stopPropagation();
    setIsDrawing(true);
    const { offsetX: x, offsetY: y } = nativeEvent;
    sharedCursorsStore.collectCoordinates([x, y]);
    localPen.x = x;
    localPen.y = y;

    // display name on first drawing
    const isNameDisplayed = sharedCursorsStore.isNameDisplayed(participantId);
    if (!isNameDisplayed) {
      sharedCursorsStore.displayName(color, x, y, participantId, userName);
    }
  };

  const newAct = useCallback(() => {
    sharedCursorsStore.addIncomingDrawings("drawing");
  }, [sharedCursorsStore]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledActivity = useCallback(throttle(newAct, 1000), [newAct]);

  const draw = ({ nativeEvent }) => {
    nativeEvent.preventDefault();
    nativeEvent.stopPropagation();
    if (!isDrawing) return;
    const { offsetX: x, offsetY: y } = nativeEvent;
    sharedCursorsStore.collectCoordinates([x, y]);
    sharedCursorsStore.drawLine(color, 4, localPen.x, localPen.y, x, y);
    localPen.x = x;
    localPen.y = y;
    throttledActivity();
  };

  const paginate = useCallback(
    (message, screenSize, coordinatesArray) => {
      let chunkStart = 0;
      let chunkEnd = 290;
      let coordinates = coordinatesArray.slice(chunkStart, chunkEnd);
      while (coordinates.length) {
        sendMyData({
          message,
          coordinates,
          screenSize,
          color,
          userName,
        });
        chunkStart = chunkEnd;
        chunkEnd += 290;
        coordinates = coordinatesArray.slice(chunkStart, chunkEnd);
      }
    },
    [color, userName, sendMyData]
  );

  const finishDrawing = () => {
    setIsDrawing(false);
    const coordinates = sharedCursorsStore.drawing;
    if (!coordinates.length) return;
    const message = "cursors";
    const screenSize = {
      height: height,
      width: width,
    };
    const socketLimit = 4096;
    const dataSize = coordinates.length * 12 + 500;
    if (dataSize > socketLimit) {
      paginate(message, screenSize, coordinates);
    } else {
      sendMyData({
        message,
        coordinates,
        screenSize,
        color,
        userName,
      });
    }
    sharedCursorsStore.addIncomingDrawings("drawing");
    sharedCursorsStore.clearCoordinates();
  };

  const updateSize = useCallback(() => {
    if (!mounted.current) return;
    setIsResize(true);
    const timer = setTimeout(() => setIsResize(false), 15);
    return () => clearTimeout(timer);
  }, [mounted]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateSize = useCallback(
    debounce(updateSize, 700, {
      leading: false,
      trailing: true,
    }),
    [updateSize]
  );

  useEventListener("resize", debouncedUpdateSize);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
        ref={canvasContainer}
      >
        <canvas
          style={{
            zIndex: 1,
            alignSelf: "center",
          }}
          ref={canvasRef}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={finishDrawing}
          onMouseLeave={finishDrawing}
        />
      </div>
    </>
  );
}

export default observer(SharedCursors);
