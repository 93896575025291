import { observer } from "mobx-react-lite";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faHashtag } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faLink,
} from "@fortawesome/pro-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tippy from "@tippyjs/react";
import { useState, useCallback } from "react";

const SpaceNameHeader = ({
  withContent,
  name,
  inRightPane,
  inCall,
  isExpanded,
  onClick,
  sessionType,
  joinButton,
  collapseSingleRoom,
  sessionUrl,
  showLinkIcon = true,
}) => {
  const [showCopiedTooltip, setShowCopiedTootip] = useState(false);

  const isMeeting = sessionType === "meeting";

  const triggerCopiedTooltip = useCallback(() => {
    setShowCopiedTootip(true);
    setTimeout(() => {
      setShowCopiedTootip(false);
    }, 2000);
  }, []);

  return (
    <>
      <div
        className={classnames("space-name-header", {
          "with-content": withContent,
          "in-right-pane": inRightPane,
          "in-call": inCall,
          "is-expanded": isExpanded,
          "collapsed-single-room": collapseSingleRoom,
        })}
        onClick={onClick}
      >
        <div className="space-name">
          {isMeeting ? (
            <div className="space-icon calendar-icon">
              <FontAwesomeIcon icon={faCalendarDay} />
            </div>
          ) : (
            <div className="space-icon">
              <FontAwesomeIcon icon={faHashtag} />
            </div>
          )}{" "}
          <div className="space-name-text">
            {name}
            &nbsp;
            {sessionUrl && showLinkIcon && (
              <div className="space-link">
                <CopyToClipboard
                  text={sessionUrl}
                  onCopy={triggerCopiedTooltip}
                >
                  <div>
                    <Tippy
                      content="Link copied to clipboard!"
                      visible={showCopiedTooltip}
                      className="blue-tippy"
                      arrow={false}
                    >
                      <Tippy content="Get link">
                        <div className="icon">
                          <FontAwesomeIcon icon={faLink} />
                        </div>
                      </Tippy>
                    </Tippy>
                  </div>
                </CopyToClipboard>
              </div>
            )}
          </div>
        </div>
        <div className="space-in-call-actions"></div>
        {joinButton ? (
          joinButton
        ) : (
          <div className="space-name-arrows">
            <div className="space-arrow">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <div className="up-arrow">
              <FontAwesomeIcon icon={faChevronUp} />
            </div>
            <div className="down-arrow">
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default observer(SpaceNameHeader);
