import { observer } from "mobx-react-lite";
import Tippy from "@tippyjs/react";
import { useStores } from "../../stores/utils";

const DebugComponent = ({ callItem }) => {
  const { appStore, callStore } = useStores();

  if (!appStore.isDebug) return null;

  const sidebarParticipant = callStore.findParticipantByCallItem(callItem);
  // const dailyDebugObject = JSON.stringify(callItem, null, 4);
  // const sidebarDebugObject = JSON.stringify(sidebarParticipant, null, 4);

  return (
    <div className="tile-debug-info">
      <Tippy
        trigger="click"
        interactive="true"
        content={<div className="js-object-pre">{/*dailyDebugObject*/}</div>}
      >
        <div>dp: {callItem.dailyId}</div>
      </Tippy>
      <Tippy
        trigger="click"
        interactive="true"
        content={<div className="js-object-pre">{/*sidebarDebugObject*/}</div>}
      >
        <div>sp: {sidebarParticipant && sidebarParticipant.id}</div>
      </Tippy>
    </div>
  );
};

export default observer(DebugComponent);
