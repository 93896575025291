import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import classnames from "classnames";
import OfficeRoomRow from "./OfficeRoomRow";
import { useStores } from "../../stores/utils";
import AddRoomButtons from "../Office/AddRoomButtons";
import SpaceNameHeader from "./SpaceNameHeader";

const RoomsListCurrentCall = ({
  disabled,
  joinButtonsDisabled,
  hideSpaceName,
  hideCreateAuditorium,
}) => {
  const { callStore } = useStores();
  const roomsMenuEl = useRef(null);
  const [focusedEditRoom, setFocusedEditRoom] = useState(null);

  const createRoom = () => {
    callStore.addRoom();
  };
  const createAuditorium = () => {
    callStore.createAuditorium(roomsMenuEl);
  };

  const sessionType = callStore?.sessionAttributes?.["session-type"];
  const isMeeting = sessionType === "meeting";
  const roomCount = callStore.groupList.length;

  const collapseSingleRoom = isMeeting && roomCount === 1;

  let sessionUrl;
  if (isMeeting) {
    sessionUrl = callStore.sessionAttributes?.["meeting-url"];
  } else {
    sessionUrl = `${window.location.origin}/office/s/${callStore.sessionId}`;
  }

  return (
    <div
      className={classnames("office-rooms-menu", { "in-call": true })}
      ref={roomsMenuEl}
    >
      {!hideSpaceName && (
        <SpaceNameHeader
          withContent={true}
          name={callStore.sessionAttributes.name}
          inCall={true}
          sessionType={sessionType}
          collapseSingleRoom={collapseSingleRoom}
          sessionUrl={sessionUrl}
        />
      )}
      <div
        className={classnames("space-outline", "space-rooms-list", {
          "space-outline--closed-top": hideSpaceName,
        })}
      >
        <ul className="rooms-list">
          {callStore.groupList.map((group) => (
            <li
              key={group.id}
              className={classnames({
                "auditorium-row": group.isAuditorium,
                "current-room": group.isCurrentRoom,
              })}
            >
              <OfficeRoomRow
                key={group.id}
                room={group}
                focusedEditRoom={focusedEditRoom}
                setFocusedEditRoom={setFocusedEditRoom}
                differentSession={false}
                hideGroupName={collapseSingleRoom}
              />
            </li>
          ))}
          <li className="add-room-button-row">
            <AddRoomButtons
              roomsMenuEl={roomsMenuEl}
              createRoom={createRoom}
              createAuditorium={createAuditorium}
              hideCreateAuditorium={hideCreateAuditorium}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default observer(RoomsListCurrentCall);
