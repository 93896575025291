import { observer } from "mobx-react-lite";
import { FaCog } from "react-icons/fa";
import Tippy from "@tippyjs/react";
import TrayButton from "../TrayButton/TrayButton";

const SettingsButton = ({ disabled, toggleSettings, theme }) => {
  return (
    <Tippy
      content="Cam/mic settings"
      disabled={disabled}
      appendTo="parent"
      theme={theme}
    >
      <div>
        <TrayButton
          disabled={disabled}
          onClick={toggleSettings}
          extraClasses="settings-tray-button"
        >
          <FaCog color="#fff" size="24px" />
        </TrayButton>
      </div>
    </Tippy>
  );
};

export default observer(SettingsButton);
