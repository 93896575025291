import { observer } from "mobx-react-lite";
import JoinSpaces from "../../JoinSpaces/JoinSpaces";
import { Link } from "@reach/router";

function JoinSpacesStep({ goNext }) {
  return (
    <div className="office-onboarding-join-spaces">
      <div className="content">
        <div className="join-description">
          <h2 className="modal-title">Join spaces</h2>
          <p>
            Spaces are where teammates gather. Join to see when colleagues are
            talking and be able to jump in.
          </p>
        </div>
        <div className="join-spaces-container">
          <JoinSpaces />
        </div>
        <div className="actions is-flex is-justify-content-center">
          <Link to="notifications" className="button is-link onboarding-button">
            Next
          </Link>
        </div>
      </div>
    </div>
  );
}

export default observer(JoinSpacesStep);
