/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";

import ReadReciept from "../ReadReciept";

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageVideoWrapperStyle,
  messageInfoWrapperStyle,
} from "./style";

const sendervideobubble = (props) => {
  return (
    <div css={messageContainerStyle()}>
      <div css={messageWrapperStyle()}>
        <div css={messageVideoWrapperStyle(props)}>
          <video controls>
            <source src={props.message.data.url} />
          </video>
        </div>
        <div css={messageInfoWrapperStyle()}>
          <ReadReciept {...props} />
        </div>
      </div>
    </div>
  );
};

export default sendervideobubble;
