import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { navigate } from "@reach/router";
import { useStores } from "../../stores/utils";
import PlainPhotoLayout from "../Layouts/PlainPhotoLayout";
import InstallUnsupported from "../InstallUnsupported/InstallUnsupported";
import { pwaProbablySupported } from "../../utilities/pwa";
import "./InstallPage.scss";

window.addEventListener("appinstalled", () => {
  navigate("/office");
});

const InstallPage = (props) => {
  const { appStore } = useStores();
  const [afterFiveSeconds, setAfterFiveSeconds] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAfterFiveSeconds(true);
    }, 5000);
  }, []);

  return (
    <PlainPhotoLayout blueLogo={true} className="install-page content">
      <div className="content-column narrower shorter">
        {pwaProbablySupported ? (
          appStore.showingInstallPromotion ? (
            <div className="install-content">
              <img src="/android-chrome-192x192.png" alt="Sidebar logo" />
              <h1>
                A virtual office
                <br /> for your team
              </h1>
              <div
                className="button is-link is-rounded is-large install-button"
                onClick={() => appStore.installPWA()}
              >
                Install Sidebar
              </div>
            </div>
          ) : (
            <div>
              {afterFiveSeconds ? (
                <div>
                  Unable to install.{" "}
                  <p>
                    <em>You may have already installed Sidebar.</em>
                  </p>
                </div>
              ) : (
                <div>Checking compatibility...</div>
              )}
            </div>
          )
        ) : (
          <InstallUnsupported />
        )}
      </div>
    </PlainPhotoLayout>
  );
};

export default observer(InstallPage);
