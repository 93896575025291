/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { BsQuestionCircle } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import { useStores } from "../../stores/utils";
import HelpMenu from "./HelpMenu";

const HelpButton = ({ isFullScreen, theme }) => {
  const [isHelpMenuOpen, setHelpMenuOpen] = useState(false);

  const { userStore } = useStores();

  const hideHelpMenu = useCallback(() => {
    setHelpMenuOpen(false);
  }, []);

  const toggleHelpMenu = useCallback(() => {
    setHelpMenuOpen(!isHelpMenuOpen);
  }, [isHelpMenuOpen]);

  return (
    <Tippy
      animation="shift-away"
      content={
        <HelpMenu
          userStore={userStore}
          isHelpMenuOpen={isHelpMenuOpen}
          hideHelpMenu={hideHelpMenu}
        />
      }
      interactive={true}
      onClickOutside={hideHelpMenu}
      theme="light"
      visible={isHelpMenuOpen}
      disabled={isFullScreen}
    >
      <Tippy
        content={isFullScreen ? "Disabled" : "Troubleshooting"}
        appendTo="parent"
        theme={theme}
      >
        <a
          className="tray-button custom-tray-item troubleshooting-button"
          target="_blank"
          rel="noopener noreferrer"
          onClick={toggleHelpMenu}
        >
          <BsQuestionCircle size="24px" color={isFullScreen ? "grey" : ""} />
        </a>
      </Tippy>
    </Tippy>
  );
};

export default observer(HelpButton);
