import amplitude from "amplitude-js";

export const init = () => {
  try {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE);
  } catch (e) {
    console.error(e);
  }
};

export const track = (eventType, eventProperties) => {
  try {
    amplitude.getInstance().logEvent(eventType, eventProperties);
  } catch (e) {
    console.error(e);
  }
};

export const setUserId = (userId) => {
  try {
    amplitude.getInstance().setUserId(userId);
  } catch (e) {
    console.error(e);
  }
};

export const setUserProperties = (userProperties) => {
  try {
    amplitude.getInstance().setUserProperties(userProperties);
  } catch (e) {
    console.error(e);
  }
};
