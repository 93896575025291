import { useEffect } from "react";
import { useNavigate, useParams } from "@reach/router";
import { useStores } from "stores/utils";

export const Confirmation = () => {
  const params = useParams();
  const { userStore } = useStores();
  const navigate = useNavigate();

  useEffect(() => {
    userStore.confirmEmail(params.token).then(() => navigate("/host"));
  }, [userStore, navigate, params.token]);

  return <div />;
};
