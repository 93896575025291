import { observable, action, makeObservable } from "mobx";

export class TileSettingsStore {
  @observable changedTiles = {};
  @observable refs = {};

  constructor(rootStores) {
    this.rootStores = rootStores;
    this.api = rootStores.api;
    makeObservable(this);
  }

  @action
  addParticipantSettings(settings, uid) {
    if (settings["tile-dimensions"] === "default") {
      this.changedTiles[uid] = undefined;
    } else {
      this.changedTiles[uid] = settings;
    }
  }

  @action
  resetAllSettings() {
    this.changedTiles = {};
    this.refs = {};
  }

  @action
  setRefs(newRefs) {
    this.refs = newRefs;
  }

  @action
  shareTileSetting = (settings) => {
    const groupId = this.rootStores.callStore.currentRoomId;
    const myParticipantId = this.rootStores.callStore.participantId;
    const dailyId = this.rootStores.callStore.participant.dailyId;

    try {
      this.api.shareTileSetting(groupId, myParticipantId, settings);
    } catch (error) {
      console.log(error);
      return this.rootStores.errorStore.handleApiError(error);
    }

    this.addParticipantSettings(settings, dailyId);
  };

  @action
  populateParticipantSettings(participants) {
    // skip if alone in the rooom
    if (participants.length === 1) return;

    participants.forEach((p) => {
      const uid = p.attributes["daily-participant-id"];
      const color = p.attributes["tile-color"];
      const dimensions = p.attributes["tile-dimensions"];

      if (color && dimensions) {
        this.addParticipantSettings(
          { "background-color": color, "tile-dimensions": dimensions },
          uid
        );
      }
    });
  }
}
