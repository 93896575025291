import { observer } from "mobx-react-lite";
import "./RoomPopulation.scss";

export default observer(function RoomPopulation(props) {
  return (
    <div className="room-population">
      {props.count}
      &nbsp;
      {props.count === 1 ? "person" : "people"}
    </div>
  );
});
