import { useState } from "react";
import { observer } from "mobx-react-lite";
import ClusterThumbnail from "./ClusterThumbnail";
import ClusterJoinThumbnail from "./ClusterJoinThumbnail";
import ClusterOverflow from "./ClusterOverflow";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";
import { useStores } from "../../stores/utils";

function ClusterPreview({ clusterId, participants, ghostMode = false }) {
  const { appStore, callStore } = useStores();
  const [joining, setJoining] = useState(false);

  const joinCluster = () => {
    setJoining(true);
    callStore.joinCluster(clusterId).then(() => {
      setJoining(false);
    });
  };

  const isCurrentCluster = clusterId === callStore.currentClusterId;
  const volume =
    ghostMode || appStore.isSafari || appStore.isIpad || appStore.isIos()
      ? 0
      : 0.03;

  if (isCurrentCluster && !ghostMode) {
    return null;
  }

  const participantsToDisplay = participants.filter((x) => x.callItem);

  return (
    <div className="cluster-padding">
      <div
        className={classnames(
          "cluster-preview",
          isCurrentCluster && "cluster-preview--current",
          joining && "cluster-preview--joining"
        )}
      >
        {participantsToDisplay.slice(0, 3).map((p, index) => {
          return (
            <>
              <ClusterThumbnail
                participant={p}
                key={p.id}
                volume={volume}
                ghostMode={ghostMode}
                officeMode={appStore.officeMode}
              />
              {index === 0 && (
                <ClusterJoinThumbnail
                  key="cluster-join-thumbnail"
                  joinCluster={joinCluster}
                />
              )}
            </>
          );
        })}
      </div>

      {joining && (
        <div className="cluster-preview__joining-overlay">
          <div className="loader" />
        </div>
      )}

      <CSSTransition
        in={!joining}
        timeout={300}
        classNames={{
          appear: "cluster-node-appear",
          appearActive: "cluster-node-appear-active",
          appearDone: "cluster-node-appear-done",
          enter: "cluster-node-enter",
          enterActive: "cluster-node-enter-active",
          enterDone: "cluster-node-enter-done",
          exit: "cluster-node-exit",
          exitActive: "cluster-node-exit-active",
          exitDone: "cluster-node-exit-done",
        }}
      >
        <div className="cluster-preview__joining-animation"></div>
      </CSSTransition>
      <ClusterOverflow
        participants={participantsToDisplay}
        officeMode={appStore.officeMode}
      />
    </div>
  );
}

export default observer(ClusterPreview);
