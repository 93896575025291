import { useState, useCallback, useEffect, useRef } from "react";
import RedButton from "../RedButton/RedButton";
import { observer } from "mobx-react-lite";
import "./EditNameModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { isIOS } from "react-device-detect";

const EditNameModal = ({ saveAction, participantName }) => {
  const [tempName, setTempName] = useState(participantName);
  const [nameError, setNameError] = useState(null);
  const nameField = useRef(null);

  const handleNameChange = useCallback(
    (event) => {
      setNameError(null);
      setTempName(event.target.value);

      if (event.target.value.length > 50) {
        setNameError("Name is limited to 50 characters!");
      }
    },
    [setTempName]
  );

  const clickSave = useCallback(
    (e, newParticipantName) => {
      e.preventDefault();
      if (nameError) {
        return null;
      }

      return saveAction(newParticipantName);
    },
    [saveAction, nameError]
  );

  useEffect(() => {
    nameField.current.focus();
  }, []);

  let autoComplete = "cc-csc";
  if (isIOS) {
    autoComplete = "off";
  }

  return (
    <>
      <h2 className="title is-4 is-size-5-mobile ">Edit your name</h2>

      <form
        onSubmit={(e) => {
          clickSave(e, tempName);
        }}
      >
        <div className="field is-grouped name-fields">
          <p className="control is-expanded has-icons-right">
            <input
              className={"input " + (nameError && " is-danger")}
              autoComplete={autoComplete}
              name="nickname"
              type="text"
              id="name-input"
              value={tempName}
              onChange={handleNameChange}
              placeholder="Enter your name"
              ref={nameField}
            />
            {nameError && (
              <>
                <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </span>
                <span className="help is-danger">{nameError}</span>
              </>
            )}
          </p>

          <p className="control">
            <RedButton label="Save" type="submit" />
          </p>
        </div>
      </form>
    </>
  );
};

export default observer(EditNameModal);
