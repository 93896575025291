import { v4 as uuidv4 } from "uuid";
import * as Amplitude from "../utilities/amplitude";
import { INVITE_CLOSE_TIMEOUT } from "./constants";

const lookupRoomByRoomId = (roomId, roomList) => {
  return roomList.find((x) => x.id === roomId);
};

export class InviteStore {
  constructor(rootStores) {
    this.rootStores = rootStores;
  }

  sendInvite = (inviteType, targetParticipantId, inviteParams) => {
    this.rootStores.cableSocketStore.participantsSubscription.send({
      sender_id: this.rootStores.callStore.participant?.id,
      recipient_id: targetParticipantId,
      invite_type: inviteType,
      sender_name: this.rootStores.callStore.participant?.name,
      invite_params: inviteParams,
    });
  };

  showInvite = (inviteType, senderName, inviteParams, senderId) => {
    let newReaction = {
      id: uuidv4(),
      invite_type: inviteType,
      sender_name: senderName,
      invite_params: inviteParams,
      sender_id: senderId,
    };

    this.rootStores.userReactionStore.addReaction(newReaction);

    setTimeout(() => {
      this.rootStores.userReactionStore.hideReaction(newReaction);
    }, INVITE_CLOSE_TIMEOUT);
  };

  switchCallRoom = (...args) => {
    this.rootStores.callStateStore.clearErrors();
    return this.rootStores.callStore.switchRoom(...args);
  };

  rejectInvite = (inviterId, reactionId) => {
    Amplitude.track("rejected invite", {});
    this.rootStores.userReactionStore.hideReaction(reactionId);
    this.rootStores.userReactionStore.sendReaction(
      "rejected invite",
      inviterId,
      `Maybe later`
    );
  };

  acceptInvite = (
    inviteType,
    inviteParams,
    senderName,
    reactionId,
    senderId
  ) => {
    let myName = this.rootStores.callStore.participant?.name;
    console.log("INVITE TYPE IS: ", inviteType);
    if (inviteType === "hereInvite") {
      Amplitude.track("Accepted invite-to-room");
      return this.acceptHereInvite(
        inviteType,
        inviteParams,
        senderName,
        reactionId,
        senderId,
        myName,
        true
      );
    } else if (inviteType === "joinMeInvite") {
      Amplitude.track("Accepted joinMeInvite");
      return this.acceptJoinMeInvite(
        inviteType,
        inviteParams,
        senderName,
        reactionId,
        senderId
      );
    } else {
      if (inviteType === "clusterSidebarInvite") {
        Amplitude.track("Accepted cluster sidebar invite");
        this.rootStores.userReactionStore.hideReaction(reactionId);
        this.rootStores.userReactionStore.sendReaction(
          "accept-cluster-sidebar-invite",
          senderId,
          `${myName} accepted your invite.`
        );
      } else {
        Amplitude.track("Accepted invite-to-sidebar");
        this.rootStores.userReactionStore.hideReaction(reactionId);
        this.rootStores.userReactionStore.sendReaction(
          "accept-sidebar-invite",
          senderId,
          `${myName} accepted your invite. Setting up your private room...`
        );
      }
    }
  };

  acceptHereInvite = async (
    inviteType,
    inviteParams,
    senderName,
    reactionId,
    senderId,
    myName,
    showAcceptance = true,
    clusterIdToJoin = null
  ) => {
    this.rootStores.userReactionStore.hideReaction(reactionId);
    if (showAcceptance) {
      this.rootStores.userReactionStore.sendReaction(
        "accept-here-invite",
        senderId,
        `${myName} accepted your invite. They're joining now.`
      );
    }
    let roomObj = lookupRoomByRoomId(
      inviteParams.roomId,
      this.rootStores.callStore.groupList
    );
    if (!roomObj) {
      const newRoomList = await this.rootStores.callStore.refreshAndGetRoomList(
        this.rootStores.callStore.sessionId,
        this.rootStores.callStore.participantId
      );
      roomObj = lookupRoomByRoomId(inviteParams.roomId, newRoomList);
      // TODO: should probably alert the user the room dosn't exist, and just leave them in their current room
    }

    if (!roomObj) {
      console.log("Oops, that invite is invalid. Room no longer exists.");
      return;
    }
    return this.switchCallRoom(
      roomObj.id,
      roomObj.attributes["daily-url"],
      null,
      null,
      clusterIdToJoin
    );
  };

  acceptJoinMeInvite = async (
    inviteType,
    inviteParams,
    senderName,
    reactionId,
    senderId,
    myName
  ) => {
    if (inviteParams.roomId !== this.rootStores.callStore.currentRoomId) {
      const showAcceptance = false;
      const clusterIdToJoin = inviteParams.clusterId;
      await this.acceptHereInvite(
        inviteType,
        inviteParams,
        senderName,
        reactionId,
        senderId,
        myName,
        showAcceptance,
        clusterIdToJoin
      );
    } else {
      this.rootStores.userReactionStore.hideReaction(reactionId);
      const clusterId = inviteParams.clusterId;
      await this.rootStores.callStore.joinCluster(clusterId);
    }
  };
}
