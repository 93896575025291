import ReactModal from "react-modal";
import RedButton from "components/RedButton/RedButton";
import "./UnbanConfirmationModal.scss";

export const UnbanConfirmationModal = ({ onConfirm, onCancel }) => {
  return (
    <ReactModal
      isOpen
      onRequestClose={onCancel}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      className="custom-modal"
      overlayClassName="modal-background"
      closeTimeoutMS={300}
    >
      <div className="modal-content">
        <div className="box is-info">
          <div className="confirm-modal-message">
            Are you sure you want to unban this user?
          </div>
          <div className="confirm-modal-buttons">
            <RedButton label="Ok" handleClick={onConfirm} />
            <button className="button" onClick={onCancel}>
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
