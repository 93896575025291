import { observer } from "mobx-react-lite";
import Circle from "../Tile/Circle";
import classnames from "classnames";
import React from "react";

function CircleContainer({ circles, withLargeTiles, forwardedRef }) {
  const withManyPeople = circles.length > 6;

  return (
    <div
      className={classnames("circle-background", {
        "with-large-tiles": withLargeTiles,
        "with-many-people": withManyPeople,
      })}
    >
      <div className={classnames("circle-container")}>
        {withManyPeople || withLargeTiles
          ? circles.map((circle) => (
              <Circle
                key={circle.callItem.dailyId}
                circle={circle}
                forwardedRef={forwardedRef}
                positionTippyTop={withLargeTiles}
              />
            ))
          : circles.map((circle, i) =>
              i !== 2 ? (
                <Circle
                  key={circle.callItem.dailyId}
                  circle={circle}
                  forwardedRef={forwardedRef}
                  positionTippyTop={withLargeTiles}
                />
              ) : (
                <React.Fragment key={circle.callItem.dailyId}>
                  <Circle
                    circle={circle}
                    forwardedRef={forwardedRef}
                    positionTippyTop={withLargeTiles}
                  />
                  <div className="break-circles"></div>
                </React.Fragment>
              )
            )}
      </div>
    </div>
  );
}

export default observer(CircleContainer);
