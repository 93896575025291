import { observer } from "mobx-react-lite";
import Tippy from "@tippyjs/react";
import TrayButton from "../TrayButton/TrayButton";
import { MdScreenShare } from "react-icons/md";

const ScreenShareButton = ({
  hasBroadcastPermissions,
  disabled,
  isSharingScreen,
  toggleSharingScreen,
  theme,
}) => {
  return (
    <Tippy
      disabled={disabled || hasBroadcastPermissions}
      content="This room only allows designated speakers to broadcast."
      trigger="mouseenter focus click"
      theme="light"
    >
      <Tippy
        appendTo="parent"
        content={isSharingScreen ? "Stop screenshare" : "Start screenshare"}
        disabled={disabled || !hasBroadcastPermissions}
        theme={theme}
      >
        <div>
          <TrayButton
            disabled={disabled || !hasBroadcastPermissions}
            onClick={toggleSharingScreen}
          >
            <MdScreenShare
              size="24px"
              color={isSharingScreen ? "#fb5554" : "#fff"}
            />
          </TrayButton>
        </div>
      </Tippy>
    </Tippy>
  );
};

export default observer(ScreenShareButton);
