import PropTypes from "prop-types";
import classnames from "classnames";
import "./Input.scss";

export const Input = ({ type = "text", className, field, form, ...props }) => {
  const isErrors = () => {
    return form.touched[field.name] && form.errors[field.name];
  };

  return (
    <div
      className={classnames(
        "form-component",
        "form-component__input-container",
        className
      )}
    >
      <input
        className={classnames("form-component__input", {
          "form-component__input-error": isErrors(),
        })}
        type={type}
        {...field}
        {...props}
      />
      {isErrors() ? (
        <div className="form-component__error">{form.errors[field.name]}</div>
      ) : null}
    </div>
  );
};

Input.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
};
