import PlainGreyLayout from "../../components/Layouts/PlainGreyLayout";

function OfflinePage() {
  return (
    <PlainGreyLayout blueLogo={true}>
      <div className="content-column narrow">
        <h1>Offline</h1>
      </div>
    </PlainGreyLayout>
  );
}

export default OfflinePage;
