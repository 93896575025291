import axios from "axios";
import merge from "lodash/merge";
import axiosBetterStacktrace from "axios-better-stacktrace";

export class HttpService {
  constructor() {
    const httpProvider = axios;
    this.httpService = httpProvider.create({
      baseURL: process.env.REACT_APP_API_HOST,
    });
    axiosBetterStacktrace(this.httpService);
    this.httpService.interceptors.request.use(function (config) {
      const participantToken = localStorage.getItem("participant-token");
      if (participantToken) {
        config.headers["X-Participant-Token"] = participantToken;
      }
      return config;
    });
    this.get = this.httpService.get;
    this.post = this.httpService.post;
    this.put = this.httpService.put;
    this.delete = this.httpService.delete;
    this.patch = this.httpService.patch;
    this.config = this.httpService.defaults;
  }

  getConfig() {
    return this.config;
  }

  configure(config) {
    merge(this.httpService.defaults, config);
  }
}

export const httpService = new HttpService();
