import { observable, computed, action, makeObservable } from "mobx";
import { TileModel } from "./TileModel";

export class TilesStore {
  @observable frozenTileCount = null;
  @observable currentTileCount = 1;
  @observable hideTiles = false;
  @observable activeSpeakerTimes = new Map();
  @observable recalculateTileSizes = undefined;

  constructor(rootStores) {
    this.rootStores = rootStores;

    makeObservable(this);
  }

  @computed
  get data() {
    const { videoCallItems } = this.rootStores.callStore;

    return videoCallItems.map((callItem) => {
      return new TileModel({ rootStores: this.rootStores, callItem });
    });
  }

  @computed
  get smallTiles() {
    return this.data.filter((x) => !x.isLarge);
  }

  @computed
  get largeTiles() {
    return this.data.filter((x) => x.isLarge && x.callItem.videoTrack);
  }

  @computed
  get circledTiles() {
    return this.data.filter((x) => x.isLarge && !x.callItem.videoTrack);
  }

  @action
  recordActiveSpeaker(id) {
    this.activeSpeakerTimes.set(id, new Date());
    this.activeSpeakerTimes = new Map(this.activeSpeakerTimes);
  }

  @computed
  get isAnyoneScreenSharing() {
    return !!this.data.find((x) => x.isScreen);
  }

  @computed
  get liveTileCount() {
    // console.log("recalculating live tile count... ");
    if (this.frozenTileCount) {
      // console.log("using frozen: ", this.frozenTileCount);
      return this.frozenTileCount;
    }
    // console.log("using current: ", this.currentTileCount);
    return this.currentTileCount;
  }

  @action
  freezeTileCount(oldLength) {
    // console.log("Freezing Tile count!", oldLength);
    this.frozenTileCount = oldLength;
    setTimeout(() => {
      this.unfreezeTileCount();
    }, 1000);
  }

  @action
  unfreezeTileCount() {
    // console.log("UNFREEZIGN! ");
    this.frozenTileCount = null;
  }

  @action
  updateTileCount(newLength) {
    // console.log("tile count is now... ", newLength);
    this.currentTileCount = newLength;
  }

  @action
  toggleHideTiles = () => {
    this.hideTiles = !this.hideTiles;
  };

  @computed
  get isMultipleScreens() {
    return this.data.filter((x) => x.isScreen).length > 1;
  }

  @action
  setRecalculateTilesSizes(func) {
    this.recalculateTileSizes = func;
  }
}
