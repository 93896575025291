import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import { pwaProbablySupported } from "../../utilities/pwa";

function OfficeWelcome() {
  const { appStore, officeStore } = useStores();

  const banner = (
    <>
      <strong>Install the app</strong>
      &nbsp;so you can launch Sidebar in one click
    </>
  );

  return (
    <div className="office-welcome">
      {pwaProbablySupported ? (
        appStore.showingInstallPromotion && (
          <div
            className="install-promo-banner"
            onClick={() => appStore.installPWA()}
          >
            <div>{banner}</div>
          </div>
        )
      ) : (
        <div
          className="install-promo-banner"
          onClick={() => officeStore.setShowingInstallUnsupportedModal(true)}
        >
          <div>{banner}</div>
        </div>
      )}
    </div>
  );
}

export default observer(OfficeWelcome);
