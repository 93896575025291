import { observer } from "mobx-react-lite";
import ParticipantNametag from "../ParticipantNametag/ParticipantNametag";
import ParticipantNameDifferentSession from "../ParticipantNametag/ParticipantNameDifferentSession";
import { useStores } from "../../stores/utils";
import classnames from "classnames";

function RestParticipantsList({
  groupId,
  restParticipants,
  loading,
  sameRoom,
  differentSession,
}) {
  const { appStore } = useStores();
  const officeMode = appStore.officeMode;

  return (
    <div
      className={classnames("rest-participants-container", {
        "participant-nametags-list": officeMode,
        "non-inline-list": officeMode,
      })}
    >
      {loading && <span className="loader" />}
      {restParticipants.map((participant) => {
        return differentSession ? (
          <ParticipantNameDifferentSession
            key={participant.id}
            participant={participant}
          />
        ) : (
          <ParticipantNametag
            key={participant.id}
            participant={participant}
            alreadyInRoom={sameRoom}
            parentEl="parent"
            officeMode={officeMode}
          />
        );
      })}
    </div>
  );
}

export default observer(RestParticipantsList);
