import { useEffect } from "react";
import classnames from "classnames";
import { useNavigate } from "@reach/router";
import { SignupForm } from "components/SignupForm";
import { SubscriptionInfo } from "components/SignupForm/SubscriptionInfo";
import { SignupStore, SIGNUP_STEPS } from "stores/SignupStore";
import { usePrivateStore, useStores, observe } from "stores/utils";
import { ReactComponent as SidebarLogo } from "images/SVG/sidebar-logo.svg";
import "./Signup.scss";

export const SignupPage = () => {
  const { subscriptionsStore, userStore } = useStores();
  // no need to define as global store
  const signupStore = usePrivateStore(SignupStore, { userStore });
  const navigate = useNavigate();

  useEffect(() => {
    if (!subscriptionsStore.selectedPlan) {
      // navigate('/');
    }

    subscriptionsStore.getSubscriptions();
  }, [subscriptionsStore, navigate]);

  const onUserDataSubmit = (userData) => {
    signupStore.setUserData({ user: userData });
    signupStore.changeStep(SIGNUP_STEPS["CREDIT_CARD_DATA"]);
  };

  const register = () => {
    signupStore
      .register()
      .then(() => {
        navigate("/login");
      })
      .catch((err) => console.log(err));
  };

  const onCreditCardSubmit = (paymentId, billingAddress) => {
    const subscription = {
      stripe_payment_method_id: paymentId,
      plan_name: subscriptionsStore.currentSubscription?.attributes?.name,
      interval: subscriptionsStore.selectedInterval,
      hours_cap: subscriptionsStore.selectedCap,
    };

    if (subscriptionsStore.coupon && subscriptionsStore.isCouponValid) {
      subscription.promo_code = subscriptionsStore.coupon.code;
    }

    signupStore.setUserData({ subscription, address: billingAddress });
    register();
  };

  return observe(
    [
      subscriptionsStore.isFetching,
      subscriptionsStore.currentSubscription,
      subscriptionsStore.coupon,
    ],
    () => {
      if (subscriptionsStore.isFetching) {
        return null;
      }

      return (
        <div className="plain-grey-page">
          <div className="title-row">
            <div className="title-image">
              <SidebarLogo />
            </div>
          </div>
          <div className="flex-container signup-container">
            {subscriptionsStore.currentSubscription
              ? observe(signupStore.currentStep, () => (
                  <div
                    className={classnames("content-column", {
                      narrow:
                        signupStore.currentStep === SIGNUP_STEPS.USER_DATA,
                    })}
                  >
                    {signupStore.currentStep === SIGNUP_STEPS.USER_DATA ? (
                      <SignupForm onSubmit={onUserDataSubmit} />
                    ) : (
                      <SubscriptionInfo
                        onSubmit={onCreditCardSubmit}
                        signupStore={signupStore}
                      />
                    )}
                  </div>
                ))
              : "No subscription selected"}
          </div>
        </div>
      );
    }
  );
};
