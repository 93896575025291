import { useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import ReactModal from "react-modal";
import { MdClose } from "react-icons/md";
import uniqBy from "lodash/uniqBy";
import { formatNumberWithCommas } from "utilities/formatNumberWithCommas";
import { useStores, observe } from "stores/utils";

import "components/SignupForm/CapModal.scss";

const PLAN_OPTIONS = [
  {
    value: "month",
    label: "Monthly",
  },
  {
    value: "year",
    label: "Annual",
  },
];

export const ChangeSubscriptionModal = ({ handleCloseModal }) => {
  const { subscriptionsStore, userSubscriptionStore } = useStores();

  useEffect(() => {
    subscriptionsStore.changeSubscription(
      userSubscriptionStore.plan?.attributes?.name
    );
    subscriptionsStore.changeInterval(
      userSubscriptionStore.plan?.attributes?.interval
    );
  }, [subscriptionsStore, userSubscriptionStore]);

  const getOptions = () => {
    return uniqBy(
      subscriptionsStore.subscriptions.map((subscription) => ({
        value: subscription?.attributes?.name,
        label: subscription?.attributes?.name,
      })),
      "value"
    );
  };

  const upgradeSubscription = () => {
    userSubscriptionStore.upgradeSubscription().then(() => handleCloseModal());
  };

  return (
    <ReactModal
      isOpen
      overlayClassName="ReactModal__Overlay__disabled"
      className="ReactModal__Content__modal"
    >
      {observe(subscriptionsStore.isFetching, () =>
        subscriptionsStore.isFetching ? null : (
          <div className="modal-wrapper">
            <div className="modal-close-wrap" onClick={handleCloseModal}>
              <MdClose size={20} />
            </div>
            <div className="modal-header">Change plan details</div>
            <div className="modal-content">
              <div
                className="subscription-container"
                style={{ marginBottom: 10 }}
              >
                <div>
                  <div className="subscription-item">
                    <div className="subscription-item__title">Sidebar plan</div>
                    <div className="subscription-item__content">
                      <Select
                        defaultValue={{
                          value:
                            subscriptionsStore.currentSubscription?.attributes
                              ?.name,
                          label:
                            subscriptionsStore.currentSubscription?.attributes
                              ?.name,
                        }}
                        label="Single select"
                        options={getOptions()}
                        onChange={(option) =>
                          subscriptionsStore.changeSubscription(option.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="subscription-item">
                    <div className="subscription-item__title">Billing plan</div>
                    <div className="subscription-item__content">
                      {observe(subscriptionsStore.currentSubscription, () => (
                        <Select
                          styles={{
                            container: (base) => ({
                              ...base,
                              width: "100%",
                            }),
                          }}
                          defaultValue={PLAN_OPTIONS.find(
                            (option) =>
                              option.value ===
                              subscriptionsStore.selectedInterval
                          )}
                          label="Single select"
                          options={PLAN_OPTIONS}
                          onChange={(option) =>
                            subscriptionsStore.changeInterval(option.value)
                          }
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="subscription-total">
                    <div className="subscription-item">
                      <div className="subscription-item__title">
                        Total (USD)
                      </div>
                      <div className="subscription-item__title-price">
                        {observe(subscriptionsStore.currentSubscription, () => (
                          <>
                            $
                            {formatNumberWithCommas(
                              (
                                subscriptionsStore.currentSubscription
                                  ?.attributes?.price / 100
                              ).toFixed(2)
                            )}
                            /
                            <div className="small-text">
                              {subscriptionsStore.selectedInterval === "year"
                                ? "yr"
                                : "mo"}
                            </div>
                          </>
                        ))}
                      </div>
                    </div>

                    <div className="subscription-item">
                      <div className="subscription-item__title">
                        Hours included
                      </div>
                      <div className="subscription-item__title-price">
                        {observe(
                          subscriptionsStore.currentSubscription,
                          () =>
                            `${subscriptionsStore.currentSubscription?.attributes?.included_hours} hours`
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {observe(subscriptionsStore.isPlanChanged, () =>
              subscriptionsStore.isPlanChanged ? (
                <div className="subscription-message">
                  Your plan changes will take effect in the next billing period.
                </div>
              ) : null
            )}

            <div className="modal-actions">
              {observe(userSubscriptionStore.isUpdatingSubscription, () => (
                <button
                  onClick={upgradeSubscription}
                  className="subscription__submit-button"
                  disabled={userSubscriptionStore.isUpdatingSubscription}
                >
                  Confirm
                </button>
              ))}
            </div>
          </div>
        )
      )}
    </ReactModal>
  );
};

ChangeSubscriptionModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};
