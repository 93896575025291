import PropTypes from "prop-types";
import Avatar from "./Avatar";
import RoomName from "../../RoomName/RoomName";
import "./FoyerCard.scss";

const FoyerCard = ({
  room,
  focusedEditRoom,
  setFocusedEditRoom,
  noActions,
  isFoyer,
  onClickRoom,
  showRoomName = true,
}) => {
  const extraItems = Math.max(0, room?.attributes?.["participants-count"] - 7);
  return (
    <div className="office-foyer__card__wrapper">
      {showRoomName && (
        <div className="office-foyer__card__title__wrapper">
          <RoomName
            room={room}
            focusedEditRoom={focusedEditRoom}
            setFocusedEditRoom={setFocusedEditRoom}
            noActions={noActions}
            isFoyer={isFoyer}
            onClickRoom={onClickRoom}
          />
        </div>
      )}
      {room?.participants?.length > 0 ? (
        <div className="office-foyer__card__avatars__wrapper">
          {(room?.participants || []).slice(0, 7).map((participant) => {
            return (
              <Avatar
                key={participant?.id}
                name={participant?.name}
                avatar={participant?.avatar}
                color={`#${participant?.color}`}
              />
            );
          })}
          {extraItems > 0 && (
            <Avatar extraItems={extraItems} groupId={room.id} color="#000" />
          )}
        </div>
      ) : (
        <>
          {!showRoomName && (
            <div className="nobody-message">
              <nobr>There's nobody</nobr> <nobr>here right now</nobr>
            </div>
          )}
        </>
      )}
    </div>
  );
};

FoyerCard.propTypes = {
  avatar: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default FoyerCard;
