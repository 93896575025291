import { observer } from "mobx-react-lite";
import SettingsInCall from "../SettingsInCall/SettingsInCall";
import ReactModal from "react-modal";

const SettingsModal = ({ showSettings, toggleSettings }) => {
  return (
    <ReactModal
      isOpen={showSettings}
      onRequestClose={() => {
        toggleSettings();
      }}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      className="custom-modal"
      overlayClassName="modal-background modal-background-clear"
    >
      <div className="modal-content">
        <div className="notification">
          <SettingsInCall
            toggleSettings={toggleSettings}
            showSettings={showSettings}
          />
          <button
            className="delete"
            aria-label="delete"
            onClick={() => toggleSettings()}
          ></button>
        </div>
      </div>
    </ReactModal>
  );
};

export default observer(SettingsModal);
