import { observer } from "mobx-react-lite";
import classnames from "classnames";

function ClusterJoinThumbnail({ joinCluster }) {
  const setHighlighting = () => {};

  return (
    <div
      className={classnames("cluster-thumbnail", "cluster-join-thumbnail")}
      onClick={() => joinCluster()}
      onMouseEnter={() => setHighlighting(true)}
      onMouseLeave={() => setHighlighting(false)}
    >
      <button className="button is-small is-rounded is-link">Join</button>
    </div>
  );
}

export default observer(ClusterJoinThumbnail);
