import { observer } from "mobx-react-lite";
import "./RoomRow.scss";
import RoomName from "../RoomName/RoomName";
import { useStores } from "../../stores/utils";
import RoomPopulation from "../RoomPopulation/RoomPopulation";
import RestBlock from "../RestParticipantsList/RestBlock";
import LimitedParticipantsList from "../LimitedParticipantsList/LimitedParticipantsList";
import classnames from "classnames";

function RoomRow({
  room,
  joinButtonsDisabled,
  focusedEditRoom,
  setFocusedEditRoom,
  noActions,
  isFoyer,
  onClickRoom,
}) {
  const { appStore } = useStores();

  return (
    <div
      className={classnames("room-row-container", {
        "room-row-container__current": room.isCurrentRoom,
      })}
    >
      <RoomName
        room={room}
        focusedEditRoom={focusedEditRoom}
        setFocusedEditRoom={setFocusedEditRoom}
        noActions={noActions}
        isFoyer={isFoyer}
        onClickRoom={onClickRoom}
      />
      {!appStore.showRoomPopulation && (
        <>
          <LimitedParticipantsList room={room} />
          <RestBlock room={room} />
        </>
      )}

      {appStore.showRoomPopulation &&
        room.attributes["participants-count"] > 0 && (
          <RoomPopulation count={room.attributes["participants-count"]} />
        )}
    </div>
  );
}

export default observer(RoomRow);
