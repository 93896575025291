import { action, computed, makeAutoObservable, observable } from "mobx";
import { httpService } from "utilities/HttpService";

export class UserSubscriptionStore {
  @observable subscription = null;
  @observable futureSubscription = null;
  @observable payment = null;
  @observable invoices = [];
  @observable isFetching = false;
  @observable isStartingSubscription = false;
  @observable isUpdatingSubscription = false;
  @observable showCancelSubscriptionModal = false;

  constructor(rootStores) {
    this.rootStores = rootStores;
    makeAutoObservable(this);
  }

  @action
  setShowCancelSubscriptionModal = (showCancelSubscriptionModal) => {
    this.showCancelSubscriptionModal = showCancelSubscriptionModal;
  };

  @action
  getUserSubscription = async () => {
    try {
      const { data } = await httpService.get("/api/v1/subscription");

      this.subscription = data;

      if (data?.data?.attributes?.upgrade_at_period_end) {
        this.getUserFutureSubscription();
      }

      return data;
    } catch (err) {
      console.log(err, "error while getting user subscription");
    }
  };

  @action
  getUserFutureSubscription = async () => {
    try {
      const { data } = await httpService.get("/api/v1/subscription/schedule");

      this.futureSubscription = data?.next_plan?.data;

      return data?.next_plan?.data;
    } catch (err) {
      console.log(err, "error while getting user future subscription");
    }
  };

  @action
  startSubscription = async () => {
    try {
      this.isStartingSubscription = true;
      const { data } = await httpService.post("/api/v1/subscription/start");

      this.subscription = data;
    } catch (err) {
      console.log(err, "error while activating subscription");
    } finally {
      this.isStartingSubscription = false;
    }
  };

  @action
  cancelSubscription = async () => {
    try {
      const { data } = await httpService.delete("/api/v1/subscription");

      this.subscription = data;
      return data;
    } catch (err) {
      console.log(err, "error while cancelling subscription");
    }
  };

  @action
  reactivateSubscription = async () => {
    try {
      const { data } = await httpService.post(
        "/api/v1/subscription/reactivate"
      );

      this.subscription = data;
      return data;
    } catch (err) {
      console.log(err, "error while reactivating subscription");
    }
  };

  @action
  upgradeSubscription = async () => {
    this.isUpdatingSubscription = true;
    const plan = {
      plan_name: this.rootStores.subscriptionsStore?.currentSubscription
        ?.attributes?.name,
      interval: this.rootStores.subscriptionsStore?.selectedInterval,
    };

    try {
      const { data } = await httpService.post(
        "/api/v1/subscription/upgrade",
        plan
      );

      this.getUserFutureSubscription();
      this.subscription = data;

      return data;
    } catch (err) {
      console.log(err, "error while upgrading plan");
    } finally {
      this.isUpdatingSubscription = false;
    }
  };

  @action
  updateCap = async () => {
    const new_cap = this.rootStores.subscriptionsStore?.selectedCap || "no_cap";
    console.log("Updating cap to: ", new_cap);
    try {
      const { data } = await httpService.put("/api/v1/subscription", {
        hours_cap: new_cap,
      });

      this.subscription = data;

      return data;
    } catch (err) {
      console.log(err, "error while updating cap");
    }
  };

  @action
  cancelUpgrade = async () => {
    try {
      const { data } = await httpService.delete(
        "/api/v1/subscription/schedule"
      );

      this.subscription = data;

      return data;
    } catch (err) {
      console.log(err, "error while cancelling upgrade");
    }
  };

  @action
  getInvoices = async () => {
    try {
      const { data } = await httpService.get("/api/v1/subscription/invoices");

      this.invoices = data.data;

      return data;
    } catch (err) {
      console.log(err, "error while getting invoices");
    }
  };

  @action
  getPayment = async () => {
    try {
      const { data } = await httpService.get(
        "/api/v1/subscription/payment_method"
      );

      this.payment = data;

      return data;
    } catch (err) {
      console.log(err, "error while getting payment");
    }
  };

  @action
  updatePayment = async (paymentId, values) => {
    try {
      const { data } = await httpService.post(
        "/api/v1/subscription/payment_method",
        {
          stripe_payment_method_id: paymentId,
          billing_details: values,
        }
      );
      console.log(data, "===");
      return data;
    } catch (err) {
      console.log(err, "error while updating payment");
    }
  };

  @computed
  get isTrialSubscription() {
    return this.subscription?.data?.attributes?.status === "trialing";
  }

  @computed
  get isActiveSubscription() {
    return (
      this.subscription?.data?.attributes?.status !== "canceled" &&
      !this.subscription?.data?.attributes?.cancel_at_period_end
    );
  }

  @computed
  get plan() {
    return this.subscription?.included?.find(
      (entity) => entity.type === "subscription_plan"
    );
  }

  @computed
  get cap() {
    console.log("CAP is: ", this.subscription?.data?.attributes?.hours_cap);
    if (this.subscription?.data?.attributes?.hours_cap === null) {
      return null;
    }
    return (
      this.subscription?.data?.attributes?.hours_cap -
      this.plan?.attributes?.included_hours
    );
  }

  @computed
  get willUpdate() {
    return this.subscription?.data?.attributes?.upgrade_at_period_end;
  }
}
