import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

function TrayCollapseButton({ toggleFn, styleDependency }) {
  return (
    <>
      {/* eslint-disable-next-line */}
      <a
        className="tray-button custom-tray-item"
        target="_blank"
        rel="noopener noreferrer"
        onClick={toggleFn}
      >
        <FontAwesomeIcon
          icon={faChevronUp}
          style={{
            fontSize: "24px",
            color: "white",
            margin: "0 5px",
            transform: styleDependency && `rotate(180deg)`,
          }}
        />
      </a>
    </>
  );
}

export default observer(TrayCollapseButton);
