import { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/utils";
import classnames from "classnames";
import Honeybadger from "honeybadger-js";

const DocumentSettings = ({ docId, modalWrapper = false, afterDelete }) => {
  const { api, docStore } = useStores();
  const nameField = useRef();
  const [docName, setDocName] = useState();
  const [confirmDelete, setConfirmDelete] = useState();
  const [docObj, setDocObj] = useState();

  useEffect(() => {
    const asyncFunc = async () => {
      if (!docId) return;
      const [doc] = await docStore.getAndBuildDoc(docId);
      setDocObj(doc);
      setDocName(doc.name);
    };
    asyncFunc();
  }, [docId, docStore]);

  useEffect(() => {
    setTimeout(() => {
      nameField.current.select();
    }, 100);
  }, []);

  const nameChange = (event) => {
    setDocName(event.target.value);
  };

  const saveNameChange = useCallback(async () => {
    await docStore.updateName(docObj.id, docName);
    if (docObj.id === docStore.openDoc?.id) {
      docStore.refetchDoc();
    }
    await docStore.showDocumentSettingsDocId(undefined);
  }, [docStore, docObj, docName]);

  const promptConfirm = () => {
    setConfirmDelete(true);
  };

  const deleteDocument = async () => {
    try {
      await api.deleteDocument(docObj.id);
      await docStore.showDocumentSettingsDocId(undefined);
      if (afterDelete) {
        afterDelete();
      }
    } catch (e) {
      Honeybadger.notify(e);
    }
  };

  const pressEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        saveNameChange();
      }
    },
    [saveNameChange]
  );

  const docUrl = `${window.location.origin}/doc/${docId}`;

  return (
    <div
      className={classnames("doc-settings-modal", "content", {
        "modal-padding": modalWrapper,
      })}
    >
      <h3>Rename</h3>

      <div className="field is-grouped">
        <p className="control is-expanded">
          <input
            className="input"
            type="text"
            placeholder="Document name"
            value={docName}
            ref={nameField}
            onChange={nameChange}
            onKeyDown={pressEnter}
          />
        </p>
        <p className="control">
          {/* eslint-disable-next-line */}
          <a className="button is-info is-sblue" onClick={saveNameChange}>
            Save
          </a>
        </p>
      </div>
      <hr />
      <div className="control">
        <h3>Document URL</h3>
        <p>You can use the link below to open the document in separate tab.</p>
        <a href={docUrl} target="_blank" rel="noreferrer noopener">
          {docUrl}
        </a>
      </div>
      <hr />
      <h3>Delete</h3>
      <div className="control">
        {confirmDelete ? (
          <>
            <p>
              Are you sure you want to delete this document?
              <br />
              This cannot be undone.
            </p>
            <button className="button is-sred" onClick={deleteDocument}>
              Confirm delete
            </button>
          </>
        ) : (
          <button className="button is-light" onClick={promptConfirm}>
            Delete
          </button>
        )}
      </div>
    </div>
  );
};

export default observer(DocumentSettings);
