export const chatComposerStyle = (props) => {
  return {
    padding: `${props.isUserChat ? "10px 5px" : "14px 16px"}`,
    backgroundColor: `${props.theme.backgroundColor.white}`,
    zIndex: "1",
    order: "3",
    position: "relative",
    flex: "none",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
  };
};

export const composerInputStyle = () => {
  return {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    zIndex: "2",
    padding: "0",
  };
};

export const inputInnerStyle = (props) => {
  return {
    flex: "9",
    position: "relative",
    outline: "none",
    borderRadius: "8px",
    border: `1px solid ${props.theme.borderColor.primary}`,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  };
};

export const messageInputStyle = (disabled) => {
  const disabledState = disabled
    ? {
        pointerEvents: "none",
        opacity: "0.4",
      }
    : {};

  return {
    width: "100%",
    fontSize: "15px",
    lineHeight: "20px",
    fontWeight: "400",
    padding: "8px",
    outline: "none",
    overflowX: "hidden",
    overflowY: "auto",
    position: "relative",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    zIndex: "1",
    minHeight: "36px",
    maxHeight: "100px",
    userSelect: "text",
    background: "#f6f6f6",
    ...disabledState,
    "&:empty:before": {
      content: "attr(placeholder)",
      color: "rgb(153, 153, 153)",
      pointerEvents: "none",
      display: "block" /* For Firefox */,
    },
  };
};

export const inputStickyStyle = (props) => {
  return {
    padding: "7px 10px",
    height: "35px",
    borderTop: `1px solid ${props.theme.borderColor.primary}`,
    backgroundColor: `${props.theme.backgroundColor.grey}`,
    display: "flex",
    justifyContent: "flex-end",
  };
};

export const stickyAttachmentStyle = () => {
  return {
    display: "flex",
    flex: "1",
    position: "relative",
  };
};

export const attachmentIconStyle = (img) => {
  return {
    padding: "5px",
    paddingTop: "10px",
    paddingLeft: "0",
    paddingRight: "7px",
    " > span": {
      display: "inline-block",
      color: "#777777",
      width: "20px",
      height: "20px",
      background: `url(${img}) center center no-repeat`,
      cursor: "pointer",
    },
  };
};

export const filePickerStyle = (state) => {
  const active = state.showFilePicker
    ? {
        height: "116px",
        opacity: "1",
      }
    : {};

  return {
    left: "-4px",
    bottom: "30px",
    position: "absolute",
    borderRadius: "8px",
    overflow: "hidden",
    zIndex: "2",
    textAlign: "center",
    opacity: "0",
    height: "0",
    transition: "height 0.5s linear",
    backgroundColor: "#f6f6f6",
    ...active,
  };
};

export const fileListStyle = () => {
  return {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    " > span": {
      margin: "4px",
    },
  };
};

export const fileItemStyle = (props, img) => {
  const icon = {
    background: `url(${img}) no-repeat 100% 100%`,
  };

  return {
    width: "21px",
    height: "21px",
    backgroundColor: `${props.theme.backgroundColor.secondary}`,
    cursor: "pointer",
    " > input": {
      display: "none",
    },
    ...icon,
  };
};

export const stickyButtonStyle = () => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  };
};

export const emojiButtonStyle = () => {
  return {
    padding: "0 5px",
    flex: "1",
    paddingTop: "5px",
    "> img": {
      width: "20px",
      height: "20px",
      display: "inline-block",
      cursor: "pointer",
    },
  };
};

export const sendButtonStyle = () => {
  return {
    paddingTop: "5px",
    flex: "1",
    "> img": {
      width: "20px",
      height: "18px",
      display: "inline-block",
      cursor: "pointer",
    },
  };
};
