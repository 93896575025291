import { observer } from "mobx-react-lite";
import Tippy from "@tippyjs/react";
import ReactionsMenu from "./ReactionsMenu";
import { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile } from "@fortawesome/pro-solid-svg-icons";

const ReactionsButton = ({ theme }) => {
  const [isReactionsMenuOpen, setReactionsMenuOpen] = useState(false);

  const hideReactionsMenu = useCallback(() => {
    setReactionsMenuOpen(false);
  }, []);

  const toggleReactionsMenu = useCallback(() => {
    setReactionsMenuOpen(!isReactionsMenuOpen);
  }, [isReactionsMenuOpen]);

  return (
    <Tippy
      animation="shift-away"
      content={
        <ReactionsMenu
          isReactionsMenuOpen={isReactionsMenuOpen}
          hideReactionsMenu={hideReactionsMenu}
        />
      }
      interactive={true}
      onClickOutside={hideReactionsMenu}
      theme="light"
      visible={isReactionsMenuOpen}
      placement="top-start"
    >
      <Tippy content={"Reactions"} appendTo="parent" theme={theme}>
        {/* eslint-disable-next-line */}
        <a
          className="tray-button custom-tray-item"
          target="_blank"
          rel="noopener noreferrer"
          onClick={toggleReactionsMenu}
        >
          <FontAwesomeIcon icon={faSmile} style={{ fontSize: "24px" }} />
        </a>
      </Tippy>
    </Tippy>
  );
};

export default observer(ReactionsButton);
