/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";

import { CometChat } from "@cometchat-pro/chat";

import SenderMessageBubble from "../SenderMessageBubble";
import ReceiverMessageBubble from "../ReceiverMessageBubble";
import SenderImageBubble from "../SenderImageBubble";
import ReceiverImageBubble from "../ReceiverImageBubble";
import SenderFileBubble from "../SenderFileBubble";
import ReceiverFileBubble from "../ReceiverFileBubble";
import SenderAudioBubble from "../SenderAudioBubble";
import ReceiverAudioBubble from "../ReceiverAudioBubble";
import SenderVideoBubble from "../SenderVideoBubble";
import ReceiverVideoBubble from "../ReceiverVideoBubble";

import { theme } from "../../resources/theme";

const messageComponentforSender = (message) => {
  let component;
  switch (message.type) {
    case CometChat.MESSAGE_TYPE.TEXT:
      component = message.text ? (
        <SenderMessageBubble message={message} theme={theme} minimized />
      ) : null;
      break;
    case CometChat.MESSAGE_TYPE.IMAGE:
      component = message.data.url ? (
        <SenderImageBubble message={message} theme={theme} minimized />
      ) : null;
      break;
    case CometChat.MESSAGE_TYPE.FILE:
      component = message.data.attachments ? (
        <SenderFileBubble message={message} theme={theme} minimized />
      ) : null;
      break;
    case CometChat.MESSAGE_TYPE.VIDEO:
      component = message.data.url ? (
        <SenderVideoBubble message={message} theme={theme} minimized />
      ) : null;
      break;
    case CometChat.MESSAGE_TYPE.AUDIO:
      component = message.data.url ? (
        <SenderAudioBubble message={message} theme={theme} minimized />
      ) : null;
      break;
    default:
      break;
  }

  return component;
};

const messageComponentforReceiver = (message) => {
  let component;
  switch (message.type) {
    case "message":
    case CometChat.MESSAGE_TYPE.TEXT:
      component = message.text ? (
        <ReceiverMessageBubble message={message} theme={theme} minimized />
      ) : null;
      break;
    case CometChat.MESSAGE_TYPE.IMAGE:
      component = message.data.url ? (
        <ReceiverImageBubble message={message} theme={theme} minimized />
      ) : null;
      break;
    case CometChat.MESSAGE_TYPE.FILE:
      component = message.data.attachments ? (
        <ReceiverFileBubble message={message} theme={theme} minimized />
      ) : null;
      break;
    case CometChat.MESSAGE_TYPE.AUDIO:
      component = message.data.url ? (
        <ReceiverAudioBubble message={message} theme={theme} minimized />
      ) : null;
      break;
    case CometChat.MESSAGE_TYPE.VIDEO:
      component = message.data.url ? (
        <ReceiverVideoBubble message={message} theme={theme} minimized />
      ) : null;
      break;
    default:
      break;
  }
  return component;
};

const getComponent = (message, thisUser) => {
  let component;

  if (thisUser && message.sender && thisUser.uid === message.sender.uid) {
    switch (message.category) {
      case "message":
        component = messageComponentforSender(message);
        break;
      case "action":
        // component = (message.message ? <div className="cc1-chat-win-action-msg-wrap"><p className="chat-txt-msg">{message.message}</p></div> : "");
        break;
      default:
        break;
    }
  } else {
    switch (message.category) {
      case "message":
        component = messageComponentforReceiver(message);
        break;
      case "action":
        // component = (message.message ? <div className="cc1-chat-win-action-msg-wrap"><p className="chat-txt-msg">{message.message}</p></div> : "");
        break;
      default:
        break;
    }
  }
  return component;
};

export { getComponent };
