import { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useStores, observe } from "stores/utils";
import { Input } from "components/Form/Input";
import "./Coupon.scss";

const CouponFormSchema = Yup.object().shape({
  coupon: Yup.string().required("Coupon is required"),
});

export const Coupon = () => {
  const { subscriptionsStore } = useStores();
  const [showCouponForm, setShowCouponForm] = useState(false);

  return (
    <div className="coupon-container">
      <div
        onClick={() => setShowCouponForm(true)}
        className="discount-code-text"
      >
        + add a non-profit discount code
      </div>
      {showCouponForm && (
        <Formik
          initialValues={{
            coupon: "",
          }}
          validationSchema={CouponFormSchema}
          onSubmit={(values) => {
            subscriptionsStore.checkCoupon(values.coupon);
          }}
        >
          {({ isValid }) => (
            <Form className="coupon-form">
              <Field name="coupon" component={Input} placeholder="Code" />
              <div className="subscription-card__actions">
                <button
                  className="subscription__submit-button"
                  type="submit"
                  disabled={!isValid}
                >
                  {observe(subscriptionsStore.isCouponValid, () =>
                    subscriptionsStore.isCouponValid === true
                      ? "Applied!"
                      : "Apply"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {observe(
        subscriptionsStore.isCouponValid,
        () =>
          subscriptionsStore.isCouponValid === false && (
            <div className="invalid-coupon">invalid code</div>
          )
      )}
    </div>
  );
};
