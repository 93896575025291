import { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import "./Tile.scss";
import TileVideo from "./TileVideo";
import TileAudio from "./TileAudio";
import { useTileSettings } from "../../hooks/useTileSettings";
import SharedCursors from "./SharedCursors";
import { useEventListener } from "hooks/useEventListener";
import ControlsOverlay from "./ControlsOverlay";

/**
 * Props
 * - videoTrack: MediaStreamTrack?
 * - audioTrack: MediaStreamTrack?
 * - isLocalPerson: boolean
 * - isLarge: boolean
 * - isLoading: boolean
 */
function Tile({
  tile,
  positionTippyTop,
  tilesCount,
  verticalLayout,
  skinnyLayout,
}) {
  const [tileWidth, setTileWidth] = useState(0);
  const settings = useTileSettings({ tile });

  const myBackgroundEl = useRef();
  const myVideoEl = useRef();
  const tileRef = useRef();

  const {
    callStore,
    roomReactionStore,
    tileSettingsStore,
    tilesStore,
  } = useStores();

  const callItem = tile.callItem;
  const isActivePerson =
    callStore.activeSpeakerEnabled &&
    callStore.activeSpeakerId === callItem.dailyId;
  const isBlocked = callItem.tracks?.video?.state === "blocked";
  const isReallyLoading =
    callItem.isLoading &&
    callItem.sidebarParticipant?.videoOn &&
    !callItem.isBlocked;

  const reasonObject = callItem.tracks?.video?.blocked;
  const blockedReasons =
    reasonObject &&
    Object.entries(reasonObject)
      .filter((x) => x[1])
      .map((x) => x[0]);

  const myTile = tile.callItem.isLocal;
  const userId = tile.callItem.dailyId;
  const largeOrSmallTile = (tile.isLarge && !tile.isScreen) || !tile.isLarge;
  const largeTileNotScreen = tile.isLarge && !tile.isScreen;
  const inScreenshareMode = tilesStore.isAnyoneScreenSharing;

  useEffect(() => {
    // reset room reactions on mount
    myTile
      ? roomReactionStore.resetReactions()
      : roomReactionStore.resetReactions(userId);
  }, [myTile, roomReactionStore, userId, tile]);

  const userTileSettings = tileSettingsStore.changedTiles[userId];

  useEffect(() => {
    if (myTile) {
      if (myVideoEl.current && myBackgroundEl.current) {
        tileSettingsStore.setRefs({
          backgroundEl: myBackgroundEl.current,
          videoEl: myVideoEl.current,
        });
      } else {
        tileSettingsStore.setRefs({
          backgroundEl: undefined,
          videoEl: undefined,
        });
      }
    }
  }, [myVideoEl, myBackgroundEl, tileSettingsStore, myTile]);

  const updateTileWidth = useCallback(() => {
    if (!tileRef.current) return;
    const w = tileRef.current?.offsetHeight * 1.778;
    setTileWidth(w);
  }, [tileRef, setTileWidth]);

  useEffect(() => {
    if (!tileRef.current) return;
    const tileSensor = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        updateTileWidth();
      });
    });
    tileSensor.observe(tileRef.current);
    return () => tileSensor.disconnect();
  }, [updateTileWidth, tileRef]);

  useEventListener("resize", updateTileWidth);

  const applyTileStyle = {
    width:
      tilesCount === 2
        ? skinnyLayout
          ? "100%"
          : verticalLayout && tileWidth && tileWidth
        : "",
  };

  const notTwoTiles = inScreenshareMode || tilesCount !== 2 || userTileSettings;

  return (
    <div
      className={classnames("tile", tile.isLarge ? "large" : "small", {
        screenshare: tile.isScreen,
        local: tile.callItem.isLocal,
        "active-speaker": isActivePerson && !tile.isScreen,
        resizing: largeTileNotScreen && !!userTileSettings,
      })}
      ref={tileRef}
      style={applyTileStyle}
    >
      <div
        className="background"
        id={myTile ? "local-tile-background" : undefined}
        style={settings.backgroundColor}
        ref={myTile ? myBackgroundEl : undefined}
      />
      {isReallyLoading && <p className="loading"></p>}
      {largeOrSmallTile ? (
        myTile ? (
          <div id="my-reactions">{roomReactionStore.myReaction || ""}</div>
        ) : (
          <div id="other-users-reactions">
            {roomReactionStore.otherUserReactions[userId] || ""}
          </div>
        )
      ) : (
        <></>
      )}
      <TileVideo
        videoTrack={tile.callItem.videoTrack}
        largeTileNotScreen={largeTileNotScreen}
        dimensions={settings.dimensions}
        myVideoEl={myTile && myVideoEl}
        isBlocked={isBlocked}
        blockedReasons={blockedReasons}
        tile={tile}
        positionTippyTop={positionTippyTop}
        twoTiles={!notTwoTiles}
      />
      {tile.callItem.videoTrack && (
        <TileAudio
          isLocalPerson={tile.callItem.isLocal}
          audioTrack={tile.callItem.audioTrack}
        />
      )}
      {tile.isScreen && !tilesStore.isMultipleScreens && (
        <SharedCursors
          videoTrack={tile.callItem.videoTrack}
          myScreen={myTile}
        />
      )}
      {notTwoTiles && (
        <ControlsOverlay
          tile={tile}
          positionTippyTop={positionTippyTop}
          isBlocked={isBlocked}
          blockedReasons={blockedReasons}
        />
      )}
    </div>
  );
}

Tile.whyDidYouRender = false;
export default observer(Tile);
