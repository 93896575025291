import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { navigate } from "@reach/router";
import ForgotPassword from "../../components/Forgot/ForgotPassword";
import "./Forgot.scss";
import * as Amplitude from "../../utilities/amplitude";
import PlainGreyLayout from "../../components/Layouts/PlainGreyLayout";

const Forgot = (props) => {
  useEffect(() => {
    Amplitude.track("Visited forgot password page.");
  }, []);

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <PlainGreyLayout>
      <div className=" content-column narrow">
        <ForgotPassword navigate={navigate} goToLogin={goToLogin} />
      </div>
    </PlainGreyLayout>
  );
};

export default observer(Forgot);
