import { observer } from "mobx-react-lite";
import { buildInitials } from "../../utilities/text";

function PlaceholderProfilePhoto({ personName, color }) {
  const initials = buildInitials(personName);
  let textColor;
  if (color) {
    color = `#${color}`;
    textColor = "#FFFFFF";
  } else {
    color = "#DDDDDD";
    textColor = "#9c9c9c";
  }

  return (
    <>
      <div
        className="tiny-rounded-profile-photo tiny-placeholder-profile-photo"
        style={{ backgroundColor: color, color: textColor }}
      >
        {initials}
      </div>
    </>
  );
}

export default observer(PlaceholderProfilePhoto);
