import Axios from "axios";
import axiosBetterStacktrace from "axios-better-stacktrace";

export default function setupApi(token, rootStores = null) {
  const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
  });
  axiosBetterStacktrace(authAxios);

  authAxios.interceptors.request.use(
    (config) => {
      if (config.method === "post" || config.method === "put") {
        config.headers["Content-Type"] = "application/vnd.api+json";
        config.headers.Accept = "application/vnd.api+json";
      }
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const code = error && error.response ? error.response.status : 0;
      if (code === 401) {
        rootStores.userStore.softLogout();
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  );

  return authAxios;
}
