import setupApi from "./setupApi";
import Honeybadger from "honeybadger-js";

export default class Api {
  constructor(rootStores) {
    this.rootStores = rootStores;
  }

  setup(token) {
    this.axios = setupApi(token, this.rootStores);
  }

  getSessions = async (organizationId) => {
    try {
      const response = await this.axios.get(
        `/api/v1/organizations/${organizationId}/session-histories`
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  getSession = async (sessionId, { silentlyFail = false } = {}) => {
    try {
      let requestPath = `/api/v1/sessions/${sessionId}?include=groups,participants,participants.group`;
      try {
        const response = await this.axios.get(requestPath);
        return response.data;
      } catch (e) {
        Honeybadger.notify(e);
      }
    } catch (err) {
      console.log(err);
      if (silentlyFail) return;
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  getSessionMembers = async (sessionId, page = 1) => {
    try {
      let requestPath = `/api/v1/sessions/${sessionId}/space-memberships?page[number]=${page}&page[size]=20&include=user`;
      const response = await this.axios.get(requestPath);
      return response.data;
    } catch (err) {
      console.log(err);

      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  updateSession = async (id, data) => {
    try {
      const response = await this.axios.put(`/api/v1/sessions/${id}`, data);
      return response.data;
    } catch (err) {
      console.log(err);
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  getUsageSummaryData = async (organizationId) => {
    try {
      const response = await this.axios.get(
        `/api/v1/organizations/${organizationId}/usage`
      );
      return response.data;
    } catch (err) {
      console.log(err); // TODO: setup a logger for this
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  muteParticipant = (id) => {
    return this.participantHostAction(id, {
      hostAction: "mute-someone",
    });
  };

  participantHostAction = async (participantId, actionParams) => {
    console.log(" !!!! API CALL !!!! - participantHostAction");

    try {
      const response = await this.axios.post(
        `/api/v1/participants/${participantId}/host_act`,
        actionParams
      );
      return response.data;
    } catch (err) {
      console.log(err); // TODO: setup a logger for this
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  muteEveryone = (groupId, myParticipantId) => {
    return this.groupHostAction(groupId, {
      hostAction: "mute-everyone",
      except: [myParticipantId],
    });
  };

  groupHostAction = async (groupId, actionParams) => {
    console.log(" !!!! API CALL !!!! - groupHostAction");

    try {
      const response = await this.axios.post(
        `/api/v1/groups/${groupId}/host_act`,
        actionParams
      );
      return response.data;
    } catch (err) {
      console.log(err); // TODO: setup a logger for this
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  broadcastMyReaction = (groupId, myParticipantId, emoji) => {
    return this.groupMyAction(groupId, {
      participantAction: "emoji-reaction",
      except: [myParticipantId],
      emojiReaction: emoji,
    });
  };

  groupMyAction = async (groupId, actionParams) => {
    console.log(" !!!! API CALL !!!! - groupMyAction");
    try {
      const response = await this.axios.post(
        `/api/v1/groups/${groupId}/participant_act`,
        actionParams
      );
      return response.data;
    } catch (err) {
      console.log(err); // TODO: setup a logger for this
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  getUser = async () => {
    try {
      const response = await this.axios.get(`/api/v1/user`);
      return response.data;
    } catch (err) {
      console.log(err);
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  getUsers = async (organizationId) => {
    try {
      const response = await this.axios.get(
        `/api/v1/organizations/${organizationId}/users`
      );
      return response.data;
    } catch (err) {
      console.log(err); // TODO: setup a logger for this
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  getHostPermissions = async (organizationId) => {
    try {
      const response = await this.axios.get(
        `/api/v1/organizations/${organizationId}/host_permissions`
      );
      return response.data;
    } catch (e) {
      console.log(e); // TODO: setup a logger for this
    }
  };

  getInvitations = async (organizationId) => {
    try {
      const response = await this.axios.get(
        `/api/v1/organizations/${organizationId}/invitations`
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  createInvitation = async ({
    email,
    firstName,
    lastName,
    role,
    permittedEvents,
    forOffice = false,
  }) => {
    try {
      const response = await this.axios.post(`/api/v1/invitations`, {
        email,
        first_name: firstName,
        last_name: lastName,
        role,
        permitted_session_ids: permittedEvents,
        for_office: forOffice,
      });
      return response.data;
    } catch (e) {
      console.log(e); // TODO: setup a logger for this
      this.rootStores.errorStore.handleApiError(e);

      if (e.response) {
        return {
          error: e,
        };
      }
    }
  };

  deleteTeamMemberInvitation = async (teamMemberId) => {
    try {
      const response = await this.axios.delete(
        `/api/v1/invitations/${teamMemberId}`
      );
      return response.data;
    } catch (e) {
      console.log(e); // TODO: setup a logger for this
      this.rootStores.errorStore.handleApiError(e);
    }
  };

  deleteUser = async (userId) => {
    try {
      const response = await this.axios.delete(`/api/v1/users/${userId}`);
      return response.data;
    } catch (e) {
      console.log(e); // TODO: setup a logger for this
      this.rootStores.errorStore.handleApiError(e);
    }
  };

  updateUser = async (data) => {
    if (!data.id) return;

    let attributes = {};
    if (data.role) {
      attributes.role = data.role;
    }
    if (data.display_name) {
      attributes["display_name"] = data.display_name;
    }
    if (data.availability_status) {
      attributes["availability_status"] = data.availability_status;
    }
    if (data.time_zone) {
      attributes["time_zone"] = data.time_zone;
    }

    try {
      const response = await this.axios.put(`/api/v1/users/${data.id}`, {
        data: {
          id: data.id,
          type: "users",
          attributes: attributes,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e); // TODO: setup a logger for this
      throw e;
    }
  };

  updateInvitation = async (data) => {
    if (!data.id) return;

    let attributes = {};
    if (data.role) {
      attributes.role = data.role;
    }

    const response = await this.axios.put(`/api/v1/invitations/${data.id}`, {
      data: {
        id: data.id,
        type: "invitations",
        attributes: attributes,
      },
    });
    return response.data;
  };

  toggleHostPermission = async ({ userId, sessionId, organizationId }) => {
    try {
      const response = await this.axios.post(
        `/api/v1/organizations/${organizationId}/toggle_host_permission`,
        {
          user_id: userId,
          session_id: sessionId,
        }
      );
      return response.data;
    } catch (e) {
      console.log(e); // TODO: setup a logger for this
      throw e;
    }
  };

  searchMembers = async (query) => {
    const organizationId = this.rootStores.userStore.organizationId;
    const url = `/api/v1/organizations/${organizationId}/users?filter[query]=${query}`;
    return await this.axios.get(url);
  };

  getMembers = async (sessionId) => {
    const url = `/api/v1/sessions/${sessionId}/space-memberships?include=user`;
    return await this.axios.get(url);
  };

  addMember = async (params) => {
    let memberParams = { userId: params.userId };
    let sessionId = params.sessionId;
    const url = `/api/v1/sessions/${sessionId}/space_memberships`;
    return await this.axios.post(url, memberParams);
  };

  getUserSpaces = async (userId) => {
    const url = `api/v1/users/${userId}/sessions`;
    return await this.axios.get(url);
  };

  getOfficeSummary = async (organizationId) => {
    const url = `api/v1/organizations/${organizationId}/office_summary`;
    return await this.axios.get(url);
  };

  getPrivateSummary = async () => {
    const url = `api/v1/user/private_summary`;
    return await this.axios.get(url);
  };

  getAllUsers = async (organizationId) => {
    const url = `api/v1/organizations/${organizationId}/all_users`;
    return await this.axios.get(url);
  };

  pingUser = async () => {
    const userId = this.rootStores.userStore.userInfo.id;
    const url = `api/v1/users/${userId}/ping`;
    return await this.axios.post(url);
  };

  userlandPingUser = async (userIdToPing) => {
    const url = `api/v1/users/${userIdToPing}/userland_ping`;
    const params = {
      from: this.rootStores.userStore.userInfo.id,
    };
    return await this.axios.post(url, params);
  };

  knock = async (targetUserId) => {
    const url = `api/v1/users/${targetUserId}/knock`;
    const params = { from: this.rootStores.userStore.userId };
    return await this.axios.post(url, params);
  };

  rejectUserlandPing = async (userIdToReject) => {
    const url = `api/v1/users/${userIdToReject}/userland_ping_reject`;
    const params = {
      from: this.rootStores.userStore.userInfo.id,
    };
    return await this.axios.post(url, params);
  };

  rejectKnock = async (userIdToReject) => {
    const url = `api/v1/users/${userIdToReject}/reject_knock`;
    const params = {
      from: this.rootStores.userStore.userInfo.id,
    };
    return await this.axios.post(url, params);
  };

  requestCallUser = async (userIdToCall) => {
    const url = `api/v1/users/${userIdToCall}/request_call`;
    const params = {
      from: this.rootStores.userStore.userInfo.id,
    };
    return await this.axios.post(url, params);
  };

  answerCall = async (callRequestId) => {
    console.log("Call request id is: ", callRequestId);
    const url = `api/v1/call_requests/${callRequestId}/accept`;
    return await this.axios.post(url);
  };

  acceptKnock = async (knockId) => {
    return await this.answerCall(knockId);
  };

  rejectCall = async (callRequestId) => {
    const url = `api/v1/call_requests/${callRequestId}/reject`;
    return await this.axios.post(url);
  };

  cancelCallRequest = async (callRequestId) => {
    const url = `api/v1/call_requests/${callRequestId}/cancel`;
    return await this.axios.post(url);
  };

  subscribeToSpace = async (sessionId, userId) => {
    const url = `api/v1/space-subscriptions`;
    const params = { user_id: userId, session_id: sessionId };
    return await this.axios.post(url, params);
  };

  unsubscribeFromSpace = async (sessionId, userId) => {
    const url = `api/v1/space-subscriptions/lookup_delete`;
    const params = { user_id: userId, session_id: sessionId };
    return await this.axios.post(url, params);
  };

  becomeMemberOfSpace = async (sessionId, userId) => {
    const url = `api/v1/space-memberships`;
    const params = { user_id: userId, session_id: sessionId };
    return await this.axios.post(url, params);
  };

  unbecomeMemberOfSpace = async (sessionId, userId) => {
    const url = `api/v1/space-memberships/lookup_delete`;
    const params = { user_id: userId, session_id: sessionId };
    return await this.axios.post(url, params);
  };

  getUserPreferences = async (userId) => {
    const url = `api/v1/user/preferences`;
    return await this.axios.get(url);
  };

  uploadProfilePhoto = async (base64Image) => {
    const formData = new FormData();
    formData.append("profile_photo", base64Image);

    const url = `/api/v1/user/upload_photo`;
    return await this.axios.post(url, formData);
  };

  bulkInvite = async ({ emailList, forOffice = false }) => {
    try {
      const response = await this.axios.post(`/api/v1/invitations/bulk`, {
        email_list: emailList,
        for_office: forOffice,
      });
      return response.data;
    } catch (e) {
      console.log(e); // TODO: setup a logger for this
      this.rootStores.errorStore.handleApiError(e);

      if (e.response) {
        return {
          error: e,
        };
      }
    }
  };

  joinMe = async (userId, groupId) => {
    const url = `api/v1/users/${userId}/join_me`;
    const params = {
      from: this.rootStores.userStore.userInfo.id,
      group_id: groupId,
    };
    return await this.axios.post(url, params);
  };

  getOrganization = async () => {
    const url = `api/v1/user/organization`;
    return await this.axios.get(url);
  };

  uploadOrganizationLogo = async (base64Image, organizationId) => {
    const formData = new FormData();
    formData.append("logo", base64Image);

    const url = `/api/v1/organizations/${organizationId}/upload_logo`;
    return await this.axios.post(url, formData);
  };

  deleteOrganizationLogo = async (organizationId) => {
    const url = `/api/v1/organizations/${organizationId}/delete_logo`;
    return await this.axios.delete(url);
  };

  getSessionMembershipSummary = async (sessionId) => {
    const url = `/api/v1/sessions/${sessionId}/membership_summary`;
    return await this.axios.get(url);
  };

  getGroup = async (groupId) => {
    let url = `/api/v1/groups/${groupId}`;
    return await this.axios.get(url);
  };

  getRest5ParticipantsInGroup = async (groupId) => {
    const url = `/api/v1/groups/${groupId}/rest5`;
    return await this.axios.get(url);
  };

  getFailedInvitations = async () => {
    const url = "/api/v1/failed_invitations";
    return await this.axios.get(url);
  };

  deleteFailedInvitation = async (failedInvitationId) => {
    const url = `/api/v1/failed_invitations/${failedInvitationId}`;
    return await this.axios.delete(url);
  };

  uploadSessionLogo = async (base64Image, sessionId) => {
    const formData = new FormData();
    formData.append("logo", base64Image);

    const url = `/api/v1/sessions/${sessionId}/upload_logo`;
    return await this.axios.post(url, formData);
  };

  removeSessionLogo = async (sessionId) => {
    const url = `/api/v1/sessions/${sessionId}/delete_logo`;

    return await this.axios.delete(url);
  };

  closeOtherTabs = async (userId, tabId) => {
    const url = `api/v1/users/${userId}/close_other_tabs`;
    const params = {
      tab_id: tabId,
    };
    return await this.axios.post(url, params);
  };

  getOfficeSession = async (userId) => {
    const url = `api/v1/users/${userId}/office_session`;
    return await this.axios.get(url);
  };

  slackUserLookup = async (userId) => {
    const url = `api/v1/slack_lookup/${userId}`;
    return await this.axios.get(url);
  };

  slackTryLinkAccount = async (userId, slackUserId, secondAttempt) => {
    const url = `api/v1/users/${userId}/slack_try_link`;
    const params = {
      slack_user_id: slackUserId,
      second_attempt: secondAttempt,
    };
    return await this.axios.post(url, params);
  };

  slackIfOffline = async (userId, message) => {
    const url = `api/v1/users/${userId}/offline_message`;
    const params = {
      message: message,
    };
    return await this.axios.post(url, params);
  };

  generateSharedInvitation = async (organizationId) => {
    const url = `api/v1/organizations/${organizationId}/shared_invitations`;
    return await this.axios.post(url);
  };

  setUserPreferenceValue = async (key, value) => {
    const url = `api/v1/user/preferences`;
    const params = {};
    params[key] = value;
    return await this.axios.post(url, params);
  };

  shareTileSetting = (groupId, myParticipantId, settings) => {
    return this.groupMyAction(groupId, {
      participantAction: "tile-settings",
      except: [myParticipantId],

      tileSettings: settings,
    });
  };

  createMeeting = async (meetingName) => {
    let attributes = {
      name: meetingName,
      "session-type": "meeting",
    };

    const payload = {
      data: {
        type: "sessions",
        attributes: attributes,
      },
    };

    return await this.axios.post("api/v1/sessions", payload);
  };

  saveMeeting = async (sessionId) => {
    const url = `api/v1/sessions/${sessionId}/save_meeting`;
    return await this.axios.post(url);
  };

  forgetMeeting = async (sessionId) => {
    const url = `api/v1/sessions/${sessionId}/forget_meeting`;
    return await this.axios.post(url);
  };

  deleteSession = async (sessionId) => {
    const url = `api/v1/sessions/${sessionId}`;
    return await this.axios.delete(url);
  };

  createDocument = async (attributes, groupId, sessionId) => {
    const url = "api/v1/group-docs";
    const payload = {
      data: {
        type: "group-docs",
        attributes: attributes,
        relationships: {
          group: {
            data: {
              id: groupId,
              type: "groups",
            },
          },
          session: {
            data: {
              id: sessionId,
              type: "sessions",
            },
          },
        },
      },
    };
    return await this.axios.post(url, payload);
  };

  getDocuments = async (groupId) => {
    const url = `api/v1/groups/${groupId}/group-docs?sort=-updated_at`;
    return await this.axios.get(url);
  };

  getOtherRoomDocuments = async (groupId) => {
    const url = `api/v1/groups/${groupId}/other-group-docs?sort=-updated_at`;
    return await this.axios.get(url);
  };

  getDocument = async (groupDocId) => {
    const url = `api/v1/group-docs/${groupDocId}?include=doc-editors`;
    return await this.axios.get(url);
  };

  updateDocument = async (groupDocId, attributes) => {
    const url = `api/v1/group-docs/${groupDocId}`;

    const response = await this.axios.put(url, {
      data: {
        id: groupDocId,
        type: "group-docs",
        attributes: attributes,
      },
    });
    return response;
  };

  updateOpenDoc = async (groupId, docId) => {
    let payload = {
      data: {
        id: groupId,
        type: "groups",
        relationships: {
          "open-doc": {
            data: {
              id: docId,
              type: "group-docs",
            },
          },
        },
      },
    };

    return await this.axios.put(`/api/v1/groups/${groupId}`, payload);
  };

  closeOpenDoc = async (groupId) => {
    return await this.axios.delete(
      `/api/v1/groups/${groupId}/relationships/open-doc`
    );
  };

  deleteDocument = async (docId) => {
    const url = `api/v1/group-docs/${docId}`;
    return await this.axios.delete(url);
  };

  deleteDocEditor = async (docEditorId) => {
    const url = `api/v1/doc-editors/${docEditorId}`;
    return await this.axios.delete(url);
  };

  createDocEditor = async (docId, userId) => {
    const url = `/api/v1/doc-editors`;
    const payload = {
      data: {
        type: "doc-editors",
        attributes: {},
        relationships: {
          "group-doc": {
            data: {
              id: docId,
              type: "group-docs",
            },
          },
          user: {
            data: {
              id: userId,
              type: "users",
            },
          },
        },
      },
    };
    return await this.axios.post(url, payload);
  };

  getSessionDocuments = (sessionId) => {
    const url = `api/v1/sessions/${sessionId}/groups_with_docs`;
    return this.axios.get(url);
  };

  // -------------------------------------------------- Links --------------------------------------------------

  createLink = async (payload) => {
    const url = "api/v1/group-links";
    return await this.axios.post(url, payload);
  };

  getLinks = async (group_id) => {
    const url = `api/v1/groups/${group_id}/group-links?sort=-updated_at`;
    return await this.axios.get(url);
  };

  getLink = async (linkId) => {
    const url = `api/v1/group-links/${linkId}`;
    return await this.axios.get(url);
  };

  updateLink = async (linkId, payload) => {
    const url = `api/v1/group-links/${linkId}`;
    return await this.axios.put(url, payload);
  };

  deleteLink = async (linkId) => {
    const url = `api/v1/group-links/${linkId}`;
    return await this.axios.delete(url);
  };

  getOtherRoomLinks = async (groupId) => {
    const url = `api/v1/groups/${groupId}/other-group-links?sort=-updated_at`;
    return await this.axios.get(url);
  };
}
