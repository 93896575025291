import { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { CgArrowsExpandUpLeft } from "react-icons/cg";
import { useStores } from "../../stores/utils";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";

function PullAside({ tile, isMe }) {
  const { callStore, callObjectStore } = useStores();
  const [inviting, setInviting] = useState(0);
  const [tippy, setTippy] = useState(null);

  const clusterPopulation = callStore.clusterParticipantIds.length;
  const hideStepAway = clusterPopulation < 2;
  const hideSidebar = clusterPopulation < 3;

  const pullAside = useCallback(() => {
    callObjectStore.stopScreensharing();
    setInviting(1);
    tile.callItem.sidebarParticipant.sendInvite("clusterSidebarInvite");
    setTimeout(() => {
      if (tippy) {
        tippy.show();
      }
      setInviting(2);
      setTimeout(() => {
        if (tippy) {
          tippy.hide();
        }
        setTimeout(() => {
          setInviting(0);
        }, 300);
      }, 1000);
    }, 500);
  }, [tile, callObjectStore, setInviting, tippy]);

  const stepAside = useCallback(() => {
    callObjectStore.stopScreensharing();
    callStore.stepAside();
  }, [callStore, callObjectStore]);

  if (tile.isScreen) {
    return null;
  }

  return (
    <>
      {isMe && !hideStepAway ? (
        <div className="pull-aside__container">
          <Tippy
            content="Create a cluster"
            theme="light"
            delay={[600, 0]}
            animation="fade"
            duration={100}
            hideOnClick={false}
            onCreate={(tippy) => setTippy(tippy)}
          >
            <button
              className="button"
              onClick={() => {
                stepAside();
              }}
            >
              <span className="icon">
                <CgArrowsExpandUpLeft />
              </span>{" "}
            </button>
          </Tippy>
        </div>
      ) : (
        !hideSidebar && (
          <div className="pull-aside__container">
            <Tippy
              content={
                <>
                  {inviting === 0 && "Invite to a cluster"}
                  {inviting === 1 && "Inviting ..."}
                  {inviting === 2 && "Invited!"}
                </>
              }
              theme="light"
              delay={[600, 0]}
              animation="fade"
              duration={100}
              hideOnClick={false}
              onCreate={(tippy) => setTippy(tippy)}
            >
              <button
                className="button"
                onClick={() => {
                  pullAside();
                }}
              >
                <span className="icon">
                  <CgArrowsExpandUpLeft />
                </span>{" "}
              </button>
            </Tippy>
          </div>
        )
      )}
    </>
  );
}

export default observer(PullAside);
