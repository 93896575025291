import WorkerBuilder from "./workerBuilder";

const isComputerAsleep = () => {
  onmessage = (e) => {
    console.log("[Web Worker]: asleep worker initiated");
  };

  let clientSession = new Date();
  let clientSessionTimer = setInterval(() => {
    const now = new Date();
    const secondsSpan = (now - clientSession) / 1000;
    if (secondsSpan > 5) {
      // the browser halted execution probably due to hibernation
      postMessage("wakeup");
      clearInterval(clientSessionTimer);
    } else {
      clientSession = now;
    }
  }, 1000);
};

const asleepWorker = new WorkerBuilder(isComputerAsleep);

export default asleepWorker;
