import { observer } from "mobx-react-lite";
import CallTiles from "../CallTiles/CallTiles";
import ClusterList from "../ClusterList";
import ReactionProjector from "../Reactions/ReactionProjector";
import CallMessage from "../CallMessage/CallMessage";
import MicrophoneList from "../MicrophoneList/MicrophoneList";
import { useStores } from "../../stores/utils";
import classnames from "classnames";
import { useEffect } from "react";

function CallRoom() {
  const { callStore, callObjectStore, tileSettingsStore } = useStores();
  const showClusterList = callStore.isAnyoneInAnotherCluster;
  const participantIsAlone = callStore.participantList.length === 1;

  useEffect(() => {
    let idleTimer;
    if (participantIsAlone) {
      idleTimer = setTimeout(function () {
        let isActionTaken = false;
        if (!callObjectStore.isMicMuted) {
          callObjectStore.setMicrophone(false);
          isActionTaken = true;
        }
        if (!callObjectStore.isCameraMuted) {
          callObjectStore.setCamera(false);
          isActionTaken = true;
        }
        isActionTaken && callObjectStore.setIdleTriggered(true);
      }, 300000);
    } else {
      clearTimeout(idleTimer);
    }
    return () => {
      clearTimeout(idleTimer);
      callObjectStore.setIdleTriggered(false);
    };
  }, [participantIsAlone, callObjectStore]);

  useEffect(() => {
    return () => {
      tileSettingsStore.resetAllSettings();
    };
  }, [tileSettingsStore]);

  return (
    <div
      className={classnames(
        "call-room",
        showClusterList && "with-cluster-list"
      )}
    >
      <div className="call-room__stage">
        <ReactionProjector />
        <CallMessage />
        {callStore.currentRoom &&
          !callStore.currentRoom?.isAuditorium &&
          callStore.microphoneListEnabled && <MicrophoneList />}
        <CallTiles />
      </div>

      {showClusterList && <ClusterList />}
    </div>
  );
}

export default observer(CallRoom);
