import { observer } from "mobx-react-lite";
import ParticipantCaret from "../ParticipantCaret/ParticipantCaret";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-away.css";
import "tippy.js/themes/translucent.css";

function ClusterOverflow({ participants }) {
  const overflowCount = participants.length - 3;
  if (overflowCount <= 0) return null;
  const overflowParticipants = participants.slice(3);

  return (
    <Tippy
      content={
        <div>
          <ul>
            {overflowParticipants.map((participant) => {
              return (
                <ParticipantCaret participant={participant} positionTippyTop>
                  {(toggleOpen, isOpen) => {
                    return (
                      <li
                        className="cluster-preview__overflow-name"
                        onClick={() => toggleOpen()}
                      >
                        {participant.name}
                      </li>
                    );
                  }}
                </ParticipantCaret>
              );
            })}
          </ul>
        </div>
      }
      trigger="mouseenter focus click"
      theme="translucent"
      interactive={true}
      arrow={false}
      animation="shift-away"
      className="cluster-overflow-tippy"
    >
      <div className="cluster-preview__overflow-count">
        +{overflowCount} more
      </div>
    </Tippy>
  );
}

export default observer(ClusterOverflow);
