import { action, computed, makeObservable, observable } from "mobx";
import * as Amplitude from "../utilities/amplitude";
import * as Sounds from "../utilities/sounds";
import api from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import TimeAgo from "../components/ShowNotificationTime/ShowNotificationTime";
import Honeybadger from "honeybadger-js";
import { ParticipantModelLite } from "./ParticipantModelLite";
import GroupModel from "./GroupModel";

export class OfficeStore {
  @observable spaceInRightPaneId = null;
  @observable currentSpaceId = null;
  @observable onSessionTab = false;
  @observable currentCall = null;
  @observable playingRingAudio = false;
  @observable showCallNotification = false;
  @observable showPingNotification = false;
  @observable showRejectNotification = false;
  @observable incomingCallRequest = null;
  @observable incomingPing = null;
  @observable incomingReject = null;
  @observable showCreateSpace = false;
  @observable creatingSpace = false;
  @observable spacesData = [];
  @observable peopleData = [];
  @observable includedData = [];
  @observable notify = null;
  @observable dismiss = null;
  @observable navigate = null;
  @observable showingChangePhoto = false;
  @observable ringingUserId = null;
  @observable showingJoinSpaces = false;
  @observable expandedSpaceId = null;
  @observable onlyAllowedCallRequestId = null;
  @observable incomingCallNotificationId = null;
  @observable creatingRoomOnFoyer = false;
  @observable creatingAuditoriumOnFoyer = false;
  @observable showingSpaceChangePhoto = false;
  @observable showingInstallUnsupportedModal = false;
  @observable tabId = undefined;
  @observable inOfficeSessionId = undefined;
  @observable knockingUserId = undefined;
  @observable joiningOfficeUserId = undefined;
  @observable showingInviteTeammates = undefined;
  @observable joiningMeUserIds = new Map();
  @observable sessionType = undefined;
  @observable editingMeetingId = undefined;
  @observable topUserMeetings = [];
  @observable bottomUserMeetings = [];
  @observable showMoreMeetings = false;
  @observable spaceInTippyPopup = undefined;
  @observable docId = undefined;

  constructor(rootStores) {
    this.rootStores = rootStores;
    this.callStore = rootStores.callStore;
    this.api = rootStores.api;
    makeObservable(this);
  }

  hangUp = () => {
    this.setCurrentCall(null);
    this.setCurrentSpaceId(null);
  };

  @action
  setCurrentSpaceId(spaceId) {
    this.currentSpaceId = spaceId;
  }

  @action
  async setSpaceInRightPaneId(spaceId, sessionType, sessionSlug) {
    await this.rootStores.cableSocketStore.unsubscribeFromFoyerSessionSubscription();
    this.spaceInRightPaneId = spaceId;
    if (sessionType === "meeting") {
      window.history.pushState(null, "", "/mu/" + sessionSlug);
    } else if (sessionType === "space") {
      window.history.pushState(null, "", "/office");
    }
    if (spaceId) {
      await this.rootStores.cableSocketStore.subscribeToFoyerSessionChannel(
        spaceId
      );
    }
  }

  @action
  setCurrentCall(call) {
    this.currentCall = call;
  }

  @action
  playRing() {
    this.playingRingAudio = true;
  }

  @action
  stopRing() {
    this.playingRingAudio = false;
  }

  @action
  showIncomingCall() {
    this.showCallNotification = true;
  }

  @action
  showIncomingPing() {
    this.showPingNotification = true;
  }

  @action
  showIncomingReject() {
    this.showRejectNotification = true;
  }

  @action
  hideIncomingCall() {
    this.showCallNotification = false;
  }

  @action
  setIncomingCallRequest(callRequest) {
    this.incomingCallRequest = callRequest;
  }

  @computed
  get myAvailabilityStatus() {
    return this.rootStores.userStore.userInfo.availability_status;
  }

  @computed
  get isDoNotDisturb() {
    return this.myAvailabilityStatus === "Do not disturb";
  }

  triggerIncomingCall(incomingCallRequest) {
    this.setIncomingCallRequest(incomingCallRequest);
    const callRequestId = incomingCallRequest.call_request_id;
    const notification = this.uniquelyNotify(
      "requesting_call",
      incomingCallRequest.call_request_id,
      {
        title: "Call",
        message: (
          <div className="is-flex">
            <div className={classnames("mr-2  animate-ring-svg")}>
              <FontAwesomeIcon icon={faPhoneAlt} />
            </div>{" "}
            Incoming call from {incomingCallRequest.caller_name}
          </div>
        ),
        position: "top-center",
        dismissible: false,
        dismissAfter: 0,
        buttons: [
          {
            name: "Maybe later",
            onClick: () => this.ignoreIncomingCall(callRequestId),
          },
          {
            name: "Answer",
            onClick: () => this.acceptCall(),
            primary: true,
          },
        ],
      }
    );
    this.setIncomingCallNotificationId(notification.id);

    if (!this.isDoNotDisturb) {
      this.playRing();
      if (!document.hasFocus()) {
        const notification = new Notification("Sidebar", {
          body: `Call from ${incomingCallRequest.caller_name}...`,
        });
        notification.onclick = function () {
          window.focus();
        };
      }
    }
  }

  ignoreIncomingCall(callRequestId) {
    this.stopRing();
    this.setIncomingCallRequest(null);
    this.hideIncomingCall();
    this.api.rejectCall(callRequestId);
  }

  @action
  triggerIncomingPing(incomingPing) {
    // record notification so we can dismiss it if the same person requests again
    this.incomingPing = incomingPing;

    const userIdToReject = incomingPing.from_user_id;
    this.uniquelyNotify("userland_ping", incomingPing.from_user_id, {
      title: "Ping",
      message: (
        <>
          {incomingPing.from_name} wants to talk!{" "}
          <div className="animate-ring is-inline-block">👋</div>
        </>
      ),
      position: "top-center",
      dismissible: false,
      dismissAfter: 0,
      showDismissButton: true,
      buttons: [
        {
          name: "Maybe later",
          onClick: () => this.rejectPing(userIdToReject),
        },
        {
          name: `Call ${incomingPing.from_name}`,
          onClick: () => this.requestCall(incomingPing.from_user_id),
          primary: true,
        },
      ],
    });

    if (!this.isDoNotDisturb) {
      Sounds.playPing();
      if (!document.hasFocus()) {
        const notification = new Notification("Sidebar", {
          body: `${incomingPing.from_name} waved!`,
        });
        notification.onclick = function () {
          window.focus();
        };
      }
    }
  }

  @action
  triggerIncomingKnock(incomingKnock) {
    const userIdToReject = incomingKnock.from_user_id;
    this.uniquelyNotify("knock", incomingKnock.from_user_id, {
      title: "Knock!",
      message: (
        <>
          {incomingKnock.from_name} knocked on your office door!{" "}
          <div className="animate-ring is-inline-block">👋</div>
        </>
      ),
      position: "top-center",
      dismissible: false,
      dismissAfter: 0,
      showDismissButton: true,
      buttons: [
        {
          name: "Maybe later",
          onClick: () => this.rejectKnock(userIdToReject),
        },
        {
          name: `Invite in`,
          onClick: () => this.acceptKnock(incomingKnock),
          primary: true,
        },
      ],
    });

    if (!this.isDoNotDisturb) {
      Sounds.playPing();
      if (!document.hasFocus()) {
        const notification = new Notification("Sidebar", {
          body: `${incomingKnock.from_name} knocked on your office door!`,
        });
        notification.onclick = function () {
          window.focus();
        };
      }
    }
  }

  acceptKnock(incomingKnock) {
    try {
      this.api.acceptKnock(incomingKnock.knock_id);
    } catch (e) {
      Honeybadger.notify(e);
    }
    if (
      this.rootStores.callStore.currentRoomId !== incomingKnock.office_group_id
    ) {
      this.joinGroupFromFoyer(
        incomingKnock.office_group_id,
        incomingKnock.office_session_id,
        incomingKnock.office_daily_url,
        "user-office"
      );
    }
  }

  @action
  triggerIncomingPingReject(incomingReject) {
    this.incomingReject = incomingReject;

    this.notify({
      title: "Busy",
      message: `${incomingReject.from_name} said "Maybe Later"`,
      position: "top-center",
      dismissible: true,
      dismissAfter: 10000,
    });
  }

  @action
  rejectPing(userIdToReject) {
    this.playingPing = false;
    this.showPingNotification = false;
    this.incomingPing = null;
    this.api.rejectUserlandPing(userIdToReject);
  }

  @action
  rejectKnock(userIdToReject) {
    this.api.rejectKnock(userIdToReject);
  }

  @action
  resetStart() {
    this.currentSpaceId = null;
  }

  @action
  setShowCreateSpace(value) {
    this.showCreateSpace = value;
  }

  @action
  setSpacesData(data) {
    this.spacesData = data;
  }

  @action
  setPeopleData(data) {
    this.peopleData = data;
  }

  @action
  setIncludedData(data) {
    this.includedData = data;
  }

  @action
  setOnSessionTab(value) {
    this.onSessionTab = value;
  }

  @action
  setNotify(notifyFn) {
    this.notify = notifyFn;
  }

  @action
  setDismiss(dismissFn) {
    this.dismiss = dismissFn;
  }

  @action
  setNavigate(navigateFn) {
    this.navigate = navigateFn;
  }

  @action
  setRingingUserId(userId) {
    this.ringingUserId = userId;
  }

  @action
  hideCreateSpace() {
    this.showCreateSpace = false;
  }

  joinRoomFromFoyer(room, sessionId, sessionType) {
    return this.joinGroupFromFoyer(
      room.id,
      sessionId,
      room.attributes["daily-url"],
      sessionType
    );
  }

  updateSessionLogo = () => {};

  clearSpecialCallStates() {
    this.setInOfficeSessionId(undefined);
  }

  async joinGroupFromFoyer(groupId, sessionId, dailyUrl, sessionType) {
    let officeSessionId;
    if (sessionType === "user-office") {
      officeSessionId = sessionId;
    }
    if (sessionType === "meeting") {
      this.api.saveMeeting(sessionId);
    } else {
      window.history.pushState(null, "", "/office");
    }
    this.setSessionType(sessionType);
    this.setInOfficeSessionId(officeSessionId);
    this.setCurrentCall(null);
    await this.changeSessionIfNeeded(sessionId);

    await this.rootStores.callStore.refreshData(sessionId);
    this.callStore.setSessionId(sessionId);
    const sideMenu = document.getElementsByClassName(
      "office-side-menu-content"
    )[0];
    if (sideMenu) {
      sideMenu.scrollTo(0, 0);
    }

    await this.callStore.switchRoom(
      groupId,
      dailyUrl,
      this.rootStores.userStore.reliableDisplayName
    );

    if (sessionType === "user-office") {
      this.rootStores.queryClient.refetchQueries("office/allUsers");
    }
    this.setSpaceInRightPaneId(null);
    return this.setCurrentSpaceId(sessionId);
  }

  async joinDirectCall(groupId, sessionId, dailyUrl) {
    await this.changeSessionIfNeeded(sessionId);
    this.callStore.setSessionId(sessionId);
    this.setSessionType("direct-call");
    this.setCurrentCall(true);
    this.resetStart();
    this.setSpaceInRightPaneId(null);
    return await this.callStore.switchRoom(
      groupId,
      dailyUrl,
      this.rootStores.userStore.reliableDisplayName
    );
  }

  @action
  setJoiningOfficeUserId(value) {
    this.joiningOfficeUserId = value;
  }

  async joinOffice(userId) {
    this.setJoiningOfficeUserId(userId);
    const officeSession = await this.api.getOfficeSession(userId);
    const group = officeSession.data.included.filter(
      (x) => x.type === "groups"
    )[0];
    const groupId = group.id;
    const sessionId = officeSession.data.data.id;
    const dailyUrl = group.attributes["daily-url"];
    await this.joinGroupFromFoyer(groupId, sessionId, dailyUrl, "user-office");
    this.setJoiningOfficeUserId(undefined);
  }

  async changeSessionIfNeeded(sessionId) {
    const lastSessionId = localStorage.getItem("lastJoinedSessionId");
    this.callStore.setSessionId(sessionId);
    if (sessionId !== lastSessionId) {
      await this.rootStores.cableSocketStore.teardownSessionSubscriptions();
      await this.rootStores.callStore.deleteParticipant();
      await this.rootStores.callStore.clearState();
      await this.rootStores.callObjectStore.leaveAndUnsubscribe();
    }

    this.callStore.setSessionId(sessionId);
    localStorage.setItem("lastJoinedSessionId", sessionId);
  }

  async switchRooms(room, sessionId) {
    await this.changeSessionIfNeeded(sessionId);
    await room.handleClickRoom({
      newParticipantName: this.rootStores.userStore.reliableDisplayName,
      joinButtonsDisabled: false,
      sessionId: sessionId,
    });
  }

  @action
  setCreatingRoomOnFoyer(value) {
    this.creatingRoomOnFoyer = value;
  }

  createRoomFromFoyer = (sessionId) => {
    let newRoomName = "New Room";
    this.setCreatingRoomOnFoyer(true);
    return api
      .createRoom({
        sessionId: sessionId,
        roomName: newRoomName,
      })
      .then((newRoom) => {
        return this.joinGroupFromFoyer(
          newRoom.newRoomId,
          sessionId,
          newRoom.newRoomDailyUrl
        ).then(() => this.setCreatingRoomOnFoyer(false));
      })
      .catch((e) => {
        this.setCreatingRoomOnFoyer(false);
        this.rootStores.errorStore.handleApiError(e, null, false);
      });
  };

  @action
  setCreatingAuditoriumOnFoyer(value) {
    this.creatingAuditoriumOnFoyer = value;
  }

  createAuditoriumFromFoyer = (sessionId) => {
    if (!this.rootStores.userStore.isAdmin) {
      return;
    }
    this.setCreatingAuditoriumOnFoyer(true);
    let newRoomName = "New Auditorium";
    return api
      .createRoom({
        sessionId: sessionId,
        roomName: newRoomName,
        broadcastRoom: true,
        authToken: this.rootStores.userStore.token,
      })
      .then((newRoom) => {
        return this.joinGroupFromFoyer(
          newRoom.newRoomId,
          sessionId,
          newRoom.newRoomDailyUrl
        ).then(() => this.setCreatingAuditoriumOnFoyer(false));
      })
      .catch((e) => {
        this.setCreatingAuditoriumOnFoyer(false);
        this.rootStores.errorStore.handleApiError(e, null, false);
      });
  };

  async acceptCall() {
    this.stopRing();
    this.hideIncomingCall();
    const callRequestId = this.incomingCallRequest.call_request_id;
    this.setIncomingCallRequest(null);
    this.doAcceptCall(callRequestId);
  }

  async doAcceptCall(callRequestId, requestType) {
    let groupData;
    try {
      groupData = await this.api.answerCall(callRequestId);
    } catch (e) {
      if (e.response.status === 410) {
        this.notify({
          title: "Call Ended",
          message: `That call has already ended.`,
          position: "top-center",
          dismissible: true,
          dismissAfter: 10000,
        });
        return;
      }
    }
    console.log("GD: ", groupData);
    const groupId = groupData.data.data.id;
    const dailyUrl = groupData.data.data.attributes["daily-url"];
    const sessionId = groupData.data.included.filter(
      (x) => x.type === "sessions"
    )[0].id;
    if (requestType === "knock") {
      this.joinGroupFromFoyer(groupId, sessionId, dailyUrl, "user-office");
    } else {
      this.joinDirectCall(groupId, sessionId, dailyUrl);
    }
  }

  @action
  async createSpace(data) {
    this.creatingSpace = true;
    try {
      await this.rootStores.sessionsStore.createSession(data);
      Amplitude.track("Created space on Office", {
        new_call_name: data.call_name,
      });
      await this.rootStores.queryClient.invalidateQueries(
        "office/officeSummary"
      );
      const data1 = this.rootStores.queryClient.getQueryData(
        "office/officeSummary"
      );
      this.rootStores.queryClient.setQueryData(
        "joinSpaces/officeSummary",
        data1
      );
    } catch (err) {
      console.log(err);
    } finally {
      this.creatingSpace = false;
      this.setShowCreateSpace(false);
    }
  }

  @action
  async updateSpace(data) {
    try {
      await this.rootStores.sessionsStore.updateSession(data);
      this.rootStores.queryClient.invalidateQueries("office/officeSummary");
      this.rootStores.queryClient.invalidateQueries(["sessions", data.id]);
    } catch (err) {
      console.log(err);
    }
  }

  @action
  hideChangePhoto() {
    this.showingChangePhoto = false;
  }

  @action
  hideSpaceChangePhoto() {
    this.showingSpaceChangePhoto = false;
  }

  @action
  showChangePhoto() {
    this.showingChangePhoto = true;
  }

  @action
  showSpaceChangePhoto() {
    this.showingSpaceChangePhoto = true;
  }

  @action
  hideJoinSpaces() {
    this.showingJoinSpaces = false;
  }

  @action
  showJoinSpaces() {
    this.showingJoinSpaces = true;
  }

  @computed
  get inACall() {
    return !!this.currentSpaceId || !!this.currentCall;
  }

  @action
  setExpandedSpaceId(sessionId) {
    this.expandedSpaceId = sessionId;
  }

  @action
  toggleSetExpandedSpaceId(sessionId) {
    if (this.expandedSpaceId === sessionId) {
      this.expandedSpaceId = null;
    } else {
      this.expandedSpaceId = sessionId;
    }
  }

  @action
  setOnlyAllowedCallRequestId(callRequestId) {
    this.onlyAllowedCallRequestId = callRequestId;
  }

  async requestCall(userIdToCall) {
    const response = await this.api.requestCallUser(userIdToCall);
    if (response.data.id) {
      this.setOnlyAllowedCallRequestId(response.data.id);
      this.setRingingUserId(userIdToCall);
      this.playRing();
      setTimeout(() => {
        this.cancelCall(response.data.id);
      }, 20000);
    } else {
      console.log("Problem ringing.");
    }
  }

  async knock(userId) {
    if (this.knockingUserId === userId) return;
    Sounds.playKnock();
    this.setKnockingUserId(userId);
    let response;
    try {
      response = await this.api.knock(userId);
    } catch (e) {
      this.setKnockingUserId(undefined);
    }
    if (response.data.id) {
      setTimeout(() => {
        this.cancelKnock(response.data.id);
      }, 20000);
      return response.data.id;
    }
  }

  @action
  triggerCallWasAnswered(groupId, sessionid, dailyUrl, callRequestId) {
    this.stopRing();
    this.setRingingUserId(null);
    if (this.onlyAllowedCallRequestId === callRequestId) {
      this.joinDirectCall(groupId, sessionid, dailyUrl);
      this.setOnlyAllowedCallRequestId(null);
    } else {
      console.log(
        `An old or bogus call request was answered. Ignoring. Call request: ${callRequestId}`
      );
    }
  }

  @action
  triggerKnockAccepted(groupId, sessionid, dailyUrl, callRequestId) {
    this.setKnockingUserId(undefined);
    this.joinGroupFromFoyer(groupId, sessionid, dailyUrl, "user-office");
  }

  @action
  triggerCallWasRejected(data) {
    this.stopRing();
    this.setRingingUserId(null);
    this.notify({
      title: "Busy",
      message: `${data.rejected_by_name} said "Maybe Later"`,
      position: "top-center",
      dismissible: true,
      dismissAfter: 10000,
    });
    this.setOnlyAllowedCallRequestId(null);
  }

  @action
  triggerKnockWasRejected(data) {
    if (this.knockingUserId === data.from_user_id) {
      this.setKnockingUserId(undefined);
    }
    this.triggerIncomingPingReject(data);
  }

  @action
  async cancelCall(callRequestId) {
    const currentCallRequest = this.onlyAllowedCallRequestId;
    if (callRequestId && callRequestId !== currentCallRequest) return;
    this.setOnlyAllowedCallRequestId(null);
    this.stopRing();
    this.setRingingUserId(null);
    await this.api.cancelCallRequest(currentCallRequest);
  }

  @action
  async cancelKnock(callRequestId) {
    this.setKnockingUserId(undefined);
    await this.api.cancelCallRequest(callRequestId);
  }

  @action
  reallyHideIncomingCall() {
    this.dismiss(this.incomingCallNotificationId);
  }

  triggerCallWasCancelled(data) {
    if (data.invitation_type === "knock") {
      this.closeConflictingNotifications({
        type: "knock",
        fromId: data.cancelled_by_id,
      });
    } else {
      this.setIncomingCallRequest(null);
      this.hideIncomingCall();
      this.reallyHideIncomingCall();
      this.stopRing();
    }
  }

  @action
  setIncomingCallNotificationId(id) {
    this.incomingCallNotificationId = id;
  }

  async fetchGroupAndJoin(groupId) {
    const group = await this.api.getGroup(groupId);
    const dailyUrl = group.data.data.attributes["daily-url"];
    const session = group.data.included.filter((x) => x.type === "sessions")[0];
    this.acceptJoinMe(
      groupId,
      session.id,
      dailyUrl,
      session.attributes["session-type"]
    );
  }

  acceptJoinMe(groupId, sessionId, dailyUrl, sessionType) {
    const isDirectCall = sessionType === "direct-call";

    if (isDirectCall) {
      this.joinDirectCall(groupId, sessionId, dailyUrl);
    } else {
      this.joinGroupFromFoyer(groupId, sessionId, dailyUrl, sessionType);
    }
  }

  triggerJoinMe(data) {
    let notificationText = "";
    let notificationTitle = "Join me!";
    let room_descriptor;
    const isDirectCall = data?.session_type === "direct-call";
    const isUserOffice = data?.session_type === "user-office";
    if (isUserOffice) {
      if (data.office_owner_id === this.rootStores.userStore.userId) {
        notificationText = `${data.from_name} is in your office!`;
        notificationTitle = "In your office";
      } else {
        if (data.office_owner_id === data.from_user_id) {
          notificationText = `${data.from_name} invited you to their office!`;
        } else {
          notificationText = `${data.from_name} invited you to ${data.office_owner_name}'s office!`;
        }
      }
    } else {
      if (isDirectCall) {
        room_descriptor = "a private call";
      } else {
        room_descriptor = `"${data.room_name}" in #${data.space_name}`;
      }
      notificationText = `${data.from_name} invited you to join ${room_descriptor}.`;
    }

    this.uniquelyNotify("join_me", data.from_user_id, {
      title: (
        <>
          <div key="1">{notificationTitle}</div>
          <TimeAgo key="2" />
        </>
      ),
      message: notificationText,
      position: "top-center",
      dismissible: false,
      buttons: [
        {
          name: "Maybe later",
          onClick: () => this.rejectPing(data.from_user_id),
        },
        {
          name: "Join",
          onClick: () =>
            this.acceptJoinMe(
              data.group_id,
              data.session_id,
              data.daily_url,
              data.session_type
            ),
          primary: true,
        },
      ],
    });

    if (!this.isDoNotDisturb) {
      Sounds.playPing();
      if (Notification.permission === "granted") {
        const notification = new Notification("Sidebar", {
          body: notificationText,
        });
        notification.onclick = function () {
          window.focus();
        };
      }
    }
  }

  triggerSpaceNotification(data) {
    if (data.participant_id === this.rootStores.callStore.participantId) {
      return;
    }
    const text = `${data.participant_name} just joined "${data.room_name}" in  #${data.space_name}`;
    if (Notification.permission === "granted") {
      Sounds.playHangingOut();
      const notification = new Notification("Sidebar", { body: text });
      notification.onclick = function () {
        window.focus();
        this.close();
      };
    }
  }

  triggerCrowdNotification(data) {
    let names = data.participant_names;
    let names_string = "";
    if (names.length === 2) {
      names_string = data.participant_names.join(" and ");
    } else {
      const final_name = names.pop();
      names_string = data.participant_names.join(", ") + `, and ${final_name}`;
    }

    const text = `${names_string} are talking in "${data.room_name}" in  #${data.space_name}`;
    if (Notification.permission === "granted") {
      Sounds.playHangingOut();
      const notification = new Notification("Sidebar", { body: text });
      notification.onclick = function () {
        window.focus();
        this.close();
      };
    }
  }

  @action
  setShowingInstallUnsupportedModal(value) {
    this.showingInstallUnsupportedModal = value;
  }

  @action
  setTabId(tabId) {
    this.tabId = tabId;
  }

  triggerCloseOtherTabs(data) {
    window.location.href = "/old-tab";
  }

  closeConflictingNotifications(config) {
    const { type, fromId } = config;
    const notificationId = this.rootStores.appStore.notificationRegistry
      .get(fromId)
      ?.get(type);
    this.dismiss(notificationId);
  }

  uniquelyNotify(notificationType, fromId, reapopConfig) {
    this.closeConflictingNotifications({
      type: notificationType,
      fromId: fromId,
    });
    const notification = this.notify(reapopConfig);

    this.rootStores.appStore.registerNotification({
      notificationId: notification.id,
      type: notificationType,
      fromId: fromId,
    });
    return notification;
  }

  @action
  setInOfficeSessionId(sessionId) {
    this.inOfficeSessionId = sessionId;
  }

  @action
  setKnockingUserId(userId) {
    this.knockingUserId = userId;
  }

  triggerRoomKnockAccepted(data) {
    const groupId = data.group_id;
    const sessionId = data.session_id;
    const dailyUrl = data.daily_url;
    const sessionType = data.session_type;
    this.rootStores.callStore.removeKnockingGroupId(groupId);
    this.acceptJoinMe(groupId, sessionId, dailyUrl, sessionType);
  }

  findPerson(userId) {
    return this.peopleData.find((user) => user.id === userId);
  }

  slackIfOffline(userId, message) {
    const receiver = this.findPerson(userId);
    if (receiver && receiver.attributes["online-status"] === "offline") {
      try {
        this.api.slackIfOffline(userId, message);
      } catch (e) {
        Honeybadger.notify(e);
      }
    }
  }

  @action
  setShowingInviteTeammates(value) {
    this.showingInviteTeammates = value;
  }

  @action
  setJoiningMeUserId(userId) {
    this.joiningMeUserIds.set(userId, true);
  }

  @action
  removeJoiningMeUserId(userId) {
    this.joiningMeUserIds.delete(userId);
  }

  @action
  setSessionType(value) {
    this.sessionType = value;
  }

  @action
  setEditingMeetingId(value) {
    this.editingMeetingId = value;
  }

  buildSpacesList(spacesData, includedData) {
    if (!spacesData || !includedData) return;
    const groupMap = new Map();

    const participants = includedData
      .filter((x) => x.type === "participants")
      .map((p) => {
        return new ParticipantModelLite({
          data: p,
          rootStores: this.rootStores,
        });
      });

    includedData
      .filter((x) => x.type === "groups")
      .forEach((g) => {
        const groupParticipants = participants.filter((x) => {
          return x.groupId === g.id;
        });
        const model = new GroupModel({
          data: g,
          participants: groupParticipants,
          rootStores: this.rootStores,
        });
        groupMap.set(g.id, model);
      });

    const spacesList = spacesData.map((s) => {
      const sessionGroups = new Set();
      for (let g of s.relationships.groups.data) {
        sessionGroups.add(groupMap.get(g.id));
      }
      return {
        session: s,
        groups: Array.from(sessionGroups),
      };
    });
    return spacesList;
  }

  @action
  setTopUserMeetings(value) {
    this.topUserMeetings = value;
  }

  @action
  setBottomUserMeetings(value) {
    this.bottomUserMeetings = value;
  }

  @action
  setShowMoreMeetings(value) {
    this.showMoreMeetings = value;
  }

  @action
  toggleShowMoreMeetings() {
    this.showMoreMeetings = !this.showMoreMeetings;
  }

  scrollToNewMeetingPopup(newId, scrollToMoreHeader) {
    this.setEditingMeetingId(newId);

    const newMeetingInBottomList = this.bottomUserMeetings.find(
      (userMeeting) => userMeeting.meeting.id === newId
    );

    if (newMeetingInBottomList) {
      this.setShowMoreMeetings(true);
      setTimeout(() => {
        scrollToMoreHeader();
      }, 100);
    }
  }

  @action
  setSpaceInTippyPopup(spaceId) {
    this.spaceInTippyPopup = spaceId;
  }

  @action
  setDocId(docId) {
    this.docId = docId;
  }
}
