import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import { useStores } from "../../stores/utils";
import GroupModel from "../../stores/GroupModel";
import RoomsList from "./RoomsList";
import api from "../../api";

function RoomsListExpanded({ space }) {
  const rootStores = useStores();
  const sessionId = space.session.id;
  const sessionType = space.session?.attributes?.["session-type"];
  const query = useQuery(
    ["expandedSession", sessionId],
    (params) => {
      return api.getSession(params.queryKey[1], true);
    },
    {
      refetchInterval: 5000,
    }
  );

  let list = [];
  console.log("QD: ", query.data);
  if (query.data && query.data.data.data.type === "sessions") {
    let participants = query.data.data.included.filter(
      (x) => x.type === "participants"
    );

    list = GroupModel.listFromGroupsAndParticipants(
      query.data.groups,
      participants,
      rootStores
    );
  }

  return (
    list &&
    list.length > 0 && (
      <RoomsList
        groups={list}
        sessionId={sessionId}
        sessionType={sessionType}
      />
    )
  );
}

export default observer(RoomsListExpanded);
