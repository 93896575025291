import Bowser from "bowser";
import "./BrowserUnsupported.scss";
import { ReactComponent as SidebarLogo } from "../../images/SVG/sidebar-logo.svg";

export default function BrowserUnsupported() {
  const {
    parsedResult: { platform, browser },
  } = Bowser.getParser(window.navigator.userAgent);
  let isIe = false;
  const patterns = ["MSIE", "Trident/"];
  for (let pattern of patterns) {
    isIe = window.navigator.userAgent.includes(pattern);
  }

  let errorMessage;

  if (platform.model === "iPad") {
    let browsermessage = "This browser";
    if (["Chrome", "Firefox"].indexOf(browser.name) >= 0) {
      browsermessage = browser.name;
    }
    errorMessage = (
      <>
        <h4>{browsermessage} not supported on iPad</h4>
        <div className="browser-warning">
          {browsermessage} does not support video calls on iPad.{" "}
          <b>Use Safari instead</b>. Or use these other browser versions (or
          later) on another device:
        </div>
      </>
    );
  } else if (platform.type === "mobile" && platform.model !== "iPad") {
    errorMessage = (
      <>
        <h4>Mobile devices not supported</h4>
        <div className="browser-warning">
          Sidebar does not work on mobile devices. Try it on a computer or
          tablet. Use these browser versions (or later):
        </div>
      </>
    );
  } else if (platform.type !== "mobile") {
    errorMessage = (
      <>
        <h4>Outdated browser</h4>
        <div className="browser-warning">
          {isIe
            ? "Sidebar does not work on Internet Explorer. Please use these other browser versions (or later):"
            : "Sidebar does not work on this browser because it is out of date. Please update your browser. Or, use these other browser versions (or later):"}
        </div>
      </>
    );
  }

  return (
    <div className="browser-unsupported-container">
      <div className="browser-unsupported black-bg">
        <div className="iphone-notification notification is-warning content">
          <SidebarLogo />
          <br />

          {errorMessage}

          <h4>Ipad</h4>
          <ul>
            <li>Safari 12.0.1</li>
          </ul>
          <h4>Windows, MacOS</h4>
          <ul>
            <li>Chrome 75</li>
            <li>Firefox 67</li>
            <li>Opera 61</li>
            <li>Safari 13.0.1</li>
            <li>Edge 79</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
