import { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/utils";
import "../CallManagement/CallManagement.scss";
import SpaceRow from "../SpaceRow/SpaceRow";
import CreateSpaceModal from "../CreateSpaceModal/CreateSpaceModal";
import * as Amplitude from "../../../utilities/amplitude";
import Loader from "../../Loader";
import ReactModal from "react-modal";
import { useQueryClient, QueryClientProvider } from "react-query";

const SpacesAdmin = () => {
  const { viewStore, sessionsStore, userStore, permissionsStore } = useStores();
  const [showCreateCall, setShowCreateCall] = useState(false);
  const [creatingCall, setCreatingCall] = useState(false);
  const [membersModal, setMembersModal] = useState();
  const queryClient = useQueryClient();

  const closeMembersModal = useCallback(() => {
    setMembersModal(null);
  }, [setMembersModal]);

  const toggleShowCreateCall = useCallback(() => {
    if (!userStore.isVerified) {
      return viewStore.showNotVerifiedModal();
    }

    Amplitude.track("Clicked create call in host dashboard");
    setShowCreateCall((x) => !x);
  }, [userStore.isVerified, viewStore]);

  const deleteCall = useCallback(
    async (sidebarSessionId) => {
      Amplitude.track("Clicked delete call in host dashboard", {
        call_id: sidebarSessionId,
      });
      const sure = window.confirm(
        "Are you sure you want to DELETE this call and all its rooms? This cannot be undone!"
      );
      if (sure) {
        await sessionsStore.deleteSession(sidebarSessionId);
      }
    },
    [sessionsStore]
  );

  const createCall = useCallback(
    async (data) => {
      setCreatingCall(true);
      try {
        await sessionsStore.createSession(data);
        Amplitude.track("Created call in host dashboard", {
          new_call_name: data.call_name,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setCreatingCall(false);
        setShowCreateCall(false);
      }
    },
    [setShowCreateCall, sessionsStore]
  );

  return (
    <QueryClientProvider client={queryClient}>
      <div className="call-management">
        <h1 className="title is-1">Spaces</h1>

        {permissionsStore.canCreateEvent && (
          <div
            className="button create-call-button is-sblue"
            onClick={() => {
              toggleShowCreateCall();
            }}
          >
            Create Space
          </div>
        )}

        {creatingCall && <div className="create-call-loading"></div>}
        <div className="box">
          <table className="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>Name</th>
                <th>Created by</th>
                <th>...</th>
              </tr>
            </thead>

            <tbody>
              {sessionsStore.spaces.length > 0 ? (
                sessionsStore.spaces.map((session) => (
                  <SpaceRow
                    session={session}
                    key={session.id}
                    deleteCall={deleteCall}
                    setMembersModal={setMembersModal}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan="5">
                    <div className="no-sessions">
                      {sessionsStore.isFetching ? (
                        <Loader />
                      ) : (
                        <>
                          No event links yet. {/* eslint-disable-next-line */}
                          <a onClick={() => toggleShowCreateCall()}>
                            Create event link
                          </a>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <CreateSpaceModal
          showCreateCall={showCreateCall}
          setShowCreateCall={setShowCreateCall}
          toggleShowCreateCall={toggleShowCreateCall}
          refetchSessions={() => null}
          createCall={createCall}
          creatingCall={creatingCall}
        />
        <ReactModal
          isOpen={membersModal}
          onRequestClose={() => {
            closeMembersModal();
          }}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          className="custom-modal"
          overlayClassName="modal-background"
          closeTimeoutMS={300}
        >
          <div className="modal-content content">
            <div className="box is-info">{membersModal}</div>
          </div>
        </ReactModal>
      </div>
    </QueryClientProvider>
  );
};

export default observer(SpacesAdmin);
