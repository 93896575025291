import { observer } from "mobx-react-lite";
import Tippy from "@tippyjs/react";
import { useStores } from "../../../stores/utils";
import StatusDropdown from "../../StatusDropdown";
import { getStatusLabel } from "../../../stores/TeamMemberModel";

const StatusCell = ({ teamMember }) => {
  const { permissionsStore, sessionsStore } = useStores();

  if (!permissionsStore.canEditUser || teamMember.status === "superuser") {
    return (
      <div className="team-status--invited">
        {getStatusLabel(teamMember.status)}
      </div>
    );
  }

  const isTippyEnabled =
    permissionsStore.canEditUser &&
    teamMember.status === "host" &&
    teamMember.permittedEvents.length > 0;

  return (
    <div className="status-cell">
      <Tippy
        disabled={!isTippyEnabled}
        content={
          <ul>
            {teamMember.permittedEvents.map((x) => (
              <li key={x.id}>{x.attributes.name}</li>
            ))}
          </ul>
        }
      >
        <div>
          <StatusDropdown
            onRoleChange={teamMember.updateStatus}
            role={teamMember.status}
            sessionsStore={sessionsStore}
            permittedEvents={teamMember.permittedEventsIds}
            togglePermittedEvent={teamMember.togglePermittedEvent}
          />
        </div>
      </Tippy>
      {teamMember.invitation && (
        <div className="team-status--invited">Invited </div>
      )}
    </div>
  );
};

export default observer(StatusCell);
