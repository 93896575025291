import { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import ControlsOverlay from "./ControlsOverlay";
import { useEventListener } from "hooks/useEventListener";
import { useStores } from "../../stores/utils";

const TileVideo = ({
  videoTrack,
  volume,
  largeTileNotScreen,
  dimensions,
  myVideoEl,
  isBlocked,
  blockedReasons,
  tile,
  positionTippyTop,
  twoTiles,
}) => {
  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);
  const videoEl = useRef(null);

  const { callStore, tilesStore } = useStores();
  const participantCount = callStore.participantCount;
  const tileCount = tilesStore.liveTileCount;

  const updateVideoSize = useCallback(() => {
    if (!videoEl.current) return;
    setVideoWidth(videoEl.current.offsetWidth);
    setVideoHeight(videoEl.current.offsetHeight);
  }, [videoEl, setVideoWidth, setVideoHeight]);

  /**
   * When video track changes, update video srcObject
   */
  useEffect(() => {
    videoEl.current &&
      videoTrack &&
      (videoEl.current.srcObject = new MediaStream([videoTrack]));
    if (volume != null) {
      videoEl.current.volume = volume;
    }
  }, [videoTrack, volume]);

  useEffect(() => {
    setTimeout(() => {
      updateVideoSize();
    }, 500);
  }, [updateVideoSize, participantCount, tileCount]);

  useEffect(() => {
    if (!videoEl.current) return;
    const videoResizeSensor = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        updateVideoSize();
      });
    });
    videoResizeSensor.observe(videoEl.current);
    return () => videoResizeSensor.disconnect();
  }, [updateVideoSize, videoEl]);

  useEventListener("resize", updateVideoSize);

  if (!videoTrack) return null;

  const controlsOverlay = twoTiles && tile && (
    <ControlsOverlay
      tile={tile}
      positionTippyTop={positionTippyTop}
      isBlocked={isBlocked}
      blockedReasons={blockedReasons}
      size={{ width: videoWidth, height: videoHeight }}
    />
  );

  return (
    <>
      {largeTileNotScreen ? (
        <div
          className="video-item"
          id={myVideoEl ? "local-video" : undefined}
          style={dimensions}
          ref={myVideoEl || undefined}
        >
          <video autoPlay muted playsInline ref={videoEl}></video>
          {controlsOverlay}
        </div>
      ) : (
        <>
          <video autoPlay muted playsInline ref={videoEl}></video>
          {controlsOverlay}
        </>
      )}
    </>
  );
};

export default observer(TileVideo);
