import ReactSelect from "react-select";

export const Select = ({ options, field, form, ...rest }) => {
  const getOptionValue = (value) => {
    return options.find((option) => option.value === value);
  };

  return (
    <ReactSelect
      styles={{
        container: (base) => ({
          ...base,
          width: "100%",
          marginTop: "10px",
        }),
      }}
      label="Single select"
      options={options}
      defaultValue={getOptionValue(field.value)}
      value={getOptionValue(field.value)}
      onChange={(option) => form.setFieldValue(field.name, option.value)}
      {...rest}
    />
  );
};
