import { useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import CallRoom from "../CallRoom";
import DocPane from "../DocPane/DocPane";

import Tray from "../Tray/Tray";
import "./CallContent.scss";

import ChatMessages from "../ChatMessages/ChatMessages";
import { useIntercom } from "react-use-intercom";

function CallContent({ disabled, fullscreenRef }) {
  const { boot } = useIntercom();
  const { appStore, callStateStore, userStore, viewStore } = useStores();

  useEffect(() => {
    boot({
      name: userStore.firstishName,
      email: userStore.userInfo?.email,
      hideDefaultLauncher: true,
      customAttributes: { vertical_padding: 65 },
    });
  }, [boot, userStore.userInfo?.email, userStore.firstishName]);

  /**
   * Start a timer to show the "click allow" message, when the component mounts.
   */

  useEffect(() => {
    callStateStore.t = setTimeout(() => {
      callStateStore.allowTimeout();
    }, 3500);

    return function cleanup() {
      clearTimeout(callStateStore.t);
    };
  }, [callStateStore]);

  const resizeAndAssign = useCallback(() => {
    if (!fullscreenRef.current) return;
    viewStore.setCallContentSize({
      width: fullscreenRef.current.offsetWidth,
      height: fullscreenRef.current.offsetHeight,
    });
  }, [fullscreenRef, viewStore]);

  useEffect(() => {
    if (!fullscreenRef.current) return;
    const resizeSensor = new ResizeObserver(resizeAndAssign);
    resizeSensor.observe(fullscreenRef.current);
    return () => resizeSensor.disconnect();
  }, [fullscreenRef, resizeAndAssign]);

  return (
    <>
      <div className="call">
        <ChatMessages />
        <CallRoom />
        {appStore.officeMode && <DocPane />}
      </div>
      <Tray fullscreenRef={fullscreenRef} />
    </>
  );
}

CallContent.whyDidYouRender = false;
export default observer(CallContent);
