import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import "./StatusDropdownItem.scss";

const StatusDropdownItem = ({ label, description, onClick, isSelected }) => {
  return (
    <div className="StatusDropdownItem" onClick={onClick}>
      <div className="StatusDropdownItem__content">
        <div className="StatusDropdownItem__label">{label}</div>
        <div className="StatusDropdownItem__description">{description}</div>
      </div>

      {isSelected && (
        <div className="StatusDropdownItem__icon-wrap">
          <div className="StatusDropdownItem__icon">
            <FontAwesomeIcon icon={faCheck} />
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(StatusDropdownItem);
