import PropTypes from "prop-types";
import { CardElement } from "@stripe/react-stripe-js";
import { Field } from "formik";
import { Input } from "components/Form/Input";
import { Select } from "components/Form/Select";
import "./index.scss";
import "./Cards.scss";
import "./CreditCardForm.scss";

const CARD_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#8898AA",
      color: "#8898AA",
      lineHeight: "36px",
      fontWeight: 300,
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSize: "19px",
      border: "1px solid red",

      "::placeholder": {
        color: "#8898AA",
      },
    },
    invalid: {
      iconColor: "#e85746",
      color: "#e85746",
    },
  },
  classes: {
    focus: "is-focused",
    empty: "is-empty",
  },
};

const COUNTRY_OPTIONS = [
  {
    value: "US",
    label: "United States",
  },
  {
    value: "CA",
    label: "Canada",
  },
];

export const CreditCardForm = () => {
  return (
    <form className="subscription-card">
      <h1 className="title">Payment details</h1>
      <div className="subscription-row">
        <div className="subscription-column-title">
          If you decide a paid plan isn't for you, canceling is easy.
        </div>
      </div>
      <div className="stripe-container">
        <CardElement options={CARD_OPTIONS} />
      </div>
      <div className="stripe-billing-info">
        <Field name="line1" component={Input} placeholder="Address line 1" />
        <Field name="line2" component={Input} placeholder="Address line 2" />
        <Field name="city" component={Input} placeholder="City" />
        <div className="stripe-billing-info__two-cols">
          <Field name="state" component={Input} placeholder="State" />
          <Field
            name="postal_code"
            component={Input}
            placeholder="Postal Code"
          />
        </div>
        <Field
          name="country"
          component={Select}
          placeholder="Country"
          options={COUNTRY_OPTIONS}
        />
      </div>
    </form>
  );
};

CreditCardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
