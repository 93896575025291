import { HiArrowNarrowRight } from "react-icons/hi";
import enableCamMacSystem from "images/help/enable-cam-mac-system.png";

export const getMacNoCamera = (browserName) => (
  <>
    <p>
      {browserName} might not have access to your camera. To fix this, open{" "}
      <a href="x-apple.systempreferences:com.apple.preference">
        System Preferences
      </a>{" "}
      —{">"} Security {"&"} Privacy and follow these instructions:
    </p>
    <br />
    <img src={enableCamMacSystem} alt="ff1" />
  </>
);
export const NO_CAMERA =
  "Check your system and browser settings to make sure that a camera is available. If not, turn it on. You might have to restart your browser after.";
export const NO_MICRO =
  "Check your system and browser settings to make sure that a microphone is available. If not, turn it on. You might have to restart your browser after.";
export const NO_OUTPUTS =
  "Check your system and browser settings to make sure that a camera and a microphone are available. If not, turn them on. You might have to restart your browser after.";
export const NO_OUTPUTS_CHROME =
  "Click the icon on the left side of your address bar and make sure Chrome has allowed camera and microphone access for Sidebar. You will then be prompted to refresh. If that doesn’t work, make sure your camera and microphone are connected and refresh the page.";
export const NO_OUTPUTS_SAFARI = (
  <>
    Try refreshing Sidebar. If prompted, allow Sidebar to use your camera and
    microphone. If there’s no prompt, you’ll need to click <i>Safari</i>{" "}
    <HiArrowNarrowRight style={{ verticalAlign: "middle" }} />{" "}
    <i>Settings for This Website</i> and choose to 'allow' camera and
    microphone. Then refresh the page. Click ‘Learn More’ for additional help.
  </>
);
export const NO_OUTPUTS_FIREFOX =
  "Try refreshing Firefox. When prompted, allow Sidebar to use your camera and microphone. Also, make sure your camera and microphone are connected. Click ‘Learn More’ for additional help.";
export const NO_OUTPUTS_FIREFOX_MAC_SYSTEM = (
  <>
    Also, make sure your camera and microphone are connected and that Firefox
    has access to your camera in{" "}
    <a href="x-apple.systempreferences:com.apple.preference">
      System Preferences
    </a>
    . Click "Learn More" for additional help.
  </>
);
export const NO_OUTPUTS_FIREFOX_MAC = (
  <>
    Try refreshing Firefox. When prompted, allow Sidebar to use your camera and
    microphone. {NO_OUTPUTS_FIREFOX_MAC_SYSTEM}
  </>
);
export const NO_OUTPUTS_EDGE =
  "Click the icon on the left side of your address bar and make sure Edge has allowed camera and microphone access for Sidebar. You will then be prompted to refresh. If that doesn’t work, make sure your camera and microphone are connected and refresh the page.";
export const ALLOW_CAMERA =
  "Sidebar needs to access your camera and microphone so other participants can see and hear you.";
export const ALLOW_CAMERA_FIREFOX =
  "Sidebar needs to access your camera and microphone so other participants can see and hear you. Check the box to remember this decision so Firefox won't need permission every time you switch rooms.";
export const PERMISSION_DENIED_BY_BROWSER = "permission denied";
export const PERMISSION_DENIED_BY_SYSTEM = "permission denied by system";
export const CAMERA_REJECTED = "could not start video source";

export const INVITE_CLOSE_TIMEOUT = 90000;
