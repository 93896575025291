import classnames from "classnames";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";

import "./Circle.scss";

import Mute from "./Mute";
import Name from "./Name";
import { useEffect } from "react";

function Circle({ circle, forwardedRef, positionTippyTop }) {
  const { callStore, roomReactionStore } = useStores();
  const isActivePerson = callStore.activeSpeakerId === circle.callItem.dailyId;
  const myCircle = circle.callItem.isLocal;
  const userId = circle.callItem.dailyId;

  useEffect(() => {
    // reset room reactions on mount
    myCircle
      ? roomReactionStore.resetReactions()
      : roomReactionStore.resetReactions(userId);
  }, [myCircle, roomReactionStore, userId]);

  return (
    <div
      className={classnames("circle", {
        "active-speaker": isActivePerson,
      })}
      ref={forwardedRef}
    >
      <div className="circle-item">
        <Mute tile={circle} isCircle />
        <Name
          tile={circle}
          callItem={circle.callItem}
          isCircle
          positionTippyTop={positionTippyTop}
        />
      </div>
      {myCircle ? (
        <div className="room-reactions-me">
          {roomReactionStore.myReaction || ""}
        </div>
      ) : (
        <div className="room-reactions-others">
          {roomReactionStore.otherUserReactions[userId] || ""}
        </div>
      )}
    </div>
  );
}

export default observer(Circle);
