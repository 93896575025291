import { observer } from "mobx-react-lite";
import {
  faMicrophoneSlash,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { buildInitials } from "../../utilities/text";
import classnames from "classnames";

function Mute({ tile, isCircle }) {
  if (tile.isLoading || tile.isScreen) {
    return null;
  }

  const avatar = tile.callItem?.sidebarParticipant?.avatar;
  const initials = buildInitials(tile.callItem?.sidebarParticipant?.name);
  const isMicMuted = tile.callItem.audioTrack === undefined;

  if (tile.callItem.videoTrack === undefined) {
    return (
      <div className={classnames("cam-muted")}>
        {!!avatar ? (
          <img
            className={classnames("cam-muted__avatar", {
              "mic-muted": isMicMuted,
            })}
            src={avatar}
            alt="user-avatar"
          />
        ) : (
          <div
            className={classnames("cam-muted__initials", {
              "mic-muted": isMicMuted,
            })}
            style={{
              backgroundColor: `#${tile.callItem?.sidebarParticipant?.color}`,
            }}
          >
            {initials?.toUpperCase?.()}
          </div>
        )}
        <span className="video-mute-indicator">
          {!isCircle && (
            <FontAwesomeIcon icon={faVideoSlash} className="video-mute-icon" />
          )}
          {tile.callItem.audioTrack === undefined && (
            <span className="mic-mute-indicator">
              <FontAwesomeIcon icon={faMicrophoneSlash} />
            </span>
          )}
        </span>
      </div>
    );
  } else {
    return (
      tile.callItem.audioTrack === undefined && (
        <span className="mic-mute-indicator">
          <FontAwesomeIcon icon={faMicrophoneSlash} />
        </span>
      )
    );
  }
}

export default observer(Mute);
