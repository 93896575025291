import { observable, action, makeObservable } from "mobx";

export class AblyStore {
  @observable.ref organizationChannel = null;
  @observable.ref userChannel = null;

  constructor(rootStores) {
    this.rootStores = rootStores;
    this.callStore = rootStores.callStore;
    this.officeStore = rootStores.officeStore;
    this.userStore = rootStores.userStore;
    makeObservable(this);
  }

  @action
  subscribeToOrganizationAblyChannel() {
    if (this.organizationChannel) {
      console.log("Already subscribed to Ably organization channel");
      return;
    }
    const organizationId = this.rootStores.userStore.organizationId;
    const ably = this.rootStores.ably;
    const channel = ably.channels.get(`office_summary/${organizationId}`);
    this.organizationChannel = channel;

    const queryClient = this.rootStores.queryClient;
    channel.subscribe(function (message) {
      console.log("Ably RECEIVED: ", message);
      if (message.name === "sync") {
        queryClient.setQueryData("office/officeSummary", {
          data: message.data,
        });
      } else if (message.name === "users_sync") {
        queryClient.setQueryData("office/allUsers", { data: message.data });
      } else {
        console.error(
          "Unrecognized ably message (organization channel): ",
          message
        );
      }
    });
  }

  @action
  subscribeToUserAblyChannel() {
    if (this.userChannel) {
      console.log("Already subscribed to Ably user channel");
      return;
    }

    const userId = this.userStore.userInfo.id;
    const ably = this.rootStores.ably;
    const channel = ably.channels.get(`user/${userId}`);
    this.userChannel = channel;

    channel.subscribe(async (message) => {
      console.log("RECEIVED USER MSG: ", message);
      if (message.name === "userland_ping") {
        this.officeStore.triggerIncomingPing({
          from_name: message.data.from_name,
          from_user_id: message.data.from_user_id,
        });
      } else if (message.name === "knock") {
        this.officeStore.triggerIncomingKnock(message.data);
      } else if (message.name === "userland_ping_reject") {
        this.officeStore.triggerIncomingPingReject({
          from_name: message.data.from_name,
          from_user_id: message.data.from_user_id,
        });
      } else if (message.name === "requesting_call") {
        this.officeStore.triggerIncomingCall({
          call_request_id: message.data.call_request_id,
          caller_name: message.data.from_name,
        });
      } else if (message.name === "enter_call") {
        this.officeStore.triggerCallWasAnswered(
          message.data.group_id,
          message.data.session_id,
          message.data.daily_url,
          message.data.call_request_id
        );
      } else if (message.name === "enter_office") {
        this.officeStore.triggerKnockAccepted(
          message.data.group_id,
          message.data.session_id,
          message.data.daily_url,
          message.data.call_request_id
        );
      } else if (message.name === "call_rejected") {
        this.officeStore.triggerCallWasRejected(message.data);
      } else if (message.name === "call_cancelled") {
        this.officeStore.triggerCallWasCancelled(message.data);
      } else if (message.name === "join_me") {
        this.officeStore.triggerJoinMe(message.data);
      } else if (message.name === "user_preferences") {
        this.userStore.updatePreferences(message);
      } else if (message.name === "space_notification") {
        this.officeStore.triggerSpaceNotification(message.data);
      } else if (message.name === "crowd_notification") {
        this.officeStore.triggerCrowdNotification(message.data);
      } else if (message.name === "close_other_tabs") {
        this.officeStore.triggerCloseOtherTabs(message.data);
      } else if (message.name === "knock_rejected") {
        this.officeStore.triggerKnockWasRejected(message.data);
      } else if (message.name === "user_update") {
        this.userStore.updateUser(message.data);
      } else if (message.name === "room_knock_accepted") {
        this.officeStore.triggerRoomKnockAccepted(message.data);
      } else if (message.name === "private_summary_sync") {
        const queryClient = this.rootStores.queryClient;
        queryClient.setQueryData("office/privateSummary", {
          data: message.data,
        });
      }
    });
  }
}
