import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import FoyerCard from "../Office/Foyer/FoyerCard";
import classnames from "classnames";
import JoinButton from "../JoinButton/JoinButton";
import { useStores } from "../../stores/utils";

const SingleFoyerCard = ({ onClickRoom, onlyRoom }) => {
  const { appStore, callStore } = useStores();
  const join = useCallback(
    (e) => {
      return onClickRoom(
        e,
        onlyRoom.id,
        onlyRoom.attributes["daily-url"],
        appStore.appState
      );
    },
    [appStore, onClickRoom, onlyRoom]
  );

  const isCurrentRoom =
    callStore.currentRoomId && callStore.currentRoomId === onlyRoom.id;

  return (
    <>
      <div className="single-join-container">
        <JoinButton
          join={join}
          joining={callStore.joiningRoomId === onlyRoom.id}
          disabled={false}
          isFull={onlyRoom.isFull}
          isCurrentRoom={isCurrentRoom}
          outlined={false}
          small={false}
        />
      </div>
      <div
        className={classnames("box", "office-foyer-box", {
          "room-locked": onlyRoom.attributes["locked"],
        })}
        onClick={join}
      >
        <FoyerCard
          key={onlyRoom.id}
          room={onlyRoom}
          joinButtonsDisabled={false}
          noActions
          isFoyer
          onClickRoom={join}
          showRoomName={false}
        />
      </div>
    </>
  );
};

export default observer(SingleFoyerCard);
