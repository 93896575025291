import { observer } from "mobx-react-lite";
import ChatButton from "../ChatButton/ChatButton";
import ReactionsButton from "../ReactionsButton/ReactionsButton";
import DocumentsButton from "./DocumentsButton";
import { useStores } from "stores/utils";

function LeftTrayActions({ isAuditorium, theme, disabled }) {
  const { viewStore } = useStores();

  return (
    <>
      <ChatButton />
      {!isAuditorium && <ReactionsButton theme={theme} />}
      <DocumentsButton
        disabled={disabled}
        toggleDocumentsList={viewStore.toggleDocumentsList}
        hideDocumentsList={viewStore.hideDocumentsList}
        showingDocuments={viewStore.showingDocumentsList}
      />
    </>
  );
}

export default observer(LeftTrayActions);
