import { observable, action, makeObservable, computed } from "mobx";

export class SharedCursorsStore {
  @observable myCurrentDrawing = [];
  @observable myCanvasSize = new Map();
  @observable.ref contextRef = undefined;
  @observable.ref canvasContainer = undefined;
  @observable newIncomingDrawings = [];
  @observable aspectRatio = undefined;
  @observable displayedNamesIds = [];
  @observable localPen = {};

  constructor(rootStores) {
    this.rootStores = rootStores;
    this.api = rootStores.api;
    makeObservable(this);
  }

  @action
  setCanvasSize(width, height) {
    this.myCanvasSize.set("width", width);
    this.myCanvasSize.set("height", height);
  }

  @action
  setRef(ref, destination) {
    if (destination === "canvasContainer") {
      this.canvasContainer = ref;
    } else if (destination === "context") {
      this.contextRef = ref;
    }
  }

  @action
  collectCoordinates(coordinates) {
    this.myCurrentDrawing.push(coordinates);
  }

  @action
  clearCoordinates() {
    this.myCurrentDrawing.clear();
  }

  @computed
  get drawing() {
    // convert and return JS array
    return this.myCurrentDrawing.slice().map((XYpoint) => XYpoint.slice());
  }

  @action
  computeCoordinates(coordinates, screenSize) {
    const widthDiff = screenSize.width / this.myCanvasSize.get("width");
    const heightDiff = screenSize.height / this.myCanvasSize.get("height");
    return coordinates.map((point) => [
      point[0] / widthDiff,
      point[1] / heightDiff,
    ]);
  }

  @action
  receiveUserDrawing(data) {
    console.log("Shared cursors - drawing received");
    if (!this.contextRef) return;
    this.addIncomingDrawings("drawing");
    const { coordinates, userName, color, screenSize, participantId } = data;
    const newCoordinates = this.computeCoordinates(coordinates, screenSize);

    let x1 = newCoordinates[0][0];
    let y1 = newCoordinates[0][1];
    // display name on first drawing
    if (!this.isNameDisplayed(participantId)) {
      this.displayName(color, x1, y1, participantId, userName);
    }
    // loop over coordinates and draw
    for (let i = 1; i < newCoordinates.length; i++) {
      const x2 = newCoordinates[i][0];
      const y2 = newCoordinates[i][1];
      this.drawLine(color, 4, x1, y1, x2, y2);
      x1 = x2;
      y1 = y2;
    }
  }

  @action
  clearCanvas() {
    if (!this.contextRef || !this.myCanvasSize) return;
    const width = this.myCanvasSize.get("width");
    const height = this.myCanvasSize.get("height");
    this.contextRef.clearRect(0, 0, width, height);
    this.clearDisplayedNamesIds();
  }

  @action
  addIncomingDrawings(newDrawing) {
    this.newIncomingDrawings.push(newDrawing);
    setTimeout(() => this.removeIncomingDrawings(), 5000);
  }

  @action
  removeIncomingDrawings() {
    this.newIncomingDrawings.pop();
  }

  @computed
  get noNewDrawings() {
    return this.newIncomingDrawings && this.newIncomingDrawings.length === 0;
  }

  @action
  receiveAspectRatio(data) {
    console.log("Shared cursors - aspectRatio received");
    const { ar } = data;
    if (this.aspectRatio !== ar) {
      this.aspectRatio = ar;
    }
  }

  @action
  calculateCanvasSize() {
    if (!this.aspectRatio || !this.canvasContainer) return;
    const ccWidth = this.canvasContainer.offsetWidth;
    const ccHeight = this.canvasContainer.offsetHeight;

    const isVideoFullWidth = ccWidth / ccHeight <= this.aspectRatio;

    const width = isVideoFullWidth ? ccWidth : ccHeight * this.aspectRatio;
    const height = isVideoFullWidth ? ccWidth / this.aspectRatio : ccHeight;

    this.setCanvasSize(width, height);
    return { width, height };
  }

  @action
  addToDisplayedNamesIds(id) {
    this.displayedNamesIds.push(id);
  }

  @action
  clearDisplayedNamesIds() {
    this.displayedNamesIds.clear();
  }

  @action
  isNameDisplayed(id) {
    return this.displayedNamesIds.find((item) => item === id);
  }

  @action
  drawLine(color, thickness, x1, y1, x2, y2) {
    if (!this.contextRef) return;
    const ctx = this.contextRef;
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;

    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
  }

  @action
  displayName(color, x, y, participantId, userName) {
    this.addToDisplayedNamesIds(participantId);
    this.contextRef.font = "20px Marker Felt";
    this.contextRef.fillStyle = color;
    this.contextRef.fillText(userName, x + 15, y + 20);
  }
}
