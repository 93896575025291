import { observer } from "mobx-react-lite";

function OrganizationLogo({ imageSrc, editable }) {
  return (
    <>
      {imageSrc && (
        <img src={imageSrc} className="organization-logo" alt="Logo" />
      )}
    </>
  );
}

export default observer(OrganizationLogo);
