/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { CHAT_STATE_LOADED } from "../../stores/ChatStore";
import { USER_CHAT_STATE_LOADED } from "../../stores/UserChatStore";
import { CometChatMessageListScreen } from "../CometChat";
import ChatBoundary from "../../error-boundaries/ChatBoundary";
import { useStores } from "../../stores/utils";
import "./ChatWidget.scss";
import { useMountedRef } from "hooks/useMountedRef";

const ChatWidget = ({
  isOpen,
  index = 0,
  type,
  item,
  person,
  closeUserChat,
}) => {
  const rootStores = useStores();
  const {
    appStore,
    chatStore,
    callStore,
    officeStore,
    userChatStore,
  } = rootStores;
  const [tab, updateTab] = useState(chatStore.activeTab);
  const mounted = useMountedRef();

  const closeChat = useCallback(() => {
    chatStore.closeChat(item.key);
    chatStore.setActiveTab(tab);
  }, [chatStore, item.key, tab]);

  let participantOnline = callStore.sessionParticipantList.find(
    (participant) => participant.id === item.key
  );
  if (!participantOnline) {
    participantOnline = callStore.sessionParticipantList.find(
      (participant) => participant.userId === item.key
    );
  }

  // needs Testing!
  useEffect(() => {
    if (type === "user" && !participantOnline) {
      closeChat();
    }
  }, [type, participantOnline, closeChat]);

  let config = {};
  let tabs = [];
  let updateTabAction;
  let everyoneNewMessages = 0;
  let roomNewMessages = 0;

  let everyoneLabel = "Everyone";
  let groupLabel = "My room";
  if (appStore.officeMode) {
    if (officeStore.currentCall) {
      everyoneLabel = "";
    } else {
      everyoneLabel = "#";
    }
    everyoneLabel += `${callStore?.sessionAttributes?.name}`;
    groupLabel = callStore.currentRoom?.attributes?.name;
  }

  if (type === "group") {
    const currentRoom = callStore.currentRoom;
    const currentRoomChat =
      currentRoom && currentRoom.attributes["comet-chat-guid"];
    const cometChatGuid =
      tab === "room" ? currentRoomChat : callStore.sessionCometChatGuid;

    everyoneNewMessages = chatStore.everyoneNewMessages;

    roomNewMessages = chatStore.newMessages.filter(
      (item) => item.conversationId === `group_${currentRoomChat}`
    ).length;

    tabs = [
      {
        key: "room",
        label: groupLabel,
        newMessages: roomNewMessages,
      },
      {
        key: "everyone",
        label: everyoneLabel,
        newMessages: everyoneNewMessages,
      },
    ];

    updateTabAction = (newTab) => {
      chatStore.setCurrentOpenedGroupChat(
        newTab === "room"
          ? `group_${currentRoomChat}`
          : `group_${callStore.sessionCometChatGuid}`
      );
      chatStore.setNewMessages(
        newTab === "room"
          ? chatStore.newMessages.filter(
              (item) => item.conversationId !== `group_${currentRoomChat}`
            )
          : chatStore.newMessages.filter(
              (item) =>
                item.conversationId !==
                `group_${callStore.sessionCometChatGuid}`
            )
      );
      updateTab(newTab);
    };

    config = {
      guid: cometChatGuid,
      name: "Test",
    };
  } else if (person) {
    config = {
      uid: item.key,
      name: person.attributes["name"] || "",
    };
  } else {
    config = {
      uid: item.key,
      name: participantOnline?.name || "",
    };
  }

  // useEffect(() => {
  //   if (type === "group") {
  //     updateTabAction(
  //       roomNewMessages && !everyoneNewMessages ? "everyone" : "room"
  //     );
  //   }
  // }, [callStore.currentRoomId, everyoneNewMessages, roomNewMessages, type]);
  //

  return (
    <div
      className={classNames("dm-chat-wrapper", {
        "show-portal": isOpen,
        "hide-portal": !isOpen,
        "user-chat": person,
      })}
      style={{ left: index * 310 + 8 }}
    >
      {person
        ? userChatStore.chatState === USER_CHAT_STATE_LOADED && (
            <ChatBoundary>
              <CometChatMessageListScreen
                item={config}
                type={type}
                actionGenerated={() => false}
                closeChat={closeUserChat}
                isOpen={isOpen}
                person={person}
                mounted={mounted}
                rootStores={rootStores}
              />
            </ChatBoundary>
          )
        : chatStore.chatState === CHAT_STATE_LOADED && (
            <ChatBoundary>
              <CometChatMessageListScreen
                item={config}
                type={type}
                actionGenerated={() => false}
                closeChat={closeChat}
                isOpen={isOpen}
                tab={tab}
                tabs={tabs}
                updateTab={updateTabAction}
                mounted={mounted}
                rootStores={rootStores}
              />
            </ChatBoundary>
          )}
    </div>
  );
};

export default observer(ChatWidget);
