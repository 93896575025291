import { observer } from "mobx-react-lite";
import "./CallTiles.scss";
import { useStores } from "../../stores/utils";
import AudioTags from "./AudioTags";
import SmallTilesList from "./SmallTilesList";
import TileLayout from "../TileLayout/TileLayout";
import CircleContainer from "./CircleContainer";
import { useState, useEffect, useRef } from "react";
import { isOffscreen } from "../../utilities/dom";
function CallTiles() {
  const { appStore, callStore, tilesStore } = useStores();
  const { largeTiles, smallTiles, circledTiles } = tilesStore;
  const [sortedCircles, setSortedCircles] = useState([]);

  const withSmallTiles = smallTiles.length > 0 && !tilesStore.hideTiles;
  const withLargeTiles = largeTiles.length > 0;
  const withCircledTiles =
    circledTiles.length > 0 && !tilesStore.isAnyoneScreenSharing;

  const activeSpeakerId = callStore.activeSpeakerId;
  const lastCircleRef = useRef(null);

  useEffect(() => {
    if (activeSpeakerId) {
      tilesStore.recordActiveSpeaker(activeSpeakerId);
    }
  }, [activeSpeakerId, tilesStore]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      let isAnyOffscreen = false;
      entries.forEach((entry) => {
        if (isOffscreen(entry.target)) {
          isAnyOffscreen = true;
        }
      });

      if (isAnyOffscreen) {
        const newCircleOrder = circledTiles.slice().sort((a, b) => {
          let aTime = tilesStore.activeSpeakerTimes.get(a.callItem.dailyId);
          let bTime = tilesStore.activeSpeakerTimes.get(b.callItem.dailyId);
          if (!aTime) return 1;
          if (!bTime) return -1;
          return aTime > bTime ? -1 : 1;
        });
        setSortedCircles(newCircleOrder);
      } else {
        setSortedCircles(circledTiles);
      }
    });

    if (lastCircleRef.current) {
      observer.observe(lastCircleRef.current);
    } else {
      setSortedCircles(circledTiles);
    }

    return () => {
      observer.disconnect();
    };
  }, [activeSpeakerId, circledTiles, tilesStore.activeSpeakerTimes]);

  return (
    <div className="call-tiles">
      {appStore.appState === "STATE_JOINING" && (
        <div className="call-loading">
          Connecting... <div className="call-loader-spinner"></div>
        </div>
      )}
      {withLargeTiles && (
        <TileLayout
          message={callStore.message}
          tiles={largeTiles}
          withSmallTiles={withSmallTiles}
          withCircledTiles={withCircledTiles}
        />
      )}
      {withSmallTiles && <SmallTilesList smallTiles={smallTiles} />}
      {withCircledTiles && (
        <CircleContainer
          circles={sortedCircles}
          withLargeTiles={withLargeTiles}
          forwardedRef={lastCircleRef}
        />
      )}
      <AudioTags />
    </div>
  );
}

CallTiles.whyDidYouRender = false;
export default observer(CallTiles);
