import { observer } from "mobx-react-lite";
import { navigate } from "@reach/router";
import SetNewPassword from "../../components/Forgot/SetNewPassword";
import ForgotPassword from "../../components/Forgot/ForgotPassword";
import HostLoginForm from "../../components/HostLoginModal/HostLoginForm";
import PlainGreyLayout from "../../components/Layouts/PlainGreyLayout";
import "./StyleGuide.scss";

const Forms = (props) => {
  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <PlainGreyLayout>
      <div className=" content-column narrow">
        <div className="style-guide-example">
          <HostLoginForm
            navigate={navigate}
            goToForgot={() => navigate("forgot")}
          />
        </div>
        <div className="style-guide-example">
          <ForgotPassword navigate={navigate} goToLogin={goToLogin} />
        </div>
        <div className="style-guide-example">
          <SetNewPassword navigate={navigate} goToLogin={goToLogin} />
        </div>
      </div>
    </PlainGreyLayout>
  );
};

export default observer(Forms);
