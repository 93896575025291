// https://github.com/aaronshaf/react-toggle
import { PureComponent } from "react";
import classNames from "classnames";
import { pointerCoord } from "./util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";

import "./Toggle.scss";

export default class Toggle extends PureComponent {
  constructor(props) {
    super(props);

    this.previouslyChecked = !!(props.checked || props.defaultChecked);
    this.state = {
      checked: !!(props.checked || props.defaultChecked),
      hasFocus: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: !!this.props.checked });
    }
  }

  handleClick = (event) => {
    if (this.props.disabled) {
      return;
    }
    const checkbox = this.input;
    if (event.target !== checkbox && !this.moved) {
      this.previouslyChecked = checkbox.checked;
      event.preventDefault();
      checkbox.focus();
      checkbox.click();
      return;
    }

    const checked = this.props.hasOwnProperty("checked")
      ? this.props.checked
      : checkbox.checked;

    this.setState({ checked });
  };

  handleTouchStart = (event) => {
    if (this.props.disabled) {
      return;
    }
    this.startX = pointerCoord(event).x;
    this.activated = true;
  };

  handleTouchMove = (event) => {
    if (!this.activated) return;
    this.moved = true;

    if (this.startX) {
      let currentX = pointerCoord(event).x;
      if (this.state.checked && currentX + 15 < this.startX) {
        this.setState({ checked: false });
        this.startX = currentX;
        this.activated = true;
      } else if (currentX - 15 > this.startX) {
        this.setState({ checked: true });
        this.startX = currentX;
        this.activated = currentX < this.startX + 5;
      }
    }
  };

  handleTouchEnd = (event) => {
    if (!this.moved) return;
    const checkbox = this.input;
    event.preventDefault();

    if (this.startX) {
      let endX = pointerCoord(event).x;
      if (this.previouslyChecked === true && this.startX + 4 > endX) {
        if (this.previouslyChecked !== this.state.checked) {
          this.setState({ checked: false });
          this.previouslyChecked = this.state.checked;
          checkbox.click();
        }
      } else if (this.startX - 4 < endX) {
        if (this.previouslyChecked !== this.state.checked) {
          this.setState({ checked: true });
          this.previouslyChecked = this.state.checked;
          checkbox.click();
        }
      }

      this.activated = false;
      this.startX = null;
      this.moved = false;
    }
  };

  handleFocus = (event) => {
    const { onFocus } = this.props;

    if (onFocus) {
      onFocus(event);
    }

    this.setState({ hasFocus: true });
  };

  handleBlur = (event) => {
    const { onBlur } = this.props;

    if (onBlur) {
      onBlur(event);
    }

    this.setState({ hasFocus: false });
  };

  render() {
    const { className, ...inputProps } = this.props;

    return (
      <div
        className={classNames(
          "react-toggle",
          {
            "react-toggle--checked": this.state.checked,
            "react-toggle--focus": this.state.hasFocus,
            "react-toggle--disabled": this.props.disabled,
          },
          className
        )}
        onClick={this.handleClick}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
      >
        <div className="react-toggle-track">
          <div className="react-toggle-track-check" />
          <div className="react-toggle-track-x" />
        </div>
        <div className="react-toggle-thumb">
          <FontAwesomeIcon icon={this.state.checked ? faLock : faLockOpen} />
        </div>
        <input
          {...inputProps}
          ref={(ref) => {
            this.input = ref;
          }}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          className="react-toggle-screenreader-only"
          type="checkbox"
        />
      </div>
    );
  }
}
