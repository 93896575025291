import { observer } from "mobx-react-lite";
import "./ProgressLine.scss";

const ProgressLine = ({ value = 0.5, height = 40, isLoading = false }) => {
  return (
    <div className="progress-line" style={{ height }}>
      <span
        className="progress-line__inner"
        style={{ height: height - 2, width: `${value * 100}%` }}
      ></span>
      {isLoading && <span className="progress-line__status">loading...</span>}
    </div>
  );
};

export default observer(ProgressLine);
