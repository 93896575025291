import { observable, computed, makeObservable } from "mobx";

export default class ClusterModel {
  id;
  data = {};
  @observable.ref attributes = {};
  @observable.ref relationships = {};
  type;

  constructor({ data = {}, rootStores }) {
    this.rootStores = rootStores;
    this.callStore = this.rootStores.callStore;
    this.data = data;
    this.mergeData(data);
    makeObservable(this);
  }

  mergeData(data) {
    Object.keys(data).forEach((k) => (this[k] = data[k]));
  }

  @computed
  get population() {
    return this.relationships?.participants?.data?.length;
  }

  @computed
  get empty() {
    return !this.population;
  }

  participants = () => {
    const clusterParticipantIds = this.relationships?.participants?.data?.map(
      (p) => p.id
    );
    console.log("cluster part ids: ", clusterParticipantIds);
    console.log(
      "All parts in list: ",
      this.callStore.participantList.map((x) => x.id)
    );
    return this.callStore.participantList.filter((participant) => {
      return clusterParticipantIds.includes(participant.id);
    });
  };
}
