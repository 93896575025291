import { observer } from "mobx-react-lite";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

function InstallUnsupported({ modalWrapper = false }) {
  const [showingLearnMore, setShowingLearnMore] = useState(false);

  return (
    <div
      className={classnames("install-unsupported", "content", {
        "install-unsupported-modal": modalWrapper,
      })}
    >
      <h4>Your browser does not support installation.</h4>
      <p>
        Please visit{" "}
        <a href="/install" target="_blank" rel="noreferrer noopener">
          sidebar.vc/install
        </a>{" "}
        using Google Chrome or Microsoft Edge to install the Sidebar app.
      </p>
      {/* eslint-disable-next-line */}
      <a
        onClick={() => setShowingLearnMore((x) => !x)}
        className="learn-more-link"
      >
        <strong>
          Learn more{" "}
          {!showingLearnMore && (
            <FontAwesomeIcon icon={faCaretDown} size="sm" />
          )}
        </strong>
      </a>
      {showingLearnMore && (
        <p>
          Your browser only has partial support for{" "}
          <a
            href="https://en.wikipedia.org/wiki/Progressive_web_application"
            target="_blank"
            rel="noreferrer noopener"
          >
            Progressive Web Apps
          </a>
          , the technology that powers our app. If you’d like to launch Sidebar
          from your dock or start menu, you can use Google Chrome or Microsoft
          Edge to install the app.
        </p>
      )}
    </div>
  );
}

export default observer(InstallUnsupported);
