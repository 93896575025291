import { useState, useCallback, useEffect, useRef } from "react";
import StartButton from "../StartButton/StartButton";
import "./NameModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { isIOS } from "react-device-detect";

export default function NameModal(props) {
  const [tempName, setTempName] = useState("");
  const [nameError, setNameError] = useState(null);
  const nameField = useRef(null);
  const { joinAction } = props;

  const handleNameChange = useCallback(
    (event) => {
      setNameError(null);
      setTempName(event.target.value);

      if (event.target.value.length > 50) {
        setNameError("Name is limited to 50 characters!");
      }
    },
    [setTempName]
  );

  const clickJoin = useCallback(
    (e, participantName) => {
      e.preventDefault();
      if (participantName === null || participantName === "") {
        setNameError("Please, type your name!");
      } else if (!nameError) {
        return joinAction(participantName);
      }
    },
    [joinAction, nameError]
  );

  useEffect(() => {
    nameField.current.focus();
  }, []);

  let autoComplete = "cc-csc";
  if (isIOS) {
    autoComplete = "off";
  }

  return (
    <>
      <h2 className="title is-4 is-size-5-mobile ">
        Enter your name to head in
      </h2>

      <form
        onSubmit={(e) => {
          clickJoin(e, tempName);
        }}
      >
        <div className="field is-grouped name-fields">
          <p className="control is-expanded has-icons-right">
            <input
              className={"input " + (nameError && " is-danger")}
              autoComplete={autoComplete}
              name="nickname"
              type="text"
              id="name-input"
              value={tempName}
              onChange={handleNameChange}
              placeholder="Enter your name"
              ref={nameField}
            />
            {nameError && (
              <>
                <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </span>
                <span className="help is-danger">{nameError}</span>
              </>
            )}
          </p>

          <p className="control">
            <StartButton />
          </p>
        </div>
      </form>
    </>
  );
}
