import Tippy from "@tippyjs/react";
import { observer } from "mobx-react-lite";
import { FaVideo, FaVideoSlash } from "react-icons/fa";
import TrayButton from "../TrayButton/TrayButton";

const CameraButton = ({
  hideCanBroadcastTippy,
  showCanBroadcastTippy,
  hasBroadcastPermissions,
  stageHasSpace,
  isCameraMuted,
  allowedToEnableCam,
  toggleCamera,
  disabled,
  theme,
}) => {
  return (
    <Tippy
      content={
        <div
          className="broadcast-tippy"
          onClick={() => {
            hideCanBroadcastTippy();
          }}
        >
          The Host has invited you to speak. Click the icons below to turn on
          your webcam and/or microphone.
        </div>
      }
      theme="light"
      visible={!disabled && showCanBroadcastTippy}
      interactive={true}
    >
      <Tippy
        disabled={disabled || hasBroadcastPermissions}
        content="This room only allows designated speakers to broadcast."
        trigger="mouseenter focus click"
        theme="light"
      >
        <Tippy
          disabled={disabled || stageHasSpace}
          content="The stage is currently full until someone else turns off their camera."
          trigger="mouseenter focus click"
          theme="light"
        >
          <Tippy
            appendTo="parent"
            content={isCameraMuted ? "Start video" : "Stop video"}
            disabled={disabled || !allowedToEnableCam}
            theme={theme}
          >
            <div>
              <TrayButton
                disabled={disabled || !allowedToEnableCam}
                onClick={toggleCamera}
                extraClasses={
                  isCameraMuted ? "camera-toggle-red" : "camera-toggle-white"
                }
              >
                {isCameraMuted ? (
                  <FaVideoSlash size="26px" color="#fb5554" />
                ) : (
                  <FaVideo size="24px" color="#fff" />
                )}
              </TrayButton>
            </div>
          </Tippy>
        </Tippy>
      </Tippy>
    </Tippy>
  );
};

export default observer(CameraButton);
