import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import ParticipantCaret from "../ParticipantCaret/ParticipantCaret";
import TileVideo from "../Tile/TileVideo";
import { setVideoQuality } from "../../utilities/daily-hacks";

function ClusterThumbnail({ participant, volume, ghostMode, officeMode }) {
  const callItem = participant.callItem;

  useEffect(() => {
    setVideoQuality(callItem.dailyId, 0);
  }, [callItem]);

  const firstInitial = participant.name?.charAt(0)?.toLocaleUpperCase();
  const firstSeven = participant.name?.substring(0, 7);
  const showEllipsis = participant.name?.length > 7;
  return (
    <ParticipantCaret
      participant={participant}
      alreadyInRoom={false}
      officeMode={officeMode}
    >
      {(toggleOpen, isOpen) => {
        return (
          <div
            className="cluster-thumbnail"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleOpen();
            }}
          >
            {!ghostMode && (
              <>
                {callItem.videoTrack ? (
                  <TileVideo videoTrack={callItem.videoTrack} volume={0} />
                ) : (
                  <div className="cluster-thumbnail__camless-tile">
                    {firstInitial}
                  </div>
                )}

                <div className="cluster-thumbnail__name">
                  {firstSeven}
                  {showEllipsis && <>&hellip;</>}
                </div>
              </>
            )}
          </div>
        );
      }}
    </ParticipantCaret>
  );
}

export default observer(ClusterThumbnail);
