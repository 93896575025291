import { observer } from "mobx-react-lite";
import DailyIframe from "@daily-co/daily-js";
import MicButton from "./MicButton";
import SettingsButton from "./SettingsButton";
import MoreButton from "./MoreButton";
import ScreenShareButton from "./ScreenShareButton";
import CameraButton from "./CameraButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

function MiddleTrayActions({
  hideCanBroadcastTippy,
  showCanBroadcastTippy,
  hasBroadcastPermissions,
  stageHasSpace,
  isCameraMuted,
  allowedToEnableCam,
  toggleCamera,
  disabled,
  isMicMuted,
  toggleMic,
  callObjectStore,
  isSharingScreen,
  toggleSharingScreen,
  toggleSettings,
  leaveCall,
  theme,
}) {
  return (
    <>
      <CameraButton
        hideCanBroadcastTippy={hideCanBroadcastTippy}
        showCanBroadcastTippy={showCanBroadcastTippy}
        hasBroadcastPermissions={hasBroadcastPermissions}
        stageHasSpace={stageHasSpace}
        isCameraMuted={isCameraMuted}
        allowedToEnableCam={allowedToEnableCam}
        toggleCamera={toggleCamera}
        disabled={disabled}
        theme={theme}
      />
      <MicButton
        hasBroadcastPermissions={hasBroadcastPermissions}
        isMicMuted={isMicMuted}
        toggleMic={toggleMic}
        disabled={disabled}
        callObjectStore={callObjectStore}
        theme={theme}
      />
      {DailyIframe.supportedBrowser().supportsScreenShare && (
        <ScreenShareButton
          hasBroadcastPermissions={hasBroadcastPermissions}
          disabled={disabled}
          isSharingScreen={isSharingScreen}
          toggleSharingScreen={toggleSharingScreen}
          theme={theme}
        />
      )}
      <SettingsButton
        disabled={disabled}
        toggleSettings={toggleSettings}
        theme={theme}
      />
      <MoreButton disabled={disabled} theme={theme} />
      <div
        className={
          "custom-tray-item exit-button " + (disabled ? " disabled" : "")
        }
        onClick={leaveCall}
      >
        <FontAwesomeIcon
          icon={faPhone}
          style={{
            transform: "rotate(225deg)",
            marginTop: "3px",
          }}
        />
      </div>
    </>
  );
}

export default observer(MiddleTrayActions);
