import { observer } from "mobx-react-lite";
import EventLinkUsage from "./EventLinkUsage";

const EventsTime = ({ store }) => {
  return (
    <div className="info-block box info-block--full-width mb10">
      <div className="info-block__title">By event link</div>
      {store.sessions.map((s) => (
        <EventLinkUsage key={s.id} session={s} store={store} />
      ))}
    </div>
  );
};

export default observer(EventsTime);
