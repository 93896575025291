import { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import TileLayout from "../TileLayout/TileLayout";
import { TileModel } from "../../stores/TileModel";
import { useStores } from "../../stores/utils";
import "./TestTiles.scss";

export default observer(function TestTiles() {
  const rootStores = useStores();
  const [largeTiles, setTiles] = useState([]);
  const [stream, setStream] = useState(null);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(function (stream) {
        setStream(stream);
      });
  }, []);

  const addTile = useCallback(
    (quantity = 1, delay = 0) => {
      for (let i = 0; i < quantity; i++) {
        setTiles((t) => {
          const id = "tile-id-" + t.length + 1;

          const callItem = {
            videoTrack: stream.getVideoTracks()[0],
            audioTrack: null,
            isLocal: true,
            isLarge: true,
            isLoading: false,
            dailyId: null,
            listKey: id,
          };
          console.log("Key will be: ", id);

          const tileModel = new TileModel({
            rootStores: rootStores,
            callItem: callItem,
          });
          console.log("tile model to pass in: ", tileModel);
          console.log("Key is: ", tileModel.key);

          const newTiles = t.concat(tileModel);
          console.log("after pushing... ", newTiles);
          return newTiles;
        });
      }
    },
    [setTiles, stream, rootStores]
  );

  const removeTile = useCallback(() => {
    setTiles((t) => {
      t.pop();
      return t.slice();
    });
  }, [setTiles]);

  return (
    <>
      <div className="test-buttons">
        <button className="button" onClick={() => removeTile()}>
          -
        </button>
        <button className="button" onClick={() => addTile()}>
          +
        </button>
        <button className="button" onClick={() => addTile(4)}>
          + 4
        </button>
        <button className="button" onClick={() => addTile(8)}>
          + 8
        </button>
        <button className="button" onClick={() => addTile(8, 10000000)}>
          + 8s
        </button>
      </div>
      <TileLayout message={null} tiles={largeTiles} />
    </>
  );
});
