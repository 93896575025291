import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

function TopMembers({ data }) {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (data && data.data) {
      setMembers(data.data.included);
    }
  }, [data]);

  if (members.length === 0) {
    return (
      <div className="top-members">
        <FontAwesomeIcon icon={faUser} />
      </div>
    );
  }

  return (
    <div className="top-members">
      {members.map((member) => (
        <figure className="image is-24x24" key={member.id}>
          <img
            className="is-rounded"
            src={member.attributes["tiny-photo-url"]}
            alt={member.attributes["name"]}
          />
        </figure>
      ))}
    </div>
  );
}

export default observer(TopMembers);
