import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import { useStores } from "../../stores/utils";
import JoinSpacesRow from "./JoinSpacesRow";
import _ from "lodash";
import "./JoinSpaces.scss";

function JoinSpaces() {
  const { api, userStore } = useStores();
  const [spacesList, setSpacesList] = useState([]);

  const officeSummaryQuery = useQuery(
    "joinSpaces/officeSummary",
    () => api.getOfficeSummary(userStore.userInfo.organization_id),
    {
      staleTime: Infinity,
    }
  );

  useQuery(
    "joinSpaces/userPreferences",
    () => api.getUserPreferences(userStore.userId),
    {
      staleTime: Infinity,
      onSuccess: (data) => userStore.updatePreferences(data),
    }
  );

  useEffect(() => {
    if (!officeSummaryQuery.data) return;

    let spaces = officeSummaryQuery.data.data.included.filter(
      (x) => x.type === "sessions"
    );
    let sortedSpaces = _.sortBy(spaces, [(space) => space.attributes.name]);
    setSpacesList(sortedSpaces);
  }, [officeSummaryQuery.data]);

  const memberSpaceIds = userStore.memberSpaces;
  const userId = userStore.userId;

  return (
    <>
      <div className="join-spaces">
        <div className="join-spaces-scroller">
          {spacesList.map((space) => (
            <JoinSpacesRow
              key={space.id}
              space={space}
              memberSpaceIds={memberSpaceIds}
              userId={userId}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default observer(JoinSpaces);
