import { action, computed, makeObservable, observable } from "mobx";
import Honeybadger from "honeybadger-js";
import oldApi from "../api";

const { Codox } = window;

export class DocStore {
  @observable openDoc = {};
  @observable codox;
  @observable groupId;
  @observable showingDocumentSettingsDocId;
  @observable editors;
  @observable showingLinkModalId;
  @observable linkUpdate;
  @observable savingContent;
  @observable allowedDocId;

  constructor(rootStores) {
    this.rootStores = rootStores;
    makeObservable(this);
  }

  @action
  async setOpenDoc(docObj) {
    this.openDoc = docObj;
  }

  @action
  setCodoxInstance(codox) {
    this.codox = codox;
  }

  @action
  initCodox() {
    if (!this.codox) {
      const codox = new Codox();
      this.setCodoxInstance(codox);
      codox.on("error", function (data) {
        console.error("Codox error: ", data);
      });
    }
  }

  @action
  teardownCodox() {
    this.codox && this.codox.stop();
    this.openDoc = undefined;
  }

  @action
  changeOpenDoc(doc, withCodox = true) {
    if (!this.openDoc) {
      this.rootStores.viewStore.forceHideSideMenuIfInCall();
    }
    if (!this.openDoc || doc.id !== this.openDoc.id) {
      if (withCodox) {
        //leave the session
        this.codox && this.codox.stop();

        //create a new codox instance
        this.codox = new Codox();
        this.codox.on("error", function (data) {
          console.error("Codox error: ", data);
        });
      }
    }
  }

  @action
  setAllowedFromDocPage(docId) {
    this.allowedDocId = docId;
  }

  @action
  async shareDocWithGroup(docId, groupId) {
    try {
      await this.rootStores.api.updateOpenDoc(groupId, docId);
      this.allowedDocId = docId;
    } catch (e) {
      Honeybadger.notify(e);
    }
    if (!this.openDoc?.id) {
      this.rootStores.viewStore.forceHideSideMenuIfInCall();
    }
  }

  @action
  async closeDocPane(groupId) {
    this.codox && this.codox.stop();
    try {
      await this.rootStores.api.closeOpenDoc(groupId);
    } catch (e) {
      Honeybadger.notify(e);
    }
  }

  async saveDocToBackend(docId, content) {
    this.setSavingContent(true);
    try {
      const response = await this.rootStores.api.updateDocument(docId, {
        content: content,
      });
      this.setSavingContent(false);
      return response;
    } catch (e) {
      Honeybadger.notify(e);
    } finally {
      this.setSavingContent(false);
    }
  }

  async updateName(docId, name) {
    try {
      return await this.rootStores.api.updateDocument(docId, {
        name: name,
      });
    } catch (e) {
      Honeybadger.notify(e);
    }
  }

  @action
  async fetchDoc(docId, withCodox = true) {
    const [doc, editors] = await this.getAndBuildDoc(docId);
    if (!doc || !doc.id) {
      return;
    }
    this.checkIfDocIdAllowed(docId, "fetchDoc");
    this.setEditors(editors);
    this.changeOpenDoc(doc, withCodox);
    this.setOpenDoc(doc); // NEW
    return true;
  }

  @action
  setEditors(editors) {
    this.editors = editors;
  }

  async getAndBuildDoc(docId) {
    try {
      const data = await this.rootStores.api.getDocument(docId);
      const attributes = data?.data?.data?.attributes;
      const doc = {
        id: docId,
        name: attributes?.name,
        content: attributes?.content,
      };

      const editors = data?.data?.included?.filter(
        (x) => x.type === "doc-editors"
      );

      return [doc, editors];
    } catch (e) {
      Honeybadger.notify(e);
      return [{}, []];
    }
  }

  @computed
  get isEditor() {
    const editorId = this.editors?.find(
      (x) => x.attributes["user-id"] === this.rootStores.userStore.userId
    )?.id;
    return editorId;
  }

  @action
  async selectDoc(docId) {
    // runInAction(async () => {
    const [doc, editors] = await this.getAndBuildDoc(docId);
    if (!doc) {
      console.error("NO DOC - ABORTING!");
      return;
    }
    this.checkIfDocIdAllowed(docId, "selectDoc");
    if (this.openDoc?.id) {
      this.changeOpenDoc(doc, true);
      this.setOpenDoc(doc);
    } else {
      this.rootStores.viewStore.forceHideSideMenuIfInCall();
      this.setEditors(editors); // NEW
      this.changeOpenDoc(doc, true);
      this.setOpenDoc(doc);
      // if (
      //   !editors?.find(
      //     (x) => x.attributes["user-id"] === this.rootStores.userStore.userId
      //   )
      // ) {
      //   this.startEditing(docId);
      // }
    }
    // });
  }

  checkIfDocIdAllowed = (docId, codeTag) => {
    if (docId !== this.allowedDocId) {
      // console.trace();
      for (let i = 0; i < 3; i++) {
        console.error(
          "WAIT!! OUT OF DATE DOC ID! (",
          codeTag,
          ") allowed is ",
          this.allowedDocId,
          " but trying to use data for ",
          docId
        );
      }
    }
  };

  @action
  async refetchDoc() {
    if (this.openDoc?.id) {
      this.fetchDoc(this.openDoc?.id, this.isEditor);
    }
  }

  @action
  async showDocumentSettingsDocId(docId) {
    this.showingDocumentSettingsDocId = docId;
  }

  @action
  updateContent(id, content) {
    this.checkIfDocIdAllowed(id, "updateContent");
    if (id === this.openDoc.id) {
      this.openDoc.content = content;
    } else {
      console.error("WRONG DOC ID !!!!!");
    }
  }

  @action
  updateMetadata(docId, name, editors) {
    this.checkIfDocIdAllowed(docId, "updateMetadata");
    if (name) {
      if (this.openDoc?.id) {
        this.openDoc["name"] = name;
      }
    }
    this.setEditors(editors || []);
  }

  updateMetadataFromGroup(groupData) {
    const docId = groupData?.data?.attributes["open-doc-id"];
    const name = groupData?.data?.attributes["open-doc-name"];
    const editors = groupData?.included?.filter(
      (x) => x.type === "doc-editors"
    );
    this.updateMetadata(docId, name, editors);
  }

  updateMetadataFromMetadataJson(metadata) {
    const docId = metadata?.data?.id;
    const name = metadata?.data?.attributes["name"];
    const editors = metadata?.included?.filter((x) => x.type === "doc-editors");
    this.updateMetadata(docId, name, editors);
  }

  @action
  async triggerOpenOrCloseDocFromWebsocket(openDocId) {
    this.allowedDocId = openDocId;
    if (openDocId) {
      await this.selectDoc(openDocId);
    } else {
      if (this.openDoc?.id) {
        this.teardownCodox();
      }
    }
  }

  @action
  async startEditing(docId) {
    try {
      await this.rootStores.api.createDocEditor(
        docId,
        this.rootStores.userStore.userId
      );
    } catch (e) {
      Honeybadger.notify(e);
    }
  }

  @action
  async stopEditing(editorId) {
    try {
      await this.rootStores.api.deleteDocEditor(editorId);
    } catch (e) {
      Honeybadger.notify(e);
    }
  }

  @computed
  get editorListFull() {
    return this.editors?.length > 9;
  }

  @action
  setSavingContent(value) {
    this.savingContent = value;
  }

  @action
  showLinkModal(linkId) {
    this.showingLinkModalId = linkId;
  }

  @action
  setLinkUpdate(boolean) {
    this.linkUpdate = boolean;
  }

  @action
  async getLinks(roomId, guestToken) {
    try {
      if (guestToken) {
        return await oldApi.getLinksForParticipant(roomId, guestToken);
      } else {
        return await this.rootStores.api.getLinks(roomId);
      }
    } catch (err) {
      console.error("Link get all failed: ", err);
    }
  }

  @action
  async getLink(linkId, guestToken) {
    try {
      if (guestToken) {
        return await oldApi.getLinkForParticipant(linkId, guestToken);
      } else {
        return await this.rootStores.api.getLink(linkId);
      }
    } catch (err) {
      console.error("Link get failed: ", err);
    }
  }

  @action
  async updateLink(linkId, attributes, guestToken) {
    const payload = {
      data: {
        id: linkId,
        type: "group-links",
        attributes: attributes,
      },
    };
    try {
      if (guestToken) {
        await oldApi.updateLinkByParticipant(linkId, payload, guestToken);
      } else {
        await this.rootStores.api.updateLink(linkId, payload);
      }
    } catch (err) {
      console.error("Link update failed: ", err);
    }
    this.showLinkModal(undefined);
    this.setLinkUpdate(false);
  }

  @action
  async createLink(attributes, guestToken) {
    const uStore = this.rootStores.userStore;
    const cStore = this.rootStores.callStore;
    const creatorId = guestToken ? cStore.participantId : uStore.userId;
    const tableName = guestToken ? "participants" : "users";
    const payload = {
      data: {
        type: "group-links",
        attributes: attributes,
        relationships: {
          group: {
            data: {
              id: cStore.currentRoomId,
              type: "groups",
            },
          },
          session: {
            data: {
              id: cStore.sessionId,
              type: "sessions",
            },
          },
          organization: {
            data: {
              id: cStore.organizationId,
              type: "organizations",
            },
          },
          "group-linkable": {
            data: {
              id: creatorId,
              type: tableName,
            },
          },
        },
      },
    };
    try {
      if (guestToken) {
        await oldApi.createLinkByParticipant(payload, guestToken);
      } else {
        await this.rootStores.api.createLink(payload);
      }
    } catch (err) {
      console.error("Link create failed: ", err);
    }
    this.showLinkModal(undefined);
    this.setLinkUpdate(false);
  }

  @action
  async deleteLink(linkId, guestToken) {
    try {
      if (guestToken) {
        await oldApi.deleteLinkByParticipant(linkId, guestToken);
      } else {
        await this.rootStores.api.deleteLink(linkId);
      }
    } catch (err) {
      console.error("Link delete failed: ", err);
    }
    this.showLinkModal(undefined);
    this.setLinkUpdate(false);
  }

  @action
  async getOtherRoomLinks(roomId, guestToken) {
    try {
      if (guestToken) {
        return await oldApi.getOtherRoomLinksForParticipant(roomId, guestToken);
      } else {
        return await this.rootStores.api.getOtherRoomLinks(roomId);
      }
    } catch (err) {
      console.error("Get other room links failed: ", err);
    }
  }
}
