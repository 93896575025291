import { v4 as uuidv4 } from "uuid";
import { action, observable, makeObservable } from "mobx";

export class UserReactionStore {
  @observable.shallow reactionList = [];

  constructor(rootStores) {
    this.rootStores = rootStores;
    this.api = rootStores.api;
    makeObservable(this);
  }

  @action
  setReactionList(reactionList) {
    this.reactionList.replace(reactionList);
  }

  @action
  addReaction(newReaction) {
    this.reactionList.push(newReaction);
  }

  @action
  showReaction = (reactionName, senderName, reactionTitle, senderId) => {
    let newReaction = {
      id: uuidv4(),
      reaction: reactionName,
      sender_name: senderName,
      reaction_title: reactionTitle,
      sender_id: senderId,
    };

    this.addReaction(newReaction);

    console.log("REACTION: ", reactionName);
    if (reactionName === "muted-by-host") {
      console.log("Muting!!");
      this.rootStores.callObjectStore.setMicrophone(false);
      setTimeout(() => {
        this.hideReaction(newReaction.id);
      }, 2500);
    } else if (reactionName === "you-are-banned") {
      setTimeout(() => {
        return this.rootStores.viewStore.clickLeaveCall();
      }, 2500);
    } else if (reactionName === "accept-cluster-sidebar-invite") {
      setTimeout(() => {
        this.hideReaction(newReaction.id);
      }, 1500);
    } else {
      setTimeout(() => {
        this.hideReaction(newReaction.id);
      }, 3500);
    }
  };

  hideReaction(idToDelete) {
    this.setReactionList(this.reactionList.filter((r) => r.id !== idToDelete));
  }

  sendReaction = (
    reactionName,
    targetParticipantId,
    reactionTitle,
    reactionParams
  ) => {
    this.rootStores.cableSocketStore.participantsSubscription.send({
      sender_id: this.rootStores.callStore.participant?.id,
      recipient_id: targetParticipantId,
      reaction_type: reactionName,
      sender_name: this.rootStores.callStore.participant?.name,
      reaction_title: reactionTitle,
      reaction_params: reactionParams,
    });
  };
}
