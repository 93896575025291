import { observer } from "mobx-react-lite";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "react-query";

import { useStores } from "../../stores/utils";
function JoinSpacesRow({ space, memberSpaceIds, userId }) {
  const { api } = useStores();
  const queryClient = useQueryClient();

  const selected = memberSpaceIds.includes(space.id);

  const toggleMembership = async () => {
    if (selected) {
      await api.unbecomeMemberOfSpace(space.id, userId);
    } else {
      await api.becomeMemberOfSpace(space.id, userId);
    }
    await queryClient.refetchQueries();
  };

  const memberCount = space.attributes["user-count"] || 0;

  return (
    <>
      <div
        className={classnames("join-spaces-row", {
          checked: selected,
        })}
        onClick={() => toggleMembership()}
      >
        <div className="checkbox-container">
          <div className="fancy-checkbox">
            <FontAwesomeIcon icon={faCheck} />
          </div>
        </div>
        <div className="space-name-container">
          <div className="space-name-box">
            <span className="space-name">{space.attributes.name}</span>
            <div className="space-popuplation">
              {memberCount} {memberCount === 1 ? "member" : "members"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(JoinSpacesRow);
