import { useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import { useStores, observe } from "stores/utils";
import { ChangePlanModal } from "./ChangePlanModal";
import { formatNumberWithCommas } from "utilities/formatNumberWithCommas";
import { Coupon } from "./Coupon";
import "./Billing.scss";
import "./SubscriptionForm.scss";
import "./Cards.scss";

const PLAN_OPTIONS = [
  {
    value: "month",
    label: "Monthly",
  },
  {
    value: "year",
    label: "Annual",
  },
];

export const Billing = ({ onSubmit, signupStore }) => {
  const { subscriptionsStore } = useStores();
  const { isValid, values, handleSubmit, validateForm } = useFormikContext();
  const stripe = useStripe();
  const elements = useElements();
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);

  const submitPayment = async (event) => {
    handleSubmit();
    const validationResult = await validateForm();

    event.preventDefault();
    if (!isValid || Object.keys(validationResult).length) {
      return;
    }
    signupStore.setIsFetching(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      signupStore.setIsFetching(false);
      toast.error(error.message);
    } else {
      onSubmit(paymentMethod.id, values);
    }
  };

  return (
    <>
      <div className="subscription-card">
        <h1 className="title">Billing</h1>
        <div className="subscription-row">
          {observe(subscriptionsStore.currentSubscription, () => (
            <Select
              styles={{
                container: (base) => ({
                  ...base,
                  width: "100%",
                  marginTop: "10px",
                }),
              }}
              defaultValue={PLAN_OPTIONS.find(
                (option) => option.value === subscriptionsStore.selectedInterval
              )}
              label="Single select"
              options={PLAN_OPTIONS}
              onChange={(option) =>
                subscriptionsStore.changeInterval(option.value)
              }
            />
          ))}
        </div>
        <div className="subscription-row-cols">
          <div>Due at end of trial</div>
          {observe(
            [subscriptionsStore.coupon, subscriptionsStore.subscriptionPrice],
            () => (
              <div>
                {subscriptionsStore.coupon ? (
                  <>
                    <strike>
                      $
                      {formatNumberWithCommas(
                        (subscriptionsStore.subscriptionPrice / 100).toFixed(2)
                      )}
                    </strike>{" "}
                    $
                    {formatNumberWithCommas(
                      (subscriptionsStore.currentPrice / 100).toFixed(2)
                    )}
                  </>
                ) : (
                  `$${formatNumberWithCommas(
                    (subscriptionsStore.subscriptionPrice / 100).toFixed(2)
                  )}`
                )}
              </div>
            )
          )}
        </div>
        {observe(
          [
            subscriptionsStore.selectedInterval,
            subscriptionsStore.currentPrice,
          ],
          () =>
            subscriptionsStore.selectedInterval === "year" && (
              <div className="subscription-row-cols subscription-row__annual-per-month">
                <div></div>
                <div>
                  ($
                  {formatNumberWithCommas(
                    (subscriptionsStore.currentPrice / 1200).toFixed(2)
                  )}{" "}
                  per month x 12)
                </div>
              </div>
            )
        )}
        {observe(subscriptionsStore.selectedInterval, () =>
          subscriptionsStore.selectedInterval === "month" ? (
            <div className="subscription-row-cols subscription-row-cols__annual-missing-savings">
              <div>Missed annual savings</div>
              <div>
                $
                {formatNumberWithCommas(
                  (subscriptionsStore.annualSaving / 100).toFixed(2)
                )}
                /yr
              </div>
            </div>
          ) : (
            <div className="subscription-row-cols subscription-row-cols__annual-savings">
              <div>Annual savings</div>
              <div>
                $
                {formatNumberWithCommas(
                  (subscriptionsStore.annualSaving / 100).toFixed(2)
                )}
                /yr
              </div>
            </div>
          )
        )}
        <div className="subscription-row subscription-row__border-top">
          <div className="subscription-column subscription-column__bold">
            Due today
          </div>
          <div className="subscription-column subscription-column__bold">
            $0.00 USD
          </div>
        </div>
        <div className="subscription-row">
          <div className="help-text">
            By confirming, you agree to our{" "}
            <a
              href="https://get.sidebar.vc/subscriber-terms"
              rel="noopener noreferrer"
              target="_blank"
            >
              subscriber terms
            </a>
            . You will be <b>automatically charged</b> for your subscription at
            the end of the trial period, then your subscription will{" "}
            <b>automatically renew</b> until you cancel.
          </div>
        </div>
        <div className="subscription-card__actions">
          {observe(signupStore.isFetching, () => (
            <button
              className="subscription__submit-button"
              onClick={submitPayment}
              disabled={!stripe || !isValid || signupStore.isFetching}
            >
              {signupStore.isFetching ? (
                <div className="spinner"></div>
              ) : (
                "Confirm"
              )}
            </button>
          ))}
        </div>
        {showChangePlanModal && (
          <ChangePlanModal
            handleCloseModal={() => setShowChangePlanModal(false)}
          />
        )}
      </div>
      <Coupon />
    </>
  );
};

Billing.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  signupStore: PropTypes.object.isRequired,
};
