import { useEffect, useState } from "react";
import App from "../App/App";
import ImpersonatingBanner from "./ImpersonatingBanner";
import { Provider } from "../../stores/utils";
import { rootStores } from "../../stores/RootStores";
import { NotificationsProvider } from "reapop";
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";

function AppWrapper() {
  const [impersonating, setImpersonating] = useState(false);

  useEffect(() => {
    async function awaitInit() {
      rootStores.cableSocketStore.init();
      await rootStores.userStore.init();
      if (window.localStorage.impersonating) {
        setImpersonating(true);
      }
    }

    awaitInit();

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        console.log("setting up applyer...");
        rootStores.appStore.applyAppUpdate = () => {
          if (registration.waiting) {
            // let waiting Service Worker know it should became active
            registration.waiting.postMessage({ type: "SKIP_WAITING" });
            rootStores.appStore.setApplyAppUpdate(undefined);
          }
        };
      },
    });
  }, []);

  return (
    <Provider store={rootStores}>
      <NotificationsProvider>
        <ImpersonatingBanner impersonating={impersonating} />
        <App />
      </NotificationsProvider>
    </Provider>
  );
}

export default AppWrapper;
