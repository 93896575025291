import { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "@reach/router";
import ReactModal from "react-modal";
import { CSSTransition } from "react-transition-group";

import Call from "../Call/Call";
import Foyer from "../Foyer/Foyer";
import EditNameModal from "../EditNameModal/EditNameModal";
import HostLoginModal from "../HostLoginModal/HostLoginModal";
import NotificationModal from "../NotificationModal";
import BrowserUnsupported from "../BrowserUnsupported/BrowserUnsupported";

import * as URlUtil from "../../utilities/url";
import * as UserAgentUtil from "../../utilities/user-agent";

import EndedEventScreen from "../EndedEventScreen";
import { useStores } from "../../stores/utils";
import Modal from "../Office/Modal";
import LinkModal from "../LinkModal/LinkModal";

import "./Home.scss";

function Home({ spaceId }) {
  const { appStore, callStore, viewStore, officeStore, docStore } = useStores();

  const [tempName, setTempName] = useState("");

  const navigate = useNavigate();

  ReactModal.setAppElement("#root");

  const handleNameChange = useCallback(
    (event) => {
      setTempName(event.target.value);
    },
    [setTempName]
  );

  let appClassName = "";

  if (!appStore.showCall) {
    appClassName = "white-app-bg";
  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      viewStore.setHideSideMenu(true);
    }

    let lastURLSegment = "";
    const spaceId = officeStore?.currentSpaceId;
    if (spaceId) {
      lastURLSegment = spaceId;
    } else {
      lastURLSegment = URlUtil.parseSessionIdFromUrl();
    }

    if (lastURLSegment) {
      callStore.refreshData(lastURLSegment).then(() => {
        appStore.setDataInitialized(true);
      });
    }
  }, [callStore, appStore, viewStore, officeStore?.currentSpaceId]);

  if (UserAgentUtil.shouldBlockBrowser()) return <BrowserUnsupported />;

  if (callStore.isEventEnded) return <EndedEventScreen />;

  return (
    <>
      <NotificationModal />
      <div className={"app " + appClassName}>
        {appStore.isIdleOrLurking ? (
          <CSSTransition
            in={appStore.dataInitialized}
            timeout={200}
            classNames="foyer-transition"
          >
            <Foyer handleNameChange={handleNameChange} tempName={tempName} />
          </CSSTransition>
        ) : (
          <Call navigate={navigate} />
        )}
      </div>

      <ReactModal
        isOpen={viewStore.showEditNameForm}
        onRequestClose={() => {
          viewStore.toggleShowEditNameForm();
        }}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        className="custom-modal"
        overlayClassName="modal-background"
        closeTimeoutMS={300}
      >
        <div className="modal-content">
          <div className="box is-info">
            <EditNameModal
              participantName={callStore.participantName || ""}
              saveAction={callStore.updateParticipant}
            />
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={viewStore.showHostLogin}
        onRequestClose={() => {
          viewStore.toggleShowHostLogin();
        }}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        className="custom-modal"
        overlayClassName="modal-background"
        closeTimeoutMS={300}
      >
        <div className="modal-content">
          <div className="box is-info">
            <HostLoginModal
              navigate={navigate}
              onLogin={viewStore.toggleShowHostLogin}
            />
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={viewStore.showBanModal}
        onRequestClose={() => {
          viewStore.hideBanModal();
        }}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        className="custom-modal"
        overlayClassName="modal-background"
        closeTimeoutMS={300}
      >
        <div className="modal-content">
          <div className="box is-info">{viewStore.banModalContent}</div>
        </div>
      </ReactModal>
      <Modal
        onRequestClose={() => {
          docStore.showLinkModal(undefined);
          docStore.linkUpdate && docStore.setLinkUpdate(false);
        }}
        showingModal={docStore.showingLinkModalId}
      >
        <LinkModal
          isUpdate={docStore.linkUpdate}
          linkId={docStore.linkUpdate && docStore.showingLinkModalId}
        />
      </Modal>
    </>
  );
}

export default observer(Home);
