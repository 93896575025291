import { observer } from "mobx-react-lite";
import ProgressLine from "./ProgressLine";
import Loader from "../../Loader";
import "./EventLinkUsage.scss";

const EventLinkUsage = ({ store, session }) => {
  const usageValueMinutes =
    store.data && store.bySession[session.id]
      ? Math.round(store.bySession[session.id] / 60)
      : 0;

  const usageValue = {
    hours: Math.floor(usageValueMinutes / 60),
    minutes: usageValueMinutes % 60,
  };

  return (
    <div className="event-usage">
      <div className="event-usage__column event-usage__column--progress">
        <ProgressLine
          height={22}
          value={store.getProgressForSession(session.id)}
          isLoading={store.isWaitingPushData}
        />
      </div>
      <div className="event-usage__column event-usage__column--time">
        {usageValue.hours} hours {usageValue.minutes} min
        {store.isWaitingPushData && <Loader />}
      </div>
      <div className="event-usage__column event-usage__column--name">
        {session.name}
      </div>
      <div className="event-usage__column event-usage__column--link">
        <a href={session.url} target="_blank" rel="noopener noreferrer">
          {session.url}
        </a>
      </div>
    </div>
  );
};

export default observer(EventLinkUsage);
