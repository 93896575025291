import { useCallback } from "react";
import ReactModal from "react-modal";
import { useStores, observe } from "stores/utils";

export const GeneralNotification = () => {
  const { viewStore } = useStores();

  const closeGeneralNotification = useCallback(() => {
    viewStore.setGeneralNotification(null);
  }, [viewStore]);

  return observe(viewStore.generalNotification, () => {
    return (
      <ReactModal
        isOpen={!!viewStore.generalNotification}
        onRequestClose={closeGeneralNotification}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        className="custom-modal"
        overlayClassName="modal-background"
      >
        <div className="modal-content">
          <div className="box is-info has-text-centered">
            {viewStore.generalNotification?.bodyText}{" "}
            {viewStore.generalNotification?.buttonText && (
              <div className="modal-action-row">
                <div className="level">
                  <div className="level-item">
                    <button
                      className="button is-link"
                      onClick={closeGeneralNotification}
                    >
                      {viewStore.generalNotification?.buttonText}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    );
  });
};
