import PlainGreyLayout from "../../components/Layouts/PlainGreyLayout";

function InactiveTabPage() {
  return (
    <PlainGreyLayout blueLogo={true}>
      <div className="content-column narrow content has-text-centered	">
        <h1>You opened Sidebar in another tab.</h1>
        <p>
          <a href="/office">Click here to use Sidebar in this tab.</a>
        </p>
      </div>
    </PlainGreyLayout>
  );
}

export default InactiveTabPage;
