import { useRef } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import LimitedParticipantsList from "../LimitedParticipantsList/LimitedParticipantsList";
import RoomsListCurrentCall from "./RoomsListCurrentCall";
import AddRoomButtons from "../Office/AddRoomButtons";

function PrivateCall() {
  const { callStore } = useStores();
  const roomsMenuEl = useRef(null);

  const createRoom = () => {
    callStore.addRoom();
  };

  return (
    <>
      {callStore.groupList.length === 1 ? (
        <div
          className="conversation-participants space-outline"
          ref={roomsMenuEl}
        >
          <LimitedParticipantsList
            room={callStore.groupList[0]}
            differentSession={true}
          />
          {callStore.participantList.length > 2 && (
            <div className="add-room-button-row">
              <AddRoomButtons
                roomsMenuEl={roomsMenuEl}
                createRoom={createRoom}
                hideCreateAuditorium={true}
              />
            </div>
          )}
        </div>
      ) : (
        <RoomsListCurrentCall
          hideSpaceName={true}
          hideCreateAuditorium={true}
        />
      )}
    </>
  );
}

export default observer(PrivateCall);
