import { useState, useEffect } from "react";
import moment from "moment";
import { useStores, observe } from "stores/utils";
import { ChangeSubscriptionModal } from "./ChangeSubscriptionModal";
import { ChangeCapModal } from "./ChangeCapModal";
import "./shared.scss";

export const Plan = () => {
  const { userSubscriptionStore, subscriptionsStore } = useStores();
  const [showChangeCapModal, setShowChangeCapModal] = useState(false);
  const [showChangeSubscrModal, setShowChangeSubscrModal] = useState(false);

  useEffect(() => {
    subscriptionsStore.getSubscriptions();
  }, [subscriptionsStore]);

  return (
    <div className="billing-card box">
      <div className="billing-card__title">Your plan</div>

      <div className="billing-card-table">
        <div className="billing-card-table__row">
          <div>
            {observe(userSubscriptionStore.plan, () =>
              userSubscriptionStore.plan ? (
                <>
                  {userSubscriptionStore.plan?.attributes?.name} -{" "}
                  {userSubscriptionStore.plan?.attributes?.included_hours}{" "}
                  included hours/
                  {userSubscriptionStore.plan?.attributes?.interval === "month"
                    ? "mo"
                    : "yr"}
                  , ${userSubscriptionStore.plan?.attributes?.price / 100}/
                  {userSubscriptionStore.plan?.attributes?.interval === "month"
                    ? "mo"
                    : "yr"}
                  {userSubscriptionStore.willUpdate ? (
                    <div className="current-subscription">Current plan</div>
                  ) : null}
                </>
              ) : null
            )}
          </div>
          {observe(userSubscriptionStore.cap, () =>
            userSubscriptionStore.plan ? (
              <div
                className="link-button"
                onClick={() => setShowChangeCapModal(true)}
              >
                Change cap
              </div>
            ) : null
          )}
          {observe(userSubscriptionStore.plan, () =>
            userSubscriptionStore.plan ? (
              <div
                className="link-button"
                onClick={() => setShowChangeSubscrModal(true)}
              >
                Change plan
              </div>
            ) : null
          )}
        </div>
        <div className="billing-card-table__row billing-card-table__cap">
          {observe(userSubscriptionStore.cap, () =>
            userSubscriptionStore.cap === null
              ? "$50 for 50 additional hours - no cap"
              : `$50 for 50 additional hours - ${userSubscriptionStore.subscription?.data?.attributes?.hours_cap} cap`
          )}
        </div>
        <div className="billing-card-container__subtitle">
          {observe(
            [
              userSubscriptionStore.isActiveSubscription,
              userSubscriptionStore.willUpdate,
            ],
            () =>
              (userSubscriptionStore.subscription?.data?.attributes
                ?.current_period_end &&
                (userSubscriptionStore.isActiveSubscription ? (
                  <div className="subscription-renew">
                    {userSubscriptionStore.willUpdate ? "Expires" : "Renews"}{" "}
                    <b>
                      {moment(
                        userSubscriptionStore.subscription?.data?.attributes
                          ?.current_period_end
                      ).format("MMMM DD, YYYY")}
                    </b>
                  </div>
                ) : (
                  <div className="subscription-renew">
                    Ends{" "}
                    <b>
                      {moment(
                        userSubscriptionStore.subscription?.data?.attributes
                          ?.current_period_end
                      ).format("MMMM DD, YYYY")}
                    </b>
                  </div>
                ))) ||
              null
          )}
        </div>
        {observe(
          [
            userSubscriptionStore.willUpdate,
            userSubscriptionStore.futureSubscription,
          ],
          () =>
            userSubscriptionStore.willUpdate ? (
              <>
                <div
                  className="billing-card-table__row"
                  style={{ marginTop: 30 }}
                >
                  <div>
                    {observe(userSubscriptionStore.plan, () =>
                      userSubscriptionStore.plan
                        ? `${
                            userSubscriptionStore.futureSubscription?.attributes
                              ?.name
                          } - ${
                            userSubscriptionStore.futureSubscription?.attributes
                              ?.included_hours
                          } included hours/
                  ${
                    userSubscriptionStore.futureSubscription?.attributes
                      ?.interval === "month"
                      ? "mo"
                      : "yr"
                  }, $${
                            userSubscriptionStore.futureSubscription?.attributes
                              ?.price / 100
                          }/
                  ${
                    userSubscriptionStore.futureSubscription?.attributes
                      ?.interval === "month"
                      ? "mo"
                      : "yr"
                  }
                `
                        : null
                    )}
                  </div>
                  <div
                    className="link-button"
                    onClick={userSubscriptionStore.cancelUpgrade}
                  >
                    Cancel plan change
                  </div>
                </div>
                <div className="billing-card-table__row billing-card-table__cap">
                  {observe(userSubscriptionStore.cap, () =>
                    userSubscriptionStore.cap === null
                      ? "$50 for 50 additional hours - no cap"
                      : `$50 for 50 additional hours - ${userSubscriptionStore.subscription?.data?.attributes?.hours_cap} cap`
                  )}
                </div>
                <div className="billing-card-container__subtitle">
                  <div className="subscription-renew">
                    Starts{" "}
                    <b>
                      {moment(
                        userSubscriptionStore.subscription?.data?.attributes
                          ?.current_period_end
                      ).format("MMMM DD, YYYY")}
                    </b>
                  </div>
                </div>
              </>
            ) : null
        )}
      </div>
      {showChangeSubscrModal && (
        <ChangeSubscriptionModal
          handleCloseModal={() => setShowChangeSubscrModal(false)}
        />
      )}
      {showChangeCapModal && (
        <ChangeCapModal handleCloseModal={() => setShowChangeCapModal(false)} />
      )}
    </div>
  );
};
