import { action, makeAutoObservable, observable } from "mobx";
import { toast } from 'react-toastify';
import { httpService } from "utilities/HttpService";

export const SIGNUP_STEPS = {
  'USER_DATA': 'USER_DATA',
  'CREDIT_CARD_DATA': 'CREDIT_CARD_DATA',
}

export class SignupStore {
  @observable data = {};
  @observable isFetching = false;
  @observable currentStep = SIGNUP_STEPS['USER_DATA'];

  constructor({ userStore }) {
    this.userStore = userStore;
    makeAutoObservable(this);
  }

  @action
  setIsFetching = (isFetching) => {
    this.isFetching = isFetching;
  }

  @action
  register = async () => {
    this.isFetching = true;
    try {
      const { data } = await httpService.post(
        "/api/v1/registrations",
        this.data,
      );

      await this.userStore.login({ email: this.data.user.email, password: this.data.user.password });

      return data;
    } catch (err) {
      toast.error(err.response?.data?.error);
      throw new Error(err);
    } finally {
      this.isFetching = false;
    }
  };

  @action
  changeStep = (step) => {
    this.currentStep = step;
  }

  @action
  setUserData = (data) => {
    this.data = { ...this.data, ...data };
  }
}
