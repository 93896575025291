import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import SpaceNameHeader from "./SpaceNameHeader";
import RoomsListExpanded from "./RoomsListExpanded";
import RoomsList from "./RoomsList";
import classnames from "classnames";
import { useEffect } from "react";

function SpaceListRow({ space, subscribed }) {
  const rootStores = useStores();
  const { officeStore, sessionsStore } = rootStores;

  const sessionId = space.session.id;
  const isExpanded = officeStore.expandedSpaceId === sessionId;
  const isViewed = sessionsStore.viewedSpaces.includes(sessionId);
  const sessionType = space.session?.attributes?.["session-type"];

  useEffect(() => {
    if (!subscribed && space.groups && space.groups.length > 0 && !isViewed) {
      sessionsStore.setOtherSpacesActivityCount(0);
      sessionsStore.addViewedSpace(sessionId);
    }
  }, [subscribed, space.groups, sessionId, sessionsStore, isViewed]);

  const sessionUrl = `${window.location.origin}/office/s/${sessionId}`;

  return (
    <li
      key={sessionId}
      className={classnames("office-rooms-menu", {
        unsubscribed: !subscribed,
        "in-a-call": officeStore.inACall,
      })}
    >
      <SpaceNameHeader
        withContent={space.groups && space.groups.length > 0}
        name={space.session.attributes.name}
        inRightPane={sessionId === officeStore?.spaceInRightPaneId}
        isExpanded={isExpanded}
        onClick={() => {
          if (officeStore.inACall) {
            officeStore.toggleSetExpandedSpaceId(sessionId);
          } else {
            officeStore.setSpaceInRightPaneId(sessionId, "space");
          }
        }}
        sessionUrl={sessionUrl}
        showLinkIcon={false}
      />
      {isExpanded ? (
        <RoomsListExpanded space={space} />
      ) : (
        space.groups &&
        space.groups.length > 0 && (
          <RoomsList
            groups={space.groups}
            sessionId={sessionId}
            sessionType={sessionType}
          />
        )
      )}
    </li>
  );
}

export default observer(SpaceListRow);
