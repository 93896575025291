import { action, computed, observable, makeObservable } from "mobx";
import { detect } from "detect-browser";
import osDetector from "detect-os";
import { isIOS } from "react-device-detect";

export const STATE_IDLE = "STATE_IDLE";
export const STATE_LURKING = "STATE_LURKING";
export const STATE_JOINING = "STATE_JOINING";
export const STATE_JOINED = "STATE_JOINED";
export const STATE_LEAVING = "STATE_LEAVING";
export const STATE_ERROR = "STATE_ERROR";

function validateAppState(value) {
  if (
    [
      STATE_JOINING,
      STATE_JOINED,
      STATE_LEAVING,
      STATE_ERROR,
      STATE_IDLE,
      STATE_LURKING,
    ].some((x) => value === x)
  )
    return false;

  return true;
}

export class AppStore {
  @observable appState = null;
  @observable dataInitialized = false;
  @observable isDebug = false;
  @observable officeMode = false;
  @observable pwaInstallPromptEvent = null;
  @observable showingInstallPromotion = false;
  @observable applyAppUpdate = undefined;
  @observable showingPostInstallModal = false;
  @observable notify = undefined;
  @observable dismiss = undefined;
  notificationRegistry = new Map();

  intercomAppId = process.env.REACT_APP_INTERCOM_KEY;

  constructor(rootStores) {
    this.rootStores = rootStores;
    this.appState = STATE_IDLE;
    this.isFirefox = this.isFirefox();
    this.isChrome = this.isChrome();
    this.isMac = this.isMac();
    this.isSafari = this.isSafari();
    this.isEdge = this.isEdge();
    this.browserName = this.getBrowserName();
    this.isAndroid = this.isAndroid();
    this.isIOS = this.isIos();
    this.isIpad = navigator.maxTouchPoints === 5;
    this.initPWAInstallListener();
    makeObservable(this);
  }

  @action
  initPWAInstallListener() {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.pwaInstallPromptEvent = e;
      // Update UI notify the user they can install the PWA
      this.showInstallPromotion();
      // Optionally, send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`);
    });

    window.addEventListener("appinstalled", () => {
      // Hide the app-provided install promotion
      this.hideInstallPromotion();
      // Clear the deferredPrompt so it can be garbage collected
      this.clearPWAInstallEvent();
      // Optionally, send analytics event to indicate successful install
      console.log("PWA was installed");
      this.setShowingPostInstallModal(true);
    });
  }

  @action
  showInstallPromotion() {
    this.showingInstallPromotion = true;
  }

  @action
  hideInstallPromotion() {
    this.showingInstallPromotion = false;
  }

  @action
  clearPWAInstallEvent() {
    this.pwaInstallPromptEvent = null;
  }

  @action
  async installPWA() {
    // Show the install prompt
    this.pwaInstallPromptEvent.prompt();
    // Wait for the user to respond to the prompt
    const { outcome } = await this.pwaInstallPromptEvent.userChoice;
    // Hide the app provided install promotion
    this.hideInstallPromotion();
    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`);
    // We've used the prompt, and can't use it again, throw it away
    this.clearPWAInstallEvent();
  }

  @action
  setShowingPostInstallModal(value) {
    this.showingPostInstallModal = value;
  }

  getBrowserName = () => {
    const browser = detect();

    if (browser && browser.name === "edge-chromium") {
      return "Edge";
    }

    if (browser && browser.name) {
      return browser.name.charAt(0).toUpperCase() + browser.name.slice(1);
    }

    return "";
  };

  isFirefox = () => {
    const browser = detect();
    // for easy access
    window.browserInfo = browser;

    return browser && browser.name === "firefox";
  };

  isChrome = () => {
    const browser = detect();

    return browser && browser.name === "chrome";
  };

  isSafari = () => {
    const browser = detect();

    return browser && browser.name === "safari";
  };

  isEdge = () => {
    const browser = detect();

    return (
      browser && (browser.name === "edge" || browser.name === "edge-chromium")
    );
  };

  isMac = () => {
    const osDetect = new osDetector();

    return osDetect.detect()?.os === "macos";
  };

  isAndroid = () => {
    const osDetect = new osDetector();

    return osDetect.detect()?.os === "android";
  };

  isIos = () => {
    return isIOS;
  };

  checkIos = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  @action.bound
  setAppState(value) {
    if (validateAppState(value)) {
      this.appState = value; // TODO: check cond
    }

    this.appState = value;
  }

  @action
  setDataInitialized = (value) => {
    this.dataInitialized = value;
  };

  @action
  setIsDebug(value) {
    this.isDebug = value;
  }

  @computed
  get showRoomPopulation() {
    return [STATE_IDLE, STATE_JOINING, STATE_LURKING, STATE_LEAVING].includes(
      this.appState
    );
  }

  /**
   * Show the call UI if we're either joining, already joined, or are showing
   * an error.
   */
  @computed
  get showCall() {
    console.log("APP STATE IS NOW: ", this.appState);
    return [STATE_JOINING, STATE_JOINED, STATE_ERROR].includes(this.appState);
  }

  /**
   * Only enable the call buttons (camera toggle, leave call, etc.) if we're joined
   * or if we've errored out.
   *
   * !!!
   * IMPORTANT: calling callObject.destroy() *before* we get the "joined-meeting"
   * can result in unexpected behavior. Disabling the leave call button
   * until then avoids this scenario.
   * !!!
   */
  @computed
  get enableCallButtons() {
    return [STATE_JOINED, STATE_ERROR].includes(this.appState);
  }

  /**
   * Only enable the start button if we're in an idle state (i.e. not creating,
   * joining, etc.).
   *
   * !!!
   * IMPORTANT: only one call object is meant to be used at a time. Creating a
   * new call object with DailyIframe.createCallObject() *before* your previous
   * callObject.destroy() completely finishes can result in unexpected behavior.
   * Disabling the start button until then avoids that scenario.
   * !!!
   */
  @computed
  get enableStartButton() {
    return this.appState === STATE_IDLE;
  }

  toggleIsDebug = (e) => {
    if (!e) e = window.event;
    if (e.shiftKey) {
      this.setIsDebug(!this.isDebug);
    }
  };

  @computed
  get isIdleOrLurking() {
    return [STATE_IDLE, STATE_LURKING].includes(this.appState);
  }

  @action
  setOfficeMode(value) {
    this.officeMode = value;
  }

  @action
  setApplyAppUpdate(func) {
    this.applyAppUpdate = func;
  }

  @action
  setNotify(notifyFn) {
    this.notify = notifyFn;
  }

  @action
  setDismiss(dismissFn) {
    this.dismiss = dismissFn;
  }

  registerNotification(config) {
    const { notificationId, type, fromId } = config;
    if (!this.notificationRegistry.has(fromId)) {
      this.notificationRegistry.set(fromId, new Map());
    }
    this.notificationRegistry.get(fromId).set(type, notificationId);
  }
}
