import { useEffect, useRef, useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import "./TileVariables.css";
import { useStores } from "../../stores/utils";
import _ from "lodash";

const Sliders = ({ myId }) => {
  const [inputs, setInputs] = useState([]);
  const { tileSettingsStore } = useStores();

  const colorInput = useRef();
  const dimensionsInput = useRef();
  const mySettings = tileSettingsStore.changedTiles[myId];

  useEffect(() => {
    if (colorInput?.current && dimensionsInput?.current) {
      setInputs([colorInput?.current, dimensionsInput?.current]);
    }
  }, [colorInput, dimensionsInput]);

  useEffect(() => {
    if (!mySettings) {
      colorInput.current.value = "#222222";
      dimensionsInput.current.value = "50";
    }
  }, [mySettings]);

  const broadcastMySettings = useCallback(() => {
    if (!colorInput?.current || !dimensionsInput?.current) return;
    const settings = {
      "background-color": colorInput.current.value,
      "tile-dimensions": dimensionsInput.current.value,
    };
    tileSettingsStore.shareTileSetting(settings);
  }, [colorInput, dimensionsInput, tileSettingsStore]);

  const handleUpdate = useCallback(
    (event) => {
      const input = event.currentTarget;
      const inputName = event.currentTarget.name;
      let targetElement;
      if (inputName === "spacing") {
        targetElement = tileSettingsStore.refs.videoEl;
      } else if (inputName === "base-color") {
        targetElement = tileSettingsStore.refs.backgroundEl;
      }
      if (!input) return;
      const suffix = input.dataset.sizing || "";
      targetElement.style.setProperty(`--${input.name}`, input.value + suffix);
    },
    [tileSettingsStore.refs.videoEl, tileSettingsStore.refs.backgroundEl]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedBroadcast = useCallback(
    _.debounce(broadcastMySettings, 700, { leading: false, trailing: true }),
    []
  );

  useEffect(() => {
    if (inputs.length) {
      inputs.forEach((input) => {
        if (input.name === "spacing") {
          input.addEventListener("mousemove", handleUpdate);
          input.addEventListener("change", debouncedBroadcast);
        } else if (input.name === "base-color") {
          input.addEventListener("input", handleUpdate);
          input.addEventListener("input", debouncedBroadcast);
        }
        input.addEventListener("change", handleUpdate);
      });
    }

    return () => {
      if (inputs.length) {
        inputs.forEach((input) => {
          if (input.name === "spacing") {
            input.removeEventListener("mousemove", handleUpdate);
            input.removeEventListener("change", debouncedBroadcast);
          } else if (input.name === "base-color") {
            input.removeEventListener("input", handleUpdate);
            input.removeEventListener("input", debouncedBroadcast);
          }
          input.removeEventListener("change", handleUpdate);
        });
      }
    };
  }, [inputs, handleUpdate, debouncedBroadcast]);

  return (
    <div className="sliders-container">
      <span className="color-input-container">
        <input
          id="base"
          type="color"
          name="base-color"
          defaultValue={mySettings ? mySettings["background-color"] : "#222222"}
          ref={colorInput}
          className="color-input"
        />
      </span>
      <input
        id="spacing"
        type="range"
        name="spacing"
        min="12"
        max="70"
        defaultValue={mySettings ? mySettings["tile-dimensions"] : "50"}
        data-sizing="%"
        ref={dimensionsInput}
        className="dimensions-input"
      />
    </div>
  );
};

export default observer(Sliders);
