import { action, makeAutoObservable, observable } from "mobx";
import { toast } from "react-toastify";
import Honeybadger from "honeybadger-js";
import { httpService } from "utilities/HttpService";

export class BannedUsersStore {
  @observable data = [];
  @observable unbanUserId = null;
  @observable isFetching = false;
  @observable isUnbanningUser = false;

  constructor(rootStores) {
    this.rootStores = rootStores;

    makeAutoObservable(this);
  }

  @action
  setIsFetching = (isFetching) => {
    this.isFetching = isFetching;
  };

  @action
  setUnbanUserId = (userId) => {
    this.unbanUserId = userId;
  };

  @action
  getBannedUsers = async () => {
    this.isFetching = true;
    try {
      const { data } = await httpService.get(
        `/api/v1/organizations/${this.rootStores.userStore.userInfo.organization_id}/ip-address-bans`
      );

      this.data = data.data;
      return data.data;
    } catch (err) {
      toast.error(err.response?.data?.error);
      Honeybadger.notify(err);
      throw new Error(err);
    } finally {
      this.isFetching = false;
    }
  };

  @action
  unbanUser = async () => {
    this.isUnbanningUser = true;

    try {
      const { data } = await httpService.delete(
        `/api/v1/ip-address-bans/${this.unbanUserId}`
      );

      this.data = this.data.filter(
        (bannedUser) => bannedUser.id !== this.unbanUserId
      );
      return data;
    } catch (err) {
      Honeybadger.notify(err);
      toast.error(err.response?.data?.error);
    } finally {
      this.unbanUserId = null;
      this.isUnbanningUser = false;
    }
  };
}
