import { action, computed, makeObservable, observable } from "mobx";
import LogRocket from "logrocket";

export const FAST_UPDATE_RATE = 5000;
export const SLOW_UPDATE_RATE = 8000;

const thresholdRewordings = {
  good: "good",
  low: "bad",
  "very-low": "very bad",
};

export class NetworkStatsStore {
  @observable receivedBitrate = 0;
  @observable sentBitrate = 0;
  @observable threshold = null;
  @observable isNetworkPanelOpen = false;
  @observable quality = null;

  constructor(rootStores) {
    this.rootStores = rootStores;
    this.callStore = rootStores.callStore;
    makeObservable(this);
  }

  @computed get receivedKbps() {
    return Math.round(this.receivedBitrate / 1000) || 0;
  }

  @computed get sentKbps() {
    return Math.round(this.sentBitrate / 1000) || 0;
  }

  @computed get updateRate() {
    if (this.isNetworkPanelOpen) {
      return FAST_UPDATE_RATE;
    } else {
      return SLOW_UPDATE_RATE;
    }
  }

  @computed get connectionQualityWord() {
    return thresholdRewordings[this.connectionStatus] || "(measuring)";
  }

  @computed get connectionStatus() {
    if (this.quality >= 75 && thresholdRewordings[this.threshold] === 'good') {
      return 'good';
    } else if (this.quality < 50 || thresholdRewordings[this.threshold] === 'very bad') {
      return 'very-low';
    } else if (this.quality || this.threshold) {
      return 'low';
    } else {
      return 'good';
    }
  }

  @computed get connected() {
    if (this.receivedBitrate === undefined) {
      return false;
    }
    return this.receivedBitrate !== 0 || this.sentBitrate !== 0;
  }

  @action async fetch() {
    const callObject = this.rootStores.callObjectStore.callObject;
    if (!callObject) {
      return;
    }
    const result = await this.rootStores.callObjectStore.getNetworkStats();
    this.logStats(result);
    LogRocket.log(result);
    this.setFetchData(result);
  }

  logStats = (result) => {
    try {
      let statsString = "";

      if (result.threshold === "low" || result.threshold === "very-low") {
        statsString += "BAD NETWORK ";
      } else {
        statsString += "Network: ";
      }

      statsString += `quality: ${result.quality}, threshold: ${result.threshold} `;
      if (result.stats) {
        if (result.stats.latest) {
          statsString += `up: ${result.stats.latest.sendBitsPerSecond / 1000} `;
          statsString += `videoUp: ${result.stats.latest.videoSendBitsPerSecond / 1000
            } `;
          statsString += `down: ${result.stats.latest.recvBitsPerSecond / 1000
            } `;
          statsString += `videoDown: ${result.stats.latest.videoRecvBitsPerSecond / 1000
            } `;
          statsString += `upLoss: ${result.stats.latest.videoSendPacketLoss} `;
          statsString += `downLoss: ${result.stats.latest.videoRecvPacketLoss} `;
        }
        statsString += `upLossMax: ${result.stats.worstVideoSendPacketLoss} `;
        statsString += `downLossMax: ${result.stats.worstVideoRecvPacketLoss} `;
      }
      LogRocket.log(statsString);
    } catch (e) {
      console.error(
        "oops, something wrong with NetworkStatsStore.logStats, ",
        e
      );
    }
  };

  @action
  setFetchData(result) {
    this.receivedBitrate = result?.stats?.latest?.videoRecvBitsPerSecond;
    this.sentBitrate = result?.stats?.latest?.videoSendBitsPerSecond;
    this.quality = result?.quality;
    this.threshold = result?.threshold;
  }

  @action.bound toggleNetworkPanel() {
    this.isNetworkPanelOpen = !this.isNetworkPanelOpen;
  }

  @action hideNetworkPanel() {
    this.isNetworkPanelOpen = false;
  }
}
