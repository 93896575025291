import { useCallback, useMemo, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { ImPhoneHangUp } from "react-icons/im";
import KnockButton from "../KnockButton/KnockButton";
import JoinButton from "../JoinButton/JoinButton";
import Honeybadger from "honeybadger-js";
import "./PersonRow.scss";

function UserParticipantActions({
  userId,
  availabilityStatus,
  officeId,
  differentSession,
  userChat,
  sayStart,
  hideHere,
}) {
  const rootStores = useStores();
  const { api, callStore, officeStore, userStore } = rootStores;
  const [knockId, setKnockId] = useState(undefined);
  const [forceHere, setForceHere] = useState(undefined);

  const myUserId = userStore?.userInfo?.id;

  const knocking = officeStore.knockingUserId === userId;
  const showJoinMeSent = officeStore.joiningMeUserIds.has(userId);

  const requestCall = useCallback(async () => {
    officeStore.requestCall(userId);
  }, [officeStore, userId]);

  const knock = async (e) => {
    e.stopPropagation();
    let knockId;
    knockId = await officeStore.knock(userId);
    if (knockId) {
      setKnockId(knockId);
    }
  };

  const cancelKnock = (e) => {
    e.stopPropagation();
    if (knockId) {
      officeStore.cancelKnock(knockId);
    }
  };

  const cancelCall = (e) => {
    e.stopPropagation();
    officeStore.cancelCall();
  };

  const ringing = useMemo(() => {
    return userId === officeStore.ringingUserId;
  }, [officeStore.ringingUserId, userId]);

  const sendJoinMe = async (e) => {
    e.stopPropagation();
    if (!showJoinMeSent) {
      try {
        await api.joinMe(userId, callStore.currentRoomId);
      } catch (e) {
        Honeybadger.notify(e);
      }
      officeStore.setJoiningMeUserId(userId);
    }
  };

  useEffect(() => {
    if (userChat) return;
    if (!showJoinMeSent) return;
    const timeoutId = setTimeout(() => {
      officeStore.removeJoiningMeUserId(userId);
    }, 5000);
    return () => {
      clearTimeout(timeoutId);
      officeStore.removeJoiningMeUserId(userId);
    };
  }, [showJoinMeSent, officeStore, userId, userChat]);

  const thisIsntYourRow = userId !== myUserId;
  const thisIsYourRow = !thisIsntYourRow;
  const alreadyInRoom = callStore?.currentRoom?.userIdList?.includes(userId);
  const alreadyInThisOffice = callStore.currentRoomId === officeId;
  const isDoorOpen = availabilityStatus === "Door is open";

  const join = async (e, userId) => {
    e.stopPropagation();
    setForceHere(undefined);
    await officeStore.joinOffice(userId);
    setForceHere(true);
    setTimeout(() => {
      setForceHere(undefined);
    }, 500);
  };

  const joinOwnOffice = async (e) => {
    await join(e, myUserId);
  };

  const joinOffice = async (e) => {
    await join(e, userId);
  };

  const renderYourOwnActions = () => {
    if (alreadyInThisOffice || forceHere) {
      if (hideHere) return <></>;
      return <div className="here-indicator">Here</div>;
    } else {
      const joining =
        officeStore.joiningOfficeUserId === userId ||
        callStore.joiningRoomId === officeId;
      return (
        <JoinButton
          join={joinOwnOffice}
          joining={joining}
          sayStart={sayStart}
        />
      );
    }
  };

  const inACall =
    officeStore.currentSpaceId ||
    officeStore.currentCall ||
    officeStore.inOfficeSessionId;

  const renderPhoneIcon = () => {
    return (
      <div
        className={classnames(
          "button is-rounded is-small private-call-button",
          {
            ringing: ringing,
            "direct-call-button": ringing,
            "is-white": ringing,
            "is-outlined": !ringing,
            "is-sblue": !ringing,
          }
        )}
        onClick={() => {
          if (ringing) {
            officeStore.cancelCall();
          } else {
            requestCall();
          }
        }}
      >
        <span className="icon">
          <FontAwesomeIcon icon={faPhoneAlt} />
        </span>
        &nbsp; {ringing ? "" : "Private Call"}
      </div>
    );
  };

  const renderHangUpIcon = () => {
    return (
      <div
        className="button is-white hang-up-button"
        onClick={(e) => cancelCall(e)}
      >
        <span className="icon">
          <ImPhoneHangUp color="red" size="1rem" />
        </span>
      </div>
    );
  };

  const renderKnockingButtons = () => {
    return (
      <KnockButton
        knocking={knocking}
        knock={knock}
        cancelKnock={cancelKnock}
      />
    );
  };

  const renderOfficeJoinButton = () => {
    const joining =
      officeStore.joiningOfficeUserId === userId ||
      callStore.joiningRoomId === officeId;
    return <JoinButton join={joinOffice} joining={joining} />;
  };

  const renderJoinMeButton = () => {
    return (
      <>
        <div
          className={classnames("button is-buttongrey join-me-button", {
            "in-user-chat": userChat,
          })}
          disabled={showJoinMeSent ? true : false}
          onClick={(e) => sendJoinMe(e)}
        >
          {showJoinMeSent ? "Sent!" : "Join me!"}
        </div>
      </>
    );
  };

  const renderSecondAction = () => {
    if (ringing) {
      return renderHangUpIcon();
    }
    if (isDoorOpen && !alreadyInThisOffice) {
      return renderOfficeJoinButton();
    } else {
      return <KnockButton knocking={knocking} knock={knock} />;
    }
  };

  const renderDmCardActions = () => {
    const onAPrivateCall =
      callStore.sessionAttributes["session-type"] === "direct-call";
    if (inACall) {
      if (alreadyInRoom) {
        return (
          <>
            {ringing && renderHangUpIcon()}
            {!onAPrivateCall && renderPhoneIcon()}
          </>
        );
      } else if (alreadyInThisOffice) {
        return (
          <>
            {renderJoinMeButton()}
            {renderPhoneIcon()}
          </>
        );
      } else {
        return (
          <>
            {renderJoinMeButton()}
            {renderSecondAction()}
            {renderPhoneIcon()}
          </>
        );
      }
    } else if (!differentSession) {
      return (
        <>
          {renderSecondAction()}
          {renderPhoneIcon()}
        </>
      );
    }
  };

  const renderActions = () => {
    if (thisIsYourRow && !differentSession) {
      return renderYourOwnActions();
    }

    if (knocking) {
      return renderKnockingButtons();
    }

    if (userChat) {
      return renderDmCardActions();
    }

    if (ringing) {
      return renderHangUpIcon();
    }

    if (inACall) {
      return (
        <>
          {!alreadyInRoom
            ? renderJoinMeButton()
            : alreadyInThisOffice &&
              !hideHere && <div className="here-indicator">Here</div>}
        </>
      );
    } else {
      if (!differentSession) {
        return <>{renderSecondAction()}</>;
      }
    }
  };

  return <div className="user-participant-actions">{renderActions()}</div>;
}

export default observer(UserParticipantActions);
