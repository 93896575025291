import { observer } from "mobx-react-lite";
import AddRoomButtons from "../Office/AddRoomButtons";
import FoyerCard from "../Office/Foyer/FoyerCard";
import classnames from "classnames";
import { useStores } from "../../stores/utils";

const FoyerCardList = ({
  addRoom,
  createAuditorium,
  createRoom,
  groupList,
  onClickRoom,
  sessionId,
}) => {
  const { appStore, viewStore } = useStores();
  return (
    <>
      <div className="add-room-button-row">
        <AddRoomButtons
          addRoom={addRoom}
          sessionId={sessionId}
          createRoom={createRoom}
          createAuditorium={createAuditorium}
        />
      </div>
      <div
        className={classnames("columns is-multiline", {
          "with-side-menu": !viewStore.hideSideMenu,
          "without-side-menu": viewStore.hideSideMenu,
        })}
      >
        {groupList.map((room) => (
          <div className="column is-4" key={room.id}>
            <div
              className={classnames("box", "office-foyer-box", {
                "room-locked": room.attributes["locked"],
              })}
              onClick={(e) =>
                onClickRoom(
                  e,
                  room.id,
                  room.attributes["daily-url"],
                  appStore.appState
                )
              }
            >
              <FoyerCard
                key={room.id}
                room={room}
                joinButtonsDisabled={false}
                noActions
                isFoyer
                onClickRoom={onClickRoom}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default observer(FoyerCardList);
