/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { observable, action, makeObservable } from "mobx";
import { NO_OUTPUTS, NO_OUTPUTS_FIREFOX_MAC_SYSTEM } from "./constants";
import chromeMacInstructions from "../images/help/enable-cam-chrome-mac.png";
import safariMacInstructions from "../images/help/enable-cam-safari-mac.png";
import safariIosInstructions from "../images/help/enable-cam-safari-ios.png";
import safariIos2Instructions from "../images/help/enable-cam-safari-ios-2.png";
import edgePcInstructions from "../images/help/enable-cam-edge-pc.png";
import firefoxInstructions1 from "../images/help/enable-cam-ff-pc.png";
import iosSettings from "../images/help/iOS-Settings-icon.png";

export class NotificationModalStore {
  constructor(rootStores) {
    this.rootStores = rootStores;
    makeObservable(this);
  }

  @observable notificationMessage = null;

  @action
  setNotificationMessage = (message) => {
    this.notificationMessage = message;
  };

  @action
  clearNotificationMessage = () => {
    this.notificationMessage = null;
  };

  showCantFind = () => {
    return this.setNotificationMessage({
      title: "Can't find your camera and microphone",
      message: this.getOutputErrorMessage(),
      showTroubleshooting: true,
    });
  };

  getOutputErrorMessage = () => {
    if (this.rootStores.appStore.isChrome) {
      return (
        <div className="chrome-mac-instructions">
          <img className="chrome-mac-image" src={chromeMacInstructions} />
          <p className="modal-action-row">
            Then you'll be prompted to{" "}
            <a href="javascript:window.location.href=window.location.href">
              Reload
            </a>
          </p>
        </div>
      );
    } else if (this.rootStores.appStore.isSafari) {
      if (this.rootStores.appStore.isIos()) {
        return (
          <div className="safari-mac-instructions">
            Try{" "}
            <a href="javascript:window.location.href=window.location.href">
              refreshing
            </a>{" "}
            Sidebar. If prompted, allow Sidebar to use your camera and
            microphone.
            <p className="modal-action-row">
              If there’s no prompt, open your iPad's{" "}
              <img className="ios-settings" src={iosSettings} />{" "}
              <strong>Settings</strong> app and click <strong>Safari</strong>:
            </p>
            <p className="modal-action-row">
              <img className="safari-ios-image" src={safariIosInstructions} />
            </p>
            <p className="modal-action-row">
              Click <strong>Allow</strong> for both the Camera and Microphone.
            </p>
            <p className="modal-action-row">
              <img className="safari-ios2-image" src={safariIos2Instructions} />
            </p>
            <p className="modal-action-row">
              Then{" "}
              <a href="javascript:window.location.href=window.location.href">
                Refresh{" "}
              </a>
              Sidebar
            </p>
          </div>
        );
      } else {
        return (
          <div className="safari-mac-instructions">
            <p>
              Try{" "}
              <a href="javascript:window.location.href=window.location.href">
                refreshing
              </a>{" "}
              Sidebar. If prompted, allow Sidebar to use your camera and
              microphone.
            </p>
            <p className="modal-action-row">
              If there’s no prompt, try the following:
            </p>
            <p className="modal-action-row">
              <img className="safari-mac-image" src={safariMacInstructions} />
            </p>
            <p className="modal-action-row">
              Then{" "}
              <a href="javascript:window.location.href=window.location.href">
                Refresh{" "}
              </a>
              the page
            </p>
          </div>
        );
      }
    } else if (this.rootStores.appStore.isFirefox) {
      return (
        <div className="firefox-instructions">
          <img className="firefox-image-1" src={firefoxInstructions1} />
          <p className="modal-action-row">
            Then{" "}
            <a href="javascript:window.location.href=window.location.href">
              Reload the page
            </a>
            .
          </p>
          {this.rootStores.appStore.isMac && (
            <p className="modal-action-row">{NO_OUTPUTS_FIREFOX_MAC_SYSTEM}</p>
          )}
        </div>
      );
    } else if (this.rootStores.appStore.isEdge) {
      return (
        <div className="edge-pc-instructions">
          <img className="edge-pc-image" src={edgePcInstructions} />
          <p className="modal-action-row">
            Then you'll be prompted to{" "}
            <a href="javascript:window.location.href=window.location.href">
              Reload
            </a>
          </p>
        </div>
      );
    }

    return NO_OUTPUTS;
  };
}
