import { useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { useStores } from "../../stores/utils";
import "./DebugMenu.scss";

function DebugMenu() {
  const {
    callStateStore,
    callObjectStore,
    callStore,
    appStore,
    viewStore,
  } = useStores();
  const [automoving, setAutomoving] = useState(false);
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    if (!callObjectStore.callObject) {
      return;
    }
    callObjectStore.callObject.setBandwidth({
      kbs: parseInt(data.uploadBandwidthCap),
      trackConstraints: {
        width: parseInt(data.videoWidth),
        height: parseInt(data.videoHeight),
      },
    });
  };

  const toggleAutomoving = useCallback(() => {
    if (!automoving) {
      setAutomoving(true);
    } else {
      setAutomoving(false);
    }
  }, [setAutomoving, automoving]);

  const triggerFakeException2 = useCallback(() => {
    const mousepad = {};
    console.log(mousepad.material.this_is_a_test_error_ignore);
  }, []);

  const triggerUnhandledPromise = useCallback(() => {
    new Promise((resolve, reject) => {
      reject("this is a test unhandled promise! from debug menu");
    });
  }, []);

  const triggerErrorPopup = useCallback(() => {
    callStore.leaveCallAndLog(
      true,
      { response: { status: 400, message: "fake error" } },
      viewStore.showErrorToast
    );
  }, [callStore, viewStore.showErrorToast]);

  const addRoom = callStore.addRoom;
  const createThriftyRoom = useCallback(() => {
    addRoom(null, { thriftyRoom: true });
  }, [addRoom]);

  const autoMove = callStore.autoMove;
  useEffect(() => {
    if (!automoving) {
      return;
    }
    const id = setInterval(() => {
      autoMove();
    }, 15000);
    return function () {
      clearInterval(id);
    };
  }, [autoMove, automoving]);

  const triggerFakeError = useCallback(() => {
    callStateStore.setCamOrMicError("Fake error.");
  }, [callStateStore]);

  const triggerFakeException = useCallback(() => {
    throw new Error("Triggered fake error from call.");
  }, []);

  return (
    <div className="debug-console">
      <div>
        [{callStore.clusterParticipantIds.length}][
        {callStore.clusterParticipants.length}][
        {callStore.clusterlessParticipantIds.length}]
      </div>
      <div
        className={(automoving ? "is-warning " : "") + "button is-small"}
        onClick={() => toggleAutomoving()}
      >
        Automove
      </div>
      <div className="button is-small" onClick={() => callStore.simpleSwitch()}>
        Switch
      </div>
      <div className="button is-small" onClick={() => triggerFakeError()}>
        Error
      </div>
      <div className="button is-small" onClick={() => triggerFakeException()}>
        Exception
      </div>
      <div className="button is-small" onClick={() => triggerFakeException2()}>
        Exc 2
      </div>
      <div className="button is-small" onClick={() => triggerErrorPopup()}>
        Error Popup
      </div>
      <div
        className="button is-small"
        onClick={() => triggerUnhandledPromise()}
      >
        Unh Promise
      </div>
      <div className="button is-small" onClick={() => createThriftyRoom()}>
        + Thrify Room
      </div>
      <br />
      appState: {appStore.appState}
      <br />
      {process.env.REACT_APP_GIT_SHA}
      <br />
      <div className="joining-feeds-div">
        V: {callStore.videoCallItems && callStore.videoCallItems.length} A:{" "}
        {callStore.audioCallItems && callStore.audioCallItems.length}
        CS.CI:{" "}
        {callStateStore &&
          callStateStore.callItems &&
          Object.keys(callStateStore.callItems).length}
      </div>
      <br />
      <a href={callStore.roomUrl}>{callStore.roomUrl}</a>
      <br />P sb: <input value={callStore.participant?.id} readOnly />
      <br />P daily: <input value={callStore.participant?.dailyId} readOnly />
      <br />
      G: <input value={callStore.currentRoomId} readOnly />
      <br />
      S: <input value={callStore.sessionId} readOnly />
      <div className="upload-options">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* register your input into the hook by invoking the "register" function */}
          <div>
            <label>Uploap cap (kbps):</label>
            <input
              name="uploadBandwidthCap"
              defaultValue="1200"
              ref={register}
            />
          </div>
          <div>
            <label>Video width:</label>
            <input name="videoWidth" defaultValue="1280" ref={register} />
          </div>
          <div>
            <label>Video height:</label>
            <input name="videoHeight" defaultValue="720" ref={register} />
          </div>

          <input type="submit" />
        </form>
      </div>
    </div>
  );
}

export default observer(DebugMenu);
