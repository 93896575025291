/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "@reach/router";
import { useStores, observe } from "stores/utils";
import "./NotVerifiedModal.scss";

export const NotVerifiedModal = () => {
  const { viewStore, userStore } = useStores();

  return observe(userStore.userInfo, () => {
    if (userStore.userInfo?.confirmed) {
      viewStore.setGeneralNotification(null);

      return null;
    }

    return (
      <div className="modal-not-verified">
        <div className="modal-not-verified__title">
          Welcome! Let’s verify your email.
        </div>
        <div className="modal-not-verified__content">
          Check your inbox{" "}
          <div className="user-email">({userStore.userInfo?.email})</div> for a
          verification email.
        </div>
        <div
          className="modal-not-verified__resend"
          onClick={() => viewStore.setGeneralNotification(null)}
        >
          Didn’t receive it?{" "}
          <a
            onClick={userStore.resendConfirmationEmail}
            href="javascript:void(0);"
          >
            Resend
          </a>
          . Or, <Link to="/host/profile">edit your email</Link>.
        </div>
      </div>
    );
  });
};
