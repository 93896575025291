import { observer } from "mobx-react-lite";

import TeamMemberRow from "./TeamMemberRow";

const TeamMembersList = ({ teamStore }) => {
  return (
    <tbody>
      {teamStore.teamMembers.map((t) => (
        <TeamMemberRow key={t.id} teamMember={t} />
      ))}
    </tbody>
  );
};

export default observer(TeamMembersList);
