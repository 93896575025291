import { useEffect } from "react";
import "./LandingPage.css";
import { ReactComponent as SidebarLogo } from "../../images/SVG/sidebar-logo.svg";
import * as Amplitude from "../../utilities/amplitude";
import { navigate } from "@reach/router";

/**
 * Props:
 * - onClickLeaveCall: () => ()
 * - disabled: boolean
 */
export default function LandingPage(props) {
  useEffect(() => {
    Amplitude.track("visit landing page", { url: window.location.href });
  }, []);

  const toLogin = () => {
    navigate("/login");
  };

  return (
    <div className="landing">
      <div id="logo-container">
        <SidebarLogo />
        <h2>Less awkward video chat.</h2>
        <div>
          <button onClick={() => toLogin()}>Login</button>
        </div>
      </div>
    </div>
  );
}
