import { useState } from "react";
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";
import { buildInitials } from "../../../utilities/text";
import ParticipantListModal from "./ParticipantListModal";
import "tippy.js/themes/light.css";

const OfficeAvatar = ({ avatar, name, color, extraItems, groupId }) => {
  const [mountExtras, setMountExtras] = useState(false);
  const initials = buildInitials(name);
  const tooltip = extraItems ? "View all" : name;
  return (
    <div className="office-foyer__card__avatar__wrapper">
      <Tippy content={tooltip}>
        <div className="office-foyer__card__avatar_and_name_wrapper">
          <div className="office-foyer__card__avatar">
            {!extraItems && (
              <>
                {avatar ? (
                  <img
                    className="office-foyer__card__avatar__img"
                    src={avatar}
                    alt={avatar}
                    title={name}
                  />
                ) : (
                  <div
                    style={{ backgroundColor: color }}
                    className="office-foyer__card__avatar__initials"
                    title={name}
                  >
                    {initials}
                  </div>
                )}
              </>
            )}
            {extraItems && (
              <Tippy
                content={
                  mountExtras ? <ParticipantListModal groupId={groupId} /> : ""
                }
                trigger="click"
                placement="right"
                theme="light"
                onShow={() => setMountExtras(true)}
                onHide={() => setMountExtras(false)}
                interactive={true}
              >
                <div
                  style={{ backgroundColor: color }}
                  className="office-foyer__card__avatar__initials office-foyer__card__avatar__count"
                >
                  +{extraItems}
                </div>
              </Tippy>
            )}
          </div>

          <div className="office-foyer__card__avatar__name">{name}</div>
        </div>
      </Tippy>
    </div>
  );
};

OfficeAvatar.propTypes = {
  avatar: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default OfficeAvatar;
