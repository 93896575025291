import PropTypes from "prop-types";
import ReactModal from "react-modal";

import "./SubscriptionModal.scss";

export const CancelSubscriptionModal = ({
  handleCloseModal,
  onConfirm,
  message,
  title,
}) => {
  const submit = () => {
    onConfirm().then(() => {
      handleCloseModal();
    });
  };

  return (
    <ReactModal
      isOpen
      overlayClassName="ReactModal__Overlay__disabled"
      className="ReactModal__Content__notification"
    >
      <div className="modal-header">{title}</div>
      <div className="modal-content">{message}</div>
      <div className="modal-actions">
        <div onClick={handleCloseModal} className="modal-actions__cancel">
          Cancel
        </div>
        <div onClick={submit} className="modal-actions__confirm">
          Confirm
        </div>
      </div>
    </ReactModal>
  );
};

CancelSubscriptionModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
};

export default CancelSubscriptionModal;
