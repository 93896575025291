import TimeAgo from "javascript-time-ago";
import ReactTimeAgo from "react-time-ago";
import en from "javascript-time-ago/locale/en";
import "./ShowNotificationTime.scss";

TimeAgo.addDefaultLocale(en);

export default function ShowNotificationTime() {
  const now = new Date();
  return (
    <div className="notification-time-stamp">
      <ReactTimeAgo
        date={now}
        timeStyle="twitter-first-minute"
        locale="en-US"
      />
    </div>
  );
}
