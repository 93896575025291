import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import RoomRow from "../RoomRow/RoomRow";
import { useStores } from "../../stores/utils";
import EventHostMenu from "./EventHostMenu";
import AddRoom from "./AddRoom";

const RoomsMenu = ({ disabled, joinButtonsDisabled }) => {
  const { callStore } = useStores();
  const roomsMenuEl = useRef(null);
  const [focusedEditRoom, setFocusedEditRoom] = useState(null);

  return (
    <div className="rooms-menu" ref={roomsMenuEl}>
      <ul>
        {callStore.groupList.map((value) => (
          <li
            key={value.id}
            className={value.isAuditorium ? "auditorium-row" : undefined}
          >
            <RoomRow
              key={value.id}
              room={value}
              focusedEditRoom={focusedEditRoom}
              setFocusedEditRoom={setFocusedEditRoom}
            />
          </li>
        ))}
        <li className="add-room-button-row">
          <AddRoom />
          <EventHostMenu roomsMenuEl={roomsMenuEl} />
        </li>
      </ul>
    </div>
  );
};

export default observer(RoomsMenu);
