export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function buildInitials(name) {
  if (!name) return "";
  const names = name.split(" ");
  let initials = names[0]?.[0];
  if (names.length > 1) {
    initials = initials + names[names.length - 1][0];
  }
  return initials;
}
