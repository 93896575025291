import { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { Tooltip } from "react-tippy";
import "./StatusDropdown.scss";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import StatusDropdownItem from "./StatusDropdownItem";
import EventsSelect from "../EventsSelect";
import {
  adminStatus,
  eventHostStatus,
  managerStatus,
  memberStatus,
} from "../../stores/TeamStore";
import { getStatusLabel } from "../../stores/TeamMemberModel";

const StatusDropdown = ({
  sessionsStore,
  onRoleChange,
  role,
  permittedEvents,
  togglePermittedEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen((x) => !x);
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  // const debouncedCloseMenu = useDebounce(closeMenu, 1000, true);

  const handleManagerRoleSelect = useCallback(() => {
    onRoleChange("manager");
  }, [onRoleChange]);

  const handleAdminRoleSelect = useCallback(() => {
    onRoleChange("admin");
  }, [onRoleChange]);

  const handleEventHostRoleSelect = useCallback(() => {
    onRoleChange("host");
  }, [onRoleChange]);

  const handleMemberRoleSelect = useCallback(() => {
    onRoleChange("member");
  }, [onRoleChange]);

  return (
    <Tooltip
      open={isOpen}
      onRequestClose={closeMenu}
      trigger="click"
      theme="light"
      className="borderless-tooltip"
      position="bottom"
      animateFill={false}
      interactive
      appendTo="parent"
      duration={0}
      hideDelay={0}
      html={
        <div className="StatusDropdown-menu">
          <ul>
            <li>
              <StatusDropdownItem
                onClick={handleAdminRoleSelect}
                isSelected={role === adminStatus}
                label={getStatusLabel(adminStatus)}
                description={
                  "Can change all account settings, and invite new members to the organization. Can host all events."
                }
              />
            </li>

            <li>
              <StatusDropdownItem
                onClick={handleManagerRoleSelect}
                isSelected={role === managerStatus}
                label={getStatusLabel(managerStatus)}
                description={
                  "Cannot change account settings, or invite new members to the organization. Can host all events."
                }
              />
            </li>

            <li>
              <StatusDropdownItem
                onClick={handleEventHostRoleSelect}
                isSelected={role === eventHostStatus}
                label={getStatusLabel(eventHostStatus)}
                description={
                  "Can only host designated events. Cannot see any account settings or billing information."
                }
              />
            </li>

            <li>
              <StatusDropdownItem
                onClick={handleMemberRoleSelect}
                isSelected={role === memberStatus}
                label={getStatusLabel(memberStatus)}
                description={"Cannot host events. Has no special privileges."}
              />
            </li>
          </ul>

          {role === eventHostStatus && (
            <EventsSelect
              sessionsStore={sessionsStore}
              onChange={togglePermittedEvent}
              value={permittedEvents}
            />
          )}
        </div>
      }
    >
      <div
        className="button is-text"
        aria-haspopup="true"
        aria-controls="dropdown-menu7"
        onClick={toggleOpen}
      >
        <div className="teammember-role-content">
          <span>{getStatusLabel(role)}</span>
          <div>{isOpen ? <BsChevronUp /> : <BsChevronDown />}</div>
        </div>
      </div>
    </Tooltip>
  );
};

export default observer(StatusDropdown);
