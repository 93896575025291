import { observer } from "mobx-react-lite";
import { MdRefresh } from "react-icons/md";
import Tippy from "@tippyjs/react";
import TrayButton from "../TrayButton/TrayButton";

const RefreshButton = ({ disabled, toggleRefresh, theme }) => {
  return (
    <Tippy content="Improve Connection" appendTo="parent" theme={theme}>
      <div>
        <TrayButton
          disabled={disabled}
          onClick={toggleRefresh}
          extraClasses="refresh-button"
        >
          <MdRefresh color="#fff" size="28px" />
        </TrayButton>
      </div>
    </Tippy>
  );
};

export default observer(RefreshButton);
