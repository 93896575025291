import { observer } from "mobx-react-lite";
import { useStores } from "../../../stores/utils";
import { useQuery } from "react-query";
import classnames from "classnames";

const SlackIntegration = () => {
  const { api, userStore } = useStores();
  const organizationId = userStore.organizationId;
  const organizationData = useQuery(["organization", organizationId], () =>
    api.getOrganization()
  );

  const slackInstallUrl = process.env.REACT_APP_SLACK_INSTALL_URL;
  const slackUrlWithState = slackInstallUrl + `&state=${userStore.userId}`;

  const slackTeamId =
    organizationData?.data?.data?.data?.attributes?.["slack-team-id"];

  let urlForLink = slackUrlWithState;
  if (slackTeamId) {
    urlForLink = "";
  }

  return (
    <div className="content">
      <h1>Slack</h1>

      {organizationData.isSuccess && (
        <div>
          {!slackTeamId && (
            <p>
              This will connect your Sidebar organization with Slack, allowing
              you to call and invite teammates even when they have Sidebar
              closed.
            </p>
          )}
          <a
            href={urlForLink}
            className={classnames({ "slack-app-installed": slackTeamId })}
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
          {slackTeamId && (
            <div>
              <p>
                Status: <strong>Installed</strong>
              </p>
              <p>
                To remove the app, follow{" "}
                <a href="https://slack.com/help/articles/360003125231-Remove-apps-and-custom-integrations-from-your-workspace#remove-an-app">
                  these instructions
                </a>{" "}
                from Slack.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(SlackIntegration);
