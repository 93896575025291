import twemoji from "twemoji";
import ReactHtmlParser from "react-html-parser";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import "tippy.js/themes/light-border.css";

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";

import ReadReciept from "../ReadReciept";

import { parseUrls } from "../../util/parser";

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageTxtWrapperStyle,
  messageTxtStyle,
} from "./style";

const SenderMessageBubble = (props) => {
  const urlsParsedMessage = parseUrls(props.message.text);
  const emojiParsedMessage = twemoji.parse(urlsParsedMessage, {
    folder: "svg",
    ext: ".svg",
  });
  const parsedMessage = ReactHtmlParser(emojiParsedMessage);
  const emojiMessage = parsedMessage.filter(
    (message) => message instanceof Object && message.type === "img"
  );

  let showVariation = true;
  if (
    props.hasOwnProperty("widgetsettings") &&
    props.widgetsettings &&
    props.widgetsettings.hasOwnProperty("main") &&
    props.widgetsettings.main.hasOwnProperty("show_emojis_in_larger_size") &&
    props.widgetsettings.main["show_emojis_in_larger_size"] === false
  ) {
    showVariation = false;
  }

  return (
    <div css={messageContainerStyle()}>
      <div css={messageWrapperStyle()}>
        <Tippy
          placement="left"
          theme="light-border"
          interactive={true}
          content={<ReadReciept theme={props.theme} {...props} />}
        >
          <div css={messageTxtWrapperStyle(props)}>
            <p
              css={messageTxtStyle(parsedMessage, emojiMessage, showVariation)}
            >
              {parsedMessage}
            </p>
          </div>
        </Tippy>
      </div>
    </div>
  );
};

export default SenderMessageBubble;
