import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import ClusterPreview from "./ClusterPreview";
import "./ClusterList.scss";

function ClusterList() {
  const { callStore } = useStores();

  const clusterList = callStore.otherClusters;
  const nonEmptyClusters = clusterList.filter((cluster) => !cluster.empty);

  return (
    <div className="cluster-list">
      {callStore.clusterlessParticipants.length > 0 && (
        <ClusterPreview
          clusterId="null-cluster"
          participants={callStore.clusterlessParticipants}
          key="null-cluster"
        />
      )}
      {nonEmptyClusters.map((cluster) => {
        return (
          <ClusterPreview
            clusterId={cluster.id}
            participants={cluster.participants()}
            key={cluster.id}
          />
        );
      })}
    </div>
  );
}

export default observer(ClusterList);
