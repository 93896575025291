import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { useStores } from "stores/utils";
import api from "../../api";
import classnames from "classnames";
import "./AcceptInviteForm.scss";

const AcceptInviteForm = (props) => {
  const { userStore } = useStores();
  const { register, handleSubmit, errors, setError } = useForm();
  const [resetLoading, setResetLoading] = useState(false);
  const [errors2, setErrors2] = useState(false);
  const [invitationData, setInvitationData] = useState({});
  const [checked, setChecked] = useState(false);

  const navigate = props.navigate;

  const focusRef = useCallback(
    (inputElement) => {
      register(inputElement, { required: true });
      if (inputElement) {
        inputElement.focus();
      }
    },
    [register]
  );

  useEffect(() => {
    const func = async () => {
      try {
        const info = await api.getInvitationInfo(props.inviteToken);
        setInvitationData(info.data);
      } catch (e) {
        console.log("Oops, this invitation has already been accepted.");
        window.location.href = "/login/accepted";
        return;
      }
    };
    func();
  }, [props.inviteToken]);

  console.log("form errors: ", errors);

  const onSubmit = useCallback(
    async (credentials) => {
      setErrors2(false);
      setResetLoading(true);
      try {
        await api.acceptOfficeInvitation(
          props.inviteToken,
          credentials.full_name,
          credentials.password
        );
      } catch (error) {
        const errorObj = error.response?.data?.details;
        if (errorObj) {
          for (const property in errorObj) {
            setError(property, {
              type: "manual",
              message: error.response.data.error,
            });
          }
        }
        return;
      }

      const loginCredentials = {
        email: invitationData.email,
        password: credentials.password,
      };
      await userStore.login(loginCredentials);

      await new Promise((r) => setTimeout(r, 700));
      setResetLoading(false);
      navigate("/office/setup");
    },
    [userStore, invitationData, navigate, props.inviteToken, setError]
  );

  const inviterFirstName = useMemo(() => {
    if (!invitationData || !invitationData.inviter) {
      return;
    }
    return invitationData.inviter.name.split(" ")[0];
  }, [invitationData]);

  const handleInputChange = useCallback(
    (event) => {
      setChecked(event.target.checked);
    },
    [setChecked]
  );

  console.log("form errors: ", errors);
  return (
    <div className="accept-invite-form">
      <>
        <h1 className="title is-4 is-size-4-mobile">
          {inviterFirstName} created an account for you in{" "}
          {invitationData?.organization?.name}
        </h1>

        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            {errors2 && (
              <div className="has-text-danger has-text-weight-bold">
                There was a problem accepting the invitation.
              </div>
            )}
            <div className="field">
              <label className="label">Full name</label>
              <div className="control">
                <input
                  name="full_name"
                  className="input"
                  type="text"
                  placeholder="Your name"
                  ref={focusRef}
                />
                {errors.full_name && (
                  <div className="message-row has-text-danger">
                    Name is required
                  </div>
                )}
              </div>
            </div>
            <div className="field">
              <label className="label">Password</label>
              <div className="control">
                <input
                  name="password"
                  type="password"
                  className="input"
                  placeholder="Create password"
                  ref={register({
                    required: "Password is required",
                  })}
                />
                {errors.password && (
                  <div className="message-row has-text-danger">
                    {errors.password.message}
                  </div>
                )}
              </div>
            </div>
            <div className="field i-agree-field">
              <div className="control">
                <label className="checkbox i-agree-content">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleInputChange}
                  />{" "}
                  <div className="i-agree-text">
                    I agree to the{" "}
                    <a
                      href="https://office.sidebar.vc/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <nobr>
                      <a
                        href="https://office.sidebar.vc/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </nobr>
                  </div>
                </label>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button
                  className={classnames("button get-started-button", {
                    "is-buttongrey": !checked,
                    "is-link": checked,
                  })}
                  disabled={!checked}
                >
                  Create Account
                </button>
                {resetLoading && <span className="reset-loading" />}
              </div>
            </div>
          </form>
        </>
      </>
    </div>
  );
};

export default observer(AcceptInviteForm);
