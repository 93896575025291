import { useRef } from "react";
import { observer } from "mobx-react-lite";
import Tile from "../Tile/Tile";

const SmallTilesList = ({ smallTiles }) => {
  const scrollRef = useRef(null);

  return (
    <div className="small-tiles-scroller" ref={scrollRef}>
      <div className="small-tiles">
        {smallTiles.map((t) => (
          <Tile key={t.key} tile={t} positionTippyTop={true} />
        ))}
      </div>
    </div>
  );
};

export default observer(SmallTilesList);
