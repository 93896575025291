import PropTypes from "prop-types";
import moment from "moment";
import Tippy from "@tippyjs/react";
import { FaTrashRestore } from "react-icons/fa";

export const BannedUsersTable = ({ bannedUsers, onUnbanUser }) => {
  return (
    <table className="table is-striped is-fullwidth">
      <thead>
        <tr>
          <th>Participant Name</th>
          <th>Session</th>
          <th>Banned by</th>
          <th>Ban date</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        {bannedUsers.map((bannedUser) => (
          <tr key={bannedUser.id}>
            <td>{bannedUser.attributes?.["participant-name"]}</td>
            <td>{bannedUser.attributes?.["session-name"]}</td>
            <td>{bannedUser.attributes?.["banned-by-email"]}</td>
            <td>
              {moment(bannedUser.attributes?.["created-at"]).format("LLL")}
            </td>
            <td>
              <Tippy content="Unban user">
                <div
                  className="unban-user"
                  onClick={() => onUnbanUser(bannedUser.id)}
                >
                  <FaTrashRestore size={18} />
                </div>
              </Tippy>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

BannedUsersTable.propTypes = {
  bannedUsers: PropTypes.array.isRequired,
  onUnbanUser: PropTypes.func.isRequired,
};
