import { useState, useCallback } from "react";
import classnames from "classnames";
import { observer } from "mobx-react-lite";

const STATE_SUBMITTING = "STATE_SUBMITTING";
const STATE_SUBMITTED = "STATE_SUBMITTED";

const ActionMenuItem = ({
  label,
  onClick,
  icon,
  hideSending,
  stepTexts,
  bottomAlignIcon,
}) => {
  const [currentState, setCurrentState] = useState(null);

  const getLabel = () => {
    let step1 = "Sending ...";
    let step2 = "Sent!";
    if (stepTexts) {
      step1 = stepTexts[0];
      step2 = stepTexts[1];
    }

    if (currentState === STATE_SUBMITTING) {
      return step1;
    } else if (currentState === STATE_SUBMITTED) {
      return step2;
    }

    return label;
  };

  const onItemClick = useCallback(() => {
    if (hideSending) {
      return onClick();
    }

    setCurrentState(STATE_SUBMITTING);
    onClick();

    setTimeout(() => {
      setCurrentState(STATE_SUBMITTED);
      setTimeout(() => {
        setCurrentState(null);
      }, 1500);
    }, 400);
  }, [hideSending, onClick]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onItemClick();
      }}
      className={classnames(
        "action-menu-item",
        bottomAlignIcon ? "bottom-align-icon" : ""
      )}
    >
      {icon} {getLabel()}
    </div>
  );
};

export default observer(ActionMenuItem);
