import { useState, useEffect } from "react";
import { calculateLocalTime } from "./utils";

function LocalTime({ prefetchedLocalTime, timezone }) {
  const [currentLocalTime, setCurrentLocalTime] = useState(prefetchedLocalTime);

  useEffect(() => {
    const interval = setInterval(() => {
      const localTime = calculateLocalTime(timezone);
      setCurrentLocalTime(localTime);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timezone]);

  return <div>{currentLocalTime}</div>;
}

export default LocalTime;
