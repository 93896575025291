import { ReactComponent as BlueSidebarLogo } from "../../images/SVG/blue_logo.svg";
import "./ErrorPage.scss";

export default function ErrorPage({ status }) {
  return (
    <div className="plain-grey-page error-page">
      <div className="title-row">
        <a href="https://get.sidebar.vc">
          <div className="title-image blue-logo">
            <BlueSidebarLogo />
          </div>
        </a>
      </div>

      <section className="section">
        <div className="container">
          <article className="message error-page-message">
            <div className="message-body">
              {" "}
              {status === 403 ? (
                <>
                  <h1 className="title">403</h1>Sorry, you don't have permission
                  to see this page.
                </>
              ) : (
                <>
                  <h1 className="title">404</h1>Sorry, we couldn't find this
                  page.
                </>
              )}
            </div>
          </article>
        </div>
      </section>
    </div>
  );
}
