import { observer } from "mobx-react-lite";
import Mute from "./Mute";
import DebugComponent from "./DebugComponent";
import Name from "./Name";
import CameraBlockedIcon from "../CameraBlockedIcon/CameraBlockedIcon";

function ControlsOverlay({
  tile,
  positionTippyTop,
  isBlocked,
  blockedReasons,
  size,
}) {
  return (
    <div
      className="controls-overlay"
      style={{
        width: size?.width,
        height: size?.height,
        margin: size && "auto",
      }}
    >
      <DebugComponent callItem={tile.callItem} />
      {!tile.isScreen && (
        <>
          <Name
            tile={tile}
            callItem={tile.callItem}
            positionTippyTop={positionTippyTop}
          />
          {isBlocked ? (
            <div className="camera-blocked-indicator">
              {tile.callItem.isLocal ? (
                <CameraBlockedIcon reasons={blockedReasons} />
              ) : (
                <Mute tile={tile} />
              )}
            </div>
          ) : (
            <Mute tile={tile} />
          )}
        </>
      )}
    </div>
  );
}

export default observer(ControlsOverlay);
