import { action, computed, makeObservable, observable } from "mobx";
import { httpService } from "utilities/HttpService";

export class ExtraMenuStore {
  @observable isMenuOpen = false;

  constructor(rootStores) {
    this.rootStores = rootStores;
    makeObservable(this);
  }

  @action.bound toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  @action.bound hideMenu() {
    this.isMenuOpen = false;
  }

  @computed
  get showButton() {
    return this.rootStores.callStore.isEventHost;
  }

  deleteCurrentSession = async () => {
    const sessionId = this.rootStores.callStore.sessionId;

    try {
      await httpService.delete(`/api/v1/sessions/${sessionId}`);
    } catch (err) {
      console.log(err, "error while deleting session");
    }
  };

  handleLeaveAndExitClick = async () => {
    try {
      await this.deleteCurrentSession();
    } catch (e) {
      console.log(e);
    } finally {
      this.hideMenu();
    }
  };
}
