import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { useStores } from "stores/utils";
import CallPreview from "./CallPreview";

import "./CallPreview.scss";

const CallPreviewModal = ({ onCancel, onJoin, videoOffMessage }) => {
  const { callPreviewStore } = useStores();

  const onModalClose = () => {
    callPreviewStore.stopAndCleanup();
    onCancel();
  };

  return (
    <ReactModal
      isOpen
      className="ReactModal__Content__preview__modal"
      overlayClassName="Overlay__preview__modal"
      onRequestClose={onModalClose}
    >
      <CallPreview onJoin={onJoin} videoOffMessage={videoOffMessage} />
    </ReactModal>
  );
};

CallPreviewModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onJoin: PropTypes.func.isRequired,
};

export default observer(CallPreviewModal);
