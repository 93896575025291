import { action, observable, makeObservable, computed } from "mobx";
import { TeamMemberModel } from "./TeamMemberModel";
import _ from "lodash";

export const adminStatus = "admin";
export const superuserStatus = "superuser";
export const managerStatus = "manager";
export const userStatus = "user";
export const invitedStatus = "invited";
export const eventHostStatus = "host";
export const memberStatus = "member";

export class TeamStore {
  @observable.shallow teamMembers = [];
  @observable.shallow invitations = [];
  @observable isLoading = false;
  @observable isShowCreateModal = false;
  @observable createInvitationError = null;
  @observable.shallow hostPermissions = [];

  allowedStatuses = [
    adminStatus,
    managerStatus,
    eventHostStatus,
    memberStatus,
    superuserStatus,
  ];

  constructor(rootStores) {
    this.rootStores = rootStores;

    makeObservable(this);
  }

  init = () => {
    this.fetchData();
  };

  @action.bound
  toggleShowCreateModal() {
    if (!this.rootStores.userStore.isVerified) {
      return this.rootStores.viewStore.showNotVerifiedModal();
    }

    this.isShowCreateModal = !this.isShowCreateModal;
    this.createInvitationError = null;
  }

  @action.bound
  setHostPermissions(data) {
    this.hostPermissions.replace(data);
  }

  reloadHostPermissions = async () => {
    const permissionsData = await this.rootStores.api.getHostPermissions(
      this.rootStores.userStore.organizationId
    );

    this.setHostPermissions(permissionsData.data);
  };

  fetchData = async () => {
    try {
      this.setLoading(true);

      const permissionsData = await this.rootStores.api.getHostPermissions(
        this.rootStores.userStore.organizationId
      );

      this.setHostPermissions(permissionsData.data);

      const usersPromise = this.rootStores.api.getUsers(
        this.rootStores.userStore.organizationId
      );

      const invitationsPromise = this.rootStores.api.getInvitations(
        this.rootStores.userStore.organizationId
      );

      const users = await usersPromise;
      const invitations = await invitationsPromise;

      // this.setTeamMembers([
      //   ...users.data.map(this.createTeamMember),
      //   ...invitations.data.map(this.createInvitation),
      // ]);

      const userObjects = users.data.map(this.createTeamMember);
      const invitationObjects = invitations.data.map(this.createInvitation);
      const sortedUsers = _.sortBy(userObjects, ["status", "name", "email"]);
      const sortedInvitations = _.sortBy(invitationObjects, [
        "status",
        "name",
        "email",
      ]);
      this.setTeamMembers([...sortedUsers, ...sortedInvitations]);
    } catch (e) {
      console.log(e);
    } finally {
      this.setLoading(false);
    }
  };

  createInvitation = (data) => {
    return new TeamMemberModel(
      {
        id: data.id,
        email: data.attributes.email,
        name: data.attributes["full-name"] || data.attributes["first-name"],
        invitedBy: data.attributes["invited-by-user"],
        status: data.attributes["role"],
        invitation: true,
      },
      this
    );
  };

  createTeamMember = (data) => {
    return new TeamMemberModel(
      {
        id: data.id,
        email: data.attributes.email,
        name: data.attributes["full-name"] || data.attributes["first-name"],
        invitedBy: "",
        status: data.attributes.role || userStatus,
      },
      this
    );
  };

  inviteNewMember = async (data) => {
    const {
      teammate_email,
      teammate_name,
      teammate_role,
      permitted_events,
      for_office,
    } = data;
    try {
      this.setLoading(true);
      const resp = await this.rootStores.api.createInvitation({
        email: teammate_email,
        firstName: teammate_name,
        role: teammate_role,
        lastName: "",
        permittedEvents: permitted_events,
        forOffice: for_office,
      });

      if (resp.error) {
        this.createInvitationError = resp.error.response.data.error;

        return;
      }

      const memberData = {
        id: Math.random(),
        attributes: {
          email: teammate_email,
          "full-name": teammate_name,
          "invited-by-user": this.rootStores.userStore.userInfo?.email,
        },
      };

      this.addTeamMember(this.createInvitation(memberData));
      this.toggleShowCreateModal();
      this.fetchData();
    } catch (e) {
      console.log(e);
    } finally {
      this.setLoading(false);
    }
  };

  deleteTeamMember = async (teamMember) => {
    try {
      this.setLoading(true);

      if (teamMember.invitation) {
        await this.rootStores.api.deleteTeamMemberInvitation(teamMember.id);
      } else {
        await this.rootStores.api.deleteUser(teamMember.id);
      }

      this.removeTeamMember(teamMember);
    } catch (e) {
      console.log(e);
    } finally {
      this.setLoading(false);
    }
  };

  @action.bound
  removeTeamMember(teamMember) {
    this.teamMembers.remove(teamMember);
  }

  @action.bound
  setTeamMembers(teamMembers) {
    this.teamMembers.replace(teamMembers);
  }

  @action.bound
  setLoading(value) {
    this.isLoading = value;
  }

  @action.bound
  addTeamMember(teamMember) {
    this.teamMembers.push(teamMember);
  }

  @computed
  get adminsCount() {
    return this.teamMembers.filter((x) => x.status === adminStatus).length;
  }

  bulkInvite = async (data) => {
    this.setLoading(true);
    try {
      const emails = data.email_list
        .split("\n")
        .filter((email) => email.length > 0);
      const params = { forOffice: data.for_office, emailList: emails };
      await this.rootStores.api.bulkInvite(params);
      this.toggleShowCreateModal();
      this.fetchData();
      this.rootStores.queryClient.refetchQueries("failedInvitations");
    } catch (e) {
    } finally {
      this.setLoading(false);
    }
  };
}
