import { ReactComponent as SidebarLogo } from "../../images/SVG/sidebar-logo.svg";
import "./EndedEventScreen.scss";

const EndedEventScreen = () => {
  return (
    <div className="app white-app-bg ended-event">
      <div className="title-row title-row--EndedEventScreen">
        <a href="https://get.sidebar.vc">
          <div className="title-image title-image--EndedEventScreen">
            <SidebarLogo />
          </div>
        </a>
      </div>
      <h1 className="page-title">The host ended the event.</h1>
    </div>
  );
};

export default EndedEventScreen;
