import { observer } from "mobx-react-lite";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import "./CameraBlockedIcon.scss";

const CameraBlockedIcon = ({ reasons }) => {
  console.log("Reason: ");
  const reasonText =
    "You webcam has an error. You may not have allowed your webcam, or other software on your computer may still be using it.";
  return (
    <div className="camera-blocked-wrapper">
      <Tippy content={reasonText}>
        <div className="camera-blocked-wrapper--exclamation">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
      </Tippy>
    </div>
  );
};

export default observer(CameraBlockedIcon);
