import { useState } from "react";
import { observer } from "mobx-react-lite";
import HostLoginForm from "./HostLoginForm";
import ForgotPassword from "../Forgot/ForgotPassword";

function HostLoginModal(props) {
  const [showForgot, setShowForgot] = useState(false);

  const goToForgot = () => {
    setShowForgot(true);
  };

  const goToLogin = () => {
    setShowForgot(false);
  };

  return (
    <div className="login-modal-padding">
      {showForgot ? (
        <ForgotPassword goToLogin={goToLogin} />
      ) : (
        <HostLoginForm
          {...props}
          goToForgot={goToForgot}
          redirectDestination={props.redirectDestination}
          hideHostTitle={props.hideHostTitle}
        />
      )}
    </div>
  );
}

export default observer(HostLoginModal);
