import { useState, useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/utils";
import classnames from "classnames";
import { isValidUrl } from "../../utilities/dom";
import "./LinkModal.scss";

const LinkModal = ({ isUpdate, linkId }) => {
  const { docStore, userStore } = useStores();

  const [linkName, setLinkName] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [isError, setIsError] = useState(false);

  const linkNameField = useRef();
  const linkUrlField = useRef();

  const guestToken =
    !userStore.userId && localStorage.getItem("participant-token");

  const linkNameChange = (event) => {
    setLinkName(event.target.value);
  };

  const linkUrlChange = (event) => {
    setLinkUrl(event.target.value);
  };

  useEffect(() => {
    if (!isUpdate) {
      linkUrlField.current.focus();
      return;
    }
    if (!linkId) return;
    docStore.getLink(linkId, guestToken).then((link) => {
      setLinkName(link.data.data?.attributes?.name);
      setLinkUrl(link.data.data?.attributes?.url);
      linkUrlField.current.select();
    });
  }, [isUpdate, linkId, docStore, guestToken]);

  const validateUrl = useCallback(() => {
    if (isValidUrl(linkUrl)) return true;
    linkUrlField.current.value = "";
    linkUrlField.current.style.borderColor = "#f00e03";
    setIsError(true);
    return false;
  }, [linkUrl]);

  const updateLink = useCallback(() => {
    if (!validateUrl()) return;
    const attributes = { url: linkUrl, name: linkName };
    docStore.updateLink(linkId, attributes, guestToken);
  }, [linkUrl, linkName, docStore, linkId, guestToken, validateUrl]);

  const createLink = useCallback(() => {
    if (!validateUrl()) return;
    docStore.createLink({ url: linkUrl }, guestToken);
  }, [linkUrl, docStore, guestToken, validateUrl]);

  const deleteLink = useCallback(() => {
    docStore.deleteLink(linkId, guestToken);
  }, [docStore, linkId, guestToken]);

  const pressEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        isUpdate ? updateLink() : createLink();
      }
    },
    [createLink, isUpdate, updateLink]
  );

  return (
    <div
      className={classnames("doc-settings-modal", "content", "link-modal", {
        "modal-padding": true,
      })}
    >
      <h3>{isUpdate ? "Update Link" : "Add a new link"}</h3>

      <div className="field">
        {isUpdate && (
          <p className="control is-expanded">
            <input
              className="input"
              type="text"
              placeholder="Link name (optional)"
              value={linkName}
              ref={linkNameField}
              onChange={linkNameChange}
              onKeyDown={pressEnter}
            />
          </p>
        )}

        <p className="control is-expanded">
          <input
            className="input"
            type="text"
            placeholder="Paste valid url"
            value={linkUrl}
            ref={linkUrlField}
            onChange={linkUrlChange}
            onKeyDown={pressEnter}
          />
        </p>

        {isError && (
          <p className="help is-sred">
            Invalid URL. Note: must begin with http:// or https://
          </p>
        )}

        <p className="control">
          <button
            className="button is-sblue"
            onClick={isUpdate ? updateLink : createLink}
          >
            Save
          </button>
        </p>
      </div>
      {isUpdate && (
        <>
          <hr />
          <h3>Delete</h3>
          <div className="control">
            <p>This will remove the link forever.</p>
            <button className="button is-sred" onClick={deleteLink}>
              Delete link
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(LinkModal);
