import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ChatWidget from "../ChatWidget/ChatWidget";

import { getComponent } from "../CometChat/components/MessageList/utils";

import { useStores } from "../../stores/utils";

const ChatMessages = ({ person, closeUserChat }) => {
  const { chatStore, userChatStore } = useStores();

  let indexModifier = 0;
  if (chatStore.newMessageNotificationList.length) {
    indexModifier = indexModifier + 1;
  }
  if (chatStore.groupChats.length) {
    indexModifier = indexModifier + 1;
  }

  const openChat = useCallback(() => {
    chatStore.openChat({
      type: "group",
      key: "group",
    });
  }, [chatStore]);

  return (
    <>
      <div id="message-notification-container">
        <TransitionGroup className="message-notification-list">
          {chatStore.newMessageNotificationList.map((message) => (
            <CSSTransition
              key={message.id}
              timeout={500}
              classNames="message-notification-item"
            >
              <div className="box" onClick={openChat}>
                {getComponent(message)}
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
      <div className="dm-chat-portal">
        {person ? (
          userChatStore.userChat.map((item) => (
            <ChatWidget
              isOpen
              key={item.key}
              item={item}
              type="user"
              person={person}
              closeUserChat={closeUserChat}
            />
          ))
        ) : (
          <>
            {chatStore.groupChats.map((item) => (
              <ChatWidget isOpen key={item.key} item={item} type="group" />
            ))}
            {chatStore.directChats.map((item, index) => (
              <ChatWidget
                isOpen
                key={item.key}
                item={item}
                index={index + indexModifier}
                type="user"
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default observer(ChatMessages);
