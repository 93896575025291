import { observer } from "mobx-react-lite";
import RedButton from "../RedButton/RedButton";

import "./ExtraMenu.scss";

const ExtraMenu = ({ store }) => {
  return (
    <div className="is-info extra-menu extra-menu__box">
      <h4 className="extra-menu__title">End event</h4>
      <div className="field">
        You're about to end the event for everyone and delete the event link.
      </div>
      <div className="field is-grouped">
        <div className="control">
          <RedButton handleClick={store.handleLeaveAndExitClick} label={"Ok"} />
        </div>
        <div className="control">
          <span className="button is-danger is-light" onClick={store.hideMenu}>
            Cancel
          </span>
        </div>
      </div>
    </div>
  );
};

export default observer(ExtraMenu);
