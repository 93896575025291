import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import classnames from "classnames";
import { observe, useStores } from "../../stores/utils";

import "./NotificationModal.scss";

const NotificationModal = observer(
  ({
    cancelLabel = "Dismiss",
    title,
    message,
    handleCloseModal,
    showInCorner = false,
    showTroubleshooting = false,
  }) => {
    return (
      <ReactModal
        isOpen
        overlayClassName="ReactModal__Overlay__disabled"
        className={classnames("ReactModal__Content__notification", {
          ReactModal__Content__corner: showInCorner,
        })}
      >
        <div className="modal-header">{title}</div>
        <div className="modal-content">{message}</div>
        <div className="modal-actions">
          <div onClick={handleCloseModal} className="modal-actions__cancel">
            {cancelLabel}
          </div>
          {showTroubleshooting && (
            <a
              className="modal-actions__troubleshoot"
              href="https://www.notion.so/Troubleshooting-7cfde550f53441f1ac59c91d9828ad15"
              target="_blank"
              rel="noreferrer noopener"
            >
              Learn More
            </a>
          )}
        </div>
      </ReactModal>
    );
  }
);

NotificationModal.propTypes = {
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  cancelLabel: PropTypes.string,
};

const NotificationModalWrapper = () => {
  const { notificationModalStore, callStore } = useStores();
  return observe(notificationModalStore.notificationMessage, () => {
    if (!notificationModalStore.notificationMessage) {
      return null;
    }

    if (notificationModalStore.notificationMessage.hideIfSwitchingRooms) {
      if (callStore.joiningRoomId) return null;
    }

    return (
      <NotificationModal
        cancelLabel="Dismiss"
        title={notificationModalStore.notificationMessage.title}
        message={notificationModalStore.notificationMessage.message}
        handleCloseModal={notificationModalStore.clearNotificationMessage}
        showTroubleshooting={
          notificationModalStore.notificationMessage.showTroubleshooting
        }
        showInCorner={notificationModalStore.notificationMessage.showInCorner}
      />
    );
  });
};

export default observer(NotificationModalWrapper);
