import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import ReactModal from "react-modal";
import { useForm } from "react-hook-form";
import { useStores } from "../../../stores/utils";
import "./CreateCallModal.scss";

function CreateSpaceModal(props) {
  const { officeStore } = useStores();
  const [callEventName, setCallEventName] = useState("");
  const { register, handleSubmit } = useForm();
  const callbackRef = useCallback(
    (inputElement) => {
      register(inputElement, { required: true });
      if (inputElement) {
        inputElement.focus();
      }
    },
    [register]
  );

  const onSubmit = (values) => {
    values.isSpace = true;
    officeStore.createSpace(values);
    props.toggleShowCreateSpace();
  };

  return (
    <ReactModal
      isOpen={props.showCreateSpace}
      onRequestClose={() => {
        props.toggleShowCreateSpace();
      }}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      className="custom-modal"
      overlayClassName="modal-background"
    >
      {" "}
      aaaa
      <div className="modal-content create-call-modal">
        <div className="box is-info">
          <h1 className="title is-5">Create Space</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  name="call_name"
                  type="text"
                  placeholder="Name of the Space"
                  ref={callbackRef}
                  onChange={(event) => setCallEventName(event.target.value)}
                />
              </div>
            </div>
            <div className="field is-grouped">
              <div className="control">
                <input
                  disabled={!callEventName.length || props.creatingCall}
                  type="submit"
                  className="button is-link"
                  value="Create"
                />
              </div>
              <div className="control">
                {/* eslint-disable-next-line */}
                <a
                  className="button is-link is-light"
                  onClick={() => props.toggleShowCreateSpace()}
                >
                  Cancel
                </a>
              </div>
              {props.creatingCall && (
                <div className="create-call-loading"></div>
              )}
            </div>
          </form>
        </div>
      </div>
    </ReactModal>
  );
}
export default observer(CreateSpaceModal);
