import { useState, useEffect, useCallback } from "react";
import { useSpring } from "react-spring";

export function useSpringHover({
  rotation = 0,
  timing = 150,
  scale = 1,
  springConfig = {
    tension: 300,
    friction: 10,
  },
}) {
  const [isHovered, setIsHovered] = useState(false);

  const style = useSpring({
    display: "inline-block",
    backfaceVisibility: "hidden",
    transform: isHovered
      ? `rotate(${rotation}deg) scale(${scale})`
      : `rotate(0deg) scale(0.9)`,
    config: springConfig,
  });

  useEffect(() => {
    if (!isHovered) {
      return;
    }
    const timeoutId = setTimeout(() => {
      setIsHovered(false);
    }, timing);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isHovered, timing]);

  const trigger = useCallback(() => {
    setIsHovered(true);
  }, [setIsHovered]);

  return [style, trigger];
}
