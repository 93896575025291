import RedButton from "../RedButton/RedButton";

import "./ConfirmModal.scss";

const ConfirmModal = ({ positiveCallback, negativeCallback }) => {
  return (
    <>
      <div className="confirm-modal-message">
        Are you sure you want to kick out and ban this user? <br />
        This user’s IP address will not be able to access any of your
        organization’s links.
      </div>
      <div className="confirm-modal-buttons">
        <RedButton label="Ok" handleClick={positiveCallback} />
        <button className="button" onClick={negativeCallback}>
          <span>Cancel</span>
        </button>
      </div>
    </>
  );
};

export default ConfirmModal;
