import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/utils";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import CreateInvitationModal from "../CreateInvitationModal";
import { useQuery, useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import "./Team.scss";

import TeamMembersList from "./TeamMembersList";

const Team = () => {
  const { api, permissionsStore, teamStore } = useStores();
  const [failedInvitations, setFailedInvitations] = useState([]);
  const queryClient = useQueryClient();

  useEffect(() => {
    teamStore.init();
  }, [teamStore]);

  const { data: failedInvitationData } = useQuery(
    "failedInvitations",
    api.getFailedInvitations,
    {
      refetchOnWindowFocus: false,
    }
  );

  const deleteFailedInvitation = useCallback(
    async (failedInvitationId) => {
      await api.deleteFailedInvitation(failedInvitationId);
      queryClient.refetchQueries("failedInvitations");
    },
    [api, queryClient]
  );

  useEffect(() => {
    if (failedInvitationData?.data) {
      setFailedInvitations(failedInvitationData.data);
    }
  }, [failedInvitationData?.data]);

  if (!permissionsStore.canSeeTeamPage) return <ErrorPage status={403} />;

  return (
    <div className="team-management">
      <h1 className="title is-1">People</h1>

      {permissionsStore.canInvite && (
        <div
          className="button invite-teammate-button is-sblue"
          onClick={teamStore.toggleShowCreateModal}
        >
          Invite people
        </div>
      )}

      {failedInvitations?.length > 0 && (
        <div className="box invitation-failures content">
          <h4>Invitation errors</h4>
          <table className="table is-fullwidth">
            <tbody>
              {failedInvitations.map((failedInvitation) => (
                <tr key={failedInvitation.id}>
                  <td>{failedInvitation.email}</td>
                  <td>{failedInvitation.error_text}</td>
                  <td className="actions">
                    <div
                      className="icon-button-on-white"
                      onClick={() =>
                        deleteFailedInvitation(failedInvitation.id)
                      }
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="box">
        <table className="table is-striped is-fullwidth team-table">
          <thead>
            <tr>
              <th className="team-table__column-header">Name</th>
              <th className="team-table__column-header">Email</th>
              <th className="team-table__column-header">Invited by</th>
              <th className="team-table__status-header">Role</th>
              <th></th>
            </tr>
          </thead>

          <TeamMembersList teamStore={teamStore} />
        </table>
      </div>
      <CreateInvitationModal />
    </div>
  );
};

export default observer(Team);
