import { observer } from "mobx-react-lite";
import AddRoomButton from "./AddRoomButton";

const AddRoom = ({ addRoom, creatingRoom }) => {
  return (
    <div className="level">
      <div className="level-left">
        <div className="level-item">
          <AddRoomButton addRoom={addRoom} creatingRoom={creatingRoom} />
        </div>
      </div>

      <div className="level-right">
        <div className="level-item"></div>
      </div>
    </div>
  );
};

export default observer(AddRoom);
