import PropTypes from "prop-types";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Field, useFormikContext } from "formik";
import { toast } from "react-toastify";
import { Input } from "components/Form/Input";
import { Select } from "components/Form/Select";
import { useStores } from "stores/utils";
import "components/SignupForm/CapModal.scss";
import "components/SignupForm/Cards.scss";
import "components/SignupForm/CreditCardForm.scss";

const CARD_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#8898AA",
      color: "#8898AA",
      lineHeight: "36px",
      fontWeight: 300,
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSize: "19px",
      border: "1px solid red",

      "::placeholder": {
        color: "#8898AA",
      },
    },
    invalid: {
      iconColor: "#e85746",
      color: "#e85746",
    },
  },
  classes: {
    focus: "is-focused",
    empty: "is-empty",
  },
};

const COUNTRY_OPTIONS = [
  {
    value: "US",
    label: "United States",
  },
  {
    value: "CA",
    label: "Canada",
  },
];

export const ChangePaymentForm = ({ handleCloseModal }) => {
  const { userSubscriptionStore } = useStores();
  const { isValid, values, handleSubmit, validateForm } = useFormikContext();
  const stripe = useStripe();
  const elements = useElements();

  const submitPayment = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit();
    const validationResult = await validateForm();

    if (!isValid || Object.keys(validationResult).length) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      toast.error(error.message);
    } else {
      userSubscriptionStore
        .updatePayment(paymentMethod.id, values)
        .then(handleCloseModal);
    }
  };

  return (
    <form className="subscription-card">
      <div className="stripe-container">
        <CardElement options={CARD_OPTIONS} />
      </div>
      <div className="stripe-billing-info">
        <Field name="line1" component={Input} placeholder="Address line 1" />
        <Field name="line2" component={Input} placeholder="Address line 2" />
        <Field name="city" component={Input} placeholder="City" />
        <div className="stripe-billing-info__two-cols">
          <Field name="state" component={Input} placeholder="State" />
          <Field
            name="postal_code"
            component={Input}
            placeholder="Postal Code"
          />
        </div>
        <Field
          name="country"
          component={Select}
          placeholder="Country"
          options={COUNTRY_OPTIONS}
        />
      </div>
      <div className="modal-actions">
        <button className="subscription__submit-button" onClick={submitPayment}>
          Confirm
        </button>
      </div>
    </form>
  );
};

ChangePaymentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
