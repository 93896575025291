import { observer } from "mobx-react-lite";
import { useEffect, useCallback, useState, useLayoutEffect } from "react";
import Tile from "../Tile/Tile";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useStores } from "../../stores/utils";
import classnames from "classnames";

const maxRatio = 1.8;
const minRatio = 0.5;
let timeout = false;

function TileLayout({ tiles, message, withSmallTiles, withCircledTiles }) {
  const { tilesStore } = useStores();

  const [height, updateHeight] = useState(1);
  const [width, updateWidth] = useState(1);

  // console.log("tiles, tiles.count: ", tiles);
  // console.log("tiles, tiles.count: ", tiles.length);
  useEffect(() => {
    tilesStore.updateTileCount(tiles.length);
  }, [tiles, tiles.length, tilesStore]);

  const updateSize = useCallback(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      const node = document.getElementById("tile-layout");
      if (node) {
        updateHeight(node.offsetHeight);
        updateWidth(node.offsetWidth);
      }
    }, 500);
  }, [updateHeight, updateWidth]);

  useLayoutEffect(() => {
    updateSize();
    tilesStore.setRecalculateTilesSizes(updateSize);
    window.addEventListener("resize", updateSize);
    return () => {
      tilesStore.setRecalculateTilesSizes(undefined);
      window.removeEventListener("resize", updateSize);
    };
  }, [updateSize, tilesStore]);

  const tilesCount = tilesStore.liveTileCount;
  // console.log("count in component is: ", tilesCount);
  const leastSquare = Math.pow(Math.ceil(Math.sqrt(tilesCount)), 2);
  const tileClass =
    tilesCount < 6 ? "tile-for-" + tilesCount : "tile-for-" + leastSquare;
  // console.log("Tiles calss is: ", tileClass);

  const ratio = width / height;
  const isVertical = ratio < 2;
  const isVertical3 = ratio < 1.0;
  const isSuperwide = ratio > 4;
  // console.log("isSuperwide");
  // console.log(isVertical);

  const unfreeze = useCallback(() => {
    return tilesStore.unfreezeTileCount(tilesCount);
  }, [tilesStore, tilesCount]);

  const freeze = () => {
    return tilesStore.freezeTileCount(tilesCount);
  };

  let maxWidth = "none";
  let maxHeight = "none";
  if (tilesCount === 2 && ratio <= 1.5 && ratio > 1.1) {
    // console.log("A");
    maxWidth = height * 1.1;
  } else if (tilesCount === 3 && ratio <= 1 && ratio > 0.7) {
    // console.log("B");
    maxWidth = height * 0.7;
  } else if (isSuperwide) {
    // console.log("C");
    maxWidth = height * 2 * tilesCount;
  } else {
    // console.log("D");
    if (tilesCount > 2) {
      maxWidth = ratio >= maxRatio ? height * maxRatio : "none";
      maxHeight = ratio <= minRatio ? width / minRatio : "none";
    } else {
      // maxWidth = height * 1.778;
    }
  }

  // console.log("tiles count: ", tilesCount);
  // console.log("Height: ", height);
  // console.log("Width: ", width);
  // console.log("Ratio: ", ratio);
  // console.log("MAX WIDTH: ", maxWidth);
  // console.log("MAX HEIGHT: ", maxHeight);

  const applyLayoutStyle = {
    display: !isVertical3 && isVertical && tilesCount === 2 && "flex",
  };

  const applyTilesForStyle = {
    flexGrow: !isVertical && tilesCount === 2 && 1,
  };

  return (
    <div
      id="tile-layout"
      className={classnames(
        withSmallTiles && "with-small-tiles",
        withCircledTiles && "with-circled-tiles"
      )}
      style={applyLayoutStyle}
    >
      {
        !message ? (
          <TransitionGroup
            className={
              tileClass +
              " large-tiles" +
              (isVertical ? " vertical" : "") +
              (isVertical3 ? " vertical-3" : "") +
              (isSuperwide ? " superwide" : "")
            }
            style={{
              maxWidth,
              maxHeight,
              ...applyTilesForStyle,
            }}
            appear={true}
            enter={true}
            exit={true}
          >
            {tiles.map((tile) => (
              <CSSTransition
                in={true}
                key={tile.key}
                timeout={300}
                classNames={{
                  appear: "my-node-appear",
                  appearActive: "my-node-appear-active",
                  appearDone: "my-node-appear-done",
                  enter: "my-node-enter",
                  enterActive: "my-node-enter-active",
                  enterDone: "my-node-enter-done",
                  exit: "my-node-exit",
                  exitActive: "my-node-exit-active",
                  exitDone: "my-node-exit-done",
                }}
                onExit={freeze}
                onExited={unfreeze}
              >
                <Tile
                  key={tile.key}
                  tile={tile}
                  positionTippyTop={true}
                  tilesCount={tilesCount}
                  verticalLayout={isVertical}
                  skinnyLayout={isVertical3}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        ) : null /* Avoid showing large tiles to make room for the message */
      }
    </div>
  );
}

export default observer(TileLayout);
