import { computed, observable, makeObservable } from "mobx";
import * as Amplitude from "../utilities/amplitude";
import { ParticipantModelLite } from "./ParticipantModelLite";
import api from "../api";

export const MAX_ROOM_POPULATION = 39;

export default class GroupModel {
  id;
  @observable.ref attributes = {};
  @observable.ref relationships = {};
  participants;
  type;

  constructor({ data, participants, rootStores }) {
    this.rootStores = rootStores;
    this.callStore = this.rootStores.callStore;
    this.participants = participants;
    this.mergeData(data);
    makeObservable(this);
  }

  mergeData(data) {
    Object.keys(data).forEach((k) => {
      this[k] = data[k];
    });
  }

  delete = async () => {
    Amplitude.track("Clicked delete room", { roomName: this.attributes.name });
    try {
      await this.rootStores.callStore.deleteRoom(this.id);
    } catch (err) {
      console.log(err);
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  updateName = async (text) => {
    try {
      await api.updateRoomName(this.id, text, this.rootStores.userStore.token);
    } catch (err) {
      console.log(err);
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  togglePermanent = async () => {
    Amplitude.track("Clicked update room permanent", {
      roomName: this.attributes.name,
    });
    try {
      await api.updateRoomPermanent(
        this.id,
        !this.permanent,
        this.rootStores.userStore.token
      );
    } catch (err) {
      console.log(err);
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  lockRoom = async () => {
    try {
      await api.lockRoom(this.id, this.rootStores.userStore.token);
    } catch (err) {
      console.log(err);
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  unlockRoom = async () => {
    try {
      await api.unlockRoom(this.id, this.rootStores.userStore.token);
    } catch (err) {
      console.log(err);
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  @computed
  get isAuditorium() {
    return !!this.attributes["broadcast-mode"];
  }

  @computed
  get isCurrentRoom() {
    return (
      this.callStore.currentRoomId && this.id === this.callStore.currentRoomId
    );
  }

  @computed
  get joiningThisRoom() {
    return this.callStore.joiningRoomId === this.id;
  }

  @computed
  get isFull() {
    return !this.isAuditorium && this.roomPopulation > MAX_ROOM_POPULATION;
  }

  @computed
  get canEdit() {
    const thisRoom = this.isCurrentRoom;
    const emptyRoom = this.callStore.currentRoomId && this.roomPopulation === 0;
    const notPermanent = !this.attributes.permanent;
    const isHost = this.callStore.isEventHost;
    const notJoining = !this.joiningThisRoom;
    const inARoom = !!this.callStore.currentRoomId;
    return (
      inARoom &&
      (thisRoom || emptyRoom || isHost) &&
      (notPermanent || isHost) &&
      notJoining
    );
  }

  @computed
  get canDelete() {
    return this.roomPopulation === 0 && this.canEdit;
  }

  @computed
  get canPermanent() {
    return this.callStore.isEventHost && this.canEdit;
  }

  enter = ({ newParticipantName } = {}) => {
    return this.callStore.switchRoom(
      this.id,
      this.attributes["daily-url"],
      newParticipantName
    );
  };

  handleClickRoom = ({ newParticipantName, joinButtonsDisabled }) => {
    setTimeout(() => {
      Amplitude.track("click join room", {
        session_id: this.callStore.sessionId,
        group_id: this.id,
        display_name: newParticipantName,
        url: window.location.href,
        room_name: this.attributes.name,
      });
    }, 5000);

    return this.enter({ newParticipantName });
  };

  @computed
  get roomPopulation() {
    return this.restCount + this.participants.length;
  }

  @computed
  get alreadyInRoom() {
    return (
      this.callStore.participant &&
      !!this.participants.find(
        (item) => item.id === this.callStore.participant?.id
      )
    );
  }

  @computed
  get permanent() {
    return this.attributes["permanent"];
  }

  @computed
  get lobby() {
    return this.attributes["lobby"];
  }

  get restCount() {
    return this.attributes["rest-count"];
  }

  isCreator = (participant) => {
    if (!participant?.id) return false;
    if (
      !this.relationships ||
      !this.relationships["creator-participant"] ||
      !this.relationships["creator-participant"].data
    )
      return false;
    return this.relationships["creator-participant"].data.id === participant.id;
  };

  static listFromSessionData(session, rootStores) {
    if (!session) return [];
    console.log("S:", session);

    let participants = session.included.filter(
      (x) => x.type === "participants"
    );

    let groups = session.included.filter((x) => x.type === "groups");

    return this.listFromGroupsAndParticipants(groups, participants, rootStores);
  }

  @computed
  get userIdList() {
    return this.participants.filter((p) => p.userId).map((p) => p.userId);
  }

  static listFromGroupsAndParticipants(groups, participants, rootStores) {
    participants = participants.map((p) => {
      return new ParticipantModelLite({
        data: p,
        rootStores: rootStores,
      });
    });

    let groupList = groups.map((g) => {
      const groupParticipants = participants.filter((x) => {
        return x.groupId === g.id;
      });

      return new GroupModel({
        data: g,
        participants: groupParticipants,
        rootStores: rootStores,
      });
    });

    console.log("Groups: ", groupList);

    return groupList;
  }
}
