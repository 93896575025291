import moment from "moment";
import { useStores, observe } from "stores/utils";
import { CancelSubscriptionModal } from "./CancelSubscriptionModal";
import "./shared.scss";

export const SubscriptionStatus = () => {
  const { userSubscriptionStore } = useStores();

  return (
    <div className="billing-card box">
      <div className="billing-card__title">Cancellation</div>

      <div className="billing-card-table subscription-actions">
        {observe(
          [
            userSubscriptionStore.isActiveSubscription,
            userSubscriptionStore.isTrialSubscription,
            userSubscriptionStore.plan,
          ],
          () =>
            userSubscriptionStore.plan ? (
              userSubscriptionStore.isActiveSubscription ? (
                <button
                  className="button button-cancel"
                  onClick={() =>
                    userSubscriptionStore.setShowCancelSubscriptionModal(true)
                  }
                >
                  Cancel{" "}
                  {userSubscriptionStore.isTrialSubscription
                    ? "Trial"
                    : "Subscription"}
                </button>
              ) : (
                <button
                  className="button button-reactivate"
                  onClick={() =>
                    userSubscriptionStore.setShowCancelSubscriptionModal(true)
                  }
                >
                  Reactivate{" "}
                  {userSubscriptionStore.isTrialSubscription
                    ? "Trial"
                    : "Subscription"}
                </button>
              )
            ) : null
        )}
      </div>

      {observe(
        [
          userSubscriptionStore.isActiveSubscription,
          userSubscriptionStore.isTrialSubscription,
          userSubscriptionStore.showCancelSubscriptionModal,
        ],
        () =>
          userSubscriptionStore.showCancelSubscriptionModal &&
          (userSubscriptionStore.isActiveSubscription ? (
            <CancelSubscriptionModal
              handleCloseModal={() =>
                userSubscriptionStore.setShowCancelSubscriptionModal(false)
              }
              onConfirm={userSubscriptionStore.cancelSubscription}
              title={
                userSubscriptionStore.isTrialSubscription
                  ? "Cancel trial?"
                  : "Cancel subscription?"
              }
              message={
                userSubscriptionStore.isTrialSubscription
                  ? `This will end your trial and you will not be able to continue using the service.`
                  : `
                Your subscription will still be active until ${moment(
                  userSubscriptionStore.subscription?.data?.attributes
                    ?.current_period_end
                ).format("LLL")}.
                It will not be renewed after that period.
              `
              }
            />
          ) : (
            <CancelSubscriptionModal
              handleCloseModal={() =>
                userSubscriptionStore.setShowCancelSubscriptionModal(false)
              }
              onConfirm={userSubscriptionStore.reactivateSubscription}
              title={
                userSubscriptionStore.isTrialSubscription
                  ? "Reactivate trial?"
                  : "Reactivate subscription?"
              }
              message={
                userSubscriptionStore.isTrialSubscription
                  ? `Your trial was cancelled but you still have ${moment(
                      userSubscriptionStore.subscription?.data?.attributes
                        ?.trial_end
                    ).diff(
                      moment(),
                      "days"
                    )} days remaining in your trial period. It will resume on confirmation.`
                  : `Your subscription is cancelled. It will be renewed on confirm.`
              }
            />
          ))
      )}
    </div>
  );
};
