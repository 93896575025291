import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { navigate } from "@reach/router";
import AcceptInviteForm from "../components/Invitations/AcceptInviteForm";
import * as Amplitude from "../utilities/amplitude";
import PlainGreyLayout from "../components/Layouts/PlainGreyLayout";

const AcceptInvite = (props) => {
  useEffect(() => {
    Amplitude.track("Visited accept invite page.");
  }, []);

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <PlainGreyLayout>
      <div className=" content-column narrow">
        <AcceptInviteForm
          navigate={navigate}
          goToLogin={goToLogin}
          inviteToken={props.inviteToken}
        />
      </div>
    </PlainGreyLayout>
  );
};

export default observer(AcceptInvite);
