import { useStores, observe } from "stores/utils";

export const StartSubscriptionModal = () => {
  const { viewStore, userSubscriptionStore } = useStores();

  const startSubscription = () => {
    userSubscriptionStore
      .startSubscription()
      .then(() => viewStore.setGeneralNotification(null));
  };

  return (
    <div className="modal-not-verified">
      <div className="modal-not-verified__title">Start your Subscription</div>
      <div className="modal-not-verified__content">
        <p>
          By starting your subscription, we'll charge the card on file and your
          subscription term will begin. Sidebar subscriptions aren't refundable,
          except when required by law.
        </p>
        <p>
          Don't worry about leaving behind any of the free hours included in
          your trial. We'll add any remaining hours to your subscription.{" "}
        </p>
      </div>
      {observe(userSubscriptionStore.isStartingSubscription, () => (
        <div className="modal-not-verified__actions">
          <button
            className="button modal-not-verified__confirm"
            onClick={startSubscription}
            disabled={userSubscriptionStore.isStartingSubscription}
          >
            Yes, get started!
          </button>
          <button
            className="button modal-not-verified__cancel"
            onClick={() => viewStore.setGeneralNotification(null)}
            disabled={userSubscriptionStore.isStartingSubscription}
          >
            No, I'll wait
          </button>
        </div>
      ))}
    </div>
  );
};
