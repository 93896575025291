import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import classnames from "classnames";
import "./KnockButton.scss";

const KnockButton = ({ knocking, knock, cancelKnock, isEventsFoyer }) => {
  if (isEventsFoyer) {
    return (
      <Tippy
        content={
          <div className="tooltip-content">
            <p>Room is locked.</p>
            <p>Join an unlocked room first.</p>
          </div>
        }
      >
        <div className="locked-icon">
          <FontAwesomeIcon icon={faLock} />
        </div>
      </Tippy>
    );
  }
  return (
    <>
      {knocking && cancelKnock && (
        <Tippy content="Cancel">
          <div
            className={classnames(
              "button",
              "is-white",
              "direct-call-button",
              "knock-cancel-button"
            )}
            onClick={(e) => cancelKnock(e)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </Tippy>
      )}
      <div
        className={classnames(
          "button is-outlined is-rounded is-small join-room-button",
          {
            "knocking-button": knocking,
            "is-spurple": !knocking,
          }
        )}
        disabled={knocking}
        onClick={(e) => knock(e)}
      >
        {knocking ? "Knocked!" : "Knock"}
      </div>
    </>
  );
};

export default observer(KnockButton);
