import { observer } from "mobx-react-lite";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import TrayButton from "components/TrayButton/TrayButton";
import DocumentsPopup from "./DocumentsPopup";

const DocumentsButton = ({
  disabled,
  hideDocumentsList,
  toggleDocumentsList,
  showingDocuments,
}) => {
  return (
    <div>
      <Tippy
        content={showingDocuments ? <DocumentsPopup /> : ""}
        visible={showingDocuments}
        appendTo="parent"
        theme="light"
        arrow={false}
        placement="top"
        interactive={true}
        className="documents-tippy"
        onClickOutside={hideDocumentsList}
        duration={[null, 0]}
      >
        <div>
          <Tippy content="Documents" appendTo="parent">
            <div>
              <TrayButton
                disabled={disabled}
                onClick={toggleDocumentsList}
                extraClasses="documents-tray-button"
              >
                <FontAwesomeIcon icon={faFile} />
              </TrayButton>
            </div>
          </Tippy>
        </div>
      </Tippy>
    </div>
  );
};

export default observer(DocumentsButton);
