import { useMemo } from "react";
import { useStores } from "../stores/utils";

export function useTileSettings({ tile = {} }) {
  const { tileSettingsStore } = useStores();

  const userId = tile?.callItem.dailyId;
  const myTile = tile?.callItem.isLocal;
  const largeTileNotScreen = tile?.isLarge && !tile?.isScreen;
  const userTileSettings = tileSettingsStore.changedTiles[userId];

  const backgroundValue =
    largeTileNotScreen &&
    userTileSettings &&
    userTileSettings["background-color"];

  const dimensionsValue =
    largeTileNotScreen &&
    userTileSettings &&
    userTileSettings["tile-dimensions"];

  const backgroundColor = useMemo(() => {
    if (!backgroundValue) return {};
    if (myTile) {
      return { "--base-color": `${backgroundValue}` };
    } else {
      return { backgroundColor: backgroundValue };
    }
  }, [backgroundValue, myTile]);

  const dimensions = useMemo(() => {
    if (!dimensionsValue) return {};
    if (myTile) {
      return { "--spacing": `${dimensionsValue}%` };
    } else {
      return { width: `${dimensionsValue}%` };
    }
  }, [dimensionsValue, myTile]);

  const settings = {
    backgroundColor: backgroundColor,
    dimensions: dimensions,
  };

  return settings;
}
