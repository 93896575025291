import { useCallback, useEffect, useState } from "react";
import { useStores } from "../stores/utils";

export function useOffscreen({ p = {} }) {
  const [idx, setIdx] = useState(undefined);
  const rootStores = useStores();
  const { userChatStore } = rootStores;
  const isAnyUnreadMessages = userChatStore.newMessages[p?.id]?.length > 0;

  useEffect(() => {
    if (idx === undefined) return;
    return () => userChatStore.removeFromOffscreenReferenceList(idx);
  }, [idx, userChatStore]);

  useEffect(() => {
    if (idx === undefined) return;
    if (isAnyUnreadMessages) {
      userChatStore.addToOffscreenReferenceList(idx);
    } else {
      userChatStore.removeFromOffscreenReferenceList(idx);
      if (!userChatStore.offscreenReferenceLength) {
        userChatStore.setOffscreenNotifRef(undefined);
      }
    }
  }, [isAnyUnreadMessages, userChatStore, idx]);

  const setNewIdx = useCallback((i) => setIdx(i), []);

  return [idx, setNewIdx];
}
