/* eslint-disable jsx-a11y/anchor-is-valid */
import { observer } from "mobx-react-lite";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "../../stores/utils";
import { FiExternalLink } from "react-icons/fi";

const BottomMenu = ({ logout, toggleShowHostLogin, toggleShowInvite }) => {
  const { userStore } = useStores();

  return (
    <div className="bottom-menu">
      <div className="call-login-menu level">
        <div className="level-left">
          {userStore.isAuthenticated ? (
            <Tippy
              content={
                <div className="menu">
                  <ul className="menu-list">
                    <li>
                      <a href="/host" target="_blank" rel="noopener">
                        Host Dashboard <FiExternalLink />
                      </a>
                    </li>
                    <li>
                      <a onClick={() => logout()}>Logout</a>
                    </li>
                  </ul>
                </div>
              }
              interactive={true}
              theme="light"
              trigger="click"
              animation="shift-away"
            >
              <div className="call-user-menu-button">
                <FontAwesomeIcon icon={faUserCircle} />
              </div>
            </Tippy>
          ) : (
            <button
              className="button is-white is-small"
              onClick={() => toggleShowHostLogin()}
            >
              <div className="call-login-button">Login</div>
            </button>
          )}
        </div>
        <div className="level-right">
          <button
            className="button is-small is-rounded is-outlined is-carribean"
            onClick={() => toggleShowInvite()}
          >
            Invite
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(BottomMenu);
