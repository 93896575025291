import { observer } from "mobx-react-lite";
import classnames from "classnames";
import "./JoinButton.scss";

const JoinButton = ({
  join,
  joining,
  disabled,
  isFull,
  isCurrentRoom,
  sayStart,
  outlined = true,
  small = true,
}) => {
  const doJoin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    join(e);
  };

  return isFull ? (
    <button
      className={classnames(
        "full-room button is-rounded is-sblue join-room-button",
        { "is-outlined": outlined, "is-small": small }
      )}
      disabled={true}
    >
      Full
    </button>
  ) : (
    !isCurrentRoom && (
      <div
        className={classnames("button is-rounded is-sblue join-room-button", {
          "join-room-button--joining": joining,
          "is-outlined": outlined,
          "is-small": small,
        })}
        disabled={disabled}
        onClick={doJoin}
      >
        {joining ? (
          <span className="joining-button">
            {sayStart ? "Starting" : "Joining"}{" "}
          </span>
        ) : sayStart ? (
          "Start"
        ) : (
          "Join"
        )}
      </div>
    )
  );
};

export default observer(JoinButton);
