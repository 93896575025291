export const msgTimestampStyle = (showMark) => {
    return {
        display: "inline-block",
        fontSize: "10px",
        fontWeight: "500",
        lineHeight: "12px",
        textTransform: "uppercase",
        whiteSpace: "nowrap",
        minWidth: showMark ? "48px" : "32px",
        ' > img': {
            height: "12px",
            marginLeft: "3px",
            display: "inline-block",
            verticalAlign: "bottom",
        }
    }
}