import "./RedButton.scss";

/**
 * Props:
 * - disabled: boolean
 * - type: string
 * - label: string
 * - handleClick: void
 */
export default function RedButton({
  disabled = false,
  type = "button",
  label,
  handleClick = () => false,
}) {
  return (
    <button
      className="button is-sred"
      disabled={disabled}
      type={type}
      onClick={handleClick}
    >
      <span>{label}</span>
    </button>
  );
}
