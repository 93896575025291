import { observer } from "mobx-react-lite";
import Tippy from "@tippyjs/react";
import { ReactComponent as Plus } from "../../images/SVG/plus-light.svg";
import OfficeAddRoomButton from "../Foyer/OfficeAddRoomButton";
import { useStores } from "stores/utils";

const AddRoomButtons = ({
  sessionId,
  hideCreateAuditorium,
  createRoom,
  createAuditorium,
}) => {
  const { officeStore, userStore } = useStores();

  return (
    <div className="level">
      <div className="level-left">
        <div className="level-item">
          <OfficeAddRoomButton sessionId={sessionId} createRoom={createRoom} />
          {!hideCreateAuditorium && userStore.isAdmin && (
            <div className="add-room-button">
              <Tippy content="Create new auditorium">
                <button
                  className="button is-host is-outlined is-small is-rounded"
                  onClick={() => createAuditorium()}
                >
                  <span className="icon">
                    <Plus />
                  </span>
                  &nbsp;&nbsp;{" "}
                  {officeStore.creatingAuditoriumOnFoyer
                    ? "Creating..."
                    : "Auditorium"}
                </button>
              </Tippy>
            </div>
          )}
        </div>
      </div>
      <div className="level-right">
        <div className="level-item"></div>
      </div>
    </div>
  );
};

export default observer(AddRoomButtons);
