import { observer } from "mobx-react-lite";
import AudioTag from "../AudioTag/AudioTag";
import { useStores } from "../../stores/utils";

const AudioTags = () => {
  const { callStore, viewStore, tilesStore } = useStores();

  return viewStore.isFullScreening && tilesStore.isAnyoneScreenSharing
    ? callStore.allAudioTagItems.map((callItem) => (
        <AudioTag key={callItem.dailyId} audioTrack={callItem.audioTrack} />
      ))
    : callStore.audioTagItems.map((callItem) => (
        <AudioTag key={callItem.dailyId} audioTrack={callItem.audioTrack} />
      ));
};

export default observer(AudioTags);
