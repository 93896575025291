export const theme = {
  fontFamily: "Inter, sans-serif",
  fontSize: "14px",
  color: {
    primary: "#141414",
    secondary: "#cccccc",
    darkSecondary: "#eaeaea",
    grey: "rgba(20,20,20,0.04)",
    helpText: "rgba(20, 20, 20, 0.6)",
    blue: "#39f",
    white: "#fff",
    red: "#ff3b30",
  },
  borderColor: {
    primary: "#eaeaea",
  },
  backgroundColor: {
    white: "#fff",
    primary: "#E6E6E6",
    secondary: "#f6f6f6",
    blue: "#a2c3ff",
    grey: "rgba(20,20,20,0.04)",
    lightGrey: "rgba(20, 20, 20, 0.08)",
    darkGrey: "rgba(20,20,20,0.9)",
  },
};
