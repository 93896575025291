import { observer } from "mobx-react-lite";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faLink } from "@fortawesome/pro-regular-svg-icons";
import FoyerCardList from "./FoyerCardList";
import { useStores } from "../../stores/utils";
import SingleFoyerCard from "./SingleFoyerCard";
import ContentEditable from "react-contenteditable";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import MeetingSettingsMenu from "../Office/MeetingSettingsMenu";
import FoyerDocumentList from "./FoyerDocumentList";

const MeetingFoyer = ({
  addRoom,
  createAuditorium,
  createRoom,
  envFriendlyUrl,
  triggerCopiedTooltip,
  showCopiedTooltip,
  groupList,
  onClickRoom,
  sessionId,
  sessionNameText,
  contentEditableRef,
  handleBlur,
  pressEnter,
  handleSessionNameChange,
  placeCaretAtEnd,
  isTitleUpdate,
  query,
  setShowingConfirmMeetingDelete,
  homeAndRefresh,
}) => {
  const { userStore } = useStores();
  const roomCount = groupList.length;
  const onlyRoom = groupList?.[0];

  return (
    <div className="meeting-foyer">
      <div className="title-wrapper">
        <h1 className="title is-3 is-size-3-mobile is-spaced">
          <div className="hover-for-actions">
            <ContentEditable
              innerRef={contentEditableRef}
              html={sessionNameText}
              onBlur={() => {
                handleBlur();
              }}
              onKeyDown={pressEnter}
              onChange={handleSessionNameChange}
              className="editable-name"
            />
            <div className="floating-actions">
              <div
                className={classnames("pen-icon", {
                  "is-saving": isTitleUpdate,
                })}
                onClick={() => placeCaretAtEnd(contentEditableRef.current)}
              >
                <FontAwesomeIcon icon={faPen} />
              </div>
              <div
                className={classnames("room-action-loader", {
                  active: isTitleUpdate,
                })}
              />
            </div>
          </div>
          <CopyToClipboard text={envFriendlyUrl} onCopy={triggerCopiedTooltip}>
            <span className="link-button-wrapper">
              <Tippy
                content="Link copied to clipboard!"
                visible={showCopiedTooltip}
                className="blue-tippy"
                arrow={false}
              >
                <Tippy content="Get link">
                  <button className="link-button button">
                    <div className="icon ">
                      <FontAwesomeIcon icon={faLink} />
                    </div>
                  </button>
                </Tippy>
              </Tippy>
            </span>
          </CopyToClipboard>
          <Tippy content="Meeting settings">
            <span className="meeting-settings-button">
              <Tippy
                interactive={true}
                theme="light"
                arrow={false}
                placement="bottom-end"
                className="tippy-menu-padding"
                trigger="click"
                content={
                  <MeetingSettingsMenu
                    currentUserId={userStore.userId}
                    session={query?.data}
                    setShowingConfirmMeetingDelete={
                      setShowingConfirmMeetingDelete
                    }
                    homeAndRefresh={homeAndRefresh}
                  />
                }
              >
                <button className="link-button button">
                  <div className="icon ">
                    <FontAwesomeIcon icon={faCog} />
                  </div>
                </button>
              </Tippy>
            </span>
          </Tippy>
        </h1>
      </div>
      <div className="meeting-foyer-content">
        {roomCount === 1 ? (
          <>
            <div className="columns is-desktop">
              <div className="column"></div>
              <div className="column">
                <SingleFoyerCard
                  onlyRoom={onlyRoom}
                  onClickRoom={onClickRoom}
                />
              </div>
              <div className="column"></div>
            </div>
            <FoyerDocumentList sessionId={sessionId} groupList={groupList} />
          </>
        ) : (
          <>
            <FoyerCardList
              addRoom={addRoom}
              createAuditorium={createAuditorium}
              createRoom={createRoom}
              groupList={groupList}
              onClickRoom={onClickRoom}
              sessionId={sessionId}
            />
            <FoyerDocumentList sessionId={sessionId} groupList={groupList} />
          </>
        )}
      </div>
    </div>
  );
};

export default observer(MeetingFoyer);
