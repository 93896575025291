import { useRef } from "react";
import { observer } from "mobx-react-lite";
import "./Call.scss";
import CallContent from "../CallContent/CallContent";
import Div100vh from "react-div-100vh";
import { useStores } from "../../stores/utils";
import SideMenu from "../SideMenu/SideMenu";

function Call(props) {
  const { viewStore } = useStores();
  const fullscreenRef = useRef();

  let sideMenuClass = viewStore.hideSideMenu ? "no-side-menu" : "";

  return (
    <Div100vh>
      <div className={"call-container " + sideMenuClass}>
        <Div100vh>
          <SideMenu
            buttonsDisabled={props.buttonsDisabled}
            navigate={props.navigate}
          />
        </Div100vh>
        <div className="main-content" ref={fullscreenRef}>
          <Div100vh>
            <CallContent fullscreenRef={fullscreenRef} />
          </Div100vh>
        </div>
      </div>
    </Div100vh>
  );
}

Call.whyDidYouRender = false;
export default observer(Call);
