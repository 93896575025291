import { observer } from "mobx-react-lite";
import { FaExpand, FaCompress } from "react-icons/fa";
import Tippy from "@tippyjs/react";
import TrayButton from "../TrayButton/TrayButton";

const FullScreenButton = ({
  isFullScreen,
  toggleFullscreen,
  disabled,
  theme,
}) => {
  return (
    <Tippy
      appendTo="parent"
      disabled={disabled}
      content={isFullScreen ? "Exit fullscreen (f)" : "Fullscreen (f)"}
      trigger="mouseenter focus click"
      theme={theme}
    >
      <div>
        <TrayButton onClick={toggleFullscreen} disabled={disabled}>
          {isFullScreen ? (
            <FaCompress size="24px" color="#fff" />
          ) : (
            <FaExpand size="24px" color="#fff" />
          )}
        </TrayButton>
      </div>
    </Tippy>
  );
};

export default observer(FullScreenButton);
