import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Link, Router } from "@reach/router";
import { useStores } from "../../stores/utils";
import SpacesAdmin from "./SpacesAdmin/SpacesAdmin";
import { ProfilePage } from "../../pages/Profile";
import { ReactComponent as ReactLogo } from "../../images/SVG/blue_logo.svg";
import AuthBoundary from "../../error-boundaries/AuthBoundary/AuthBoundary";
import Team from "./Team";
import ChangeLogo from "./ChangeLogo";
import SlackTeam from "./SlackTeam/SlackTeam";
import "../../pages/HostDashboard/HostDashboard.scss";
import "./OfficeAdmin.scss";

export default observer(function OfficeAdmin() {
  const { userStore, sessionsStore } = useStores();

  useEffect(() => {
    if (userStore.token) {
      sessionsStore.fetchSessions();
    }
  }, [sessionsStore, userStore.token]);

  return (
    <AuthBoundary>
      <div id="host-dashboard" className="office-admin">
        <div className="side-menu">
          <div className="padding">
            <div className="title-image">
              <ReactLogo />
            </div>
            {/*<Link to="/office" className="return-button">
              <FontAwesomeIcon icon={faChevronLeft} /> Back to{" "}
              {userStore.organizationName}
            </Link>*/}
            <aside className="menu">
              <ul className="menu-list">
                <li></li>
                <li>
                  <Link to="team">People</Link>
                </li>
                <li>
                  <Link to="spaces">Spaces</Link>
                </li>
                <li>
                  <Link to="logo">Logo</Link>
                </li>
                <li>
                  <Link to="slack">Slack</Link>
                </li>
                <li>
                  {/* eslint-disable-next-line */}
                  {/*<a onClick={logout}>Sign out</a>*/}
                </li>
              </ul>
            </aside>
          </div>
        </div>
        <div className="primary-content">
          <Router>
            <SpacesAdmin path="spaces" />
            <Team path="team" />
            <ChangeLogo path="logo" />
            <ProfilePage path="settings" />
            <SlackTeam path="slack" />
          </Router>
        </div>
      </div>
    </AuthBoundary>
  );
});
