import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { useStores } from "stores/utils";
import api from "../../api";
import classnames from "classnames";
import Honeybadger from "honeybadger-js";
import "./AcceptInviteForm.scss";

const AcceptSharedInviteForm = (props) => {
  const { userStore } = useStores();
  const { register, handleSubmit, errors, setError } = useForm();
  const [resetLoading, setResetLoading] = useState(false);
  const [errors2, setErrors2] = useState(false);
  const [sharedInvitation, setSharedInvitation] = useState({});
  const [checked, setChecked] = useState(false);
  const [expired, setExpired] = useState(false);

  const navigate = props.navigate;

  const focusRef = useCallback(
    (inputElement) => {
      register(inputElement, { required: true });
      if (inputElement) {
        inputElement.focus();
      }
    },
    [register]
  );

  useEffect(() => {
    const func = async () => {
      setExpired(false);
      try {
        const sharedInvitation = await api.getSharedInvitation(
          props.inviteToken
        );
        setSharedInvitation(sharedInvitation.data);
      } catch (e) {
        if (e?.response?.status === 404) {
          setExpired(true);
        } else {
          Honeybadger.notify(e);
        }
      }
    };
    func();
  }, [props.inviteToken]);

  console.log("form errors: ", errors);

  const onSubmit = useCallback(
    async (credentials) => {
      setErrors2(false);
      setResetLoading(true);
      try {
        await api.acceptSharedInvitation(
          props.inviteToken,
          credentials.full_name,
          credentials.email,
          credentials.password
        );
      } catch (error) {
        const errorObj = error.response?.data?.details;
        if (errorObj) {
          for (const property in errorObj) {
            setError(property, {
              type: "manual",
              message: error.response.data.error,
            });
          }
        } else {
          Honeybadger.notify(error);
        }
        return;
      }

      const loginCredentials = {
        email: credentials.email,
        password: credentials.password,
      };
      await userStore.login(loginCredentials);

      await new Promise((r) => setTimeout(r, 700));
      setResetLoading(false);
      navigate("/office/setup");
    },
    [userStore, navigate, props.inviteToken, setError]
  );

  const inviterName = useMemo(() => {
    if (!sharedInvitation || !sharedInvitation.inviter_name) {
      return;
    }
    return sharedInvitation.inviter_name;
  }, [sharedInvitation]);

  const handleInputChange = useCallback(
    (event) => {
      setChecked(event.target.checked);
    },
    [setChecked]
  );

  return (
    <div className="accept-invite-form">
      {expired ? (
        <h1 className="title is-5 is-size-5-mobile has-text-centered	">
          This link has expired or is invalid.
        </h1>
      ) : (
        <>
          <h1 className="title is-4 is-size-4-mobile">
            {inviterName} invited you to {sharedInvitation?.organization_name}
          </h1>

          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              {errors2 && (
                <div className="has-text-danger has-text-weight-bold">
                  There was a problem accepting the invitation.
                </div>
              )}
              <div className="field">
                <label className="label">Full name</label>
                <div className="control">
                  <input
                    name="full_name"
                    className="input"
                    type="text"
                    placeholder="Your name"
                    ref={focusRef}
                  />
                  {errors.full_name && (
                    <div className="message-row has-text-danger">
                      Name is required
                    </div>
                  )}
                </div>
              </div>
              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input
                    name="email"
                    className="input"
                    type="email"
                    placeholder="Your company email"
                    ref={register({
                      required: "Email is required",
                    })}
                  />
                  {errors.email && (
                    <div className="message-row has-text-danger">
                      {errors.email.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="field">
                <label className="label">Password</label>
                <div className="control">
                  <input
                    name="password"
                    type="password"
                    className="input"
                    placeholder="Create password"
                    ref={register({
                      required: "Password is required",
                    })}
                  />
                  {errors.password && (
                    <div className="message-row has-text-danger">
                      {errors.password.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="field i-agree-field">
                <div className="control">
                  <label className="checkbox i-agree-content">
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={handleInputChange}
                    />{" "}
                    <div className="i-agree-text">
                      I agree to the{" "}
                      <a
                        href="https://office.sidebar.vc/terms-and-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <nobr>
                        <a
                          href="https://office.sidebar.vc/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </nobr>
                    </div>
                  </label>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button
                    className={classnames("button get-started-button", {
                      "is-buttongrey": !checked,
                      "is-link": checked,
                    })}
                    disabled={!checked}
                  >
                    Create Account
                  </button>
                  {resetLoading && <span className="reset-loading" />}
                </div>
              </div>
            </form>
          </>
        </>
      )}
    </div>
  );
};

export default observer(AcceptSharedInviteForm);
