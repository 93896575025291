import { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import { useForm } from "react-hook-form";
import { debounce } from "lodash";
import { useMutation } from "react-query";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tippy from "@tippyjs/react";

const NewMeetingPopup = ({ session, privateSummaryQuery }) => {
  const { api } = useStores();
  const { register, errors } = useForm({ mode: "onBlur" });
  const [showCopiedTooltip, setShowCopiedTootip] = useState(false);

  const mutation = useMutation(
    async (newName) => {
      return await api.updateSession(session.id, {
        data: {
          type: "sessions",
          attributes: { name: newName },
          id: session.id,
        },
      });
    },
    {
      onSuccess: () => {
        privateSummaryQuery.refetch();
      },
    }
  );

  const updateName = useCallback(
    async (event) => {
      const newName = event.target.value;
      if (newName?.length > 0) {
        return mutation.mutate(newName);
      }
    },
    [mutation]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateName = useCallback(
    debounce(updateName, 500, {
      leading: false,
      trailing: true,
    }),
    [updateName]
  );

  const meetingUrl = session.attributes["meeting-url"];

  const triggerCopiedTooltip = useCallback(() => {
    setShowCopiedTootip(true);
    setTimeout(() => {
      setShowCopiedTootip(false);
    }, 2000);
  }, []);

  const focusRef = useCallback(
    (inputElement) => {
      register(inputElement, { required: true });
      if (inputElement) {
        setTimeout(() => {
          inputElement.focus();
          inputElement.select();
        }, 1);
      }
    },
    [register]
  );

  return (
    <div className="new-meeting-content content" id="new-meeting-popup">
      <h1>New Meeting</h1>
      <form>
        <div className="field">
          <div className="control">
            <input
              className="input meeting-name-input"
              name="name"
              ref={focusRef}
              defaultValue={session.attributes.name}
              onChange={debouncedUpdateName}
            />
          </div>
          {errors.name && <span>This field is required</span>}
        </div>

        <div className="field has-addons">
          <div className="control is-expanded">
            <input className="input" value={meetingUrl} readOnly></input>
          </div>

          <CopyToClipboard text={meetingUrl} onCopy={triggerCopiedTooltip}>
            <div onClick={(e) => e.stopPropagation()} className="is-clickable">
              <Tippy content="Copy URL">
                <div>
                  <Tippy
                    content="Link copied to clipboard!"
                    visible={showCopiedTooltip}
                    className="blue-tippy"
                    arrow={false}
                  >
                    <div className="control">
                      {/* eslint-disable-next-line */}
                      <a className="button is-sblue input-attached-button">
                        Copy
                      </a>
                    </div>
                  </Tippy>
                </div>
              </Tippy>
            </div>
          </CopyToClipboard>
        </div>
      </form>
    </div>
  );
};

export default observer(NewMeetingPopup);
