import { action, reaction } from "mobx";
import { setConsumerLayer } from "../utilities/daily-hacks";
import { containsScreenShare } from "./CallStateStore/CallStateStore";

export class BandwidthStore {
  constructor(rootStores) {
    this.rootStores = rootStores;
    this.callStateStore = rootStores.callStateStore;
    this.callStore = rootStores.callStore;
    this.diposer = function disposer() {};
  }

  init = () => {
    this.diposer = reaction(
      () => this.callStateStore.callItems,
      this.setBandwidth,
      { fireImmediately: true }
    );
  };

  dispose = () => {
    this.diposer();
  };

  setBandwidth = () => {
    if (!this.callStateStore.callItems) {
      return;
    }
    const screenMode = containsScreenShare(this.callStateStore.callItems);

    if (screenMode) {
      this.setLayerForAllVideoCallItems("low");
    } else {
      const tilesCount = this.callStore.videoCallItems.length;
      const totalParticipants = Object.keys(this.callStateStore.callItems)
        .length;
      if (tilesCount > 9 || (tilesCount > 6 && totalParticipants > 12)) {
        console.log(
          "Triggering Sidebar's big call mode (10+ on screen or (7+ on screen and 13+ in total). Lowering video quality."
        );
        this.setLayerForAllVideoCallItems("low");
      } else if (tilesCount > 2) {
        this.setLayerForAllVideoCallItems("medium");
      } else {
        this.setLayerForAllVideoCallItems("high");
      }
    }
  };

  @action
  setLayerForAllVideoCallItems = (newLimit) => {
    if (!window.rtcpeers || !window.rtcpeers.sfu) {
      // console.log("Not in SFU mode, so skipping bandwidth management.");
      return;
    }

    const layer = this.convertLimitToLayer(newLimit);
    const otherParticipants = this.callStore.videoCallItems.filter(
      (x) => !x.isLocal
    );

    for (let callItem of otherParticipants) {
      setConsumerLayer(callItem.dailyId, layer);
    }
  };

  convertLimitToLayer = (limit) => {
    let layer;
    if (limit === "low") {
      layer = 0;
    } else if (limit === "medium") {
      layer = 1;
    } else {
      layer = 2;
    }
    return layer;
  };
}
