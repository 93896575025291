import { action, computed, observable, makeObservable } from "mobx";
import { httpService } from "utilities/HttpService";
import Honeybadger from "honeybadger-js";

const sortSessions = (sessions) => {
  return sessions
    .slice()
    .sort((a, b) =>
      a.attributes["created-at"] < b.attributes["created-at"] ? 1 : -1
    );
};

export class SessionsStore {
  @observable.shallow data = [];
  @observable isFetching = false;
  @observable otherSpacesActivityCount = 0;
  @observable viewedSpaces = [];

  constructor(rootStores) {
    this.rootStores = rootStores;
    this.userStore = rootStores.userStore;
    this.api = rootStores.api;
    makeObservable(this);
  }

  @computed
  get sessions() {
    return sortSessions(this.data.filter((x) => !x.attributes["is-space"]));
  }

  @computed
  get spaces() {
    if (!this.data) return [];
    return sortSessions(this.data.filter((x) => x.attributes["is-space"]));
  }

  @action.bound
  setData(data) {
    this.data.replace(data);
  }

  fetchSessions = async () => {
    if (!this.userStore.organizationId) return;

    this.isFetching = true;
    try {
      const { data } = await httpService.get(
        `/api/v1/organizations/${this.userStore.userInfo.organization_id}/sessions`
      );

      this.setData(data.data);
    } catch (err) {
      if (err.response?.status === 401) {
        this.userStore.logout({ redirect: true });
      } else {
        Honeybadger.notify(err);
      }
    } finally {
      this.setFetching(false);
    }
  };

  @action.bound
  setFetching(value) {
    this.isFetching = value;
  }

  @action
  deleteSession = async (sessionId) => {
    try {
      await httpService.delete(`/api/v1/sessions/${sessionId}`);

      this.setData(this.data.filter((session) => session.id !== sessionId));
    } catch (err) {
      console.log(err, "error while deleting session");
      return this.errorStore.handleApiError(err);
    }
  };

  @action
  createSession = async (sessionData) => {
    let attributes = {
      name: sessionData.call_name,
      "is-space": sessionData.isSpace,
    };

    try {
      const payload = {
        data: {
          type: "sessions",
          attributes: attributes,
        },
      };

      const { data } = await httpService.post("/api/v1/sessions", payload);
      this.setData([...this.data, data.data]);
    } catch (err) {
      console.log(err, "error while creating session");
    }
  };

  @action
  updateSession = async (sessionData) => {
    const attributes = {};
    const { name, description, id } = sessionData;
    if (name) attributes.name = name;
    if (sessionData.hasOwnProperty("description")) {
      attributes.description = description ? description : "";
    }

    try {
      const payload = {
        data: {
          type: "sessions",
          attributes: attributes,
          id,
        },
      };

      const { data } = await this.api.updateSession(id, payload);
      this.setData([...this.data, data.data]);
    } catch (err) {
      console.log(err, "error while creating session");
    }
  };

  @action
  setOtherSpacesActivityCount(value) {
    this.otherSpacesActivityCount = value;
  }

  @action
  addViewedSpace(value) {
    this.viewedSpaces.push(value);
  }

  @action
  removeViewedSpace(value) {
    const idx = this.viewedSpaces.indexOf(value);
    this.viewedSpaces.splice(idx, 1);
  }
}
