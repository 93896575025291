import twemoji from "twemoji";
import ReactHtmlParser from "react-html-parser";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import "tippy.js/themes/light-border.css";

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";

import { SvgAvatar } from "../../util/svgavatar";

import Avatar from "../Avatar";
import ReadReciept from "../ReadReciept";

import { parseUrls } from "../../util/parser";

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageThumbnailStyle,
  messageDetailStyle,
  nameWrapperStyle,
  nameStyle,
  messageTxtWrapperStyle,
  messageTxtStyle,
} from "./style";

const receivermessagebuble = (props) => {
  if (!props.message.sender.avatar) {
    const uid = props.message.sender.getUid();
    const char = props.message.sender.getName().charAt(0).toUpperCase();

    props.message.sender.setAvatar(SvgAvatar.getAvatar(uid, char));
  }

  const avatar =
    props.type !== "user" ? (
      <div css={messageThumbnailStyle}>
        <Avatar
          cornerRadius="50%"
          borderColor={props.theme.color.secondary}
          borderWidth="1px"
          image={props.message.sender.avatar}
        />
      </div>
    ) : null;

  const name =
    props.type !== "user" ? (
      <div css={nameWrapperStyle(avatar)}>
        <span css={nameStyle(props)}>{props.message.sender.name}</span>
      </div>
    ) : null;

  const urlsParsedMessage = parseUrls(props.message.text);
  const emojiParsedMessage = twemoji.parse(urlsParsedMessage, {
    folder: "svg",
    ext: ".svg",
  });
  const parsedMessage = ReactHtmlParser(emojiParsedMessage);
  const emojiMessage = parsedMessage.filter(
    (message) => message instanceof Object && message.type === "img"
  );

  let showVariation = true;
  if (
    props.hasOwnProperty("widgetsettings") &&
    props.widgetsettings &&
    props.widgetsettings.hasOwnProperty("main") &&
    props.widgetsettings.main.hasOwnProperty("show_emojis_in_larger_size") &&
    props.widgetsettings.main["show_emojis_in_larger_size"] === false
  ) {
    showVariation = false;
  }

  return (
    <div css={messageContainerStyle()}>
      <div css={messageWrapperStyle()}>
        {avatar}
        <div css={messageDetailStyle(name)}>
          {name}
          <Tippy
            placement="right"
            theme="light-border"
            interactive={true}
            content={
              <ReadReciept theme={props.theme} showMark={false} {...props} />
            }
          >
            <div css={messageTxtWrapperStyle(props)}>
              <p
                css={messageTxtStyle(
                  parsedMessage,
                  emojiMessage,
                  showVariation
                )}
              >
                {parsedMessage}
              </p>
            </div>
          </Tippy>
        </div>
      </div>
    </div>
  );
};

export default receivermessagebuble;
