import Bowser from "bowser";
import DailyIframe from "@daily-co/daily-js";
import osDetector from "detect-os";

export function isNotCrashing() {
  let allow = true;

  const patterns = ["MSIE", "Trident/"];
  for (let pattern of patterns) {
    allow = allow && !window.navigator.userAgent.includes(pattern);
  }

  return allow;
}

export function isAllowed() {
  let allow = true;
  const browser = Bowser.getParser(window.navigator.userAgent);

  const patterns = ["EdgiOS", "EdgA"];
  for (let pattern of patterns) {
    allow = allow && !window.navigator.userAgent.includes(pattern);
  }

  const isValidBrowser = browser.satisfies({
    desktop: {
      electron: ">=6",
      chromium: ">=61",
      chrome: ">=61",
      firefox: ">=63",
      opera: ">=61",
      safari: ">=12",
      edge: ">=79",
    },
    tablet: {
      safari: ">=12",
    },
  });

  return allow && isValidBrowser;
}

export function shouldBlockBrowser() {
  const supportedBrowser =
    DailyIframe.supportedBrowser().supported && isAllowed();
  const osDetect = new osDetector();
  const isAndroid = osDetect.detect()?.os === "android";
  return !supportedBrowser || isAndroid;
}
