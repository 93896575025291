import { observer } from "mobx-react-lite";
import ReactModal from "react-modal";
import { MdClose } from "react-icons/md";
import "./Modal.scss";

function Modal({ onRequestClose, showingModal, children }) {
  return (
    <ReactModal
      isOpen={!!showingModal}
      overlayClassName="ReactModal__Overlay__disabled"
      className="ReactModal__Content__modal"
      onRequestClose={() => onRequestClose()}
    >
      <div className="modal-wrapper">
        <div className="modal-close-wrap" onClick={onRequestClose}>
          <MdClose size={20} />
        </div>

        <div className="modal-content content">{children}</div>
      </div>
    </ReactModal>
  );
}

export default observer(Modal);
