import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import Tippy from "@tippyjs/react";
import TrayButton from "../TrayButton/TrayButton";
import { useEventListener } from "hooks/useEventListener";
import { isAModifierKey, isTyping } from "utilities/dom";

const MicButton = ({
  hasBroadcastPermissions,
  isMicMuted,
  toggleMic,
  disabled,
  callObjectStore,
  theme,
}) => {
  const resetIdleTriggered = useCallback(() => {
    callObjectStore.setIdleTriggered(false);
  }, [callObjectStore]);

  const toggleMicOnM = useCallback(
    (e) => {
      e.stopPropagation();
      if (disabled || !hasBroadcastPermissions) return;
      const notM = isTyping(e) || isAModifierKey(e) || e.keyCode !== 77;
      if (notM) return;

      toggleMic();
    },
    [toggleMic, disabled, hasBroadcastPermissions]
  );

  useEventListener("keydown", toggleMicOnM, document);

  return (
    <Tippy
      content="We turned off your mic and camera since you were idle."
      theme="light"
      visible={callObjectStore.isIdleTriggered}
      interactive={true}
      onClickOutside={resetIdleTriggered}
      maxWidth="376px"
      className="idle-message"
    >
      <Tippy
        disabled={disabled || hasBroadcastPermissions}
        content="This room only allows designated speakers to broadcast."
        trigger="mouseenter focus click"
        theme="light"
      >
        <Tippy
          appendTo="parent"
          content={isMicMuted ? "Unmute (m)" : "Mute (m)"}
          disabled={disabled || !hasBroadcastPermissions}
          theme={theme}
        >
          <div>
            <TrayButton
              disabled={disabled || !hasBroadcastPermissions}
              onClick={() => {
                if (callObjectStore.isIdleTriggered) resetIdleTriggered();
                toggleMic();
              }}
            >
              {isMicMuted ? (
                <FaMicrophoneSlash size="30px" color="#fb5554" />
              ) : (
                <FaMicrophone size="24px" color="#fff" />
              )}
            </TrayButton>
          </div>
        </Tippy>
      </Tippy>
    </Tippy>
  );
};

export default observer(MicButton);
