import { useEffect, useCallback, useRef } from "react";
import { observer } from "mobx-react-lite";
import "./SettingsInCall.scss";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useStores } from "../../stores/utils";

export default observer(function SettingsInCall({ toggleSettings }) {
  const { callSettingsStore, callObjectStore } = useStores();

  const divRef = useRef(null);

  const chooseCam = useCallback(
    (dropdownOption) => {
      callObjectStore.lookupDeviceById(dropdownOption.value).then((device) => {
        callSettingsStore.setCurrentCam(device);
        const oldMicId = callSettingsStore.currentMic?.deviceId;
        callObjectStore.setCallObjectInputDevices({
          audioDeviceId: oldMicId,
          videoDeviceId: dropdownOption.value,
        });
      });

      setTimeout(() => {
        toggleSettings();
      }, 800);
    },
    [callObjectStore, callSettingsStore, toggleSettings]
  );

  const chooseMic = useCallback(
    (dropdownOption) => {
      callObjectStore.lookupDeviceById(dropdownOption.value).then((device) => {
        callSettingsStore.setCurrentMic(device);
        const oldCamId = callSettingsStore.currentCam?.deviceId;
        callObjectStore.setCallObjectInputDevices({
          audioDeviceId: dropdownOption.value,
          videoDeviceId: oldCamId,
        });
      });

      setTimeout(() => {
        toggleSettings();
      }, 800);
    },
    [callObjectStore, callSettingsStore, toggleSettings]
  );

  useEffect(() => {
    callObjectStore.prepareDevices();

    return () => callObjectStore.resetDevices();
  }, [callObjectStore, callObjectStore.isCallObjectAvailable]);

  // List cameras and microphones.

  return (
    <div className="settings-in-call-modal" ref={divRef}>
      <div className="input-section">
        <h1>Camera</h1>
        <Dropdown
          options={callObjectStore.nonEmptyCameras}
          onChange={chooseCam}
          value={callSettingsStore.currentCam}
          placeholder="Select a camera"
        />
      </div>

      <div className="input-section">
        <h1>Microphone</h1>
        <Dropdown
          options={callObjectStore.nonEmptyMics}
          onChange={chooseMic}
          value={callSettingsStore.currentMic}
          placeholder="Select a microphone"
        />
      </div>
    </div>
  );
});
