import { observer } from "mobx-react-lite";
import PlaceholderProfilePhoto from "../Office/PlaceholderProfilePhoto";

function TinyProfilePhoto({ photoUrl, personName, placeholderColor }) {
  return (
    <div className="tiny-profile-photo-container">
      {photoUrl ? (
        <img
          src={photoUrl}
          alt={`Profile pic for ${personName}`}
          className="tiny-rounded-profile-photo"
        />
      ) : (
        <PlaceholderProfilePhoto
          photoUrl={photoUrl}
          personName={personName}
          color={placeholderColor}
        />
      )}
    </div>
  );
}

export default observer(TinyProfilePhoto);
