import { useEffect } from "react";
import { useStores, observe } from "stores/utils";
import { BannedUsersTable } from "./BannedUsersTable";
import { UnbanConfirmationModal } from "./UnbanConfirmationModal";
import "./BannedUsers.scss";

export const BannedUsers = () => {
  const { bannedUsersStore } = useStores();

  useEffect(() => {
    bannedUsersStore.getBannedUsers();
  }, [bannedUsersStore]);

  return observe(bannedUsersStore.isFetching, () => {
    if (bannedUsersStore.isFetching) {
      return <div className="spinner" />;
    }

    return (
      <div className="banned-users-container">
        <h1 className="title is-1">Banned Users</h1>
        <div className="box banned-users-table-box">
          <BannedUsersTable
            bannedUsers={bannedUsersStore.data}
            onUnbanUser={bannedUsersStore.setUnbanUserId}
          />
        </div>
        {observe(bannedUsersStore.unbanUserId, () =>
          bannedUsersStore.unbanUserId ? (
            <UnbanConfirmationModal
              onCancel={() => bannedUsersStore.setUnbanUserId(null)}
              onConfirm={() => bannedUsersStore.unbanUser()}
            />
          ) : null
        )}
      </div>
    );
  });
};
