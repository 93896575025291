import { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import Tippy from "@tippyjs/react";
import { FiMessageCircle } from "react-icons/fi";
import "./ParticipantCaret.scss";
import { ReactComponent as OpenmojiWave } from "../../images/SVG/openmoji-wave.svg";
import { ReactComponent as OpenmojiFist } from "../../images/SVG/openmoji-fist.svg";
import { useStores } from "../../stores/utils";
import * as Amplitude from "../../utilities/amplitude";
import ActionMenuItem from "./ActionMenuItem";
import useDebounce from "../../hooks/useDebounce";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import "tippy.js/animations/shift-away.css";
import { CgArrowsExpandUpLeft } from "react-icons/cg";

const ParticipantCaret = ({
  alreadyInRoom,
  participant,
  children,
  positionTippyTop,
  parentEl,
  officeMode,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    appStore,
    chatStore,
    callStore,
    viewStore,
    userChatStore,
  } = useStores();
  const alreadyInCluster = participant.inSameCluster;

  const clusterPopulation = callStore.clusterParticipantIds.length;
  const hideStepAway = !callStore.clustersEnabled || clusterPopulation < 2;
  const hideSidebar = !callStore.clustersEnabled || clusterPopulation < 3;

  const toggleOpen = useCallback(() => {
    Amplitude.track("Clicked on name under video", {
      clickedName: participant?.name,
    });

    setIsOpen((x) => !x);
  }, [participant]);

  const closeMenu = useCallback(() => {
    Amplitude.track("Closed Tile menu", {
      clickedName: participant?.name,
    });

    setIsOpen(false);
  }, [participant]);

  const silentCloseMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const debouncedCloseMenu = useDebounce(closeMenu, 1000, true);

  const sendPunchReaction = useCallback(() => {
    participant.sendReaction("punch", "Fist bump");

    closeMenu();
  }, [participant, closeMenu]);

  const sendWaveReaction = useCallback(() => {
    participant.sendReaction("wave", "Hi!");

    closeMenu();
  }, [participant, closeMenu]);

  const openChat = useCallback(() => {
    if (appStore.officeMode && participant?.userId) {
      userChatStore.openChat(participant?.chatId);
    } else {
      chatStore.openChat({
        key: participant?.chatId,
        type: "user",
      });
    }
    closeMenu();
  }, [chatStore, closeMenu, participant, userChatStore, appStore.officeMode]);

  const sendSidebarInvite = useCallback(() => {
    participant.sendInvite("sidebarInvite");

    debouncedCloseMenu();
  }, [debouncedCloseMenu, participant]);

  const allowCameraMic = useCallback(() => {
    participant.allowCameraMic();
    debouncedCloseMenu();
  }, [participant, debouncedCloseMenu]);

  const disableCameraMic = useCallback(() => {
    participant.disableCameraMic();
    debouncedCloseMenu();
  }, [participant, debouncedCloseMenu]);

  const muteParticipant = useCallback(() => {
    participant.mute();
    closeMenu();
  }, [closeMenu, participant]);

  const banParticipant = useCallback(() => {
    viewStore.hideBanModal();
    participant.banParticipant();
  }, [participant, viewStore]);

  const kickUser = useCallback(() => {
    closeMenu();
    viewStore.setBanModalContent(
      <ConfirmModal
        positiveCallback={() => banParticipant()}
        negativeCallback={viewStore.hideBanModal}
      />
    );
    viewStore.setShowBanModal(true);
  }, [closeMenu, viewStore, banParticipant]);

  const sendClusterSidebarInvite = useCallback(() => {
    participant.sendInvite("clusterSidebarInvite");
    debouncedCloseMenu();
  }, [debouncedCloseMenu, participant]);

  const sendJoinMeInvite = useCallback(() => {
    // should invite the user || participant to the room via an API call
    participant.joinMeInvite(
      callStore.currentRoomId,
      callStore.currentClusterId
    );
    // participant.sendInvite("joinMeInvite", {
    //   roomId: callStore.currentRoomId,
    //   clusterId: callStore.currentClusterId,
    // });

    debouncedCloseMenu();
  }, [
    callStore.currentRoomId,
    callStore.currentClusterId,
    debouncedCloseMenu,
    participant,
  ]);

  const stepAside = useCallback(() => {
    callStore.stepAside();
    closeMenu();
  }, [callStore, closeMenu]);

  const fullscreenEle =
    document.getElementsByClassName("primary-content")[0] ||
    document.getElementsByClassName("main-content")[0];
  const appendTo = viewStore.isFullScreening
    ? fullscreenEle
    : parentEl || document.body;
  const isMe = callStore.isMe(participant.id);
  const showCreateCluster = !callStore.isAuditorium && !hideStepAway;
  const meMenuIsEmpty = !showCreateCluster && officeMode;

  return (
    <>
      <Tippy
        visible={isOpen}
        onClickOutside={closeMenu}
        onHidden={silentCloseMenu}
        theme="light"
        arrow={false}
        animation="shift-away"
        className="participant-caret-tippy"
        placement={positionTippyTop ? "top" : "bottom"}
        interactive
        appendTo={appendTo}
        duration={[0, 100]}
        delay={[null, 100]}
        disabled={isMe && meMenuIsEmpty}
        content={
          isMe ? (
            <div className="participant-actions-menu">
              <ul>
                {showCreateCluster && (
                  <>
                    <li>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          stepAside();
                        }}
                        className="action-menu-item bottom-align-icon"
                      >
                        <CgArrowsExpandUpLeft />
                        <span className="item-text">Create a cluster</span>{" "}
                      </div>
                    </li>
                    {!officeMode && <div className="divider" />}
                  </>
                )}
                {!officeMode && (
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      viewStore.toggleShowEditNameForm();
                      closeMenu();
                    }}
                  >
                    <div>Edit my name</div>
                  </li>
                )}
              </ul>
            </div>
          ) : (
            <div className="participant-actions-menu">
              <ul>
                {callStore.isEventHost && callStore.currentRoom?.isAuditorium && (
                  <>
                    {participant?.authorized ? (
                      <li className="host-only-option">
                        <ActionMenuItem
                          label="Disable camera/mic"
                          onClick={disableCameraMic}
                        />
                      </li>
                    ) : (
                      <li className="host-only-option">
                        <ActionMenuItem
                          label="Allow camera/mic"
                          onClick={allowCameraMic}
                        />
                      </li>
                    )}

                    <div className="divider" />
                  </>
                )}

                {!alreadyInCluster && (
                  <li>
                    <ActionMenuItem
                      label="Join me!"
                      onClick={sendJoinMeInvite}
                    />
                  </li>
                )}

                {alreadyInCluster && !hideSidebar && !callStore.isAuditorium && (
                  <>
                    <li>
                      <ActionMenuItem
                        label="Invite to a cluster"
                        stepTexts={["Inviting ...", "Invited!"]}
                        onClick={() => sendClusterSidebarInvite()}
                        icon={<CgArrowsExpandUpLeft />}
                        bottomAlignIcon={true}
                      />
                    </li>
                    <div className="divider" />
                  </>
                )}
                {!callStore.inAnOffice && (
                  <>
                    <li>
                      <ActionMenuItem
                        label="Private Room"
                        onClick={sendSidebarInvite}
                      />
                    </li>
                    <div className="divider" />
                  </>
                )}

                <li>
                  <ActionMenuItem
                    label={<span className="reaction-option">Fist bump</span>}
                    onClick={sendPunchReaction}
                    icon={<OpenmojiFist />}
                    hideSending
                  />
                </li>
                <li>
                  <ActionMenuItem
                    label={<span className="reaction-option">Hi!</span>}
                    onClick={sendWaveReaction}
                    icon={<OpenmojiWave />}
                    hideSending
                  />
                </li>
                <li>
                  <ActionMenuItem
                    label={<span className="reaction-option">Message</span>}
                    onClick={openChat}
                    icon={<FiMessageCircle />}
                  />
                </li>
                {callStore.isEventHost && (
                  <>
                    <div className="divider" />
                    <li>
                      <ActionMenuItem
                        label="Mute user"
                        onClick={() => {
                          muteParticipant(participant.id);
                        }}
                        hideSending
                      />
                    </li>
                    <li>
                      <ActionMenuItem
                        label="Kick out user"
                        onClick={kickUser}
                        hideSending
                      />
                    </li>
                  </>
                )}
              </ul>
            </div>
          )
        }
      >
        {children(toggleOpen, isOpen)}
      </Tippy>
    </>
  );
};

export default observer(ParticipantCaret);
