import { observable, action, computed, makeObservable } from "mobx";
import Honeybadger from "honeybadger-js";

(function polyfillGetUserMedia() {
  if (typeof window === "undefined") {
    return;
  }

  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      // First get ahold of the legacy getUserMedia, if present
      const getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        return Promise.reject(
          new Error("getUserMedia is not implemented in this browser")
        );
      }

      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  }
})();

export class CallPreviewStore {
  @observable pendingPermission = false;
  @observable isPermissionError = false;
  @observable hasUserMedia = false;
  @observable src = null;
  @observable stream = null;
  @observable devices = [];
  @observable deniedBySystemError = false;
  @observable couldNotStartSource = false;
  selectedVideoDeviceId = null;
  selectedAudioDeviceId = null;
  videoRef = null;

  constructor(rootStores) {
    this.rootStores = rootStores;
    this.callObjectStore = rootStores.callObjectStore;

    makeObservable(this);
  }

  hasGetUserMedia = () => {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  };

  @action
  setPending = (pending) => {
    this.pendingPermission = pending;
  };

  sourceSelected = (audioConstraints, videoConstraints) => {
    const constraints = {
      audio: true,
      video: { width: 2880, height: 1800 },
    };

    if (this.selectedAudioDeviceId) {
      constraints.audio = { deviceId: { ideal: this.selectedAudioDeviceId } };
    }

    if (this.selectedVideoDeviceId) {
      constraints.video = { deviceId: { ideal: this.selectedVideoDeviceId } };
    }

    this.timeout = setTimeout(() => {
      this.setPending(true);
    }, 1000);

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.selectedVideoDeviceId = stream
          .getVideoTracks()?.[0]
          ?.getSettings()?.deviceId;
        this.selectedAudioDeviceId = stream
          .getAudioTracks()?.[0]
          ?.getSettings()?.deviceId;

        this.setPending(false);
        this.handleUserMedia(null, stream);
        this.getUserDevices();
        window.clearTimeout(this.timeout);
      })
      .catch((e) => {
        this.setPending(false);
        this.handleUserMedia(e);
        this.setPermissionError(true);
        window.clearTimeout(this.timeout);
      });
  };

  @action
  setPermissionError = (isPermissionError) => {
    this.isPermissionError = isPermissionError;
  };

  requestUserMedia = () => {
    if ("mediaDevices" in navigator) {
      this.sourceSelected();
    } else {
      Honeybadger.notify("User has no mediaDevices");
    }
  };

  @action
  changeAudioDevice = (deviceId) => {
    this.selectedAudioDeviceId = deviceId;
    this.requestUserMedia();
    this.callObjectStore.setCallObjectInputDevices({ audioDeviceId: deviceId });
  };

  @action
  changeVideoDevice = (deviceId) => {
    this.selectedVideoDeviceId = deviceId;
    this.requestUserMedia();
    this.callObjectStore.setCallObjectInputDevices({ videoDeviceId: deviceId });
  };

  @action
  changeDevices = (devices) => {
    this.devices = devices;
  };

  @action
  getUserDevices = () => {
    return navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        this.changeDevices(devices);
      })
      .catch((err) => {
        Honeybadger.notify(err, "error getting user devices for preview");
      });
  };

  @action
  setVideoRef = (ref) => {
    this.videoRef = ref;
  };

  @action
  handleUserMedia = (err, stream) => {
    if (err || !stream) {
      this.hasUserMedia = false;
      this.onUserMediaError(err);

      return;
    }

    this.stream = stream;

    try {
      setTimeout(() => {
        this.src = stream;
      }, 1000);
      this.videoRef.srcObject = stream;
      this.hasUserMedia = true;
    } catch (error) {
      this.src = window.URL.createObjectURL(stream);
      this.hasUserMedia = true;
    }
  };

  onUserMediaError = (err) => {
    if (err.message === "Permission denied by system") {
      this.setDeniedBySystemError(true);
    } else if (err.message === "Could not start video source") {
      this.setCouldNotStartSource(true);
    }
  };

  @action
  setDeniedBySystemError = (deniedBySystemError) => {
    this.deniedBySystemError = deniedBySystemError;
  };

  @action
  setCouldNotStartSource = (deniedBySystemError) => {
    this.couldNotStartSource = true;
  };

  @action
  stopAndCleanup = () => {
    if (this.hasUserMedia) {
      this.stopMediaStream(this.stream);

      if (this.src) {
        this.src = null;
        this.hasUserMedia = false;
        this.videoRef = null;
      }
    }
  };

  @action
  stopMediaStream = (stream) => {
    if (stream) {
      if (stream.getVideoTracks && stream.getAudioTracks) {
        stream.getVideoTracks().map((track) => track.stop());
        stream.getAudioTracks().map((track) => track.stop());
      } else {
        stream.stop();
      }

      this.stream = null;
    }
  };

  @computed
  get cameraDevicesOptions() {
    return this.devices
      .filter((device) => device.kind === "videoinput")
      .map((device) => ({ label: device.label, value: device.deviceId }));
  }

  @computed
  get audioDevicesOptions() {
    return this.devices
      .filter((device) => device.kind === "audioinput")
      .map((device) => ({ label: device.label, value: device.deviceId }));
  }

  @computed
  get selectedCameraOption() {
    return this.cameraDevicesOptions.find(
      (option) => option.value === this.selectedVideoDeviceId
    );
  }

  @computed
  get selectedAudioOption() {
    return this.audioDevicesOptions.find(
      (option) => option.value === this.selectedAudioDeviceId
    );
  }
}
