import { useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import RealTimeQuillEditor from "../RealTimeQuillEditor/RealTimeQuillEditor";
import { useStores } from "../../stores/utils";
import { throttle } from "lodash";
import "./DocPane.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import DocViewer from "components/GroupDocs/DocViewer";
import DocTitle from "./DocTitle";

const DocPane = () => {
  const { callStore, docStore, userStore } = useStores();
  const editing = docStore.isEditor;

  const apiKey = process.env.REACT_APP_CODOX_QUILL_KEY;

  const user = userStore.userInfo;
  const userName = user.display_name;
  const userEmail = user.email;
  const userId = user.id;

  useEffect(() => {
    docStore.initCodox();

    return () => {
      docStore.teardownCodox();
    };
  }, [docStore]);

  const doc = docStore.openDoc;
  const docId = docStore.openDoc?.id;
  const docContent = docStore.openDoc?.content;
  const codox = docStore.codox;

  // eslint-disable-next-line
  const saveDoc = useCallback(
    throttle(
      async (docId, content) => {
        await docStore.saveDocToBackend(docId, content);
      },
      1000,
      { leading: false }
    ),
    [docStore]
  );

  const closeDocPane = () => {
    docStore.closeDocPane(callStore.currentRoomId);
  };

  const startEditing = () => {
    docStore.startEditing(docId);
  };

  const stopEditing = () => {
    docStore.stopEditing(docStore.isEditor);
  };

  return docId ? (
    <div className="doc-pane">
      <div className="doc-header">
        <DocTitle
          docName={doc?.name}
          docId={docId}
          editing={editing}
          startEditing={startEditing}
          stopEditing={stopEditing}
        />

        <div className="doc-closer" onClick={closeDocPane}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="editor-wrapper">
        {editing ? (
          <>
            <RealTimeQuillEditor
              userName={userName}
              userEmail={userEmail}
              userId={userId}
              apiKey={apiKey}
              codox={codox}
              docId={docId}
              model={docContent}
              saveDoc={saveDoc}
            />
          </>
        ) : (
          <DocViewer content={docContent} />
        )}
      </div>
    </div>
  ) : null;
};

export default observer(DocPane);
