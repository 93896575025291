/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";

import ReadReciept from "../ReadReciept";

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageImgWrapper,
  messageInfoWrapperStyle,
} from "./style";

const senderimagebubble = (props) => {
  return (
    <div css={messageContainerStyle()}>
      <div css={messageWrapperStyle()}>
        <div css={messageImgWrapper()}>
          <img src={props.message.data.url} alt="sender" />
        </div>
        <div css={messageInfoWrapperStyle()}>
          <ReadReciept {...props} />
        </div>
      </div>
    </div>
  );
};

export default senderimagebubble;
