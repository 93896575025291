import { AppStore } from "./AppStore";
import { CallStore } from "./CallStore";
import { NetworkStatsStore } from "./NetworkStatsStore";
import { UserStore } from "./UserStore";
import { UsageSummaryStore } from "./UsageSummaryStore";
import { ChatStore } from "./ChatStore";
import { SubscriptionsStore } from "./SubscriptionsStore";
import Api from "../utilities/Api";
import { NotificationModalStore } from "./NotificationModalStore";
import { CallStateStore } from "./CallStateStore/CallStateStore";
import { ErrorStore } from "./ErrorStore";
import { CableSocketStore } from "./CableSocketStore";
import { ViewStore } from "./ViewStore";
import { UserReactionStore } from "./UserReactionStore";
import { InviteStore } from "./InviteStore";
import { CallSettingsStore } from "./CallSettingsStore";
import { TilesStore } from "./TilesStore";
import { CallObjectStore } from "./CallObjectStore";
import { MoreMenuStore } from "./MoreMenuStore";
import { BandwidthStore } from "./BandwidthStore";
import { TeamStore } from "./TeamStore";
import { UserSubscriptionStore } from "./UserSubscriptionStore";
import { PermissionsStore } from "./PermissionsStore";
import { SessionsStore } from "stores/SessionsStore";
import { ExtraMenuStore } from "./ExtraMenuStore";
import { BannedUsersStore } from "./BannedUsersStore";
import { NetworkLogStore } from "./NetworkLogStore";
import { CallPreviewStore } from "./CallPreviewStore";
import { OfficeStore } from "./OfficeStore";
import { AblyStore } from "./AblyStore";
import * as Ably from "ably";
import { UserChatStore } from "./UserChatStore";
import { QueryClient } from "react-query";
import { RoomReactionStore } from "./RoomReactionStore";
import { TileSettingsStore } from "./TileSettingsStore";
import { SharedCursorsStore } from "./SharedCursorsStore";
import { WebWorkersStore } from "./WebWorkersStore";
import { DocStore } from "./DocStore";
export class RootStores {
  api = null;
  ably = null;

  constructor() {
    this.api = new Api(this);
    this.queryClient = new QueryClient();
    this.appStore = new AppStore();
    this.notificationModalStore = new NotificationModalStore(this);
    this.callStateStore = new CallStateStore(this);
    this.callObjectStore = new CallObjectStore(this);
    this.callStore = new CallStore(this);
    this.networkStatsStore = new NetworkStatsStore(this);
    this.usageSummaryStore = new UsageSummaryStore(this);
    this.subscriptionsStore = new SubscriptionsStore(this);
    this.userSubscriptionStore = new UserSubscriptionStore(this);
    this.userStore = new UserStore(this);
    this.chatStore = new ChatStore(this);
    this.errorStore = new ErrorStore(this);
    this.docStore = new DocStore(this);
    this.cableSocketStore = new CableSocketStore(this);
    this.viewStore = new ViewStore(this);
    this.userReactionStore = new UserReactionStore(this);
    this.inviteStore = new InviteStore(this);
    this.callSettingsStore = new CallSettingsStore(this);
    this.tilesStore = new TilesStore(this);
    this.moreMenuStore = new MoreMenuStore(this);
    this.bandwidthStore = new BandwidthStore(this);
    this.teamStore = new TeamStore(this);
    this.permissionsStore = new PermissionsStore(this);
    this.sessionsStore = new SessionsStore(this);
    this.extraMenuStore = new ExtraMenuStore(this);
    this.bannedUsersStore = new BannedUsersStore(this);
    this.networkLogStore = new NetworkLogStore(this);
    this.callPreviewStore = new CallPreviewStore(this);
    this.officeStore = new OfficeStore(this);
    this.ablyStore = new AblyStore(this);
    this.userChatStore = new UserChatStore(this);
    this.roomReactionStore = new RoomReactionStore(this);
    this.tileSettingsStore = new TileSettingsStore(this);
    this.sharedCursorsStore = new SharedCursorsStore(this);
    this.webWorkersStore = new WebWorkersStore(this);
  }

  setupAbly(token) {
    if (!token) {
      return;
    }
    this.ably = new Ably.Realtime({
      authUrl: process.env.REACT_APP_API_HOST + "/api/v1/ably_auth",
      authHeaders: {
        Authorization: `Bearer: ${token}`,
      },
    });
  }
}

export const rootStores = new RootStores();

// window.rs = rootStores; // for debugging
