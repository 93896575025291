import { observable, action, makeObservable } from "mobx";
import Anime from "react-anime";

export class RoomReactionStore {
  @observable myReaction = undefined;
  @observable otherUserReactions = {};
  @observable reactionsLibrary = {
    "0x1f91a": "✋",
    "0x1f44d": "👍",
    "0x1f44e": "👎",
    "0x1f44f": "👏",
    "0x1f44b": "👋",
    "0x1f389": "🎉",
    "❤️": "❤️", //2764 FE0F
    "0x1f602": "😂",
    "0x1f62e": "😮",
    "0x1f914": "🤔",
  };

  constructor(rootStores) {
    this.rootStores = rootStores;
    this.api = rootStores.api;
    makeObservable(this);
  }

  @action
  displayReaction(reactionId, otherUserId) {
    const newReaction = this.reactionsLibrary[reactionId];

    if (otherUserId) {
      this.addReaction(newReaction, otherUserId);
    } else {
      this.addReaction(newReaction);
      this.broadcastMyReaction(reactionId);
    }
  }

  @action
  broadcastMyReaction = async (reactionId) => {
    const groupId = this.rootStores.callStore.currentRoomId;
    const myParticipantId = this.rootStores.callStore.participantId;
    try {
      await this.api.broadcastMyReaction(groupId, myParticipantId, reactionId);
    } catch (err) {
      console.log(err);
      return this.rootStores.errorStore.handleApiError(err);
    }
  };

  @action
  addReaction = (item, otherUserId) => {
    const ele = (
      <Anime
        easing="easeOutElastic"
        duration={3000}
        translateY="-5vh"
        scale={[0.1, 1]}
      >
        <Anime delay={2500} opacity={[1, 0]}>
          {item}
        </Anime>
      </Anime>
    );

    if (otherUserId) {
      this.otherUserReactions[otherUserId] = ele;
    } else {
      this.myReaction = ele;
    }
  };

  @action
  resetReactions = (otherUserId) => {
    if (otherUserId) {
      this.otherUserReactions[otherUserId] = undefined;
    } else {
      this.myReaction = undefined;
    }
  };
}
