import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/utils";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import CreateInvitationModal from "../CreateInvitationModal";
import "./Team.scss";

import TeamMembersList from "./TeamMembersList";

const Team = () => {
  const { teamStore, permissionsStore } = useStores();

  useEffect(() => {
    teamStore.init();
  }, [teamStore]);

  if (!permissionsStore.canSeeTeamPage) return <ErrorPage status={403} />;

  return (
    <div className="team-management">
      <h1 className="title is-1">Team</h1>

      {permissionsStore.canInvite && (
        <div
          className="button invite-teammate-button is-sblue"
          onClick={teamStore.toggleShowCreateModal}
        >
          Invite teammate
        </div>
      )}

      <div className="box">
        <table className="table is-striped is-fullwidth team-table">
          <thead>
            <tr>
              <th className="team-table__column-header">Name</th>
              <th className="team-table__column-header">Email</th>
              <th className="team-table__column-header">Invited by</th>
              <th className="team-table__status-header">Role</th>
              <th></th>
            </tr>
          </thead>

          <TeamMembersList teamStore={teamStore} />
        </table>
      </div>
      <CreateInvitationModal />
    </div>
  );
};

export default observer(Team);
