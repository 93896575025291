import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import * as Yup from "yup";
import { Formik } from "formik";
import { loadStripe } from "@stripe/stripe-js";
import { CreditCardForm } from "components/SignupForm/CreditCardForm";
import { SubscriptionForm } from "components/SignupForm/SubscriptionForm";
import { Billing } from "components/SignupForm/Billing";
import "./SubscriptionInfo.scss";

const BillingAddressSchema = Yup.object().shape({
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  postal_code: Yup.string().required(
    "Postal code is required"
  ),
  line1: Yup.string().required("Address is required"),
  line2: Yup.string(),
});

export const SubscriptionInfo = ({ onSubmit, signupStore }) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const strPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    setStripePromise(strPromise);
  }, []);

  if (!stripePromise) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        city: "",
        country: "US",
        state: "",
        postal_code: "",
        line1: "",
        line2: "",
      }}
      validationSchema={BillingAddressSchema}
    >
      <Elements stripe={stripePromise}>
        <div className="subscription-wrap">
          <h1 className="title is-3 is-size-3-mobile is-spaced subscription-title">
            Confirm your plan
          </h1>
          <div className="subtitle">
            14 day trial, free for up to 20 participant hours. Cancel any time.
          </div>
          <div className="subscription-layout">
            <div>
              <SubscriptionForm />
              <CreditCardForm onSubmit={onSubmit} />
            </div>
            <div>
              <Billing onSubmit={onSubmit} signupStore={signupStore} />
            </div>
          </div>
        </div>
      </Elements>
    </Formik>
  );
};
