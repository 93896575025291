import { observer } from "mobx-react-lite";
import Loader from "../../Loader";

const TotalUsage = ({ store }) => {
  return (
    <div className="total-usage-row">
      <div className="info-block box mb10">
        <div className="main-value">
          <span className="main-value__number">{store.totalUsage.hours}</span>{" "}
          <span className="main-value__label is-size-6">hours</span>
          <div className="minutes-spacer"></div>
          <span className="main-value__number">
            {store.totalUsage.minutes}
          </span>{" "}
          <span className="main-value__label is-size-6">minutes</span>
          {store.isWaitingPushData && (
            <div className="main-value__loader-wrap">
              <Loader />
            </div>
          )}
        </div>

        <div className="main-value--label">used this month</div>
      </div>
      {store.isWaitingForPushData && (
        <div className="total-usage-row__waiting">
          <h1>
            Fetching latest usage data...{" "}
            <div className="total-usage-row__waiting-loader">
              <Loader />
            </div>
          </h1>
        </div>
      )}
    </div>
  );
};

export default observer(TotalUsage);
