import { observable, action, makeObservable, computed } from "mobx";
import { CometChat } from "@cometchat-pro/chat";
import * as Sounds from "../utilities/sounds";
import { setPwaBadge, clearPwaBadge } from "utilities/pwa";

export const USER_CHAT_STATE_LOADING = "USER_CHAT_STATE_LOADING";
export const USER_CHAT_STATE_LOADED = "USER_CHAT_STATE_LOADED";

export class UserChatStore {
  @observable userChat = [];
  @observable newMessages = {};
  @observable chatState = USER_CHAT_STATE_LOADING;
  @observable initialized = false;
  @observable tokenHash = undefined;
  @observable cometChatListenerId = undefined;
  @observable currentOpenedChatId = null;
  @observable offscreenNotifRefTop = undefined;
  @observable offscreenNotifRefBottom = undefined;
  @observable afterLoadCallbacks = [];
  @observable totalMessageCount = 0;
  @observable offscreenReferenceList = [];
  @observable isShowingOffscreenNotifTop = false;
  @observable isShowingOffscreenNotifBottom = false;

  constructor(rootStores) {
    this.rootStores = rootStores;
    makeObservable(this);
  }

  @action
  setChatState(value) {
    this.chatState = value;
  }

  @action
  setUserChat = (newChat) => {
    this.userChat = [newChat];
  };

  @action
  closeChat = () => {
    this.userChat = [];
  };

  @action
  setCurrentOpenedChatId = (uid) => {
    this.currentOpenedChatId = uid;
  };

  @action
  setNewMessages = (id, message) => {
    if (!this.newMessages[id]) {
      this.newMessages[id] = [];
    }
    this.newMessages[id].push(message);
    this.setTotalMessageCount(this.totalMessageCount + 1);
  };

  @action
  clearNewMessages = (id) => {
    if (this.newMessages[id] && this.newMessages[id].length) {
      const newCount = this.totalMessageCount - this.newMessages[id].length;
      this.setTotalMessageCount(newCount);
    }
    this.newMessages[id] = [];
  };

  addMessageNotification = (message) => {
    if (message.metadata.isUserToUser) {
      const id = message.sender.uid;

      if (
        !this.rootStores.callStore.currentRoomId &&
        !this.rootStores.officeStore.isDoNotDisturb
      ) {
        if (!document.hasFocus()) {
          Sounds.playDirectMessage();

          const text =
            `${message.sender?.name} sent you a new message` ||
            "You received a new message";

          const notification = new Notification("Sidebar", {
            body: text,
          });

          notification.onclick = function () {
            window.focus();
          };
        } else if (this.currentOpenedChatId !== id) {
          Sounds.playDirectMessage();
        }
      }

      if (this.currentOpenedChatId !== id) {
        this.setNewMessages(id, message);
      }
    }
  };

  // does not do anything, but we should consider adding/removing listeners in the future
  @action
  setListenerId(id) {
    this.cometChatListenerId = id;
  }

  setupCometChatListener = () => {
    console.log("[userChat] SETTING UP COMET CHAT LISTENERS !!!!");
    const myId = this.rootStores.userStore.userId;

    this.setListenerId(myId);
    CometChat.addMessageListener(
      myId,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage) => {
          console.log(
            "[userChat] Text message received successfully",
            textMessage
          );
          // Handle text message
          this.addMessageNotification(textMessage);
        },
        onMediaMessageReceived: (mediaMessage) => {
          console.log(
            "[userChat] Media message received successfully",
            mediaMessage
          );
          // Handle media message
          this.addMessageNotification(mediaMessage);
        },
        onCustomMessageReceived: (customMessage) => {
          console.log(
            "[userChat] Custom message received successfully",
            customMessage
          );
          // Handle custom message
          this.addMessageNotification(customMessage);
        },
      })
    );

    this.afterLoadCallbacks.forEach((callback) => {
      callback();
    });
  };

  openChat = (personId) => {
    this.clearNewMessages(personId);
    this.setCurrentOpenedChatId(personId);
    this.setUserChat({
      key: personId,
      type: "user",
    });
  };

  @action
  populateUnreadMessages(unreadMessages) {
    let total = 0;
    unreadMessages.forEach((msg) => {
      if (msg.unread_count > 0) {
        this.newMessages[msg.sender_id] = [...Array(msg.unread_count)].map(
          (x) => "msg"
        );
        total += msg.unread_count;
      }
    });

    if (total) {
      this.setTotalMessageCount(total);
    }
  }

  @action
  setTotalMessageCount(number) {
    this.totalMessageCount = number;
    if (number <= 0) {
      this.tryClearAppBadge();
    } else {
      this.trySetAppBadge(number);
    }
  }

  @action
  trySetAppBadge(messagesCount) {
    setPwaBadge(messagesCount);
  }

  @action
  tryClearAppBadge() {
    clearPwaBadge();
  }

  @action
  addAfterLoadCallback(callback) {
    this.afterLoadCallbacks = [...this.afterLoadCallbacks, callback];
  }

  @action
  setOffscreenNotifRef = (ele, pos) => {
    if (pos === "top") {
      this.offscreenNotifRefTop = ele;
    } else {
      this.offscreenNotifRefBottom = ele;
    }
  };

  @action
  setShowingOffscreenNotif = (val, pos) => {
    if (pos === "top") {
      this.isShowingOffscreenNotifTop = val;
    } else {
      this.isShowingOffscreenNotifBottom = val;
    }
  };

  @action
  addToOffscreenReferenceList(idx) {
    this.offscreenReferenceList.push(idx);
  }

  @action
  removeFromOffscreenReferenceList(idx) {
    this.offscreenReferenceList = this.offscreenReferenceList
      .filter((num) => num !== idx)
      .slice();
  }

  @computed
  get lastOffscreenReference() {
    return Math.max(...this.offscreenReferenceList);
  }

  @computed
  get offscreenReferenceLength() {
    return this.offscreenReferenceList.length;
  }

  @computed
  get firstOffscreenReference() {
    return Math.min(...this.offscreenReferenceList);
  }
}
