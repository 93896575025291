import { observer } from "mobx-react-lite";
import { useRef, useEffect } from "react";

const TileAudio = ({ isLocalPerson, audioTrack, volume }) => {
  const audioEl = useRef(null);

  /**
   * When audio track changes, update audio srcObject
   */
  useEffect(() => {
    if (audioEl.current && audioTrack) {
      audioEl.current.srcObject = new MediaStream([audioTrack]);
      if (volume != null) {
        audioEl.current.volume = volume;
      }
    }
  }, [audioTrack, volume]);

  if (isLocalPerson || !audioTrack) return null;

  return <audio autoPlay playsInline ref={audioEl} volume={volume} />;
};

export default observer(TileAudio);
