import { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import ReactModal from "react-modal";
import { useForm } from "react-hook-form";
import { useStores } from "../../../stores/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function CreateInvitationModal() {
  const { teamStore, userStore } = useStores();

  const [emailList, setEmailList] = useState("");

  const { register, handleSubmit /* watch, errors, reset*/ } = useForm();

  const callbackRef = useCallback(
    (inputElement) => {
      register(inputElement);
      if (inputElement && inputElement.name === "email_list") {
        inputElement.focus();
      }
    },
    [register]
  );

  return (
    <ReactModal
      isOpen={teamStore.isShowCreateModal}
      onRequestClose={teamStore.toggleShowCreateModal}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      className="custom-modal office-invite-modal"
      overlayClassName="modal-background"
    >
      <div className="modal-content">
        <div className="box is-info">
          <h1 className="title is-4 is-size-4-mobile">
            Invite people to {userStore.organizationName}
          </h1>
          <form onSubmit={handleSubmit(teamStore.bulkInvite)}>
            <div className="field email-addresses-field">
              <label className="label">Email addresses (1 per line)</label>
              <div className="control">
                <textarea
                  className="textarea"
                  name="email_list"
                  placeholder={`alex@sidebar.vc\ncasey@sidebar.vc`}
                  ref={callbackRef}
                  onChange={(event) => setEmailList(event.target.value)}
                ></textarea>
              </div>
            </div>

            <input
              type="hidden"
              name="for_office"
              value="true"
              ref={callbackRef}
            />

            <div className="field is-grouped is-grouped-right">
              <div className="control">
                {/* eslint-disable-next-line */}
                <a
                  className="button is-link is-light"
                  onClick={teamStore.toggleShowCreateModal}
                >
                  Cancel
                </a>
              </div>
              <div className="control">
                <button
                  disabled={!emailList.length || teamStore.isLoading}
                  type="submit"
                  className="button is-link send-button"
                  value="Invite"
                >
                  <FontAwesomeIcon icon={faEnvelope} /> Send
                </button>
              </div>

              {teamStore.isLoading && (
                <div className="create-call-loading"></div>
              )}
            </div>
          </form>
        </div>
      </div>
    </ReactModal>
  );
}

export default observer(CreateInvitationModal);
