import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import Tippy from "@tippyjs/react";
import ContentEditable from "react-contenteditable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrashAlt,
  faUserCog,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import JoinButton from "../JoinButton/JoinButton";
import Toggle from "../Toggle/Toggle";
import CallPreview from "components/CallPreview";
import { STATE_IDLE } from "../../stores/AppStore";
import { useStores } from "../../stores/utils";
import { faPodium } from "@fortawesome/pro-solid-svg-icons";
import * as Amplitude from "../../utilities/amplitude";
import KnockButton from "../KnockButton/KnockButton";
import "./RoomName.scss";
import "tippy.js/themes/light.css";
import classnames from "classnames";

const placeCaretAtEnd = (el, room) => {
  if (!room.canEdit) return;
  el.focus();
  if (
    typeof window.getSelection != "undefined" &&
    typeof document.createRange != "undefined"
  ) {
    var range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange != "undefined") {
    var textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
};

function RoomName({
  setFocusedEditRoom,
  focusedEditRoom,
  room,
  noActions,
  isFoyer,
  onClickRoom,
}) {
  const { viewStore, callStore, appStore, officeStore } = useStores();
  const [showCallPreview, setShowCallPreview] = useState(false);
  const [participantName, setParticipantName] = useState(null);
  const [knockId, setKnockId] = useState(undefined);
  const joinButtonsDisabled = callStore.joinButtonsDisabled;

  const {
    id: roomId,
    attributes: { name, permanent, lobby },
  } = room;

  const [text, setText] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const contentEditableRef = useRef();

  const dailyUrl = room.attributes["daily-url"];
  const isLocked = room.attributes["locked"];
  const isSidebar = room.attributes["sidebar"];
  const isOfficeModeOrASidebar = appStore.officeMode || isSidebar;

  const handleChange = useCallback((evt) => {
    setText(evt.target.value.trim());
  }, []);

  const handleBlur = useCallback(async () => {
    Amplitude.track("Saving new room name", {
      newRoomName: contentEditableRef.current.innerText.trim(),
    });
    if (text !== contentEditableRef.current.innerText.trim()) {
      setShowLoader(true);
      await room.updateName(contentEditableRef.current.innerText.trim());
      setShowLoader(false);
    }
  }, [room, text]);

  const pressEnter = useCallback((e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }, []);

  useEffect(() => {
    if (name) {
      setText(name);
    }
  }, [name, lobby]);

  const clickRoomName = useCallback(() => {
    Amplitude.track("Clicked room name (to edit)", { roomName: name });
    if (setFocusedEditRoom) {
      setFocusedEditRoom(roomId);
    }
  }, [name, roomId, setFocusedEditRoom]);

  const onJoin = useCallback(
    async (callbackParticipantName) => {
      localStorage.setItem("lastJoinedSessionId", callStore.sessionId);
      const name =
        callbackParticipantName && typeof callbackParticipantName === "string"
          ? callbackParticipantName
          : participantName;
      return room.handleClickRoom({
        newParticipantName: name,
        joinButtonsDisabled,
      });
    },
    [joinButtonsDisabled, room, participantName, callStore]
  );

  const joinOrPreview = useCallback(
    (participantName) => {
      const lastJoinedRoomId = localStorage.getItem("lastJoinedSessionId");
      const skipCallPreview =
        lastJoinedRoomId === callStore.sessionId || !isFoyer;
      if (skipCallPreview) {
        onJoin(participantName);
      } else {
        setShowCallPreview(true);
      }
    },
    [onJoin, setShowCallPreview, isFoyer, callStore]
  );

  const clickRoomHere = useCallback(
    (event, roomId, dailyUrl, appState) => {
      if (onClickRoom) {
        return onClickRoom(event, roomId, dailyUrl, appState);
      }

      if (!appStore.officeMode && appState === STATE_IDLE) {
        return viewStore.toggleShowNameForm((participantName) => {
          setParticipantName(participantName);
          joinOrPreview(participantName);
        });
      } else {
        if (appStore.officeMode) {
          officeStore.switchRooms(room, callStore.sessionId);
        } else {
          joinOrPreview();
        }
      }
    },
    [
      viewStore,
      joinOrPreview,
      callStore.sessionId,
      room,
      officeStore,
      onClickRoom,
      appStore.officeMode,
    ]
  );

  const clickRoom = (event) => {
    clickRoomHere(event, roomId, dailyUrl, appStore.appState);
  };

  const handleDeleteRoom = useCallback(
    async (groupId) => {
      if (!room.canDelete) return;

      setShowLoader(true);
      await room.delete();
    },
    [room]
  );

  const togglePermanent = useCallback(async () => {
    if (!room.canPermanent) return;
    setShowLoader(true);
    await room.togglePermanent();
    setShowLoader(false);
  }, [room]);

  const thriftyRoom = useMemo(() => {
    return room && !!room.attributes["thrifty-mode"];
  }, [room]);

  const knockingThisRoom = callStore.knockingGroupIds.includes(roomId);

  const knock = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const knockId = await callStore.knock(roomId);
    setKnockId(knockId);
  };

  const cancelKnock = async () => {
    await callStore.cancelKnock(knockId, roomId);
  };

  const joiningThisRoom = callStore.joiningRoomId === roomId;
  const hideTrash = noActions || joiningThisRoom || !room.canDelete;
  const hidePermanentIcon =
    noActions ||
    joiningThisRoom ||
    !callStore.isEventHost ||
    appStore.officeMode;
  const isAuditorium = callStore.checkIsAuditorium(room);

  const isCurrentRoom =
    callStore.currentRoomId && callStore.currentRoomId === roomId;

  return (
    <div className="lobby-name-row">
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item" onClick={() => clickRoomName()}>
            <div className="room-name">
              {isAuditorium && (
                <Tippy content="This is an auditorium. Everyone’s camera and microphone are off by default, but a host can invite you to to turn them on and address the room.">
                  <div className="podium-icon-wrapper">
                    <FontAwesomeIcon className="podium-icon" icon={faPodium} />
                  </div>
                </Tippy>
              )}
              <ContentEditable
                innerRef={contentEditableRef}
                html={text}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={!room.canEdit}
                className="editable-name"
                onKeyDown={pressEnter}
                id={roomId}
                placeholder={"Name the room"}
                spellCheck={false}
              />
              {thriftyRoom && (
                <Tippy content="Camera/mic start off">
                  <div className="thrifty-tag">$</div>
                </Tippy>
              )}
              <span className="no-break">
                <div
                  className={classNames("pencil-icon", {
                    "invisible-icon":
                      noActions || joiningThisRoom || !room.canEdit,
                  })}
                  onClick={() =>
                    placeCaretAtEnd(contentEditableRef.current, room)
                  }
                >
                  <div className="floating-actions">
                    <div className="pen-icon">
                      <FontAwesomeIcon icon={faPen} />
                    </div>
                    {!hideTrash && (
                      <div
                        className={classNames("trash-icon", {})}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteRoom(roomId);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </div>
                    )}

                    {!hidePermanentIcon && (
                      <Tippy
                        content={
                          !permanent
                            ? "Anyone can edit or delete this room"
                            : "Only hosts can edit or delete this room"
                        }
                        disabled={hidePermanentIcon}
                      >
                        <div
                          className={classNames("permanent-icon", {
                            red: permanent,
                            green: !permanent,
                          })}
                          onClick={(e) => {
                            e.stopPropagation();
                            togglePermanent();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={permanent ? faUserCog : faUsersCog}
                          />
                        </div>
                      </Tippy>
                    )}

                    <div
                      className={classNames("room-action-loader", {
                        active: showLoader,
                      })}
                    />
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            {isCurrentRoom && isOfficeModeOrASidebar ? (
              <Tippy
                content={
                  <div className="tooltip-content">
                    {isLocked ? (
                      <div className="has-text-centered">
                        <p>Door is locked. Participants must knock.</p>
                        <p>Unlock?</p>
                      </div>
                    ) : (
                      <div>
                        <p>Lock door?</p>
                      </div>
                    )}
                  </div>
                }
              >
                <div
                  className={classnames("lock-toggle-container", {
                    unlocked: !isLocked,
                  })}
                >
                  <Toggle
                    className="locked-toggle"
                    checked={isLocked}
                    name="locked"
                    onChange={(event) => {
                      if (event.target.checked) {
                        room.lockRoom();
                      } else {
                        room.unlockRoom();
                      }
                    }}
                  />
                </div>
              </Tippy>
            ) : isLocked ? (
              <Tippy
                content={
                  <div className="tooltip-content">
                    <p>Room is locked.</p>
                    {!room.isCurrentRoom && (
                      <p>You can join if someone invites you.</p>
                    )}
                  </div>
                }
              >
                <>
                  <KnockButton
                    knocking={knockingThisRoom}
                    knock={knock}
                    cancelKnock={cancelKnock}
                    isEventsFoyer={isFoyer && !appStore.officeMode}
                  />
                </>
              </Tippy>
            ) : (
              !room.isCurrentRoom && (
                <JoinButton
                  join={clickRoom}
                  joining={callStore.joiningRoomId === roomId}
                  disabled={joinButtonsDisabled}
                  isFull={room.isFull}
                  isCurrentRoom={isCurrentRoom}
                />
              )
            )}
          </div>
        </div>
      </div>
      {showCallPreview && (
        <CallPreview
          onCancel={() => setShowCallPreview(false)}
          onJoin={onJoin}
        />
      )}
    </div>
  );
}

export default observer(RoomName);
