import { action, computed, observable, makeObservable } from "mobx";
import * as Amplitude from "../utilities/amplitude";

const deleteTeammateWarning =
  "Are you sure you want to remove this teammate from your organization?";

const changeMeRoleWarning =
  "Are you sure you want to make yourself a manager? This will mean you won’t be able to invite or remove teammates, manage teammates’ event links, or view billing info.";

export const getStatusLabel = (status) => {
  if (status === "host") return "Event Host";
  if (status === "admin") return "Admin";
  if (status === "superuser") return "Superuser";
  if (status === "manager") return "Manager";
  if (status === "invited") return "Invited";
  if (status === "member") return "Member";
};

export class TeamMemberModel {
  id;
  email;
  name;
  invitedBy;
  invitation;
  @observable status = null;

  @observable isLoading = false;

  constructor(data, teamStore) {
    this.teamStore = teamStore;
    this.mergeData(data);
    makeObservable(this);
  }

  togglePermittedEvent = async (eventId) => {
    await this.togglePermittedEventOnServer(eventId);

    this.teamStore.reloadHostPermissions();
  };

  togglePermittedEventOnServer = (eventId) => {
    return this.teamStore.rootStores.api.toggleHostPermission({
      userId: this.id,
      sessionId: eventId,
      organizationId: this.teamStore.rootStores.userStore.organizationId,
    });
  };

  @computed
  get permittedEvents() {
    return this.teamStore.hostPermissions
      .filter(
        (hostPermission) => hostPermission.attributes["user-id"] === this.id
      )
      .map((hostPermission) =>
        this.teamStore.rootStores.sessionsStore.sessions.find(
          (session) => session.id === hostPermission.attributes["session-id"]
        )
      )
      .filter((x) => Boolean(x));
  }

  @computed
  get permittedEventsIds() {
    return this.permittedEvents.map((x) => x.id);
  }

  mergeData(data) {
    Object.keys(data).forEach((k) => (this[k] = data[k]));
  }

  @computed
  get canDelete() {
    return this.id !== this.teamStore.rootStores.userStore?.userInfo?.id;
  }

  updateStatus = async (newValue) => {
    Amplitude.track("Updated status (role) of user", {
      memberId: this.id,
    });

    if (!this.validateStatus(newValue)) return;

    if (this.itsMe) {
      const sure = window.confirm(changeMeRoleWarning);

      if (!sure) {
        return;
      }
    }

    const oldValue = this.status;

    this.setStatus(newValue);

    const result = await this.updateStatusOnServer(newValue);

    if (!result) {
      this.setStatus(oldValue);
    }
  };

  updateStatusOnServer = async (newStatus) => {
    try {
      this.setLoading(true);

      if (this.invitation) {
        return await this.teamStore.rootStores.api.updateInvitation({
          id: this.id,
          role: this.status,
        });
      } else {
        return await this.teamStore.rootStores.api.updateUser({
          id: this.id,
          role: this.status,
        });
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        window.alert(e?.response?.data?.error);
      }
    } finally {
      this.setLoading(false);
    }
  };

  delete = async () => {
    Amplitude.track("Clicked delete team member in host dashboard", {
      memberId: this.id,
    });

    try {
      const sure = window.confirm(deleteTeammateWarning);

      if (sure) {
        this.setLoading(true);
        await this.teamStore.deleteTeamMember(this);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setLoading(false);
    }
  };

  @computed
  get itsMe() {
    return this.id === this.teamStore.rootStores.userStore.userInfo.id;
  }

  @computed
  get isLastAdmin() {
    return this.teamStore.adminsCount === 1;
  }

  @action.bound
  setLoading(value) {
    this.isLoading = value;
  }

  validateStatus = (newValue) => {
    return this.teamStore.allowedStatuses.some((x) => {
      return x === newValue;
    });
  };

  @action.bound
  setStatus(value) {
    this.status = value;
  }
}
