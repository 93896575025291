import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import "./MicrophoneList.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MicParticipantList from "../MicParticipantList";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-away.css";
import "tippy.js/themes/translucent.css";

export default observer(function MicrophoneList() {
  const { callStore } = useStores();

  let microphoneCount = 0;
  if (callStore.audioCallItems) {
    microphoneCount = callStore.audioCallItems.length;
  }

  return (
    <div className="microphone-list">
      {microphoneCount > 0 && (
        <Tippy
          content={<MicParticipantList />}
          trigger="mouseenter focus click"
          theme="translucent"
          interactive={true}
          arrow={false}
          animation="shift-away"
          className="microphone-list-tippy"
        >
          <div className="microphone-list-label">
            <div>
              <span>+ {microphoneCount}</span>
              <FontAwesomeIcon icon={faMicrophone} />
            </div>
            <div className="on-mic">on mic</div>
          </div>
        </Tippy>
      )}
    </div>
  );
});
