import Honeybadger from "honeybadger-js";
import { NotVerifiedModal } from "components/NotVerifiedModal";

export class ErrorStore {
  constructor(rootStores) {
    this.rootStores = rootStores;
  }

  handleApiError = (e, leaveCall = true) => {
    console.log("API error: ", e);
    console.log("error name, desc:", e.name, e.description);

    if (typeof e === "string" && e.includes("network is offline")) {
      this.rootStores.callStore.resetCall();
      Honeybadger.notify(e);
      this.rootStores.viewStore.showOfflineToast(e);
      return;
    }

    if (!e || !e.response) {
      this.rootStores.callStore.leaveCallAndLog(
        leaveCall,
        e,
        this.rootStores.viewStore.showErrorToast
      );
      return;
    }

    if (e.response.status === 401) {
      this.rootStores.userStore.softLogout();
      this.rootStores.viewStore.showMessageToast(
        "Sorry, your login expired. Please log in again."
      );
      return;
    }

    if (
      e.response.data["error-code"] === 403 &&
      e.response.data.error === "UserNotConfirmed"
    ) {
      return this.rootStores.viewStore.setGeneralNotification({
        bodyText: <NotVerifiedModal />,
      });
    }

    if (e.response.status === 403) {
      if (this.rootStores.userStore.isAuthenticated) {
        this.rootStores.viewStore.showMessageToast(
          "Sorry, please log in again."
        );
        this.rootStores.userStore.logout({ redirect: false });
      } else {
        this.rootStores.callStore.leaveCallAndLog(
          leaveCall,
          e,
          this.rootStores.viewStore.showErrorToast
        );
      }
      return;
    }
    if (e.response.status === 429) {
      this.rootStores.callStore.resetCall();
      this.rootStores.viewStore.showMessageToast(
        <>
          Usage limit exceeded.{" "}
          <p>
            <small>
              {e.response.data.error}, <nobr>Code: 429</nobr>
            </small>
          </p>
        </>
      );
      return;
    }
    if (!e.response.data || !e.response.data.errors) {
      this.rootStores.callStore.leaveCallAndLog(
        leaveCall,
        e,
        this.rootStores.viewStore.showErrorToast
      );
      return;
    }
    if (e.response.data.errors[0].detail === "Room is full.") {
      this.rootStores.callStore.resetCall();
      this.rootStores.viewStore.showMessageToast("Sorry, that room is full.");
      return;
    } else {
      this.rootStores.callStore.resetCall();
      console.log(
        "error in Home.js!! - printing for Flood.io log, since it doesnt show objects:"
      );
      console.log("name: ", e.name);
      console.log("message: ", e.message);
      Honeybadger.notify(e);
      this.rootStores.viewStore.showErrorToast(e);
      return;
    }
  };

  notify = (e) => {
    return Honeybadger.notify(e);
  };
}
