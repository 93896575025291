import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { FaCommentAlt } from "react-icons/fa";

import { CHAT_STATE_LOADING } from "../../stores/ChatStore";
import TrayButton from "../TrayButton/TrayButton";

import { useStores } from "../../stores/utils";

const ChatButton = () => {
  const { chatStore } = useStores();
  const isChatOpen = chatStore.chats.find((item) => item.type === "group");

  return (
    <div
      className={classNames("chat-button", {
        disabled: chatStore.chatState === CHAT_STATE_LOADING,
      })}
      onClick={() =>
        isChatOpen
          ? chatStore.closeChat("group")
          : chatStore.openChat({
              key: "group",
              type: "group",
            })
      }
    >
      <TrayButton>
        <FaCommentAlt size="24px" color="#fff" />
      </TrayButton>
      {chatStore.newMessageCount > 0 && (
        <div className="new-message-count">{chatStore.newMessageCount}</div>
      )}
    </div>
  );
};

export default observer(ChatButton);
