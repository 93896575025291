import { observer } from "mobx-react-lite";
import Tippy from "@tippyjs/react";
import { useStores } from "../../stores/utils";
import { ReactComponent as Plus } from "../../images/SVG/plus-light.svg";

const OfficeAddRoomButton = ({ sessionId, createRoom }) => {
  const { officeStore } = useStores();

  return (
    <div className="add-room-button">
      <Tippy content="Create new room">
        <button
          className="button is-link is-outlined is-small is-rounded"
          onClick={() => createRoom()}
        >
          <span className="icon">
            <Plus />
          </span>
          &nbsp;&nbsp;{" "}
          {officeStore.creatingRoomOnFoyer ? "Creating..." : "Room"}
        </button>
      </Tippy>
    </div>
  );
};

export default observer(OfficeAddRoomButton);
