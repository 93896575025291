import { useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Link, Router } from "@reach/router";
import { useStores } from "stores/utils";
import { TrialBanner } from "components/TrialBanner";
import CallManagement from "../../components/HostDashboard/CallManagement/CallManagement";
import Billing from "pages/Billing";
import SpacesAdmin from "../../components/HostDashboard/SpacesAdmin/SpacesAdmin";
import { ProfilePage } from "pages/Profile";
import { BannedUsers } from "components/HostDashboard/BannedUsers";
import UsageSummary from "../../components/HostDashboard/UsageSummary/UsageSummary";
import { ReactComponent as ReactLogo } from "../../images/SVG/sidebar-logo.svg";
import AuthBoundary from "../../error-boundaries/AuthBoundary/AuthBoundary";
import * as Amplitude from "../../utilities/amplitude";
import Team from "../../components/HostDashboard/Team";
import "./HostDashboard.scss";

export default observer(function HostDashboard() {
  const { userStore, viewStore, sessionsStore, permissionsStore } = useStores();

  const popVerifyNotification = useCallback(() => {
    viewStore.showNotVerifiedModal();
  }, [viewStore]);

  useEffect(() => {
    if (userStore.token) {
      sessionsStore.fetchSessions();
    }
  }, [sessionsStore, userStore.token]);

  useEffect(() => {
    if (userStore.userInfo && !userStore.isVerified) {
      popVerifyNotification();
    }
  }, [userStore, popVerifyNotification]);

  const logout = useCallback(() => {
    Amplitude.track("Clicked log out in host dashboard");
    userStore.logout();
  }, [userStore]);

  return (
    <AuthBoundary>
      <div id="host-dashboard">
        <div className="side-menu">
          <div className="padding">
            <div className="title-image">
              <ReactLogo />
            </div>
            <aside className="menu">
              {/*<p className="menu-label">General</p>*/}
              <ul className="menu-list">
                <li>
                  <Link to="/host">Event Links</Link>
                </li>
                <li>
                  <Link to="spaces">Spaces</Link>
                </li>
                {permissionsStore.canSeeTeamPage && (
                  <li>
                    <Link to="team">Team</Link>
                  </li>
                )}
                <li>
                  <Link to="usage">Usage</Link>
                </li>
                {permissionsStore.canSeeBillingPage && (
                  <li>
                    <Link to="billing">Plan {"&"} Billing</Link>
                  </li>
                )}
                <li>
                  <Link to="banned">Banned Users</Link>
                </li>
                <li>
                  <Link to="settings">Settings</Link>
                </li>
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={logout}>Sign out</a>
                </li>
              </ul>
            </aside>
          </div>
        </div>
        <div className="primary-content">
          <TrialBanner />
          <Router>
            <CallManagement path="/" />
            <UsageSummary path="usage" />
            <SpacesAdmin path="spaces" />
            <Team path="team" />
            <BannedUsers path="banned" />
            <Billing path="billing" />
            <ProfilePage path="settings" />
          </Router>
        </div>
      </div>
    </AuthBoundary>
  );
});
