import { observer } from "mobx-react-lite";
import ParticipantNametag from "./ParticipantNametag/ParticipantNametag";
import ParticipantNameDifferentSession from "./ParticipantNametag/ParticipantNameDifferentSession";
import { useStores } from "../stores/utils";
import classnames from "classnames";

function ParticipantsList({ participants, differentSession, alreadyInRoom }) {
  const { appStore } = useStores();

  return (
    <div
      className={classnames("participant-nametags-list", {
        "non-inline-list": appStore.officeMode,
      })}
    >
      {participants.map((participant) => {
        return differentSession ? (
          <ParticipantNameDifferentSession
            key={participant.id}
            participant={participant}
          />
        ) : (
          <ParticipantNametag
            key={participant.id}
            participant={participant}
            alreadyInRoom={alreadyInRoom}
            officeMode={appStore.officeMode}
          />
        );
      })}
    </div>
  );
}

export default observer(ParticipantsList);
