const pushSupported = "serviceWorker" in navigator && "PushManager" in window;
const bgSyncSupported = "serviceWorker" in navigator && "SyncManager" in window;
const storageSupported = "storage" in navigator && "StorageManager" in window;
const persistentStorageSupported =
  navigator.storage && navigator.storage.persist;
const badgeFeatureSupported =
  "setAppBadge" in navigator && "clearAppBadge" in navigator;

const pwaProbablySupported =
  pushSupported &&
  bgSyncSupported &&
  storageSupported &&
  persistentStorageSupported;

const setPwaBadge = (badgeCount) => {
  if (badgeFeatureSupported) {
    navigator.setAppBadge(badgeCount).catch((error) => {
      console.log(error);
    });
  }
};

const clearPwaBadge = () => {
  if (badgeFeatureSupported) {
    navigator.clearAppBadge().catch((error) => {
      console.log(error);
    });
  }
};

export { pwaProbablySupported, setPwaBadge, clearPwaBadge };
