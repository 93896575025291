import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import ReactModal from "react-modal";
import AuthBoundary from "../../error-boundaries/AuthBoundary/AuthBoundary";
import OfficeFoyerBody from "../Foyer/OfficeFoyerBody";
import OfficeSideMenuHeader from "./SideMenuHeader";
import OfficeSideMenuContent from "./SideMenuContent";
import OfficeWelcome from "./OfficeWelcome";
import { useNotifications } from "reapop";
import CreateSpaceModal from "../HostDashboard/CreateSpaceModal/CreateSpaceModal";
import CallContent from "../CallContent/CallContent";
import * as URlUtil from "../../utilities/url";
import { useStores } from "../../stores/utils";
import useInterval from "../../hooks/useInterval";
import useQueryParams from "../../hooks/useQueryParams";
import { useQuery } from "react-query";
import useAudio from "../../hooks/useAudio";
import { useLocation, useNavigate } from "@reach/router";
import Div100vh from "react-div-100vh";
import { ReactComponent as RightArrow } from "../../images/SVG/chevron-right-solid.svg";
import ChangePhotoModal from "./ChangePhotoModal";
import JoinSpacesModal from "./JoinSpacesModal";
import InstallUnsupported from "../InstallUnsupported/InstallUnsupported";
import Modal from "./Modal";
import { v4 as uuidv4 } from "uuid";
import * as UserAgentUtil from "../../utilities/user-agent";
import BrowserUnsupported from "../BrowserUnsupported/BrowserUnsupported";
import Honeybadger from "honeybadger-js";
import InviteTeammates from "../InviteTeammates/InviteTeammates";
import nonLoggedInApi from "../../api";
import CallPreviewModal from "../CallPreview/CallPreviewModal";
import MeetingNotFound from "./MeetingNotFound";
import DocumentSettings from "./DocumentSettings";
import LinkModal from "components/LinkModal/LinkModal";

import "../Layouts/Layouts.scss";
import "./Office.scss";

function askNotificationPermission() {
  // function to actually ask the permissions
  function handlePermission(permission) {
    // Whatever the user answers, we make sure Chrome stores the information
    if (!("permission" in Notification)) {
      Notification.permission = permission;
    }

    // set the button to shown or hidden, depending on what the user answers
    // if (
    //   Notification.permission === "denied" ||
    //   Notification.permission === "default"
    // ) {
    //   notificationBtn.style.display = "block";
    // } else {
    //   notificationBtn.style.display = "none";
    // }
  }

  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    console.log("This browser does not support notifications.");
  } else {
    if (checkNotificationPromise()) {
      Notification.requestPermission().then((permission) => {
        handlePermission(permission);
      });
    } else {
      Notification.requestPermission(function (permission) {
        handlePermission(permission);
      });
    }
  }
}

// Function to check whether browser supports the promise version of requestPermission()
// Safari only supports the old callback-based version
function checkNotificationPromise() {
  try {
    Notification.requestPermission().then();
  } catch (e) {
    return false;
  }

  return true;
}

function Office({ foyerSessionId, slug, directToFoyer }) {
  const rootStores = useStores();
  const {
    ablyStore,
    api,
    appStore,
    callStore,
    chatStore,
    docStore,
    officeStore,
    queryClient,
    userChatStore,
    userStore,
    viewStore,
  } = rootStores;
  ReactModal.setAppElement("#root");

  const { notify, dismissNotification } = useNotifications();
  const navigate = useNavigate();
  const location = useLocation();
  officeStore.setNotify(notify);
  officeStore.setDismiss(dismissNotification);
  officeStore.setNavigate(navigate);
  const urlQueryParams = useQueryParams();
  const fullscreenRef = useRef();
  const [callPreviewOnJoin, setCallPreviewOnJoin] = useState(undefined);
  const [meetingNotFound, setMeetingNotFound] = useState(false);

  const myCometChatToken = userStore.userInfo.comet_chat_auth_token;
  useEffect(() => {
    if (myCometChatToken) {
      const data = { comet_chat_auth_token: myCometChatToken };
      chatStore.handleCometChatReady(data, "office");
    }
  }, [myCometChatToken, chatStore]);

  useEffect(() => {
    ablyStore.subscribeToOrganizationAblyChannel();
    ablyStore.subscribeToUserAblyChannel();
    try {
      api.pingUser();
    } catch (e) {
      Honeybadger.notify(e);
    }

    askNotificationPermission();

    let tabId = uuidv4();
    officeStore.setTabId(tabId);
    localStorage.openpages = Date.now();
    const onLocalStorageEvent = function (e) {
      if (e.key === "openpages") {
        // Listen if anybody else is opening the same page!
        localStorage.page_available = Date.now();
      }
      if (e.key === "page_available") {
        console.log("Closing tab if isn't tab_id: ", tabId);
        api.closeOtherTabs(userStore.userId, tabId);
      }
    };
    window.addEventListener("storage", onLocalStorageEvent, false);
  }, [ablyStore, api, officeStore, userStore.userId]);

  // https://freesound.org/people/talhanesb/sounds/563822/
  const { play: startRinging, stop: stopRinging } = useAudio(
    "/sounds/ring_talhanesb.mp3",
    true
  );

  const getOfficeSummary = () => {
    const organizationId = userStore.userInfo.organization_id;
    return api.getOfficeSummary(organizationId);
  };

  const officeSummaryQuery = useQuery(
    "office/officeSummary",
    getOfficeSummary,
    {
      refetchOnWindowFocus: false,
    }
  );

  const privateSummaryQuery = useQuery(
    "office/privateSummary",
    () => api.getPrivateSummary(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const allUsersQuery = useQuery(
    "office/allUsers",
    () => api.getAllUsers(userStore.userInfo.organization_id),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (allUsersQuery?.data?.data) {
      officeStore.setPeopleData(allUsersQuery?.data?.data?.data);
    }
  }, [allUsersQuery?.data?.data, officeStore]);

  useEffect(() => {
    if (officeSummaryQuery.data) {
      let spacesData = [];
      let includedData = [];
      spacesData = officeSummaryQuery.data.data.included.filter(
        (x) => x.type === "sessions"
      );
      includedData = officeSummaryQuery.data.data.included.filter(
        (x) => x.type !== "sessions" && x.type !== "users"
      );
      officeStore.setSpacesData(spacesData);
      officeStore.setIncludedData(includedData);
    }
  }, [officeSummaryQuery.data, officeStore]);

  const { isSuccess: isPreferencesSuccess } = useQuery(
    "office/userPreferences",
    () => {
      return api.getUserPreferences(userStore.userId);
    },
    {
      staleTime: Infinity,
      onSuccess: (data) => userStore.updatePreferences(data),
    }
  );

  useEffect(() => {
    appStore.setOfficeMode(true);
    return () => {
      appStore.setOfficeMode(false);
    };
  }, [appStore]);

  useEffect(() => {
    if (foyerSessionId) {
      officeStore.setSpaceInRightPaneId(foyerSessionId);
      navigate("/office");
    }
  }, [foyerSessionId, officeStore, navigate]);

  useEffect(() => {
    const fetchSession = async (slug) => {
      try {
        const fetchedSession = await nonLoggedInApi.getSessionBySlug(slug);
        const sessionId = fetchedSession?.data?.data?.id;
        try {
          await api.saveMeeting(sessionId);
          await queryClient.refetchQueries("office/privateSummary");
        } catch (e) {
          Honeybadger.notify(e);
        }

        officeStore.setSpaceInRightPaneId(sessionId);
      } catch (e) {
        if (e?.response?.status === 404) {
          setMeetingNotFound(true);
        } else {
          Honeybadger.notify(e);
        }
      }
    };

    if (slug && location.pathname.startsWith("/mu/")) {
      fetchSession(slug);
    }
  }, [api, slug, location, officeStore, queryClient]);

  useInterval(
    () => {
      try {
        api.pingUser();
      } catch (e) {
        Honeybadger.notify(e);
      }
    },
    userStore.isAuthenticated ? 30000 : null
  );

  useEffect(() => {
    if (window.innerWidth < 768) {
      viewStore.setHideSideMenu(true);
    }
  }, [viewStore]);

  useEffect(() => {
    if (directToFoyer) {
      let lastURLSegment = "";
      const spaceId = officeStore?.currentSpaceId;
      if (spaceId) {
        lastURLSegment = spaceId;
      } else {
        lastURLSegment = URlUtil.parseSessionIdFromUrl();
      }

      if (lastURLSegment && lastURLSegment !== "office") {
        callStore.refreshData(lastURLSegment).then(() => {
          appStore.setDataInitialized(true);
        });
      }
    }
  }, [callStore, appStore, officeStore?.currentSpaceId, directToFoyer]);

  useEffect(() => {
    if (officeStore.playingRingAudio) {
      startRinging();
    } else {
      stopRinging();
    }
  }, [officeStore.playingRingAudio, startRinging, stopRinging]);

  let showOrHideMenuClass = viewStore.hideSideMenu ? "no-side-menu" : "";

  useEffect(() => {
    if (urlQueryParams?.call) {
      const callRequestId = urlQueryParams.call;
      navigate("/office");
      officeStore.doAcceptCall(callRequestId);
    } else if (urlQueryParams?.knock) {
      const callRequestId = urlQueryParams.knock;
      navigate("/office");
      officeStore.doAcceptCall(callRequestId, "knock");
    } else if (urlQueryParams?.room) {
      navigate("/office");
      officeStore.fetchGroupAndJoin(urlQueryParams.room);
    } else if (urlQueryParams?.dm) {
      navigate("/office");
      userChatStore.addAfterLoadCallback(() => {
        setTimeout(() => {
          userChatStore.openChat(urlQueryParams?.dm);
        }, 1000);
      });
    }
  }, [navigate, officeStore, urlQueryParams, userChatStore]);

  if (UserAgentUtil.shouldBlockBrowser()) return <BrowserUnsupported />;

  return (
    <>
      <AuthBoundary>
        <>
          <div id="office-container" className={`${showOrHideMenuClass || ""}`}>
            <div className="office-side-menu">
              <div className="padding">
                <OfficeSideMenuHeader officeSummary={officeSummaryQuery} />
                <OfficeSideMenuContent
                  people={allUsersQuery?.data?.data?.data}
                  includedDataForPeople={allUsersQuery?.data?.data?.included}
                  fetchingPreferencesCompleted={isPreferencesSuccess}
                  privateSummaryQuery={privateSummaryQuery}
                />
              </div>
            </div>

            <div className="primary-content" ref={fullscreenRef}>
              <Div100vh>
                {officeStore.currentCall || officeStore.currentSpaceId ? (
                  <CallContent fullscreenRef={fullscreenRef} />
                ) : officeStore.spaceInRightPaneId ? (
                  <OfficeFoyerBody sessionId={officeStore.spaceInRightPaneId} />
                ) : (
                  <OfficeWelcome />
                )}
              </Div100vh>
            </div>
          </div>
          {viewStore.hideSideMenu && (
            <div
              className={`show-button`}
              onClick={() => viewStore.toggleSideMenu()}
            >
              <div className="show-icon">
                <RightArrow />
              </div>
            </div>
          )}
          <CreateSpaceModal
            showCreateSpace={officeStore.showCreateSpace}
            refetchSessions={() => null}
            createCall={officeStore.createSpace}
            creatingCall={officeStore.creatingSpace}
          />
          <ChangePhotoModal
            showingModal={officeStore.showingChangePhoto}
            handleCloseModal={() => officeStore.hideChangePhoto()}
          />
          <JoinSpacesModal
            showingModal={officeStore.showingJoinSpaces}
            handleCloseModal={() => officeStore.hideJoinSpaces()}
          />
          <Modal
            onRequestClose={() =>
              officeStore.setShowingInstallUnsupportedModal(false)
            }
            showingModal={officeStore.showingInstallUnsupportedModal}
          >
            <InstallUnsupported modalWrapper={true} />
          </Modal>
          <Modal
            onRequestClose={() => officeStore.setShowingInviteTeammates(false)}
            showingModal={officeStore.showingInviteTeammates}
          >
            <InviteTeammates modalWrapper={true} />
          </Modal>
          {callPreviewOnJoin && (
            <CallPreviewModal
              onCancel={() => setCallPreviewOnJoin(undefined)}
              onJoin={callPreviewOnJoin}
              videoOffMessage={true}
            />
          )}
          <Modal
            onRequestClose={() => {
              setMeetingNotFound(false);
              navigate("/office");
            }}
            showingModal={meetingNotFound}
          >
            <MeetingNotFound />
          </Modal>
          <Modal
            onRequestClose={() => docStore.showDocumentSettingsDocId(undefined)}
            showingModal={docStore.showingDocumentSettingsDocId}
          >
            <DocumentSettings
              docId={docStore.showingDocumentSettingsDocId}
              modalWrapper={true}
            />
          </Modal>
          <Modal
            onRequestClose={() => {
              docStore.showLinkModal(undefined);
              docStore.linkUpdate && docStore.setLinkUpdate(false);
            }}
            showingModal={docStore.showingLinkModalId}
          >
            <LinkModal
              isUpdate={docStore.linkUpdate}
              linkId={docStore.linkUpdate && docStore.showingLinkModalId}
            />
          </Modal>
        </>
      </AuthBoundary>
    </>
  );
}

export default observer(Office);
