import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useIntercom } from "react-use-intercom";
import "./Foyer.scss";
import { ReactComponent as SidebarLogo } from "../../images/SVG/sidebar-logo.svg";
import RoomRow from "../RoomRow/RoomRow";
import { isMobileOnly } from "react-device-detect";
import { useStores } from "../../stores/utils";

function Foyer({ handleNameChange, joinButtonsDisabled, tempName }) {
  const { viewStore, callStore, userStore } = useStores();
  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    boot({ email: userStore.userInfo?.email });

    return function cleanup() {
      shutdown();
    };
  }, [boot, shutdown, userStore.userInfo?.email]);

  const limitExceeded = callStore.sessionAttributes["usage-limit-reached"];

  return (
    <div
      className={(viewStore.forceShowFoyer ? "visible-foyer" : "") + " foyer "}
    >
      <div className="title-row">
        <a href="https://get.sidebar.vc">
          <div className="title-image">
            <SidebarLogo />
          </div>
        </a>
      </div>
      <div className="flex-container">
        <div id="name-input-container">
          {isMobileOnly ? (
            <div className="iphone-notification notification is-warning content">
              <p>Sorry, Sidebar can’t be used on phones yet.</p>
              <p>
                <strong>Use a tablet or PC.</strong>
              </p>
            </div>
          ) : (
            <>
              {callStore.sessionId && (
                <>
                  <h1 className="title is-2 is-size-3-mobile is-spaced">
                    Glad you could make&nbsp;it!
                  </h1>
                  <h2 className="subtitle is-4 is-size-5-mobile ">
                    Join a room to get started
                  </h2>
                  {limitExceeded && (
                    <div className="notification is-warning">
                      Usage limit exceeded.
                    </div>
                  )}

                  <div className="columns is-multiline">
                    {callStore.groupList.map((value) => (
                      <div className="column is-4" key={value.id}>
                        <div className="box">
                          <RoomRow
                            key={value.id}
                            room={value}
                            joinButtonsDisabled={callStore.joinButtonsDisabled}
                            noActions
                            isFoyer
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(Foyer);
