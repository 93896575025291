import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useQueryClient, useQuery } from "react-query";
import ChangePhoto from "../ChangePhoto/ChangePhoto";
import { useStores } from "stores/utils";
import "./ChangeSpacePhotoModal.scss";

function ChangeSpacePhotoModal() {
  const { api, officeStore } = useStores();
  const sessionId = officeStore.spaceInRightPaneId;
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const queryClient = useQueryClient();

  const query = useQuery(["sessions", sessionId]);

  const uploadPhoto = async (croppedImage) => {
    await api.uploadSessionLogo(croppedImage, sessionId);
    await queryClient.invalidateQueries(["sessions", sessionId]);
    setUpdateSuccess(true);
  };

  const logoUrl = query?.data?.data?.data?.attributes?.["logo-url"];

  return (
    <div className="change-photo-modal">
      {updateSuccess ? (
        <>
          <h2 className="modal-title">Success!</h2>
          <div className="change-photo-container">
            <img src={logoUrl} alt="Uploaded" />
          </div>
        </>
      ) : (
        <>
          <h2 className="modal-title">Upload image</h2>
          <div className="change-photo-container">
            <ChangePhoto doUpload={uploadPhoto} oldPhotoUrl={logoUrl} />
          </div>
        </>
      )}
    </div>
  );
}

export default observer(ChangeSpacePhotoModal);
