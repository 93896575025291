export const chatHeaderStyle = (props) => {
  return {
    padding: `${
      props.person ? "4px" : props.type === "user" ? "8px 16px" : "14px 16px"
    }`,
    width: "100%",
    backgroundColor: `${props.theme.backgroundColor.white}`,
    zIndex: "1",
    borderBottom: `1px solid ${props.theme.borderColor.primary}`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
  };
};

export const chatDetailStyle = (props) => {
  return {
    display: `${props.person ? "block" : "flex"}`,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  };
};

export const modalCloseStyle = (person, img) => {
  return {
    position: "absolute",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    top: `${person ? "14px" : "19px"}`,
    right: "14px",
    background: `url(${img}) center center no-repeat`,
    cursor: "pointer",
  };
};

export const chatSideBarBtnStyle = (img, props) => {
  const displayValue =
    props.hasOwnProperty("sidebar") && props.sidebar === 0
      ? { display: "none!important" }
      : {};
  const mq = [`@media (min-width : 320px) and (max-width: 767px)`];

  return {
    cursor: "pointer",
    display: "none",
    background: `url(${img}) center center no-repeat`,
    padding: "20px",
    width: "24px",
    height: "24px",
    float: "left",
    [mq[0]]: {
      display: "block",
    },
    ...displayValue,
  };
};

export const chatThumbnailStyle = () => {
  return {
    display: "inline-block",
    width: "36px",
    height: "36px",
    flexShrink: "0",
  };
};

export const chatUserStyle = (props) => {
  // const mq = [`@media (min-width : 320px) and (max-width: 767px)`];
  let userStyle = {};
  if (props.type === "user") {
    userStyle.fontWeight = 600;
    userStyle.padding = "3px 0";
  } else {
    userStyle.alignItems = "flex-start";
    userStyle.padding = "0";
  }

  return {
    ...userStyle,
    width: "100%",
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
    // [mq[0]]: {
    //   width: "calc(100% - 80px)!important",
    // },
  };
};

export const chatNameStyle = () => {
  return {
    margin: "0",
    fontSize: "15px",
    fontWeight: "600",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
};

export const chatStatusStyle = (props, state) => {
  let status = {};
  if (props.type === "user") {
    status = {
      color: `${props.theme.color.blue}`,
      textTransform: "capitalize",
    };

    if (state.presence === "offline") {
      status = {
        color: `${props.theme.color.helpText}`,
        textTransform: "capitalize",
      };
    }
  } else if (props.type === "group") {
    status = {
      color: `${props.theme.color.helpText}`,
    };
  }

  return {
    fontSize: "13px",
    lineHeight: "20px",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    ...status,
  };
};

export const chatOptionWrapStyle = () => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100px",
  };
};

export const chatOptionStyle = (img) => {
  return {
    display: "inline-block",
    width: "20px",
    height: "20px",
    margin: "0 10px",
    cursor: "pointer",
    background: `url(${img}) center center no-repeat`,
    "&:first-of-type": {
      marginLeft: 0,
    },
    "&:last-of-type": {
      marginRight: 0,
    },
  };
};

export const userNameStyle = () => {
  return {
    display: "flex",
    alignItems: "center",
    "& > div:first-of-type": {
      marginRight: "8px",
    },
  };
};

export const cssActivityDot = (online, isDoNotDisturb, isDoorOpen) => {
  return {
    width: "0.8em",
    height: "0.8em",
    borderRadius: "0.5em",
    border: "2px solid white",
    marginLeft: "-1px",
    backgroundColor: `${
      online
        ? isDoNotDisturb
          ? "rgb(240, 14, 3)"
          : isDoorOpen
          ? "rgb(50, 115, 220)"
          : "rgb(0, 209, 178)"
        : "#aaa"
    }`,
  };
};
