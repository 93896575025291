import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { navigate } from "@reach/router";
import AcceptInviteForm from "../Office/AcceptInviteForm";
import * as Amplitude from "../../utilities/amplitude";
import PlainPhotoLayout from "../Layouts/PlainPhotoLayout";
import * as UserAgentUtil from "../../utilities/user-agent";
import BrowserUnsupported from "../BrowserUnsupported/BrowserUnsupported";

const OfficeAcceptInvite = (props) => {
  useEffect(() => {
    Amplitude.track("Visited accept invite page.");
  }, []);

  const goToLogin = () => {
    navigate("/login");
  };

  if (UserAgentUtil.shouldBlockBrowser()) return <BrowserUnsupported />;

  return (
    <PlainPhotoLayout blueLogo={true} className="accept-invite-page">
      <div className=" content-column narrower">
        <AcceptInviteForm
          navigate={navigate}
          goToLogin={goToLogin}
          inviteToken={props.inviteToken}
        />
      </div>
    </PlainPhotoLayout>
  );
};

export default observer(OfficeAcceptInvite);
