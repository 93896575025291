/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";

import ReadReciept from "../ReadReciept";

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageAudioWrapperStyle,
  messageInfoWrapperStyle,
} from "./style";

const senderaudiobubble = (props) => {
  return (
    <div css={messageContainerStyle()}>
      <div css={messageWrapperStyle()}>
        <div css={messageAudioWrapperStyle(props)}>
          <audio controls>
            <source src={props.message.data.url} />
          </audio>
        </div>
        <div css={messageInfoWrapperStyle()}>
          <ReadReciept {...props} />
        </div>
      </div>
    </div>
  );
};

export default senderaudiobubble;
