import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Router } from "@reach/router";
import ReactModal from "react-modal";
import { GeneralNotification } from "components/GeneralNotification";
import Office from "../Office/Office";
import OfficeAdmin from "../OfficeAdmin/OfficeAdmin";
import Home from "../Home/Home";
import TestTiles from "../TestTiles/TestTiles";
import HostDashboard from "../../pages/HostDashboard/HostDashboard";
import Login from "../../pages/Login/Login";
import Forgot from "../../pages/Forgot/Forgot";
import PasswordResets from "../../pages/PasswordResets";
import AcceptInvite from "../../pages/AcceptInvite";
import OfficeAcceptInvite from "../Office/AcceptInvite";
import Forms from "../../pages/StyleGuide/Forms";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import ErrorBoundary from "../ErrorBoundary";
import { useStores } from "../../stores/utils";
import { SignupPage } from "pages/Signup";
import { Confirmation } from "pages/Confirmation";
import { SubscriptionPlanSelect } from "pages/SubscriptionPlanSelect";
import OfficeOnboarding from "../Office/Onboarding/OfficeOnboarding";
import NameModal from "../NameModal/NameModal";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import NotificationsSystem, { bootstrapTheme, useNotifications } from "reapop";
import { setUpNotifications } from "reapop";
import LandingPage from "../LandingPage/LandingPage";
import { QueryClientProvider } from "react-query";
import OfflinePage from "../OfflinePage/OfflinePage";
import InstallPage from "../InstallPage/InstallPage";
import InactiveTabPage from "../InactiveTabPage/InactiveTabPage";
import { IntercomProvider } from "react-use-intercom";
import AcceptSharedInvite from "../Office/AcceptSharedInvite";
import DocPage from "../DocPage/DocPage";

ReactModal.setAppElement("#root");

function App() {
  const {
    viewStore,
    callStore,
    networkLogStore,
    queryClient,
    appStore,
  } = useStores();

  useEffect(() => {
    networkLogStore.init();
  }, [networkLogStore]);

  useEffect(() => {
    callStore.startSession();

    return () => callStore.stopSession();
  }, [callStore]);

  const { notifications, notify, dismissNotification } = useNotifications();
  setUpNotifications({
    defaultProps: {
      position: "top-center",
      dismissible: false,
    },
  });

  useEffect(() => {
    appStore.setNotify(notify);
    appStore.setDismiss(dismissNotification);
  }, [appStore, notify, dismissNotification]);

  // useEffect(() => {
  //   sessionsStore.fetchSessions();
  // }, [sessionsStore]);

  if (viewStore.errorPage) {
    return <ErrorPage status={viewStore.errorPage} />;
  } else {
    return (
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <IntercomProvider
            appId={appStore.intercomAppId}
            onUnreadCountChange={() => {}}
          >
            <Router>
              <LandingPage path="/" />
              <PrivateRoute as={Office} path="/office" />
              <PrivateRoute
                as={Office}
                path="/office/s/:foyerSessionId"
                directToFoyer={true}
              />
              <PrivateRoute as={Office} path="/mu/:slug" />
              <PrivateRoute as={DocPage} path="/doc/:groupDocId" />
              <PrivateRoute as={OfficeOnboarding} path="/office/setup/*" />
              <PrivateRoute as={OfficeAdmin} path="/office/admin/*" />
              <TestTiles path="/test-tiles" />
              <SubscriptionPlanSelect path="/subscription/:planName" />
              <Confirmation path="/confirmations/:token" />
              <SignupPage path="/signup" />
              <PrivateRoute as={HostDashboard} path="/host/*" />
              <Login path="/login/*" />
              <Forgot path="/forgot" />
              <PasswordResets path="/password_resets/:resetToken" />
              <AcceptInvite path="/invitations/:inviteToken" />
              <OfficeAcceptInvite path="/office_invitations/:inviteToken" />

              <AcceptSharedInvite path="/si/:inviteToken" />

              <Forms path="/style_guides/forms" />
              <InstallPage path="/install" />
              <OfflinePage path="/offline" />
              <InactiveTabPage path="/old-tab" />
              <Home path="/:roomId" />
            </Router>
          </IntercomProvider>
          <GeneralNotification />
          <NotificationsSystem
            // 2. Pass the notifications you want Reapop to display.
            notifications={notifications}
            // 3. Pass the function used to dismiss a notification.
            dismissNotification={(id) => dismissNotification(id)}
            // 4. Pass a builtIn theme or a custom theme.
            theme={bootstrapTheme}
          />
          <ReactModal
            isOpen={viewStore.showNameForm}
            onRequestClose={() => {
              viewStore.toggleShowNameForm();
            }}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className="custom-modal"
            overlayClassName="modal-background"
            closeTimeoutMS={300}
          >
            <div className="modal-content">
              <div className="box is-info">
                <NameModal joinAction={viewStore.clickJoinInNameModal} />
              </div>
            </div>
          </ReactModal>
        </QueryClientProvider>
      </ErrorBoundary>
    );
  }
}

export default observer(App);
