import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import ParticipantCaret from "../ParticipantCaret/ParticipantCaret";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { CSSTransition } from "react-transition-group";
import PullAside from "./PullAside";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import TileSettings from "../TileSettings/TileSettings";

const Name = ({ callItem, positionTippyTop, tile, isCircle }) => {
  const { appStore, callStore } = useStores();
  const useClusters = callStore.clustersEnabled && !callStore.isAuditorium;
  const sidebarParticipantName = callItem.sidebarParticipant?.name;
  const showComponent = !!sidebarParticipantName;
  const firstName = sidebarParticipantName?.split(" ")[0];

  const renderSmallButton = () => {
    const myLargeTile = tile.isLarge && tile.callItem.isLocal;
    if (myLargeTile) {
      return <TileSettings myId={callItem.dailyId} />;
    } else if (useClusters) {
      return <PullAside tile={tile} isMe={tile.callItem.isLocal} />;
    }
  };

  return (
    <CSSTransition
      in={showComponent}
      timeout={300}
      classNames="name-component"
      unmountOnExit
    >
      <>
        <div className="tile-name-tag">
          <div className="tile-bottom-center">
            {!isCircle && renderSmallButton()}
            {sidebarParticipantName && (
              <ParticipantCaret
                participant={callItem.sidebarParticipant}
                positionTippyTop={positionTippyTop}
                alreadyInRoom
                officeMode={appStore.officeMode}
              >
                {(toggleOpen, isOpen) => {
                  let button = isCircle ? (
                    <Tippy
                      content={sidebarParticipantName}
                      hideOnClick={true}
                      trigger="mouseenter"
                      theme="light"
                      appendTo="parent"
                    >
                      <button
                        className="button is-text-clear-bg is-text tile-name-button"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu7"
                        onClick={toggleOpen}
                      >
                        <div className="tile-name-tag-content">
                          <span>{firstName}</span>
                          <div>
                            {isOpen ? <BsChevronUp /> : <BsChevronDown />}
                          </div>
                        </div>
                      </button>
                    </Tippy>
                  ) : (
                    <button
                      className="button is-text-clear-bg is-text tile-name-button"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu7"
                      onClick={toggleOpen}
                    >
                      <div className="tile-name-tag-content">
                        <span>{sidebarParticipantName}</span>
                        <div>
                          {isOpen ? <BsChevronUp /> : <BsChevronDown />}
                        </div>
                      </div>
                    </button>
                  );
                  return button;
                }}
              </ParticipantCaret>
            )}
          </div>
        </div>
      </>
    </CSSTransition>
  );
};

export default observer(Name);
