import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import "./CallMessage.scss";

/**
 * Props:
 * - header: string
 * - detail: string
 * - isError: boolean
 */
export default observer(function CallMessage() {
  const { callStateStore, callStore } = useStores();
  const { videoItems } = callStore;
  const isAuditorium = callStore.isAuditorium;

  let message = null;
  let header = null;
  let detail = null;
  let isError = false;

  if (callStateStore?.fatalError) {
    header = `Oops, something went wrong: ${callStateStore.fatalError}. Switch rooms or refresh to try again.`;
    isError = true;
  } else if (callStateStore.camOrMicError) {
    header = (
      <>
        We're having trouble accessing your camera or microphone: <br />
        {callStateStore.camOrMicError}
      </>
    );
    detail = (
      <>
        See{" "}
        <a
          href="https://help.daily.co/en/articles/2528184-unblock-camera-mic-access-on-a-computer#chrome-users"
          target="_blank"
          rel="noopener noreferrer"
        >
          this article
        </a>{" "}
        to troubleshoot, or you can try refreshing the page.
      </>
    );

    isError = true;
  } else if (callStateStore.shouldShowClickAllow()) {
    header =
      'Unable to detect your camera/mic. If there is a popup, please click "Allow" to enable camera and mic access';
    isError = true;
  } else if (
    !callStore.joiningRoomId &&
    isAuditorium &&
    videoItems.length === 0
  ) {
    return (
      <div className="auditorium-message">
        <article className="message">
          <div className="message-body">
            <p className="call-message-header">Welcome to the auditorium</p>
            <p>
              Everyone’s camera and microphone are off by default, but a host
              can invite you to to turn them on and address the room.
            </p>
          </div>
        </article>
      </div>
    );
  }

  message = header || detail ? { header, detail, isError } : null;

  if (!message) return null;

  return null;

  // return (
  //   <>
  //     <div className={"call-message" + (isError ? " error" : "")}>
  //       <article className="message is-danger">
  //         <div className="message-body">
  //           <p className="call-message-header">{header}</p>
  //           <p>{detail}</p>
  //         </div>
  //       </article>
  //     </div>
  //   </>
  // );
});
