import { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
// import { getCroppedImg, getRotatedImage } from "./canvasUtils";
import getCroppedImg from "../../utilities/image";
import Loader from "../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ChangePhoto.scss";

// const ORIENTATION_TO_ANGLE = {
//   "3": 180,
//   "6": 90,
//   "8": -90,
// };

const ChangePhoto = ({ doUpload, oldPhotoUrl, setNowCropping, doDelete }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [updating, setUpdating] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      // const orientation = await getOrientation(file);
      // const rotation = ORIENTATION_TO_ANGLE[orientation];
      // if (rotation) {
      //   imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      // }

      setImageSrc(imageDataUrl);
      if (setNowCropping) {
        setNowCropping(true);
      }
    }
  };

  const upload = async () => {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    setUpdating(true);
    try {
      await doUpload(croppedImage);
    } finally {
      setUpdating(false);
      setImageSrc(null);
    }
  };

  return (
    <div className="change-photo">
      {imageSrc ? (
        <>
          <div className="change-photo-cropper">
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="change-photo-actions">
            <button
              className="button is-link change-photo-save"
              onClick={upload}
            >
              {updating ? (
                <>
                  Uploading{" "}
                  <div>
                    <Loader />
                  </div>
                </>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </>
      ) : (
        <>
          {oldPhotoUrl && (
            <div className="current-photo ">
              <img src={oldPhotoUrl} alt="Current" />
              {doDelete && (
                <div className="delete-photo-icon" onClick={doDelete}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              )}
            </div>
          )}

          <div className="file">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                onChange={onFileChange}
                accept="image/*"
              />
              <span className="file-cta">
                <span className="file-label">Choose a file…</span>
              </span>
            </label>
          </div>
        </>
      )}
    </div>
  );
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export default ChangePhoto;
