import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RealTimeQuillEditor = ({
  codox,
  docId,
  userName,
  userEmail,
  userId,
  apiKey,
  model,
  saveDoc,
}) => {
  const quillRef = useRef(null);
  const [editor, setEditor] = useState();

  useEffect(() => {
    if (!quillRef?.current) return;
    const editor = quillRef.current && quillRef.current.getEditor();
    quillRef.current?.focus();
    setEditor(editor);
  }, [quillRef]);

  useEffect(() => {
    if (editor) {
      // initialization of codox and passing editor object
      codox &&
        codox.init({
          app: "quilljs",
          user: {
            name: userName,
            email: userEmail,
            id: userId,
          },
          docId: docId,
          apiKey: apiKey,
          editor: editor,
          showUI: false,
          hooks: {
            // invoked whenever the document has been updated
            contentChanged: (data) => {
              if (data.source === "local") {
                const content = editor.root.innerHTML;
                console.log("CONTENT CHANGED");
                // console.log("DATA: ", data);
                saveDoc(docId, content);
              }
            },
          },
        });
    }

    //leave the session
    return () => {
      if (codox) {
        return codox.stop();
      }
    };
  }, [editor, docId, apiKey, codox, saveDoc, userEmail, userId, userName]);

  const modules = {
    toolbar: [
      [
        { header: [1, 2, false] },
        "bold",
        "italic",
        "underline",
        "strike",
        { color: [] },
        { background: [] },
        { list: "ordered" },
        { list: "bullet" },
        "link",
        "clean",
      ],
    ],
  };

  // console.log("&& &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
  // console.log("== == Rendering Quill editor... =====");
  // console.log("** ***************************");
  // console.log("RTQE content: ", model);

  return (
    <>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        style={{}}
        modules={modules}
        value={model}
      />
    </>
  );
};

export default RealTimeQuillEditor;
