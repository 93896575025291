import { observable, action, computed, makeObservable } from "mobx";
import moment from "moment";
import Honeybadger from "honeybadger-js";

export const ORGANIZATION_ANALYTICS_UPDATED = "organization_analytics_updated";

export class UsageSummaryStore {
  isMounted = false;

  @observable isLoading = false;
  @observable isWaitingForPushData = false;
  @observable.shallow data = []; // [[sessionId, duration]]
  @observable.shallow sessions = [];
  @observable monthlyUsagePeriodStart = null;
  @observable monthlyUsagePeriodEnd = null;
  @observable monthlyUsageLimitMinutes = null;
  @observable monthlyUsageMaxLimit = null;
  @observable monthlyMinutesPurchased = null;

  constructor(rootStores) {
    this.rootStores = rootStores;
    makeObservable(this);
  }

  init = () => {
    this.isMounted = true;
    this.getData();
  };

  dispose = () => {
    this.isMounted = false;
  };

  async getData() {
    try {
      this.setLoading(true);
      const sessionsData = await this.rootStores.api.getSessions(
        this.rootStores.userStore.organizationId
      );

      this.setSessions(
        sessionsData.data.map((s) => {
          return {
            id: s.attributes["session-id"],
            name: s.attributes.name,
            url: s.attributes["session-url"],
          };
        })
      );

      const usageData = await this.rootStores.api.getUsageSummaryData(
        this.rootStores.userStore.organizationId
      );
      this.setWaitingForPushData(true);
      this.setData(usageData.data);
      this.updateLimits(usageData);
    } catch (e) {
      console.log(e);
      Honeybadger.notify(e);
    } finally {
      this.setLoading(false);
    }
  }

  @action.bound
  setLoading(value) {
    this.isLoading = value;
  }

  @action
  setWaitingForPushData(value) {
    this.isWaitingForPushData = value;
  }

  @action.bound
  setData(data) {
    this.data.replace(data);
  }

  handleOrganizationAnalyticsUpdated = (data) => {
    this.setData(data.groups);
    this.setWaitingForPushData(false);
  };

  @action.bound
  setSessions(sessions) {
    this.sessions.replace(sessions);
  }

  @action.bound
  updateLimits(usageData) {
    this.monthlyUsagePeriodStart = Date.parse(
      usageData.monthlyUsagePeriodStart
    );
    this.monthlyUsagePeriodEnd = Date.parse(usageData.monthlyUsagePeriodEnd);
    this.monthlyUsageLimitMinutes = usageData.monthlyUsageLimit;
    this.monthlyUsageMaxLimit = usageData.monthlyUsageMaxLimit;
    this.monthlyMinutesPurchased = usageData.monthlyMinutesPurchased;
  }

  getProgressForSession = (sessionId) => {
    const session = this.bySession[sessionId];

    if (!session) return 0;

    const result =
      this.bySession[sessionId] / 60 / this.monthlyMinutesPurchased;

    if (result > 1) return 1;

    return result;
  };

  @computed
  get progress() {
    const result = this.totalUsageMinutes / this.monthlyMinutesPurchased;

    if (result > 1) return 1;

    return result;
  }

  @computed
  get totalUsageSeconds() {
    return this.data.reduce((acc, v) => acc + v[1], 0);
  }

  get totalUsageMinutes() {
    return Math.ceil(this.totalUsageSeconds / 60);
  }

  get totalUsage() {
    return {
      hours: Math.floor(this.totalUsageMinutes / 60),
      minutes: this.totalUsageMinutes % 60,
    };
  }

  get monthlyUsageLimit() {
    if (this.monthlyUsageLimitMinutes === null) return null;
    return {
      hours: Math.floor(this.monthlyUsageLimitMinutes / 60),
      minutes: this.monthlyUsageLimitMinutes % 60,
    };
  }

  @computed
  get monthlyTimePurchased() {
    return {
      hours: Math.floor(this.monthlyMinutesPurchased / 60),
      minutes: this.monthlyMinutesPurchased % 60,
    };
  }

  get resetDate() {
    if (this.monthlyUsagePeriodEnd) {
      return moment(new Date(this.monthlyUsagePeriodEnd)).format("MMM. D");
    }

    return moment(new Date(this.monthlyUsagePeriodStart))
      .add(1, "M")
      .format("MMM. D");
  }

  @computed
  get bySession() {
    return this.data.reduce((acc, v) => {
      if (!acc[v[0]]) {
        acc[v[0]] = v[1];
      } else {
        acc[v[0]] = acc[v[0]] + v[1];
      }

      return acc;
    }, {});
  }
}
