import {
  observable,
  action,
  makeObservable,
  computed,
  reaction,
  runInAction,
} from "mobx";
import Honeybadger from "honeybadger-js";
import * as Amplitude from "../utilities/amplitude";
import { DAILY_STATE_JOINING, DAILY_STATE_JOINED } from "@daily-co/daily-js";
import api from "../api";
import { debounce } from "lodash";
import {
  getMacNoCamera,
  PERMISSION_DENIED_BY_BROWSER,
  PERMISSION_DENIED_BY_SYSTEM,
  CAMERA_REJECTED,
} from "./constants";

import {
  STATE_JOINING,
  STATE_JOINED,
  STATE_IDLE,
  STATE_LEAVING,
} from "./AppStore";
import { isLocal } from "./CallStateStore/CallStateStore";
import {
  MAX_ROOM_STAGE_SIZE,
  MAX_CLUSTER_STAGE_SIZE,
} from "../components/Tray/Tray";
import GroupModel from "./GroupModel";
import ClusterModel from "./ClusterModel";
import { ParticipantModel } from "./ParticipantModel";
import { ParticipantModelLite } from "./ParticipantModelLite";
import { throttle } from "lodash";
import * as Sounds from "../utilities/sounds";
import { CHAT_STATE_LOADING } from "./ChatStore";

export const SESSION_DELETED = "session_deleted";

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export class CallStore {
  constructor(rootStores) {
    this.rootStores = rootStores;
    this.callObjectStore = this.rootStores.callObjectStore;
    makeObservable(this);

    this.debouncedShowAllowModal = debounce(this.showAllowModal, 1000);
    this.throttledUpdateMediaItems = throttle(this.updateMediaItems, 500);
  }

  @observable isCallError = false;
  @observable triggeredCameraError = false;
  @observable roomUrl = null;
  @observable.ref currentCam = null;
  @observable.ref currentMic = null;
  @observable activeSpeakerId = null;
  @observable participantId = null;
  @observable.ref participant = null;
  @observable currentVersion = 0;
  @observable participantVersion = 0;
  @observable groupVersion = 0;
  @observable.ref sessionAttributes = {};
  @observable currentRoomId = null;
  @observable currentClusterId = "null-cluster";
  @observable.shallow groupList = [];
  @observable.shallow clusterList = [];
  @observable.shallow participantList = []; // array of ParticipantModel
  @observable.shallow sessionParticipantList = [];
  @observable clusterlessParticipantIds = []; // array of ParticipantModel
  @observable clusterParticipantIds = [];
  @observable sessionId = null;
  @observable joiningRoomId = null;
  @observable participantName = "";
  @observable creatingRoom = false;
  @observable roomA = "true";
  @observable creatingAuditorium = false;
  @observable isAnyoneInAnotherCluster = false;
  @observable joiningClusterId = null;
  @observable joinedCallAndReceivedClusterData = false;
  @observable isSwitching = false;
  @observable sessionCometChatGuid = null;
  @observable organizationId = null;
  @observable roomKnockId = undefined;
  @observable knockingGroupIds = [];

  @observable.shallow videoItems = [];
  @observable.shallow audioItems = [];
  @observable.shallow audioTagItems = [];
  @observable.shallow ambienceTagItems = [];
  @observable.shallow allAudioTagItems = [];

  @observable isEventEnded = false;

  startSession = () => {
    this.rootStores.cableSocketStore.initSessionSubscriptions();
    this.rootStores.callObjectStore.init();
    this.rootStores.callStore.initReactions();
    this.rootStores.bandwidthStore.init();
  };

  stopSession = () => {
    console.log("STOPPING SESSION");
    this.rootStores.callStore.disposeReactions();
    this.rootStores.callObjectStore.leaveAndUnsubscribe();
    this.rootStores.cableSocketStore.teardownSessionSubscriptions();
    this.rootStores.bandwidthStore.dispose();
  };

  handleSessionDeleted = async (data) => {
    try {
      const { sessionId } = data.payload;

      if (sessionId !== this.sessionId) return;

      await this.rootStores.callObjectStore.leaveCall();

      setTimeout(() => {
        window.location.reload();
      }, 200);
    } catch (e) {
      console.log(e);
    }
  };

  initReactions = () => {
    this.disposer = reaction(
      () => {
        return {
          callItems: this.rootStores.callStateStore.callItems,
          participantListLength: this.participantList.length,
          currentRoomAttributes:
            this.currentRoom && this.currentRoom.attributes,
          clusterParticipantIdsLength: this.clusterParticipantIds.length,
        };
      },
      ({ callItems }) => {
        this.throttledUpdateMediaItems(callItems);
      },
      { fireImmediately: true }
    );
  };

  disposeReactions = () => {
    this.disposer && this.disposer();
  };

  @action
  setRoomA(value) {
    this.roomA = value;
  }

  @action
  setCreatingRoom(value) {
    this.creatingRoom = value;
  }

  @computed
  get hasBroadcastPermissions() {
    if (!this.currentRoom || !this.participant) {
      return false;
    }

    return (
      !this.isAuditorium || this.isEventHost || this.participant?.authorized
    );
  }

  @action
  setCreatingAuditorium(value) {
    this.creatingAuditorium = value;
  }

  @action
  setIsVideoActive = (active) => {
    this.isVideoActive = active;
  };

  @action
  setCamera = (isVideoActive) => {
    if (this.isCallError) {
      this.showOutputError();
    } else {
      this.isVideoActive = isVideoActive;
      this.rootStores.callObjectStore.setCallObjectLocalVideo(isVideoActive);
      this.updateVideoOnInBackend(isVideoActive);
    }
  };

  @action
  toggleCamera = () => {
    if (this.isCallError) {
      this.showOutputError();
    }
  };

  @computed
  get canBroadcast() {
    return (
      this.participant?.attributes &&
      this.participant?.attributes["can-broadcast"]
    );
  }

  configureDeviceStatesBeforeSwitching = (joiningRoomId) => {
    const joiningRoom = this.lookupRoom(joiningRoomId);

    const joiningRoomIsAuditorium = this.checkIsAuditorium(joiningRoom);
    if (joiningRoomIsAuditorium) {
      this.setCamera(false);
      this.callObjectStore.setMicrophone(false);
    }
    this.rootStores.callObjectStore.saveCameraState();
    this.rootStores.callObjectStore.saveMicState();
  };

  configureDeviceStatesAfterJoining = () => {
    this.forceSafariToGetMicPermissionsInAuditoriumsSoHTMLAudioTagsWillAutoplay();
    if (!this.joiningAuditorium) {
      this.callObjectStore.restoreMicState();
      this.callObjectStore.restoreCameraState();
    }
  };

  forceSafariToGetMicPermissionsInAuditoriumsSoHTMLAudioTagsWillAutoplay = () => {
    const joiningRoom = this.lookupRoom(this.joiningRoomId);
    const joiningRoomIsAuditorium = this.checkIsAuditorium(joiningRoom);
    if (this.rootStores.appStore.isSafari && joiningRoomIsAuditorium) {
      this.callObjectStore.setMicrophone(true);
      this.callObjectStore.setMicrophone(false);
    }
  };

  @computed
  get joiningAuditorium() {
    const joiningRoom = this.lookupRoom(this.joiningRoomId);
    return this.checkIsAuditorium(joiningRoom);
  }

  @action
  configureDevicesDuringInitialJoin = () => {};

  configureDeviceStatesAfterApiUpdate = () => {
    if (!this.rootStores.callObjectStore.callObject) {
      return Honeybadger.notify("No call object...");
    }

    if (this.isAuditorium && !this.isEventHost && !this.canBroadcast) {
      // This may be needed? However, it causes bug where when non-host with cam on switches from auditorium to normal room, it turns their cam off
      // console.log("TURNING CAM OFF BECAUSE JOINING AUD");
      // this.setCamera(false);
      // this.callObjectStore.setMicrophone(false);
    } else if (this.participant.videoOn === false) {
      if (this.rootStores.callObjectStore.getCallObjectLocalVideo()) {
        // make api call and check what correct state is.
        // if it's really false, then turn off camera
        api.getParticipant(this.participant.id).then((data) => {
          const onStage =
            data.data.data.attributes && data.data.data.attributes["on-stage"];
          if (!onStage) {
            console.log("[debug1] (TURNING CAM OFF CAUSE API SAID SO!!)");
            this.setCamera(false);
          }
        });
      }
    }
  };

  showAllowModal = () => {
    // if (this.rootStores.appStore.isSafari || this.isAuditorium) {
    //   return;
    // }
    //
    // if (this.isParticipantError() && !this.isCallError) {
    //   this.rootStores.notificationModalStore.setNotificationMessage({
    //     title: `Allow Sidebar to use your camera and microphone. ${
    //       this.rootStores.appStore.isFirefox
    //         ? "Check the box to remember this decision."
    //         : ""
    //     }`,
    //     message: this.rootStores.appStore.isFirefox
    //       ? ALLOW_CAMERA_FIREFOX
    //       : ALLOW_CAMERA,
    //     showInCorner: true,
    //   });
    // }
  };

  isDeniedBySystem = (message) => {
    return message.toLowerCase().includes(PERMISSION_DENIED_BY_SYSTEM);
  };

  isDeniedByBrowser = (message) => {
    return message.toLowerCase().includes(PERMISSION_DENIED_BY_BROWSER);
  };

  isCameraRejected = (message) => {
    return message.toLowerCase().includes(CAMERA_REJECTED);
  };

  @action
  isParticipantError = () => {
    const participant = this.callObjectStore.getLocalParticipant();
    const micBroken = !this.callObjectStore.isMicMuted && !participant?.audio;
    const camBroken = this.isVideoActive && !participant?.video;
    return micBroken || camBroken;
  };

  showOutputError = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true, camera: true });
    } catch (err) {
      if (
        this.isDeniedBySystem(err.message) &&
        this.rootStores.appStore.isMac
      ) {
        return this.rootStores.notificationModalStore.setNotificationMessage({
          title: "Can't use your camera",
          message: getMacNoCamera(this.rootStores.appStore.browserName),
          showTroubleshooting: true,
          hideIfSwitchingRooms: true,
        });
      } else if (this.isCameraRejected(err.message)) {
        return this.rootStores.notificationModalStore.setNotificationMessage({
          title: "Can't use your camera",
          message: `
            The webcam is in use by another application.
            Please make sure no other applications (like Skype) are currently accessing it,
            then refresh the page. If the problem continues, you can clear all access to your
            webcams by restarting your computer.
          `,
        });
      }
    }

    return this.rootStores.notificationModalStore.showCantFind();
  };

  startJoiningCall = (url, newSessionId, roomId, newParticipantName) => {
    this.setRoomUrl(url);
    this.rootStores.appStore.setAppState(STATE_JOINING);
    this.setCurrentDevices();
    return this.callObjectStore
      .joinCall(url)
      .then((participant) => {
        const joinedAt = participant.local.joined_at;
        const videoOn = !this.callObjectStore.isCameraMuted;
        // const videoOn = true;
        return this.createParticipant(
          newSessionId,
          newParticipantName,
          participant.local.user_id,
          roomId,
          joinedAt,
          videoOn
        );
      })
      .then((session) => {
        this.configureDevicesDuringInitialJoin();

        return session;
      })
      .catch((e) => {
        console.log(e);
        this.rootStores.errorStore.notify(e);
      });
  };

  createParticipant = (
    newSessionId,
    newParticipantName,
    dailyParticipantId,
    roomId,
    joinedAt,
    videoOn
  ) => {
    return api
      .createParticipant(
        newSessionId,
        newParticipantName,
        dailyParticipantId,
        roomId,
        joinedAt,
        videoOn,
        this.rootStores.userStore.token
      )
      .then((data) => {
        const session = api.parseSessionData(data.session);

        this.rootStores.tileSettingsStore.populateParticipantSettings(
          session.participants
        );

        runInAction(() => {
          this.applyParticipantChanges(data.participant);
          const participantId = data.participant_id;
          this.applyChanges(session, participantId);
          this.applyGroupChanges(data.group);

          Honeybadger.setContext({
            user_id: participantId,
          });
        });

        if (!this.participant) {
          Honeybadger.notify(
            "Just created Participant, and already cant find it!",
            { sbParticipantId: data.participant_id }
          );
        }

        this.rootStores.appStore.setAppState(STATE_JOINED);
        this.startSession();
        this.setCurrentRoomId(roomId);
        this.setJoinedCallAndReceivedClusterData(true);
        this.rootStores.cableSocketStore.subscribeToGroupsChannel(roomId);
        this.setJoiningRoomId(null);

        return session;
      })
      .catch((e) => {
        return this.rootStores.errorStore.handleApiError(e);
      });
  };

  @action
  setRoomUrl(url) {
    this.roomUrl = url;
  }

  @action.bound
  setParticipantId(participantId) {
    this.participantId = participantId;
  }

  setCurrentDevices = () => {
    if (this.currentMic && this.currentCam) {
      this.callObjectStore.setCallObjectInputDevices({
        audioDeviceId: this.currentMic.value,
        videoDeviceId: this.currentCam.value,
      });
    }
  };

  @action
  setSessionAttributes(attributes) {
    this.sessionAttributes = attributes;
  }

  @action
  applyParticipantChanges(participant) {
    const version = participant.data.meta.version;
    if (version) {
      if (version < this.participantVersion) {
        console.log(
          "[version] !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"
        );
        console.log(
          `[version] New participant version (${version}) was older than current (${this.participantVersion})`
        );
        console.log(
          "[version] !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"
        );
        return;
      } else {
        console.log(
          `[version] participant version: ${this.participantVersion} -> ${version}`
        );
        this.setParticipantVersion(version);
      }
    } else {
      console.log("[version] no participant version...");
      debugger;
    }

    this.participantId = participant.data.id;
    this.participant = new ParticipantModel({
      data: participant.data,
      rootStores: this.rootStores,
    });

    if (this.participant) {
      if (!this.participant.groupId) {
      } else {
        // let newCurrentRoomId = this.participant.relationships.group.data.id;
        // this.setCurrentRoomId(newCurrentRoomId);

        this.configureDeviceStatesAfterApiUpdate();
      }
    }
  }

  applyChanges = (session, participantId = null) => {
    if (session.sessionVersion < this.currentVersion) {
      console.log(
        "[version] !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"
      );
      console.log(
        `[version] !!! Session data older (${session.sessionVersion}) than current (${this.currentVersion}), SKIPPING !!!`
      );
      console.log(
        "[version] !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"
      );
      return;
    } else {
      console.log(
        `[version] session version: ${this.currentVersion} -> ${session.sessionVersion}`
      );
      this.setCurrentVersion(session.sessionVersion);
    }

    runInAction(() => {
      this.setSessionId(session.sessionId);
      this.sessionCometChatGuid = session.cometChatGuid;
      this.organizationId = session.organizationId;
      const participants = session.participants.map((p) => {
        return new ParticipantModelLite({
          data: p,
          rootStores: this.rootStores,
        });
      });
      this.setSessionParticipantList(participants);
      this.setGroupList(session.groups, participants);
      this.setSessionAttributes(session.data.data.attributes);
    });
  };

  applyGroupChanges = (group) => {
    const version = group.data.meta.version;
    if (version) {
      if (version < this.groupVersion) {
        console.log(
          "[version] !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"
        );
        console.log(
          `[version] New group version (${version}) was older than current (${this.groupVersion})`
        );
        console.log(
          "[version] !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"
        );
        return;
      } else {
        console.log(
          `[version] group version: ${this.groupVersion} -> ${version}`
        );
        this.setGroupVersion(version);
      }
    } else {
      console.log("[version] no group version...");
      debugger;
    }

    runInAction(() => {
      this.applyGroupParticipants(group);
      this.applyClusterList(group);
      this.applyCurrentCluster(group);
      this.applyClusterlessParticipants(group);
      this.applyClusterParticipants(group);
      this.applyAnyoneInOtherClusters(group);
      this.upgradeClusterVideoQuality();
      this.applyGroupAttributes(group);
    });
  };

  @action
  applyGroupParticipants = (group) => {
    // IF IS Auditorium
    // ONLY USE FULL PART MODEL FOR FIRST 16
    const auditorium =
      (group.data.attributes && group.data.attributes["broadcast-mode"]) ===
      true;
    const participants = group.included
      .filter((x) => x.type === "participants")
      .map((p, i) => {
        if (!auditorium || (auditorium && i < 16)) {
          return new ParticipantModel({
            data: p,
            rootStores: this.rootStores,
          });
        } else {
          return new ParticipantModelLite({
            data: p,
            rootStores: this.rootStores,
          });
        }
      });

    this.setParticipantList(participants);
  };

  @action
  applyClusterList = (group) => {
    const clusters = group.included.filter((x) => x.type === "clusters");
    const groupModel = this.lookupRoom(group.id);
    const clusterModels = clusters.map(
      (cluster) =>
        new ClusterModel({
          data: cluster,
          rootStores: this.rootStores,
          group: groupModel,
        })
    );

    this.clusterList.replace(clusterModels);
  };

  @action
  applyClusterlessParticipants = (group) => {
    const theClusterless = group.included.filter(
      (x) =>
        x.type === "participants" &&
        x.relationships &&
        x.relationships["cluster"].data === null
    );
    this.clusterlessParticipantIds = theClusterless.map((x) => x.id);
  };

  @action
  applyClusterParticipants = (group) => {
    const clusterId = this.currentClusterId;
    if (clusterId === "null-cluster") {
      this.clusterParticipantIds = this.clusterlessParticipantIds;
      return;
    }

    const participants = group.included.filter((x) => {
      const cluster = x.relationships?.cluster?.data;
      return x.type === "participants" && cluster && cluster.id === clusterId;
    });
    this.clusterParticipantIds = participants.map((x) => x.id);
  };

  @action
  applyCurrentCluster = (group) => {
    let currentClusterId;
    const participant = group.included.find(
      (x) => x.type === "participants" && x.id === this.participantId
    );
    if (participant && participant?.relationships?.cluster?.data?.id) {
      currentClusterId = participant.relationships.cluster.data.id;
    } else {
      currentClusterId = "null-cluster";
    }
    this.currentClusterId = currentClusterId;
  };

  @action
  applyAnyoneInOtherClusters = (group) => {
    const myClusterId =
      this.currentClusterId === "null-cluster" ? null : this.currentClusterId;
    const clusterIds = group.included
      .filter((x) => x.type === "participants")
      .map((y) => y.relationships?.cluster?.data?.id ?? null);

    this.isAnyoneInAnotherCluster = clusterIds.some((x) => x !== myClusterId);
  };

  @action
  applyGroupAttributes = async (group) => {
    console.log("appllying group attributes.... (callstore)");
    const openDocId = group?.data?.attributes["open-doc-id"];
    await this.rootStores.docStore.triggerOpenOrCloseDocFromWebsocket(
      openDocId
    );
    this.rootStores.docStore.updateMetadataFromGroup(group);
  };

  @computed
  get clusterlessParticipants() {
    return this.participantList.filter((x) =>
      this.clusterlessParticipantIds.includes(x.id)
    );
  }

  @computed
  get clusterParticipants() {
    return this.participantList.filter((x) =>
      this.clusterParticipantIds.includes(x.id)
    );
  }

  upgradeClusterVideoQuality = () => {
    this.rootStores.bandwidthStore.setBandwidth();
  };

  @computed
  get currentRoom() {
    return this.lookupRoom(this.currentRoomId);
  }

  lookupRoom = (roomId) => {
    return this.groupList.find((x) => x.id === roomId);
  };

  @computed
  get allowedToEnableCam() {
    return this.stageHasSpace && this.hasBroadcastPermissions;
  }

  @computed
  get roomHasSpace() {
    const allClustersVideos = this.participantsWithVideoOnAllClusters;

    return !(
      this.rootStores.callObjectStore.isCameraMuted &&
      allClustersVideos.length >= MAX_ROOM_STAGE_SIZE
    );
  }

  @computed
  get clusterVideoItems() {
    return this.videoCallItems.filter((callItem) => {
      return callItem.videoTrack;
    });
  }

  @computed
  get clusterHasSpace() {
    return !(
      this.rootStores.callObjectStore.isCameraMuted &&
      this.clusterVideoItems.length >= MAX_CLUSTER_STAGE_SIZE
    );
  }

  @computed
  get stageHasSpace() {
    return this.clusterHasSpace && this.roomHasSpace;
  }

  @action
  setCurrentVersion(value) {
    this.currentVersion = value;
  }

  @action
  setParticipantVersion(value) {
    this.participantVersion = value;
  }

  @action
  setGroupVersion(value) {
    this.groupVersion = value;
  }

  @action
  setCurrentRoomId(roomId) {
    // console.log("setting currrent room id ", roomId);
    this.currentRoomId = roomId;
  }

  @action
  setParticipantList(value) {
    this.participantList.replace(value);
  }

  @action
  setSessionParticipantList(value) {
    this.sessionParticipantList.replace(value);
  }

  @action
  setGroupList(groups, participants) {
    this.groupList.replace(
      groups.map((g) => {
        const groupParticipants = participants.filter((x) => {
          return x.groupId === g.id;
        });

        return new GroupModel({
          data: g,
          participants: groupParticipants,
          rootStores: this.rootStores,
        });
      })
    );
  }

  @action.bound
  setSessionId(value) {
    this.sessionId = value;
  }

  @action
  fetchAndApplyGroupData = (roomId) => {
    return api.getGroup(roomId).then((response) => {
      this.applyGroupChanges(response.data);
    });
  };

  @action
  setIsSwitching(value) {
    this.isSwitching = value;
  }

  switchRoom = async (
    roomId,
    dailyUrl,
    newParticipantName = null,
    sidebarParticipant = null,
    clusterIdToJoin = null,
    sessionIdToJoin = null
  ) => {
    this.setIsSwitching(true);
    this.rootStores.chatStore.setChatState(CHAT_STATE_LOADING);
    this.rootStores.chatStore.closeChat();
    this.rootStores.cableSocketStore.unsubscribeFromGroupsChannel();
    this.setJoiningRoomId(roomId);
    this.rootStores.chatStore.resetRoomChatStateOnSwitchRoom();
    if (!this.callObjectStore.isCallObjectAvailable || !this.participant) {
      let participantNameToCreate = null;
      if (newParticipantName) {
        this.setParticipantName(newParticipantName);
        participantNameToCreate = newParticipantName;
      } else {
        participantNameToCreate = this.participantName;
      }
      return this.startJoiningCall(
        dailyUrl,
        this.sessionId,
        roomId,
        participantNameToCreate
      ).finally(() => {
        this.setIsSwitching(false);
        if (this.rootStores.appStore.officeMode) {
          Sounds.playYouJoined();
        }
      });
    }

    this.rootStores.callStateStore.resetCall();

    let currentParticipant = this.participant;

    if (sidebarParticipant) {
      currentParticipant = sidebarParticipant;
    }

    this.setRoomUrl(dailyUrl);
    try {
      console.log("Appstate is: ", this.callObjectStore.getMeetingState());
      if (
        [DAILY_STATE_JOINED, DAILY_STATE_JOINING].includes(
          this.callObjectStore.getMeetingState()
        )
      ) {
        await this.callObjectStore.leaveCall();
      } else {
        console.log(
          " ??? NOT LEAVING CALL???? cause state is: ",
          this.callObjectStore.getMeetingState()
        );
      }
      this.rootStores.appStore.setAppState(STATE_JOINED);
      await this.callObjectStore.init();
      this.configureDeviceStatesBeforeSwitching(roomId);
      this.setCurrentDevices();

      let dailyParticipant;
      dailyParticipant = await this.callObjectStore.joinCall(dailyUrl);

      try {
        const { session, response } = await api.changeRoom(
          roomId,
          dailyParticipant,
          clusterIdToJoin,
          this.rootStores.userStore.token
        );
        this.setCurrentRoomId(roomId);

        runInAction(() => {
          this.applyParticipantChanges(response.data.participant);
          this.applyChanges(session, currentParticipant?.id);
        });
        this.setJoiningRoomId(null);
        this.applyGroupChanges(response.data.group);

        this.rootStores.cableSocketStore.subscribeToGroupsChannel(roomId);
        if (this.rootStores.appStore.officeMode) {
          Sounds.playYouJoined();
        }
      } catch (e) {
        console.log("switchRoom - oops, something went wrong joining", e);
        return this.rootStores.errorStore.handleApiError(e);
      } finally {
        this.setJoiningRoomId(null);
      }
    } catch (e) {
      console.log("switchRoom - oops, something went wrong leaving:", e);
      this.setJoiningRoomId(null);
      return this.rootStores.errorStore.handleApiError(e);
    } finally {
      runInAction(() => {
        this.setJoiningRoomId(null);
        this.setIsSwitching(false);
      });
    }
  };

  // @action
  // async joinDirectCall(groupId, sessionId, dailyUrl) {
  //   await this.leaveCall();
  //   this.rootStores.officeStore.resetStart();
  //   this.rootStores.officeStore.setCurrentCall(true);
  //   const name = this.rootStores.userStore.reliableDisplayName;
  //   this.setSessionId(sessionId);
  //   this.switchRoom(groupId, dailyUrl, name);
  // }

  // @action
  // async joinSpaceAndRoom(groupId, sessionId, dailyUrl) {
  //   await this.leaveCall();
  //   this.rootStores.officeStore.resetStart();
  //   // TODO - get space
  //   const space = api.getSession(sessionId);
  //   console.log("SP: ", space);
  //   this.rootStores.officeStore.setCurrentSpaceId(sessionId);
  //   const name = this.rootStores.userStore.reliableDisplayName;
  //   this.setSessionId(sessionId);
  //   this.switchRoom(groupId, dailyUrl, name);
  // }

  @action
  setJoinedCallAndReceivedClusterData(value) {
    this.joinedCallAndReceivedClusterData = value;
  }

  @action
  setJoiningRoomId(id) {
    this.joiningRoomId = id;
  }

  @action
  setParticipantName(name) {
    this.participantName = name;
  }

  leaveCallAndLog = (leaveCall, e, showErrorToast) => {
    if (leaveCall) {
      this.resetCall();
    }
    const message =
      e && e.response && e.response.data && e.response.data.exception
        ? `${e} - ${e.response.data.exception}`
        : `${e}`;
    showErrorToast(message, leaveCall);
  };

  leaveCall = async () => {
    this.rootStores.cableSocketStore.unsubscribeAll();
    this.rootStores.callStateStore.resetCall();
    try {
      console.log("Appstate is: ", this.callObjectStore.getMeetingState());
      if (
        [DAILY_STATE_JOINED, DAILY_STATE_JOINING].includes(
          this.callObjectStore.getMeetingState()
        )
      ) {
        await this.callObjectStore.leaveCall();
        this.rootStores.viewStore.forceShowSideMenu();
      } else {
        console.log(
          " ??? NOT LEAVING CALL???? cause state is: ",
          this.callObjectStore.getMeetingState()
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  resetCall = () => {
    if (!this.callObjectStore.isCallObjectAvailable) {
      console.log(" resetCall -- no call object, simply resetting state.");
      return this.resetCallState();
    }
    this.startLeavingCall()
      .then(() => {
        console.log("Successfully left call!!! (reset)");
      })
      .catch((e) => {
        console.log("??!?!?!? couldn't leave call....");
        console.log(e);
        this.rootStores.errorStore.notify(e);
      })
      .finally(() => {
        console.log("finally, resetting call state!!!");
        this.resetCallState();
      });
  };

  clearState = () => {
    this.rootStores.appStore.setAppState(STATE_IDLE);
    this.rootStores.appStore.setDataInitialized(true);
    this.setParticipantId(null);
    this.setCurrentRoomId(null);
    this.setJoiningRoomId(null);
    this.rootStores.viewStore.setShowNameForm(false);
    this.rootStores.viewStore.setShowEditNameForm(false);
    this.rootStores.viewStore.setNameModalJoin(null);
    this.setCreatingRoom(false);
    this.rootStores.viewStore.setShowHostLogin(false);
    this.rootStores.viewStore.setForceShowFoyer(true);
    this.rootStores.callStateStore.resetCall();
    this.rootStores.chatStore.setNewMessageNotificationList([]);
    this.rootStores.chatStore.setNewMessages([]);
    this.clearClusterState();
  };

  resetCallState = () => {
    this.clearState();
    this.stopSession();

    if (this.rootStores.appStore.officeMode) {
      this.setSessionId(null);
      this.rootStores.officeStore.hangUp(null);
      this.rootStores.officeStore.navigate("/office");

      Sounds.playLeft();
    }
  };

  async clickExit() {
    const officeStore = this.rootStores.officeStore;
    officeStore.setExpandedSpaceId(null);

    if (!officeStore.currentCall && !officeStore.inOfficeSessionId) {
      officeStore.setSpaceInRightPaneId(
        localStorage.getItem("lastJoinedSessionId")
      );
    }
    await this.startLeavingCall();
    this.rootStores.viewStore.forceShowSideMenu();
    officeStore.setInOfficeSessionId(undefined);
  }

  /**
   * Starts leaving the current call.
   */
  startLeavingCall = () => {
    this.rootStores.appStore.setAppState(STATE_LEAVING);
    this.rootStores.appStore.setDataInitialized(false);

    return this.deleteParticipant()
      .catch((e) => {
        console.log("error destroying participant (this is probably ok) ", e);
        console.log("name/desc: ", e.name, e.description);
      })
      .finally(() => {
        this.rootStores.cableSocketStore.unsubscribeAll();
        this.rootStores.callObjectStore.leaveAndUnsubscribe();
        this.resetCallState();
      });
  };

  deleteParticipant = async () => {
    if (this.participantId) {
      await api.destroyParticipant(this.participantId);
      this.setParticipantId(null);
    }
    this.setParticipant(null);
  };

  @action
  setParticipant(value) {
    this.participant = value;
  }

  simpleSwitch = () => {
    if (!this.callObjectStore.isCallObjectAvailable) return;

    let newRoomUrl;

    if (this.roomA === "true") {
      this.setRoomA("false");
      newRoomUrl = "https://sidebar.daily.co/WBj30XAVy3rLWXTyObtx";
    } else {
      this.setRoomA("true");
      newRoomUrl = "https://sidebar.daily.co/GFyegvEPcSvO2IW4IU2r";
    }

    this.callObjectStore.switchCall(newRoomUrl).then(() => {
      this.setRoomUrl(newRoomUrl);
    });
  };

  deleteRoom = (groupId) => {
    return api
      .deleteRoom(groupId, this.rootStores.userStore.token)
      .then((data) => {
        this.applyChanges(data, this.participant?.id);
      })
      .catch((e) => {
        this.rootStores.errorStore.handleApiError(e, null, false);
      });
  };

  addRoom = (newParticipantName = null, createRoomOptions = {}) => {
    let newRoomName = "New Room";
    this.setCreatingRoom(true);
    setTimeout(() => {
      Amplitude.track("click new room", {
        session_id: this.sessionId,
        display_name: this.participantName,
        url: window.location.href,
      });
    }, 5000);
    return api
      .createRoom({
        sessionId: this.sessionId,
        roomName: newRoomName,
        ...createRoomOptions,
      })
      .then((newRoom) => {
        let switchArgs = [newRoom.newRoomId, newRoom.newRoomDailyUrl];
        if (
          newParticipantName &&
          (this.participantName === null || this.participantName === "")
        ) {
          switchArgs.push(newParticipantName);
        }

        return this.switchRoom(...switchArgs).then(() => {
          this.setCreatingRoom(false);
        });
      })
      .catch((e) => {
        this.setCreatingRoom(false);
        this.rootStores.errorStore.handleApiError(e, null, false);
      });
  };

  refreshData = (sidebarSessionId, participantId = null) => {
    this.setSessionId(sidebarSessionId);

    const resp = api
      .getSession(sidebarSessionId, participantId)
      .then((session) => {
        if (session.type === "session_history") {
          // navigate("/ended-event");
          runInAction(() => {
            this.isEventEnded = true;
          });

          return;
        }

        if (session.sessionId) {
          this.applyChanges(session, participantId);
          return { sessionId: session.sessionId };
        } else {
          this.rootStores.viewStore.setErrorPage(session.url || 404);
        }
      })
      .catch((e) => {
        console.log("Error in refresh data promise... ", e.description);
        console.log("Error: ", e);
        throw e;
      });
    return resp;
  };

  autoMove = () => {
    console.log("=============== automoving... ==================");
    if (!this.groupList) return;
    const otherRooms = this.groupList.filter(
      (x) => x.id !== this.currentRoomId
    );
    const randomIndex = getRandomInt(otherRooms.length);
    const newRoom = otherRooms[randomIndex];
    this.switchRoom(newRoom.id, newRoom.attributes["daily-url"]);
  };

  refreshAndGetRoomList = (sidebarSessionId, participantId) => {
    return api
      .getSession(sidebarSessionId, participantId)
      .then((session) => {
        if (session.sessionId) {
          return session.groups;
        } else {
          throw new Error("Session does not exist.");
        }
      })
      .catch((e) => {
        console.log(
          "Error in (refreshAndGetRoomList) refresh data promise... ",
          e.description
        );
        console.log("Error: ", e);
        throw e;
      });
  };

  createSidebar = (invitedName, invitedId, inviterName, sidebarParticipant) => {
    const newRoomName = `${inviterName}'s room`;
    const sessionId = this.sessionId;
    return api
      .createRoom({
        sessionId,
        roomName: newRoomName,
        invitedUserId: invitedId,
        locked: true,
        sidebar: true,
      })
      .then((newRoom) => {
        this.applyChanges(newRoom, sidebarParticipant?.id);
        return this.switchRoom(
          newRoom.newRoomId,
          newRoom.newRoomDailyUrl,
          null,
          sidebarParticipant
        )
          .then(() => {})
          .catch((e) => {
            return this.rootStores.errorStore.handleApiError(e);
          });
      })
      .catch((e) => {
        console.log("error in create room: ", e);
        console.log("name/desc: ", e.name, e.description);
        throw e;
      });
  };

  updateVideoFromEvent = (event) => {
    const videoOn = event.action === "track-started";

    let bootFromStage = false;
    if (event.action === "track-stopped") {
      bootFromStage = true;
    }
    this.updateVideoOnInBackend(videoOn, bootFromStage);
  };

  updateVideoOnInBackend = (videoOn, bootFromStage = false) => {
    if (this.joiningRoomId) {
      console.log("[update] ignoring track event during room switch");
      return;
    }
    let participantId = this.participantId;
    if (!this.participant || !participantId) {
      return;
    }
    if (this.participant.videoOn === videoOn) {
      console.log("[update] skipping videoOn update, already matches");
      return;
    } else {
      console.log(
        "[update] proceeding with update - is ",
        this.participant.videoOn,
        " but want it to be: ",
        videoOn
      );
      console.log("[update] ", this.participant.attributes);
    }

    let params = {
      "video-on": videoOn,
    };
    if (bootFromStage) {
      params["on-stage"] = false;
    }

    api
      .updateParticipant(participantId, params, this.rootStores.userStore.token)
      .then((data) => {
        this.applyParticipantChanges(data.participant);
        this.applyChanges(api.parseSessionData(data.session));
        this.applyGroupChanges(data.group);
      })
      .catch((e) => {
        console.log("Error updating participant: ", e);
        console.log("name, desc: ", e.name, e.description);
        throw e;
      });
  };

  @computed
  get isAuditorium() {
    return this.currentRoom && this.currentRoom.isAuditorium;
  }

  checkIsAuditorium = (room) => {
    return room?.attributes["broadcast-mode"];
  };

  @computed
  get isEventHost() {
    if (!this.organizationId) {
      return false;
    }
    return this.organizationId === this.rootStores.userStore.organizationId;
  }

  updateParticipant = (value) => {
    localStorage.setItem(`participantName-session-${this.sessionId}`, value);
    return api
      .updateParticipant(
        this.participant?.id,
        {
          name: value,
        },
        this.rootStores.userStore.token
      )
      .then((data) => {
        this.applyChanges(
          api.parseSessionData(data.session),
          this.participant?.id
        );
        this.applyGroupChanges(data.group);
        this.applyParticipantChanges(data.participant);
      })
      .catch((e) => {
        console.log("Error updating participant: ", e);
        console.log("name, desc: ", e.name, e.description);
        throw e;
      })
      .finally(() => {
        this.setParticipantName(value);
        this.rootStores.viewStore.toggleShowEditNameForm();
      });
  };

  createAuditorium = (roomsMenuEl) => {
    if (!this.rootStores.userStore.isAuthenticated || !this.isEventHost) {
      return;
    }
    this.setCreatingAuditorium(true);
    let newRoomName = "New Auditorium";
    return api
      .createRoom({
        sessionId: this.sessionId,
        roomName: newRoomName,
        broadcastRoom: true,
        authToken: this.rootStores.userStore.token,
      })
      .then((session) => {
        this.applyChanges(session, this.participantId);
        return this.switchRoom(
          session.newRoomId,
          session.newRoomDailyUrl,
          null,
          this.participant
        )
          .then(() => {
            roomsMenuEl.current.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          })
          .catch((e) => {
            return this.rootStores.errorStore.handleApiError(e);
          });
      })
      .catch((e) => {
        return this.rootStores.errorStore.handleApiError(e);
      })
      .finally(() => {
        this.setCreatingAuditorium(false);
      });
  };

  @computed
  get participantsAudioOnly() {
    return this.participantList
      .filter((x) => x.videoOn === false)
      .map((x) => x.dailyId);
  }

  @computed
  get participantsWithVideoOnAllClusters() {
    return this.participantList.filter((x) => x.videoOn);
  }

  @computed
  get clusterPopulation() {
    return this.clusterParticipantIds.length;
  }

  @computed
  get microphoneListEnabled() {
    return this.clusterPopulation > MAX_CLUSTER_STAGE_SIZE;
  }

  @action
  updateMediaItems = (callItems) => {
    if (this.currentRoom == null) {
      return;
    }

    // console.log("CALL ITEMS: ", Object.entries(callItems));
    // console.log("CALL ITEMS length: ", Object.entries(callItems).length);
    // console.log("1 - Video items are: ", this.videoItems);
    // console.log("1 - length: ", this.videoItems.length);
    this.videoItems.clear();
    this.audioItems.clear();
    this.audioTagItems = [];
    this.ambienceTagItems = [];
    this.allAudioTagItems = [];

    // console.log("2 - Video items are: ", this.videoItems);
    // console.log("2 - length: ", this.videoItems.length);
    Object.entries(callItems).forEach(([id, callItem]) => {
      if (this.isThisScreenInCluster(callItem)) {
        return this.videoItems.push(callItem);
      }
      if (!callItem.sidebarParticipant && !this.inNullCluster) {
        // I think this is "if you've been archived and you're in a cluster, we'll just hide you"
        // return this.ambienceTagItems.push(callItem);
        return;
      }

      // if (callItem.sidebarParticipant) {
      // console.log("known cluster part ids is: ", this.clusterParticipantIds);
      // console.log("currently looking for: ", callItem.sidebarParticipant.id);
      // }

      if (
        callItem.sidebarParticipant &&
        !this.clusterParticipantIds.includes(callItem.sidebarParticipant.id) &&
        this.joinedCallAndReceivedClusterData
      ) {
        // console.log("SB, but not in this cluster!");
        // console.log("Cluster parts: ", this.clusterParticipantIds);
        // return this.ambienceTagItems.push(callItem);
        return;
      }

      if (!callItem.videoTrack && callItem.audioTrack && !isLocal(id)) {
        this.audioTagItems.push(callItem);
      }

      if (callItem.audioTrack && !isLocal(id)) {
        this.allAudioTagItems.push(callItem);
      }

      if (
        !callItem.sidebarParticipant &&
        (!callItem.videoTrack || callItem.isLoading)
      ) {
        console.log("skipping unknown loading video");
      } else {
        if (this.isAudioOnlyParticipant(callItem.isScreen, callItem)) {
          this.audioItems.push(callItem);
        } else {
          if (this.videoOffInAuditorium(callItem)) {
            this.audioItems.push(callItem);
          } else {
            this.videoItems.push(callItem);
          }
        }
      }
    });

    // console.log("3 - Video items are: ", this.videoItems);
    // console.log("3 - length: ", this.videoItems.length);
    // console.log("3 - audioItems: ", this.audioItems.length);
    // console.log("3 - audioTagItems: ", this.audioTagItems.length);
    // console.log("3 - ambienceTagItems: ", this.ambienceTagItems.length);
  };

  isAudioOnlyParticipant = (isScreen, callItem) => {
    if (this.isAuditorium) {
      return false;
    }
    return (
      !isScreen &&
      this.microphoneListEnabled &&
      this.participantsAudioOnly.includes(callItem.dailyId)
    );
  };

  videoOffInAuditorium = (callItem) => {
    return this.isAuditorium && !callItem.videoTrack;
  };

  @computed
  get videoCallItems() {
    return this.videoItems.slice().sort((a, b) => {
      let sidebarPartA = a.sidebarParticipant;
      let sidebarPartB = b.sidebarParticipant;
      if (!sidebarPartA) {
        return 1;
      }
      if (!sidebarPartB) {
        return -1;
      }

      if (
        (sidebarPartA.attributes && sidebarPartA.attributes["joined-at"]) <
        (sidebarPartB.attributes && sidebarPartB.attributes["joined-at"])
      ) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  @computed
  get videoPopulation() {
    return this.participantsWithVideoOnAllClusters.length;
  }

  @computed
  get audioCallItems() {
    return this.audioItems;
  }

  findParticipantByCallItem = (callItem) => {
    return this.participantList.find((p) => p.dailyId === callItem.dailyId);
  };

  @action
  setActiveSpeakerId(speakerId) {
    this.activeSpeakerId = speakerId;
  }

  stepAside = async () => {
    return await api.pullAside(this.currentRoomId);
  };

  pullAside = async (participantId) => {
    const participantIds = [participantId];
    return await api.pullAside(this.currentRoomId, participantIds);
  };

  @computed
  get hasClusters() {
    return this.clusterList.length > 0;
  }

  @computed
  get nullCluster() {
    return new ClusterModel({
      group: this.currentRoom,
      rootStores: this.rootStores,
      nullCluster: true,
    });
  }

  @computed
  get inNullCluster() {
    return this.currentClusterId === "null-cluster";
  }

  @action
  joinCluster = async (clusterId) => {
    this.joiningClusterId = clusterId;
    if (clusterId === "null-cluster") {
      if (this.currentClusterId === "null-cluster") {
        console.log("Accepted invite to null-cluster, but already there.");
        this.joiningClusterId = null;
        return;
      } else {
        return this.leaveCluster().finally(() => {
          this.animateClusterAndHideGhostClusterList();
        });
      }
    } else {
      return api
        .joinCluster(clusterId)
        .catch((e) => {
          if (e.response && e.response.status === 404) {
            this.rootStores.viewStore.showMessageToast(
              "That conversation has ended.",
              {
                autoClose: 5000,
              }
            );
          }
        })
        .finally(() => {
          this.animateClusterAndHideGhostClusterList();
        });
    }
  };

  leaveCluster = async () => {
    return await api.leaveCluster(this.currentClusterId);
  };

  @action
  animateClusterAndHideGhostClusterList = () => {
    setTimeout(() => {
      this.joiningClusterId = null;
    }, 2000);
  };

  @computed
  get otherClusters() {
    return this.clusterList.filter((x) => x.id !== this.currentClusterId);
  }

  @computed
  get clusterContainsScreen() {
    const dailyIds = this.clusterParticipants.map((x) => x.callItem?.listKey);
    const possibleScreenIds = [
      ...dailyIds.map((x) => `${x}-screen`),
      "local-screen",
    ];

    const screenIds = Object.keys(
      this.rootStores.callStateStore.callItems
    ).filter((x) => possibleScreenIds.includes(x));
    return screenIds.length > 0;
  }

  isThisScreenInCluster = (callItem) => {
    const clusterDailyIds = this.clusterParticipants.map((x) => x.dailyId);
    return callItem.isScreen && clusterDailyIds.includes(callItem.dailyId);
  };

  @action
  clearClusterState = () => {
    this.currentClusterId = null;
    this.clusterList = [];
    this.clusterlessParticipantIds = [];
    this.clusterParticipantIds = [];
    this.isAnyoneInAnotherCluster = false;
    this.joiningClusterId = null;
    this.ambienceTagItems = [];
  };

  @computed
  get clustersEnabled() {
    return true;
  }

  @computed
  get joinButtonsDisabled() {
    return this.isSwitching;
  }

  @computed
  get appButtonsDisabled() {
    return (
      !this.rootStores.appStore.enableCallButtons || this.joinButtonsDisabled
    );
  }

  @computed
  get activeSpeakerEnabled() {
    return (
      this.rootStores.callStore.videoCallItems &&
      this.rootStores.callStore.videoCallItems.length > 2
    );
  }

  isMe = (participantId) => {
    return this.participantId === participantId;
  };

  @computed
  get inAnOffice() {
    return this.sessionAttributes?.["session-type"] === "user-office";
  }

  @action
  setRoomKnockId(value) {
    this.roomKnockId = value;
  }

  @action
  addKnockingGroupId(groupId) {
    if (!this.knockingGroupIds.includes(groupId)) {
      this.knockingGroupIds.push(groupId);
    }
  }

  @action
  removeKnockingGroupId(groupId) {
    this.knockingGroupIds.remove(groupId);
  }

  async knock(roomId) {
    let userId;
    this.addKnockingGroupId(roomId);
    if (this.rootStores.appStore.officeMode) {
      userId = this.rootStores.userStore.userId;
    }
    Sounds.playKnock();
    const knock = await api.knockRoom(roomId, this.participantId, userId);
    const knockId = knock?.data?.id;
    if (knockId) {
      setTimeout(() => {
        this.cancelKnock(knockId, roomId);
      }, 20000);
      this.setRoomKnockId(knockId);
      return knockId;
    }
  }

  @action
  async cancelKnock(groupRequestId, groupId) {
    this.setRoomKnockId(undefined);
    this.knockingGroupIds.remove(groupId);
    await api.cancelRoomKnock(groupRequestId);
  }

  @computed
  get participantCount() {
    return this.participantList.length;
  }
}
