import { observer } from "mobx-react-lite";
import ReactModal from "react-modal";

const InviteModal = ({ showInvite, toggleShowInvite }) => {
  return (
    <ReactModal
      isOpen={showInvite}
      onRequestClose={() => {
        toggleShowInvite();
      }}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      className="custom-modal"
      overlayClassName="modal-background"
      closeTimeoutMS={300}
    >
      <div className="modal-content">
        <article className="message is-info">
          <div className="message-body">
            <p>Copy and share this page's URL to invite others:</p>
            <p>{window.location.href}</p>
            <button
              className="delete"
              aria-label="delete"
              onClick={() => toggleShowInvite()}
            ></button>
          </div>
        </article>
      </div>
    </ReactModal>
  );
};

export default observer(InviteModal);
