import { useStores } from "../../stores/utils";

function ImpersonatingBanner(props) {
  const { userStore } = useStores();

  if (props.impersonating) {
    return (
      <div className="impersonating-banner">
        <div className="impersonating-banner--message">
          You are impersonating{" "}
          <span className="has-text-weight-bold">
            {userStore.userInfo.email}
          </span>{" "}
          ({userStore.userInfo.first_name} {userStore.userInfo.last_name}){" "}
          {userStore.userInfo.organization_name}.
        </div>
        <button
          onClick={userStore.logout}
          className="logout-button button is-small is-sred"
        >
          Logout
        </button>
      </div>
    );
  } else {
    return null;
  }
}

export default ImpersonatingBanner;
