import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import parse from "html-react-parser";
import { useStores } from "stores/utils";

const DocViewer = ({ content }) => {
  const { cableSocketStore, docStore } = useStores();
  const parsed = content ? parse(content) : "";
  const docId = docStore.openDoc?.id;

  useEffect(() => {
    docStore.fetchDoc(docId);
    cableSocketStore.subscribeToGroupDocsChannel(docId);
    return () => {
      cableSocketStore.unsubscribeFromGroupDocsChannel();
    };
  }, [cableSocketStore, docStore, docId]);

  return (
    <div className="doc-paper ql-snow">
      <div className="doc-scroller ql-editor">{parsed}</div>
    </div>
  );
};

export default observer(DocViewer);
