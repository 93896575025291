import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import "./TrayButton.css";

function TrayButton(props) {
  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className={
        "tray-button" +
        (props.newButtonGroup ? " new-group" : "") +
        " " +
        props.extraClasses
      }
    >
      {props.children}
    </button>
  );
}

TrayButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  newButtonGroup: PropTypes.bool,
};

TrayButton.defaultProps = {
  extraClasses: "",
};

export default observer(TrayButton);
