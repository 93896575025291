/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";

import { SvgAvatar } from "../../util/svgavatar";

import Avatar from "../Avatar";

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageThumbnailStyle,
  messageDetailStyle,
  nameWrapperStyle,
  nameStyle,
  messageAudioWrapperStyle,
  messageInfoWrapperStyle,
  messageTimestampStyle,
} from "./style";

const receiveraudiobubble = (props) => {
  let avatar = null,
    name = null;
  if (props.message.receiverType === "group") {
    if (!props.message.sender.avatar) {
      const uid = props.message.sender.getUid();
      const char = props.message.sender.getName().charAt(0).toUpperCase();

      props.message.sender.setAvatar(SvgAvatar.getAvatar(uid, char));
    }

    avatar = (
      <div css={messageThumbnailStyle()}>
        <Avatar
          cornerRadius="50%"
          borderColor={props.theme.color.secondary}
          borderWidth="1px"
          image={props.message.sender.avatar}
        />
      </div>
    );
    name = (
      <div css={nameWrapperStyle(avatar)}>
        <span css={nameStyle(props)}>{props.message.sender.name}</span>
      </div>
    );
  }

  return (
    <div css={messageContainerStyle()}>
      <div css={messageWrapperStyle()}>
        {avatar}
        <div css={messageDetailStyle(name)}>
          {name}
          <div css={messageAudioWrapperStyle(props)}>
            <audio controls>
              <source src={props.message.data.url} />
            </audio>
          </div>
          <div css={messageInfoWrapperStyle()}>
            <span css={messageTimestampStyle(props)}>
              {new Date(props.message.sentAt * 1000).toLocaleTimeString(
                "en-US",
                { hour: "numeric", minute: "numeric", hour12: true }
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default receiveraudiobubble;
