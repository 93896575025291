import LogRocket from "logrocket";

export class NetworkLogStore {
  constructor(rootStores) {
    this.rootStores = rootStores;
  }

  init = () => {
    const NetworkInformation = navigator.connection;
    if (NetworkInformation && NetworkInformation.downlink) {
      setInterval(function () {
        LogRocket.log(
          "DL: ",
          navigator.connection.downlink,
          "DLM: ",
          navigator.connection.downlinkMax,
          "ET:",
          navigator.connection.effectiveType,
          "T:",
          navigator.connection.type,
          "RTT:",
          navigator.connection.rtt
        );
      }, 10000);
    } else {
      console.log("This browser doesn't support the NetworkInformation API");
    }
  };
}
