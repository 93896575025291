import { observer } from "mobx-react-lite";

const MeetingNotFound = () => {
  return (
    <div className="meeting-not-found modal-padding content">
      <h1>Meeting not found</h1>
      <p>The meeting you are looking for does not exist.</p>
      <p>
        The URL may be incorrect or the creator may have deleted the meeting.
      </p>
    </div>
  );
};

export default observer(MeetingNotFound);
