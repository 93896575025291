import { observer } from "mobx-react-lite";
import { useStores } from "stores/utils";
import Honeybadger from "honeybadger-js";
import "./DocEditorsPopup.scss";

const DocEditorsPopup = ({ docId }) => {
  const { docStore } = useStores();
  const editors = docStore.editors;

  const kickEditor = async (editorId) => {
    try {
      await docStore.stopEditing(editorId);
    } catch (e) {
      Honeybadger.notify(e);
    }
    try {
      await docStore.startEditing(docId);
    } catch (e) {
      Honeybadger.notify(e);
    }
  };

  return (
    <div className="doc-editors-popup">
      <h3>Editor limit reached (10)</h3>{" "}
      <p>Kick a current editor to take their place:</p>
      <ul className="editor-list">
        {editors?.map((editor) => {
          return (
            <li>
              <div className="editor-name">{editor.attributes.name}</div>
              <div
                className="button kick-editor-button"
                onClick={() => kickEditor(editor.id)}
              >
                Kick editor
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default observer(DocEditorsPopup);
