import {
  action,
  observable,
  makeObservable,
  computed,
  runInAction,
} from "mobx";
import * as Amplitude from "../../utilities/amplitude";
import { CallItemModel } from "./CallItemModel";
import { throttle } from "lodash";

const localCallItemData = {
  isLoading: true,
  audioTrack: null,
  videoTrack: null,
};

export class CallStateStore {
  t; // NOTE: CallContent comp timeout var

  @observable.ref callItems = {};

  @observable clickAllowTimeoutFired = false;
  @observable camOrMicError = null;
  @observable fatalError = null;

  constructor(rootStores) {
    this.rootStores = rootStores;
    const localCallItem = this.createNewLocalItem();

    this.callItems = {
      local: localCallItem,
    };
    this.throttledChangeParticipants = throttle(this.changeParticipants, 400);

    makeObservable(this);
  }

  createNewLocalItem() {
    return new CallItemModel({
      data: localCallItemData,
      rootStores: this.rootStores,
    });
  }

  @action.bound
  allowTimeout() {
    this.clickAllowTimeoutFired = true;
  }

  @action.bound
  changeParticipants(participants) {
    let callItems = { local: this.createNewLocalItem() };

    Object.keys(participants).forEach((participantId) => {
      const participant = participants[participantId];
      const hasLoaded =
        this.callItems[participantId] &&
        !this.callItems[participantId].isLoading;
      const missingTracks = !(participant.audioTrack || participant.videoTrack);
      console.log("participant! ", participant);
      callItems[participantId] = new CallItemModel({
        data: {
          listKey: participantId,
          isLoading: !hasLoaded && missingTracks,
          isLocal: participant.local,
          audioTrack: participant.audioTrack,
          videoTrack: participant.videoTrack,
          dailyId: participant.user_id,
          name: participant.user_name,
          isScreen: false,
          tracks: participant.tracks,
        },
        rootStores: this.rootStores,
      });

      if (participant.screenVideoTrack || participant.screenAudioTrack) {
        callItems[participantId + "-screen"] = new CallItemModel({
          data: {
            listKey: participantId + "-screen",
            isLoading: false,
            isLocal: participant.local,
            videoTrack: participant.screenVideoTrack,
            audioTrack: participant.screenAudioTrack,
            dailyId: participant.user_id,
            name: participant.user_name,
            isScreen: true,
          },
          rootStores: this.rootStores,
        });
      }
    });

    runInAction(() => {
      this.callItems = callItems;
    });
  }

  @action
  setCamOrMicError(errorMessage) {
    Amplitude.track("CAM_OR_MIC_ERROR in callState", {
      message: action.message,
    });

    this.camOrMicError = errorMessage;
  }

  @action
  setFatalError(errorMessage) {
    Amplitude.track("FATAL_ERROR in callState", { message: action.message });

    this.fatalError = errorMessage;
  }

  @action
  clearErrors() {
    this.camOrMicError = null;
    this.fatalError = null;
  }

  @action.bound
  resetCall() {
    this.clearErrors();
    this.clickAllowTimeoutFired = false;
    this.callItems = {
      local: this.callItems.local,
    };
  }

  getLocalCallItem() {
    return this.callItems.local;
  }

  shouldShowClickAllow() {
    const localCallItem = this.getLocalCallItem();
    const hasLoaded = localCallItem && !localCallItem.isLoading;

    return (
      !hasLoaded &&
      localCallItem.sidebarParticipant &&
      localCallItem.sidebarParticipant.videoOn &&
      this.clickAllowTimeoutFired
    );
  }

  @computed
  get nonLocalCallItems() {
    return Object.entries(this.callItems).filter((x) => x[0] !== "local");
  }
}

export function isLocal(id) {
  return id === "local";
}

export function isScreenShare(id) {
  return id.endsWith("-screen");
}

export function containsScreenShare(callItems) {
  return Object.keys(callItems).some((id) => isScreenShare(id));
}
