import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import "./EventsSelect.scss";
import EventsSelectItem from "./EventsSelectItem";

const EventsSelect = ({ sessionsStore, onChange, value }) => {
  const handleEventSelect = useCallback(
    (eventId) => {
      onChange(eventId);
    },
    [onChange]
  );

  return (
    <div className="StatusDropdown-menu">
      <div className="StatusDropdownItem__label">Hosts these events:</div>
      <ul>
        {sessionsStore.sessions.map((s) => (
          <li key={s.id}>
            {s.name}
            <EventsSelectItem
              onClick={handleEventSelect}
              isSelected={value.some((x) => x === s.id)}
              value={s}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default observer(EventsSelect);
