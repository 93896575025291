import { createContext, useContext } from "react";
import { configure } from "mobx";
import { Observer } from "mobx-react-lite";

if (process.env.NODE_ENV === "development") {
  configure({
    useProxies: "always",
  });
} else {
  configure({
    useProxies: "ifavailable",
  });
}

export const Stores = createContext(null);

export const useStores = () => useContext(Stores);

export function usePrivateStore(constructor, args) {
  return new constructor(args);
}

export const Provider = ({ children, store }) => (
  <Stores.Provider value={store}>{children}</Stores.Provider>
);

export function observe(subject, render) {
  return <Observer>{() => render()}</Observer>;
}
