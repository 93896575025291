import "./StartButton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

/**
 * Props:
 * - disabled: boolean
 * - onClick: () => ()
 */
export default function StartButton(props) {
  return (
    <button type="submit" className="button is-sred" disabled={props.disabled}>
      <span className="icon is-medium">
        <FontAwesomeIcon icon={faVideo} />
      </span>
      <span>Join the chat</span>
    </button>
  );
}
