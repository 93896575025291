import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { parse } from "query-string";
import { toast } from "react-toastify";
import { HttpService, httpService } from "utilities/HttpService";
import LogRocket from "logrocket";

export class UserStore {
  @observable userInfo = null;
  @observable token = null;
  @observable isFetching = false;
  @observable subscribedSpaces = [];
  @observable memberSpaces = [];
  @observable isInitializing = true;
  @observable loginDestination = null;
  @observable basicPreferences = {};

  constructor(rootStores) {
    this.rootStores = rootStores;
    makeObservable(this);
  }

  @action
  init = async () => {
    this.isInitializing = true;
    const queryParams = parse(window.location.search);
    if (queryParams.impersonateToken) {
      await this.impersonate(queryParams);
    } else {
      await this.loadStoredUser();
    }

    runInAction(() => {
      this.isInitializing = false;
    });
  };

  loadStoredUser = async () => {
    const token = localStorage.getItem("token");
    const userInfo = localStorage.getItem("userInfo");

    const parsedUserInfo = this.parseUserInfo(userInfo);
    if (parsedUserInfo && parsedUserInfo.id && token) {
      this.updateAuthInfo({ token, userInfo: parsedUserInfo, skipAbly: true });
      await this.refetchUser();
      await this.updateUserTimeZone();
    } else {
      // clean up invalid stored user data
      this.clearState();
    }
  };

  parseUserInfo = (data) => {
    try {
      return JSON.parse(data);
    } catch (e) {
      console.log(e);
    }
  };

  impersonate = async (queryParams = null) => {
    localStorage.clear();

    const impersonateToken = queryParams.impersonateToken;
    this.rootStores.api.setup(impersonateToken);
    const data = await this.rootStores.api.getUser();
    this.updateAuthInfo({
      token: impersonateToken,
      userInfo: data,
    });
    localStorage.setItem("impersonating", true);
    window.location = window.location.origin + "/host";
  };

  login = async (userData) => {
    const loginHttpService = new HttpService();
    this.isFetching = true;

    try {
      const response = await loginHttpService.post(
        "/users/sign_in",
        { user: userData },
        { headers: { Accept: "application/json" } }
      );

      const { data, headers } = response;

      const token = this.getToken(headers);
      this.updateAuthInfo({ token, userInfo: data });
      await this.updateUserTimeZone();
      this.configureUser();
      this.populateUnreadMessages(data);

      if (!this.isVerified) {
        setTimeout(() => {
          this.rootStores.notificationModalStore.setNotificationMessage({
            title: "Thanks for signing up!",
            message: `
              Thanks for signing up! Please check your email and click Activate Account in the message we just sent to ${data.email}.
              Once your account is activated you'll be able to start using Sidebar.
            `,
          });
        }, 2000);
      }

      return { ...data, token };
    } catch (e) {
      this.clearState();
      throw e;
    } finally {
      this.isFetching = false;
    }
  };

  configureAxios = (token) => {
    if (!token) {
      return;
    }

    httpService.configure({
      headers: {
        common: {
          Authorization: `Bearer ${token}`,
        },
      },
    });

    this.rootStores.api.setup(token);
  };

  resetAxios = () => {
    httpService.getConfig().headers.common = {};
    this.rootStores.api.setup(null);
  };

  getToken = (headers) => {
    try {
      return headers.authorization.split(" ")[1];
    } catch (err) {
      console.log(err, "errow while parsing user token");
      return null;
    }
  };

  refetchUser = async () => {
    if (!this.userInfo?.id) return;

    const data = await this.rootStores.api.getUser();
    this.updateAuthInfo({ token: this.token, userInfo: data });
    this.configureUser();
    this.populateUnreadMessages(data);
  };

  configureUser = () => {
    LogRocket.identify(this.userInfo?.id, {
      email: this.userInfo?.email,
      name: this.userInfo?.first_name,
      organizationId: this.userInfo?.organization_id,
    });

    this.rootStores.cableSocketStore.subscribeToUsersChannel();
    this.rootStores.userSubscriptionStore.getUserSubscription();
  };

  confirmEmail = async (confirmationToken) => {
    try {
      const response = await httpService.post("/api/v1/confirmations", {
        token: confirmationToken,
      });

      const { data, headers } = response;

      const token = this.getToken(headers);
      this.updateAuthInfo({ token, userInfo: data.user });

      this.configureUser();

      if (!this.isVerified) {
        setTimeout(() => {
          this.rootStores.notificationModalStore.setNotificationMessage({
            title: "Thanks for signing up!",
            message: `
              Thanks for signing up! Please check your email and click Activate Account in the message we just sent to ${data.user.email}.
              Once your account is activated you'll be able to start using Sidebar.
            `,
          });
        }, 2000);
      }

      return { ...data, token };
    } catch (err) {
      console.log(err, "error while confirming email");
    }
  };

  updateAuthInfo = ({ token, userInfo, skipAbly }) => {
    runInAction(() => {
      this.setToken(token);
      this.setUserInfo(userInfo);
      this.configureAxios(token);
    });

    if (!skipAbly) {
      this.configureAbly(token);
    }
  };

  @action
  setUserInfo = (data) => {
    if (!data) return;

    localStorage.setItem("userInfo", JSON.stringify(data));
    this.userInfo = data;
  };

  @action
  setToken = (token) => {
    if (!token) return;
    this.token = token;
    localStorage.setItem("token", token);
  };

  logout = ({ redirect = true, refresh = false } = {}) => {
    this.clearState();

    if (redirect) {
      window.location.replace(process.env.REACT_APP_API_HOST + "/log_out");
    } else if (refresh) {
      window.location.reload();
    }
  };

  softLogout = () => {
    this.clearState();
  };

  @computed
  get isAdmin() {
    return (
      this.userInfo?.role === "admin" || this.userInfo?.role === "superuser"
    );
  }

  @computed
  get firstishName() {
    return (
      this.userInfo?.first_name ||
      this.userInfo?.display_name ||
      this.userInfo?.email ||
      "Guest"
    );
  }

  @computed
  get isManager() {
    return this.userInfo?.role === "manager";
  }

  @computed
  get isEventHost() {
    return this.userInfo?.role === "host";
  }

  @action
  clearState = () => {
    this.rootStores.cableSocketStore.unsubscribeFromUsersChannel();
    this.userInfo = null;
    this.token = null;
    this.loginDestination = null;
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("impersonating");
    this.resetAxios();
  };

  changeEmail = async (email) => {
    try {
      const { data } = await httpService.put("/api/v1/user/email", { email });

      this.updateAuthInfo({ token: this.token, userInfo: data });
      toast.success("Successfully updated your email!");
    } catch (err) {
      toast.error(err?.response?.data?.error);
    }
  };

  resendConfirmationEmail = async () => {
    try {
      await httpService.post("/api/v1/user/resend_confirmation");

      toast.success("Confirmation email has been resent");
    } catch (err) {
      toast.error("Something went wrong, while resending confirmation email");
    }
  };

  @computed
  get organizationId() {
    return this.userInfo?.organization_id;
  }

  @computed
  get isVerified() {
    return this.userInfo?.confirmed;
  }

  @computed
  get isAuthenticated() {
    if (!this.token) {
      return false;
    }
    return true;
  }

  @computed
  get reliableDisplayName() {
    let name = "";
    if (this.userInfo.display_name) {
      name = this.userInfo.display_name;
    } else {
      if (this.userInfo.first_name) {
        name = this.userInfo.first_name;
      }
      if (this.userInfo.last_name) {
        name += this.userInfo.last_name;
      }
    }
    if (!name) {
      name = this.userInfo.email;
    }
    return name;
  }

  @computed
  get userId() {
    return this.userInfo?.id;
  }

  @action
  updatePreferences(data) {
    this.subscribedSpaces = data.data.user_preferences.space_subscriptions.map(
      (x) => x.session_id
    );
    this.memberSpaces = data.data.user_preferences.space_memberships.map(
      (x) => x.session_id
    );
    delete data.data.user_preferences.space_subscriptions;
    delete data.data.user_preferences.space_memberships;
    this.setBasicPreferences(data.data.user_preferences);
  }

  configureAbly(token) {
    if (!token) {
      return;
    }

    this.rootStores.setupAbly(token);
  }

  @computed
  get organizationName() {
    return this.userInfo?.organization_name;
  }

  @action
  setLoginDestination(value) {
    this.loginDestination = value;
  }

  @computed
  get availability() {
    return this.userInfo?.availability_status;
  }

  @computed
  get isDoorOpen() {
    return this.availability === "Door is open";
  }

  @action
  updateUser(data) {
    this.setUserInfo(JSON.parse(data));
  }

  updateUserTimeZone = async () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      await this.rootStores.api.updateUser({
        id: this.userId,
        time_zone: timeZone,
      });
    } catch (e) {
      console.error("Couldn't update timezone.");
      console.log(e);
    }
  };

  @action
  populateUnreadMessages(data) {
    if (!data) return;
    if (data.unread_messages && data.unread_messages.length > 0) {
      this.rootStores.userChatStore.populateUnreadMessages(
        data.unread_messages
      );
    }
  }

  @action
  setBasicPreferences(data) {
    this.basicPreferences = data;
  }
}
