import { useEffect, useState, useCallback } from "react";

import { observer } from "mobx-react-lite";
import classNames from "classnames";
import * as Amplitude from "../../utilities/amplitude";
import { useStores } from "../../stores/utils";
import SettingsModal from "./SettingsModal";
import "./Tray.scss";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { useFullscreen } from "hooks/useFullscreen";
import Tippy from "@tippyjs/react";
import TrayCollapseButton from "components/TrayButton/TrayCollapseButton";
import LeftTrayActions from "./LeftTrayActions";
import MiddleTrayActions from "./MiddleTrayActions";
import RightTrayActions from "./RightTrayActions";

export const MAX_ROOM_STAGE_SIZE = 24;
export const MAX_CLUSTER_STAGE_SIZE = 16;

/**
 * Props:
 * - onClickLeaveCall: () => ()
 * - disabled: boolean
 */
function Tray({ fullscreenRef }) {
  const {
    callStore,
    networkStatsStore,
    viewStore,
    appStore,
    callObjectStore,
    chatStore,
  } = useStores();

  const disabled = callStore.appButtonsDisabled;

  const [showSettings, setShowSettings] = useState(false);
  const [showCanBroadcastTippy, setShowCanBroadcastTippy] = useState(false);
  const toggleFullscreen = useFullscreen({ fullscreenRef, disabled });

  const [showLeftTrayActions, setLeftTrayActions] = useState(false);
  const [showMiddleTrayActions, setMiddleTrayActions] = useState(false);
  const [showRightTrayActions, setShowRightTrayActions] = useState(false);

  const hideCanBroadcastTippy = useCallback(() => {
    setShowCanBroadcastTippy(false);
  }, []);

  useEffect(() => {
    if (callStore.canBroadcast) {
      setShowCanBroadcastTippy(true);
    }
  }, [callStore.canBroadcast]);

  const toggleCamera = useCallback(() => {
    hideCanBroadcastTippy();
    Amplitude.track("Clicked Camera button");
    if (callObjectStore.isCameraMuted) {
      if (!callStore.stageHasSpace) {
        alert(
          "All video slots are currently full. Please wait until someone else turns off their camera."
        );
      } else {
        console.log("[tray] turning cam on");
        callStore.setCamera(true);
      }
    } else {
      console.log("[tray] turning cam off");
      callStore.setCamera(false);
    }
  }, [hideCanBroadcastTippy, callObjectStore.isCameraMuted, callStore]);

  const toggleMic = useCallback(() => {
    hideCanBroadcastTippy();
    Amplitude.track("Clicked Mic button");
    callObjectStore.toggleMicrophone();
  }, [callObjectStore, hideCanBroadcastTippy]);

  const toggleSettings = useCallback(() => {
    setShowSettings((showSettings) => !showSettings);
    Amplitude.track("Toggled settings");
  }, [setShowSettings]);

  const toggleSharingScreen = useCallback(() => {
    hideCanBroadcastTippy();
    Amplitude.track("Clicked Screenshare button");
    callObjectStore.toggleScreenSharing();
  }, [hideCanBroadcastTippy, callObjectStore]);

  const leaveCall = useCallback(() => {
    if (viewStore.isFullScreening) {
      toggleFullscreen();
    }
    Amplitude.track("Clicked leave call button");
    viewStore.clickLeaveCall();
    chatStore.setActiveTab("room");
  }, [viewStore, chatStore, toggleFullscreen]);

  const toggleRefresh = useCallback(() => {
    Amplitude.track("Clicked improve connection button");
    const room = callStore.currentRoom;
    room.enter();
  }, [callStore]);

  const interactWithCollapsedItems = useCallback(
    (target, hide) => {
      switch (target) {
        case "left":
          hide
            ? setLeftTrayActions(false)
            : setLeftTrayActions((showLeftTrayActions) => !showLeftTrayActions);
          break;
        case "middle":
          hide
            ? setMiddleTrayActions(false)
            : setMiddleTrayActions(
                (showMiddleTrayActions) => !showMiddleTrayActions
              );
          break;
        case "right":
          hide
            ? setShowRightTrayActions(false)
            : setShowRightTrayActions(
                (showRightTrayActions) => !showRightTrayActions
              );
          break;
        default:
          break;
      }
    },
    [setLeftTrayActions, setMiddleTrayActions, setShowRightTrayActions]
  );

  const callContentWidth = viewStore.callContentSize.width;

  return (
    <div className="tray-container">
      <div
        className={classNames("tray-left-spacer", {
          "side-menu-hidden": viewStore.hideSideMenu,
        })}
      >
        &nbsp;
      </div>
      <div
        className={`tray ${viewStore.hideSideMenu ? "side-menu-hidden" : ""}`}
      >
        {callContentWidth >= 480 ? (
          <LeftTrayActions
            isAuditorium={callStore.isAuditorium}
            disabled={disabled}
            callStore={callStore}
          />
        ) : (
          callContentWidth >= 420 && (
            <div className="collapsed-left-tray-actions">
              <Tippy
                animation="shift-away"
                content={
                  <LeftTrayActions
                    isAuditorium={callStore.isAuditorium}
                    theme="light"
                    disabled={disabled}
                  />
                }
                interactive
                onClickOutside={() => interactWithCollapsedItems("left", true)}
                visible={showLeftTrayActions}
                placement="top-start"
              >
                <div>
                  <TrayCollapseButton
                    toggleFn={() => interactWithCollapsedItems("left")}
                    styleDependency={showLeftTrayActions}
                  />
                </div>
              </Tippy>
            </div>
          )
        )}
        <div className="alignment-offset">&nbsp;</div>
        <div className="flex-spacer">&nbsp;</div>
        <div className="debug-button" onClick={appStore.toggleIsDebug}>
          &nbsp;
        </div>
        <div className="debug-spacer">&nbsp;</div>
        {callContentWidth >= 420 ? (
          <MiddleTrayActions
            hideCanBroadcastTippy={hideCanBroadcastTippy}
            showCanBroadcastTippy={showCanBroadcastTippy}
            hasBroadcastPermissions={callStore.hasBroadcastPermissions}
            stageHasSpace={callStore.stageHasSpace}
            isCameraMuted={callObjectStore.isCameraMuted}
            allowedToEnableCam={callStore.allowedToEnableCam}
            toggleCamera={toggleCamera}
            disabled={disabled}
            isMicMuted={callObjectStore.isMicMuted}
            toggleMic={toggleMic}
            callObjectStore={callObjectStore}
            isSharingScreen={callObjectStore.isSharingScreen}
            toggleSharingScreen={toggleSharingScreen}
            toggleSettings={toggleSettings}
            leaveCall={leaveCall}
          />
        ) : (
          <div className="collapsed-middle-tray-actions">
            <Tippy
              animation="shift-away"
              content={
                <>
                  <LeftTrayActions
                    isAuditorium={callStore.isAuditorium}
                    theme="light"
                    disabled={disabled}
                  />
                  <div className="collapse-divider"></div>
                  <MiddleTrayActions
                    hideCanBroadcastTippy={hideCanBroadcastTippy}
                    showCanBroadcastTippy={showCanBroadcastTippy}
                    hasBroadcastPermissions={callStore.hasBroadcastPermissions}
                    stageHasSpace={callStore.stageHasSpace}
                    isCameraMuted={callObjectStore.isCameraMuted}
                    allowedToEnableCam={callStore.allowedToEnableCam}
                    toggleCamera={toggleCamera}
                    disabled={disabled}
                    isMicMuted={callObjectStore.isMicMuted}
                    toggleMic={toggleMic}
                    callObjectStore={callObjectStore}
                    isSharingScreen={callObjectStore.isSharingScreen}
                    toggleSharingScreen={toggleSharingScreen}
                    toggleSettings={toggleSettings}
                    leaveCall={leaveCall}
                    theme="light"
                  />
                  <div className="collapse-divider"></div>
                  <RightTrayActions
                    disabled={disabled}
                    toggleRefresh={toggleRefresh}
                    store={networkStatsStore}
                    isFullScreen={viewStore.isFullScreening}
                    officeMode={appStore.officeMode}
                    toggleFullscreen={toggleFullscreen}
                    theme="light"
                  />
                </>
              }
              interactive
              onClickOutside={() => interactWithCollapsedItems("middle", true)}
              visible={showMiddleTrayActions}
              placement="top"
            >
              <div>
                <TrayCollapseButton
                  toggleFn={() => interactWithCollapsedItems("middle")}
                  styleDependency={showMiddleTrayActions}
                />
              </div>
            </Tippy>
          </div>
        )}
        <div className="flex-spacer">&nbsp;</div>
        {(appStore.officeMode && callContentWidth >= 624) ||
        callContentWidth >= 670 ? (
          <RightTrayActions
            disabled={disabled}
            toggleRefresh={toggleRefresh}
            store={networkStatsStore}
            isFullScreen={viewStore.isFullScreening}
            officeMode={appStore.officeMode}
            toggleFullscreen={toggleFullscreen}
          />
        ) : (
          callContentWidth >= 420 && (
            <div className="collapsed-right-tray-actions">
              <Tippy
                animation="shift-away"
                content={
                  <RightTrayActions
                    disabled={disabled}
                    toggleRefresh={toggleRefresh}
                    store={networkStatsStore}
                    isFullScreen={viewStore.isFullScreening}
                    officeMode={appStore.officeMode}
                    toggleFullscreen={toggleFullscreen}
                    theme="light"
                  />
                }
                interactive
                onClickOutside={() => interactWithCollapsedItems("right", true)}
                visible={showRightTrayActions}
                placement="top-end"
              >
                <div>
                  <TrayCollapseButton
                    toggleFn={() => interactWithCollapsedItems("right")}
                    styleDependency={showRightTrayActions}
                  />
                </div>
              </Tippy>
            </div>
          )
        )}
      </div>
      <SettingsModal
        showSettings={showSettings}
        toggleSettings={toggleSettings}
      />
    </div>
  );
}

export default observer(Tray);
