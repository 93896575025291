import { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useQuery, useMutation } from "react-query";
import { debounce } from "lodash";
import { useStores } from "../../../stores/utils";
import Loader from "../../Loader";

const MembersModal = ({ session }) => {
  const { api: api2 } = useStores();
  const [showingAddMembers, setShowingAddMembers] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [queryTerm, setQueryTerm] = useState("");

  const getMembers = ({ queryKey }) => {
    const sessionId = queryKey[1];
    return api2.getMembers(sessionId);
  };

  const showAddMembers = useCallback(() => {
    setShowingAddMembers(true);
  }, []);

  const searchMembers = ({ queryKey }) => {
    const [{ query }] = queryKey;
    return api2.searchMembers(query);
  };

  const membersQuery = useQuery(["space/members", session.id], getMembers);
  const {
    isLoading: searchIsLoading,
    error: searchError,
    data: searchData,
  } = useQuery(["searchMembers", { query: queryTerm }], searchMembers, {
    staleTime: Infinity,
  });
  const addMutation = useMutation(api2.addMember);

  const debouncedQuery = debounce((e) => {
    setQueryTerm(e.target.value);
  }, 250);

  const updateSearch = (e) => {
    setSearchTerm(e.target.value);
    debouncedQuery(e);
  };

  const addMember = (member) => {
    let mutateParams = { sessionId: session.id, userId: member.id };
    addMutation.mutate(mutateParams);
  };

  let userMap = {};
  let memberships = [];
  if (membersQuery.data) {
    userMap = membersQuery.data.data.included
      .filter((x) => x.type === "users")
      .reduce((accum, user) => {
        accum[user.id] = user;
        return accum;
      }, {});
    memberships = membersQuery.data.data.data;
  }

  return (
    <>
      {showingAddMembers ? (
        <>
          <div className="">
            <h1>Add Member</h1>
            <p>
              <input
                type="text"
                value={searchTerm}
                onChange={updateSearch}
                placeholder="Enter a name"
              />
            </p>
            {searchIsLoading && <Loader />}
            {searchError && <p>Error: {searchError}</p>}
            {searchData ? (
              searchData.data.data.map((x) => {
                return (
                  <p>
                    {x.attributes.email}{" "}
                    <button onClick={() => addMember(x)} className="button">
                      Add
                    </button>
                  </p>
                );
              })
            ) : (
              <p></p>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="">
            <h1>Members</h1>
            <button className="button" onClick={() => showAddMembers()}>
              Add Member
            </button>
            {memberships
              .map((x) => {
                let userId = x.relationships.user.data.id;
                return userMap[userId];
              })
              .map((user) => {
                return <p>{user.attributes.email}</p>;
              })}
          </div>
        </>
      )}
    </>
  );
};

export default observer(MembersModal);
