import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/utils";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ReactComponent as OpenmojiThumbSide } from "../../images/SVG/openmoji-thumbleft.svg";
import { ReactComponent as OpenmojiWave } from "../../images/SVG/openmoji-wave.svg";
import { ReactComponent as OpenmojiFist } from "../../images/SVG/openmoji-fist.svg";

const ReactorProjector = () => {
  const { userReactionStore, inviteStore } = useStores();

  return (
    <div className="reaction-container">
      <div className="reaction-display">
        <TransitionGroup className="todo-list">
          {userReactionStore.reactionList.map(
            ({
              id,
              reaction,
              sender_name,
              reaction_title,
              reaction_type,
              invite_type,
              invite_params,
              hide_callback,
              sender_id,
            }) => {
              return (
                <CSSTransition
                  key={id}
                  timeout={200}
                  classNames="reaction-item"
                >
                  <>
                    {reaction_title && (
                      <div className="reaction-box">
                        {reaction === "dip" && <OpenmojiThumbSide />}
                        {reaction === "wave" && <OpenmojiWave />}
                        {reaction === "punch" && <OpenmojiFist />}
                        <div className="reaction-text">
                          <div className="reaction-title">{reaction_title}</div>

                          {sender_name && reaction !== "ban" && (
                            <div className="reaction-from">
                              From: {sender_name}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {invite_type && (
                      <div className="reaction-box invite-display-box">
                        {(invite_type === "hereInvite" ||
                          invite_type === "joinMeInvite") && (
                          <div className="reaction-text">
                            <div className="reaction-title">
                              {sender_name} wants you to join them!
                            </div>
                            <div className="buttons has-addons invite-actions">
                              <button
                                className="button"
                                onClick={() =>
                                  inviteStore.rejectInvite(sender_id, id)
                                }
                              >
                                Maybe later
                              </button>
                              <button
                                className="button"
                                onClick={() => {
                                  inviteStore.acceptInvite(
                                    invite_type,
                                    invite_params,
                                    sender_name,
                                    id,
                                    sender_id
                                  );
                                }}
                              >
                                Accept
                              </button>
                            </div>
                          </div>
                        )}
                        {(invite_type === "sidebarInvite" ||
                          invite_type === "clusterSidebarInvite") && (
                          <div className="reaction-text">
                            <div className="reaction-title">
                              {sender_name}{" "}
                              {invite_type === "sidebarInvite"
                                ? "invited you to a private room!"
                                : "invited you to a cluster!"}
                            </div>
                            <div className="buttons has-addons invite-actions">
                              <button
                                className="button"
                                onClick={() => {
                                  inviteStore.rejectInvite(sender_id, id);
                                }}
                              >
                                Maybe later
                              </button>
                              <button
                                className="button"
                                onClick={() => {
                                  inviteStore.acceptInvite(
                                    invite_type,
                                    invite_params,
                                    sender_name,
                                    id,
                                    sender_id
                                  );
                                }}
                              >
                                Accept
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                </CSSTransition>
              );
            }
          )}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default observer(ReactorProjector);
