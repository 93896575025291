import { observable, action, makeObservable, runInAction } from "mobx";
import ActionCable from "actioncable";
import { Promise } from "bluebird";
import api from "../api";
import LogRocket from "logrocket";
import { throttle } from "lodash";
import * as Sounds from "../utilities/sounds";

export class CableSocketStore {
  @observable.ref cableSocket = null;
  @observable.ref sessionSubscription = null;
  @observable.ref participantsSubscription = null;
  @observable.ref userSubscription = null;
  @observable.ref groupsSubscription = null;
  @observable.ref foyerSessionSubscription = null;
  @observable.ref groupDocsSubscription = null;
  @observable.ref groupDocsMetadataSubscription = null;
  pingPromise = null;
  stopPinging = false;
  @observable roomKnockNotificationId = undefined;

  constructor(rootStores) {
    this.rootStores = rootStores;
    this.callStore = rootStores.callStore;
    this.officeStore = rootStores.officeStore;
    this.docStore = rootStores.docStore;
    this.throttledHandleSessionCableEvent = throttle(
      this.handleSessionCableEvent,
      400
    );
    makeObservable(this);
  }

  init = () => {
    this.createCable();
  };

  initSessionSubscriptions = async () => {
    await this.createCable();

    if (this.callStore.participant) {
      if (this.callStore.sessionId) {
        this.subscribeToSessionsChannel();
      }
      this.subscribeToParticipantsChannel();
      this.stopPinging = false;
      if (!this.pingPromise) {
        console.log("Initializing ping...");
        this.doPing();
      }
    }
  };

  teardownSessionSubscriptions = async () => {
    if (this.sessionSubscription) {
      await this.sessionSubscription.unsubscribe();
      this.sessionSubscription = null;
    }
    if (this.participantsSubscription) {
      await this.participantsSubscription.unsubscribe();
      this.participantsSubscription = null;
    }
    this.cancelPing();
  };

  createCable = async () => {
    if (this.cableSocket) return;

    const cable = await ActionCable.createConsumer(
      process.env.REACT_APP_CABLE_URL
    );
    this.setCableSocket(cable);
  };

  subscribeToParticipantsChannel = () => {
    if (!this.cableSocket) {
      console.log(
        "Can't subscribe to participant channel yet, cableSocket isn't ready"
      );
      return;
    }
    const { callStore } = this.rootStores;

    if (!this.participantsSubscription) {
      console.log(
        "Subscribing to ParticipantsChannel: ",
        callStore.participant?.id
      );
      const participantToken = localStorage.getItem("participant-token");
      const partSub = this.cableSocket.subscriptions.create(
        {
          channel: "ParticipantsChannel",
          participant_id: callStore.participant?.id,
          participant_token: participantToken,
        },
        {
          received: (data) => {
            console.log("(Home) PARTICIPANT EVENT! (cable)");
            return this.handleParticipantCableEvent(data);
          },
          ping: (message) => {
            return this.perform("ping");
          },
        }
      );
      this.setParticipantsSubscription(partSub);
    }
  };

  subscribeToSessionsChannel = () => {
    if (!this.cableSocket) {
      console.log(
        "Can't subscribe to sessions channel yet, cableSocket isn't ready"
      );
      return;
    }
    const { callStore } = this.rootStores;

    if (!this.sessionSubscription) {
      console.log(
        "[ws] Subscribing to SessionsChannel for:",
        callStore.sessionId
      );
      const sessSub = this.cableSocket.subscriptions.create(
        {
          channel: "SessionsChannel",
          session_id: callStore.sessionId,
          participant_id: callStore.participant?.id,
        },
        {
          received: (data) => {
            console.log(
              "(Home) SidebarEvent subscription cable: ",
              data.event_type
            );
            return this.handleSessionCableEvent(data);
          },
        }
      );

      this.setSessionSubscription(sessSub);
    }
  };

  subscribeToFoyerSessionChannel = (sessionId) => {
    if (!this.cableSocket) {
      console.log(
        "Can't subscribe to sessions channel yet, cableSocket isn't ready"
      );
      return;
    }

    if (!this.foyerSessionSubscription) {
      console.log("[ws] Subscribing to Foyer SessionsChannel for:", sessionId);
      const sessSub = this.cableSocket.subscriptions.create(
        {
          channel: "SessionsChannel",
          session_id: sessionId,
        },
        {
          received: (data) => {
            console.log(
              "(Home) SidebarEvent subscription cable (Foyer): ",
              data.event_type
            );
            return this.handleFoyerSessionCableEvent(data);
          },
        }
      );

      this.setFoyerSessionSubscription(sessSub);
    } else {
      console.log("already a foyer session sub, skipping.");
    }
  };

  handleFoyerSessionCableEvent = (data) => {
    if (data.event_type === "data_sync") {
      const session = JSON.parse(data.payload);
      const parsed = api.parseSessionData(session);
      const queryKey = ["sessions", parsed.sessionId];
      console.log("Setting query key:", queryKey);
      this.rootStores.queryClient.setQueryData(queryKey, parsed);
    }
  };

  subscribeToUsersChannel = () => {
    const { userStore } = this.rootStores;
    if (!userStore.userInfo?.id) return;

    if (!this.userSubscription) {
      const userSub = this.cableSocket.subscriptions.create(
        {
          channel: "UsersChannel",
          user_id: userStore.userInfo?.id,
        },
        {
          received: (data) => {
            console.log(
              "(Home) SidebarEvent subscription cable: ",
              data.event_type
            );
            return this.handleUserCableEvent(data);
          },
        }
      );

      this.setUserSubscription(userSub);
    }
  };

  subscribeToGroupsChannel = (newGroupId = null) => {
    if (!this.cableSocket) {
      console.log("No websocket to Sidebar. Skipping groups channel.");
      return;
    }
    const { callStore } = this.rootStores;
    const groupId = newGroupId || callStore.currentRoomId;
    const participantId = callStore.participant?.id;

    if (!this.groupsSubscription) {
      console.log(
        "Subscribing to GroupsChannel - ",
        groupId,
        " participant: ",
        participantId
      );
      const groupsSub = this.cableSocket.subscriptions.create(
        {
          channel: "GroupsChannel",
          group_id: groupId,
          participant_id: participantId,
        },
        {
          connected: () => {
            callStore.fetchAndApplyGroupData(groupId);
          },
          received: (data) => {
            console.log(
              "(Home) SidebarEvent subscription cable: ",
              data.event_type
            );
            return this.handleGroupCableEvent(data);
          },
        }
      );

      this.setGroupsSubscription(groupsSub);
    } else {
      console.log("COULDN'T SUBSCRIBE, STILL HAD A GROUP SUB");
    }
  };

  subscribeToGroupDocsChannel = (groupDocId) => {
    if (!this.cableSocket) {
      console.log("No websocket to Sidebar. Skipping group docs channel.");
      return;
    }
    const userId = this.rootStores.userStore.userId;

    if (!this.groupDocSubscription) {
      const groupDocsSub = this.cableSocket.subscriptions.create(
        {
          channel: "GroupDocsContentChannel",
          group_doc_id: groupDocId,
          user_id: userId,
        },
        {
          connected: () => {
            console.log("Connected to GroupDocs channel!");
          },
          received: (data) => {
            return this.handleGroupDocsCableEvent(data);
          },
        }
      );
      this.setGroupDocsSubscription(groupDocsSub);
    } else {
      console.log("COULDN'T SUBSCRIBE, STILL HAD A GROUP DOCS SUB");
      debugger;
    }
  };

  subscribeToGroupDocsMetadataChannel = (groupDocId) => {
    if (!this.cableSocket) {
      console.log(
        "No websocket to Sidebar. Skipping group docs metadata channel."
      );
      return;
    }
    const userId = this.rootStores.userStore.userId;

    if (!this.groupDocMetadataSubscription) {
      const groupDocsSub = this.cableSocket.subscriptions.create(
        {
          channel: "GroupDocsMetadataChannel",
          group_doc_id: groupDocId,
          user_id: userId,
        },
        {
          connected: () => {
            console.log("Connected to GroupDocs channel!");
          },
          received: (data) => {
            return this.handleGroupDocsMetadataCableEvent(data);
          },
        }
      );

      this.setGroupDocsMetadataSubscription(groupDocsSub);
    } else {
      console.log("COULDN'T SUBSCRIBE, STILL HAD A GROUP DOCS SUB");
      debugger;
    }
  };

  unsubscribeFromGroupDocsChannel = () => {
    if (this.groupDocsSubscription) {
      console.log("Unsubscribed from GroupDocsChannel!");
      this.groupDocsSubscription.unsubscribe();
      this.groupDocsSubscription = null;
    }
  };

  unsubscribeFromGroupDocsMetadataChannel = () => {
    if (this.groupDocsMetadataSubscription) {
      console.log("Unsubscribed from GroupDocsMetadataChannel!");
      this.groupDocsMetadataSubscription.unsubscribe();
      this.groupDocsMetadataSubscription = null;
    }
  };

  unsubscribeFromUsersChannel = () => {
    if (this.userSubscription) this.userSubscription.unsubscribe();
  };

  @action
  unsubscribeFromGroupsChannel = () => {
    if (this.groupsSubscription) {
      console.log("Unsubscribed from GroupsChannel!");
      this.groupsSubscription.unsubscribe();
      this.groupsSubscription = null;
    }
  };

  @action
  unsubscribeFromFoyerSessionSubscription = async () => {
    if (this.foyerSessionSubscription) {
      console.log("Unsubscribing from foyer session ...");
      await this.foyerSessionSubscription.unsubscribe();
      this.setFoyerSessionSubscription(null);
    }
  };

  @action.bound
  setSessionSubscription(sessionSubscription) {
    this.sessionSubscription = sessionSubscription;
  }

  @action
  setUserSubscription = (userSub) => {
    this.userSubscription = userSub;
  };

  @action
  setGroupsSubscription = (groupsSub) => {
    this.groupsSubscription = groupsSub;
  };

  @action
  setFoyerSessionSubscription = (foyerSessionSubscription) => {
    this.foyerSessionSubscription = foyerSessionSubscription;
  };

  @action
  setGroupDocsSubscription = (sub) => {
    this.groupDocsSubscription = sub;
  };

  @action
  setGroupDocsMetadataSubscription = (sub) => {
    this.groupDocsMetadataSubscription = sub;
  };

  handleUserCableEvent = (data) => {
    if (data.event_type === "trial-limit-reaching") {
      this.rootStores.viewStore.setGeneralNotification({
        bodyText:
          "You’ve used almost all the free hours in your trial, Your subscription is about to start.",
        buttonText: "Got it!",
      });
    } else if (data.event_type === "trial-limit-reached") {
      this.rootStores.viewStore.setGeneralNotification({
        bodyText: "Your subscription has started.",
        buttonText: "Got it!",
      });
    } else if (data.event_type === "analytics-data-updated") {
      if (!this.rootStores.usageSummaryStore?.isMounted) return;
      this.rootStores.usageSummaryStore.handleOrganizationAnalyticsUpdated(
        data.payload
      );
    }
  };

  @action.bound
  setParticipantsSubscription(participantsSubscription) {
    this.participantsSubscription = participantsSubscription;
  }

  unsubscribeAll = () => {
    if (this.sessionSubscription) {
      this.sessionSubscription.unsubscribe();
    }
    if (this.participantsSubscription) {
      this.participantsSubscription.unsubscribe();
    }
    if (this.groupsSubscription) {
      this.groupsSubscription.unsubscribe();
    }
  };

  @action
  setCableSocket(cableSocket) {
    this.cableSocket = cableSocket;
  }

  handleParticipantCableEvent = async (data) => {
    const { callStore } = this.rootStores;

    if (data.recipient_id) {
      if (data.recipient_id === callStore.participant?.id) {
        if (data.reaction_type) {
          if (data.reaction_type === "accept-sidebar-invite") {
            this.rootStores.userReactionStore.showReaction(
              data.reaction_type,
              null,
              data.reaction_title,
              data.sender_id
            );
            this.rootStores.callStore.createSidebar(
              data.sender_name,
              data.sender_id,
              callStore.participant?.name,
              callStore.participant
            );
          } else if (data.rection_type === "accept-cluster-sidebar-invite") {
            // this.rootStores.userReactionStore.showReaction(
            //   data.reaction_type,
            //   null,
            //   data.reaction_title,
            //   data.sender_id
            // );
          } else if (data.reaction_type === "move-to-sidebar") {
            this.rootStores.callStore.switchRoom(
              data.reaction_params.room_id,
              data.reaction_params.daily_url,
              null,
              callStore.participant
            );
          } else if (data.reaction_type === "knock_accepted") {
            const groupId = data.reaction_params.room_id;
            this.rootStores.callStore.removeKnockingGroupId(groupId);
            this.rootStores.callStore.switchRoom(
              groupId,
              data.reaction_params.daily_url,
              null,
              callStore.participant
            );
          } else {
            let sender_name = data.sender_name;
            if (data.reaction_type === "accept-here-invite") sender_name = null;

            this.rootStores.userReactionStore.showReaction(
              data.reaction_type,
              sender_name,
              data.reaction_title,
              data.sender_id
            );
          }
          console.log("handleParticipantCableEvent - RECEIVED REACTION");
        } else if (data.invite_type) {
          this.rootStores.inviteStore.showInvite(
            data.invite_type,
            data.sender_name,
            data.invite_params,
            data.sender_id
          );
        } else if (data.event_type) {
          this.rootStores.userReactionStore.showReaction(
            data.event_type,
            data.sender_name,
            data.reaction_title,
            data.sender_id
          );
        }
      } else {
        console.log(
          "handleParticipantCableEvent - Cable was for someone else... ???"
        );
      }
    } else {
      if (data["event_type"] === "cometchat-ready") {
        this.rootStores.chatStore.handleCometChatReady(data, "in-call");
      } else if (data["event_type"] === "data_sync") {
        this.rootStores.callStore.applyParticipantChanges(data.payload);
      }
    }
  };

  handleSessionCableEvent = (data) => {
    const { callStore } = this.rootStores;

    if (data.event_type === "session_deleted") {
      this.rootStores.callStore.handleSessionDeleted(data);
    }
    if (data.event_type === "session-expired") {
      this.rootStores.callStore.resetCall();
      this.rootStores.viewStore.showMessageToast("Usage limit reached.");
      return;
    }

    if (data.event_type === "room_change") {
      console.log("handleSessionCableEvent - EVENT! Room change");
      let json = JSON.parse(data.payload);
      let sessionData = api.parseSessionData(json);
      this.rootStores.callStore.applyChanges(
        sessionData,
        callStore.participant?.id
      );
    } else if (data.event_type === "participant_quit") {
      console.log("handleSessionCableEvent - EVENT! Participant quit...");
      let json = JSON.parse(data.payload);

      let sessionData = api.parseSessionData(json);

      this.rootStores.callStore.applyChanges(
        sessionData,
        callStore.participant?.id
      );
    } else if (data.event_type === "test-shuffle") {
      console.log("RECEIVED TEST SHUFFLE CABLE!!!");
      this.rootStores.callStore.autoMove();
      console.log("SHUFFLED!");
    } else if (data.event_type === "trial-limit-reaching") {
      this.rootStores.viewStore.setGeneralNotification({
        bodyText:
          "You’ve used almost all the free hours in your trial, Your subscription is about to start.",
        buttonText: "Got it!",
      });
    } else if (data.event_type === "trial-limit-reached") {
      this.rootStores.viewStore.setGeneralNotification({
        bodyText: "Your subscription has started.",
        buttonText: "Got it!",
      });
    } else if (data.event_type === "session_deleted") {
      console.log("RECEIVED session_deleted EVENT!!!");
    } else {
      if (data.payload) {
        let json = JSON.parse(data.payload);
        let sessionData = api.parseSessionData(json);
        this.rootStores.callStore.applyChanges(
          sessionData,
          callStore.participant?.id
        );
      } else {
        console.log(
          ` ???? NO PAYLOAD for Cable event: ${data.event_type}`,
          data
        );
      }
    }
  };

  @action
  setRoomKnockNotificationId(id) {
    this.roomKnockNotificationId = id;
  }

  ignoreRoomKnock = (data) => {
    this.rootStores.appStore.dismiss(this.setRoomKnockNotificationId);
    this.setRoomKnockNotificationId(undefined);
  };

  acceptRoomKnock = (data) => {
    const notificationId = this.roomKnockNotificationId;
    try {
      api.acceptRoomKnock(data?.data?.group_request_id, notificationId);
    } catch (e) {}
  };

  handleGroupCableEvent = (data) => {
    const { callStore, roomReactionStore, tileSettingsStore } = this.rootStores;

    console.log("Received GROUP cable event! ", data);
    if (data.event_type === "group-updated") {
      callStore.applyGroupChanges(data.data);
      console.log("Applied.");
    } else if (data.event_type === "group_knock") {
      const name = data?.data?.knocker_name;
      const notification = this.rootStores.appStore.notify({
        title: "Knock",
        message: (
          <div className="is-flex">{name} knocked and wants to join.</div>
        ),
        buttons: [
          {
            name: "Ignore",
            onClick: () => {
              this.ignoreRoomKnock(data);
            },
          },
          {
            name: "Accept",
            onClick: () => {
              this.acceptRoomKnock(data);
            },
            primary: true,
          },
        ],
      });
      Sounds.playPing();
      this.setRoomKnockNotificationId(notification.id);
      this.rootStores.appStore.registerNotification({
        notificationId: notification.id,
        type: "group_knock",
        fromId: data?.data?.group_request_id,
      });
    } else if (data.event_type === "hide_group_knock") {
      const fromId = data?.data?.group_request_id;
      const notificationId = this.rootStores.appStore.notificationRegistry
        .get(fromId)
        ?.get("group_knock");
      this.rootStores.appStore.dismiss(notificationId);
    } else if (data.event_type === "participant_action") {
      // skip if it's me
      if (callStore.participant.dailyId === data.data.participant_id) return;
      console.log(data.data.message);
      switch (data.data.title) {
        case "emoji-reaction":
          roomReactionStore.displayReaction(
            data.data.item,
            data.data.participant_id
          );
          break;
        case "tile-settings":
          tileSettingsStore.addParticipantSettings(
            data.data.item,
            data.data.participant_id
          );
          break;
        default:
          break;
      }
    }
  };

  @action
  handleGroupDocsCableEvent = (data) => {
    console.log("RECEIVED!!! group docs cable event");
    const payload = JSON.parse(data);
    this.docStore.updateContent(payload.id, payload.content);
  };

  @action
  handleGroupDocsMetadataCableEvent = (data) => {
    console.log("RECEIVED!!! group docs METADATA cable event");
    this.docStore.updateMetadataFromMetadataJson(data);
  };

  @action
  doPing = () => {
    if (this.stopPinging === true) {
      console.log("Stopped pinging...");
      runInAction(() => {
        this.stopPinging = false;
        this.pingPromise = null;
      });

      return;
    }

    if (this.participantsSubscription) {
      this.participantsSubscription.send({
        event_type: "ping",
        body: "test ping",
      });
      LogRocket.log("Pinged!");
    } else {
      console.log("no subscription... can't ping");
    }
    runInAction(() => {
      this.pingPromise = Promise.delay(5000).then(() => {
        this.doPing();
      });
    });
  };

  cancelPing = () => {
    this.stopPinging = true;
  };
}
