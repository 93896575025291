export function playSound(url, volume = 0.4) {
  const audio = new Audio(url);
  audio.volume = volume;
  audio.play();
  return audio;
}

export function playLeft() {
  playSound("/sounds/case-closed-531.mp3", 0.25);
}

export function playYouJoined() {
  playSound("/sounds/open-ended-563.mp3");
}

// https://freesound.org/people/TheBuilder15/sounds/415763/
export function playPing() {
  playSound("/sounds/juntos-607.mp3");
}

// https://freesound.org/people/talhanesb/sounds/563822/
export function playRing() {
  playSound("/sounds/ring_talhanesb.mp3");
}

export function playHangingOut() {
  playSound("/sounds/appointed-529.mp3");
}

export function playKnock() {
  playSound("/sounds/your-turn-491.mp3");
}

// https://notificationsounds.com/notification-sounds/light-562
export function playDirectMessage() {
  playSound("/sounds/light-562.mp3", 0.2);
}
