import { useState, useEffect } from "react";

// https://stackoverflow.com/questions/47686345/playing-sound-in-react-js
export default function useAudio(url, loop = false) {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);
  const play = () => setPlaying(true);
  const stop = () => setPlaying(false);

  useEffect(() => {
    if (playing) {
      if (loop) {
        audio.loop = true;
      }
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [playing, audio, loop]);

  useEffect(() => {
    audio.volume = 0.4;
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, [audio]);

  return { playing, toggle, play, stop };
}
