import { useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import PlainPhotoLayout from "../../Layouts/PlainPhotoLayout";
// import JoinSpaces from "../../JoinSpaces/JoinSpaces";
import JoinSpacesStep from "./JoinSpacesStep";
import RequestNotifications from "./RequestNotifications";
import PhotoUploadStep from "./PhotoUploadStep";
import LaunchTheAppStep from "./LaunchTheAppStep";
import SlackUser from "./SlackUser";
import { useStores } from "../../../stores/utils";
import { Router, navigate } from "@reach/router";
import { useQuery } from "react-query";
import * as UserAgentUtil from "../../../utilities/user-agent";
import BrowserUnsupported from "../../BrowserUnsupported/BrowserUnsupported";
import "./OfficeOnboarding.scss";
import Honeybadger from "honeybadger-js";
import useQueryParams from "hooks/useQueryParams";

const OfficeOnboarding = (props) => {
  const { api, queryClient, userStore } = useStores();
  const urlQueryParams = useQueryParams();
  const organizationId = userStore.organizationId;
  const organizationData = useQuery(["organization", organizationId], () =>
    api.getOrganization()
  );

  const slackTeamId =
    organizationData?.data?.data?.data?.attributes?.["slack-team-id"];
  const organizationHasSlack = !!slackTeamId;

  const slackUserLookup = useCallback(async () => {
    return await api.slackUserLookup(userStore.userId);
  }, [api, userStore.userId]);

  useEffect(() => {
    queryClient.prefetchQuery("slack-user-lookup", slackUserLookup);
  }, [queryClient, slackUserLookup]);

  const skipNotifications = () => {
    if (organizationHasSlack) {
      navigate("slack");
    } else {
      skipSlack();
    }
  };

  const skipPhoto = () => {
    navigate("launch_the_app");
  };

  const dismissConnectSlack = () => {
    try {
      api.setUserPreferenceValue("dismissed_connect_slack", true);
    } catch (e) {
      Honeybadger.notify(e);
    }
  };

  const skipSlack = (e) => {
    if (urlQueryParams?.next === "office") {
      e && dismissConnectSlack();
      navigate("/office");
    } else {
      navigate("photo");
    }
  };

  if (UserAgentUtil.shouldBlockBrowser()) return <BrowserUnsupported />;

  return (
    <PlainPhotoLayout blueLogo={true} className="accept-invite-page">
      <div className="content-column narrow">
        {" "}
        <Router>
          <JoinSpacesStep path="/" />
          <RequestNotifications path="notifications" skip={skipNotifications} />
          <SlackUser path="slack" skip={skipSlack} />
          <PhotoUploadStep path="photo" skip={skipPhoto} />
          <LaunchTheAppStep path="launch_the_app" />
        </Router>
      </div>
    </PlainPhotoLayout>
  );
};

export default observer(OfficeOnboarding);
