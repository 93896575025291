import { observer } from "mobx-react-lite";
import TinyProfilePhoto from "../ProfilePhoto/TinyProfilePhoto";
import UserParticipantActions from "../Office/UserParticipantActions";
import { useStores } from "../../stores/utils";
import classname from "classnames";

function ParticipantNameDifferentSession({
  participant,
  disableUserActions,
  clickToDM,
}) {
  const { userChatStore } = useStores();

  const openChat = () => {
    if (!clickToDM) return;
    userChatStore.openChat(participant?.chatId);
  };

  return (
    <div
      className={classname("tag participant-name-tag", {
        "no-actions": disableUserActions,
      })}
      onClick={openChat}
    >
      <div className="photo-wrapper">
        <TinyProfilePhoto
          photoUrl={participant.tinyPhotoUrl}
          personName={participant.name}
          placeholderColor={participant.color}
        />{" "}
        <span className="name-text">{participant.name}</span>
        {!disableUserActions && (
          <div className="actions">
            <UserParticipantActions
              userId={participant.userId}
              differentSession
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(ParticipantNameDifferentSession);
