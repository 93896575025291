import { Plan } from "components/Billing/Plan";
import { Payment } from "components/Billing/Payment";
import { Invoices } from "components/Billing/Invoices";
import { useStores } from "stores/utils";
import ErrorPage from "../ErrorPage/ErrorPage";
import { SubscriptionStatus } from "components/Billing/SubscriptionStatus";
import "./Billing.scss";

const Billing = () => {
  const { permissionsStore } = useStores();

  if (!permissionsStore.canSeeBillingPage) return <ErrorPage status={403} />;

  return (
    <div className="billing-page">
      <h1 className="title is-1">Plan {"&"} Billing</h1>
      <Plan />
      <Payment />
      <Invoices />
      <SubscriptionStatus />
    </div>
  );
};

export default Billing;
