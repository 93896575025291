import { observer } from "mobx-react-lite";
import { useState, useCallback } from "react";
import api from "../../api";
import Tippy from "@tippyjs/react";
import { FiChevronRight } from "react-icons/fi";
import { useStores } from "../../stores/utils";
import RestParticipantsList from "../RestParticipantsList/RestParticipantsList";
import { ParticipantModelLite } from "../../stores/ParticipantModelLite";

function RestBlock({ room }) {
  const rootStores = useStores();
  const restCount = room.restCount;

  const [restParticipants, setRestParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const sameRoom = room.isCurrentRoom;

  const loadRest = useCallback(async () => {
    const result = await api.getRestParticipantsInGroup(room.id);
    setLoading(false);
    const participants = result.data.data.map((p) => {
      return new ParticipantModelLite({
        data: p,
        rootStores: rootStores,
      });
    });
    setRestParticipants(participants);
  }, [room.id, rootStores]);

  return (
    <>
      {restCount > 0 && (
        <div className="more-participants-container">
          <div className="more-participants-container-2">
            <Tippy
              trigger="click"
              theme="light"
              arrow={false}
              placement="bottom"
              duration={0}
              interactive
              onShow={() => {
                loadRest();
              }}
              content={
                <RestParticipantsList
                  groupId={room.id}
                  restParticipants={restParticipants}
                  loading={loading}
                  sameRoom={sameRoom}
                />
              }
            >
              <div className="more-participants">
                + {restCount} More <FiChevronRight />
              </div>
            </Tippy>
          </div>
        </div>
      )}
    </>
  );
}

export default observer(RestBlock);
