import {
  action,
  computed,
  observable,
  makeObservable,
  runInAction,
} from "mobx";
import { logDailyEvent } from "../logUtils";
import DailyIframe from "@daily-co/daily-js";
import Honeybadger from "honeybadger-js";
import { STATE_ERROR } from "./AppStore";
import * as Sounds from "../utilities/sounds";

const callObjectLoaded = "loaded";
const joinedMeeting = "joined-meeting";
const participantLeft = "participant-left";
const participantJoined = "participant-joined";
const participantUpdated = "participant-updated";
const cameraError = "camera-error";
const fatalError = "error";
const trackStarted = "track-started";
const trackStopped = "track-stopped";
const activeSpeakerChange = "active-speaker-change";
const appMessage = "app-message";

const dailyParticipantChangeEvents = [
  participantJoined,
  participantUpdated,
  participantLeft,
];

export class CallObjectStore {
  @observable.ref callObject = null;

  @observable isCameraMuted = false;
  @observable isMicMuted = false;
  @observable isSharingScreen = false;

  @observable savedMicState = null;
  @observable savedCameraState = null;

  @observable.shallow videoInputs = [];
  @observable.shallow audioInputs = [];
  @observable listenersAttached = false;
  @observable isIdleTriggered = false;

  constructor(rootStores) {
    this.rootStores = rootStores;
    this.callStateStore = rootStores.callStateStore;

    makeObservable(this);
  }

  init = () => {
    this.createCallObject();
    this.subscribeToCallObjectEvents();
  };

  dispose = async () => {
    this.leaveAndUnsubscribe();
    return await this.destroyCallObject();
  };

  @action
  joinCall = async (url) => {
    this.init();
    return await this.callObject.join({ url });
  };

  async leaveCall() {
    if (!this.callObject) return;
    await this.callObject.leave();
  }

  switchCall = async (newUrl) => {
    await this.leaveCall();
    return await this.joinCall(newUrl);
  };

  @action.bound
  createCallObject() {
    if (this.isCallObjectAvailable) return;
    this.callObject = DailyIframe.createCallObject({
      dailyConfig: {
        experimentalChromeVideoMuteLightOff: true,
      },
    });
  }

  @action.bound
  destroyCallObject() {
    if (this.callObject) {
      return this.callObject
        .destroy()
        .catch((e) => {
          console.log("problem destroying call object: ", e);
        })
        .finally(() => {
          runInAction(() => {
            this.nullifyCallObject();
          });
        });
    } else {
      this.nullifyCallObject();
    }
  }

  @action
  nullifyCallObject() {
    this.callObject = null;
    window.callObject = null;
  }

  async leaveAndUnsubscribe() {
    await this.leaveCall();
    this.unsubscribeFromCallObjectEvents();
  }

  subscribeToCallObjectEvents = () => {
    if (!this.callObject) {
      console.log("call object is not available for subscribe");
      return;
    }
    if (this.listenersAttached) {
      console.log("Call object listeners already attached. skipping.");
      return;
    }
    console.log("SUBSCRIBING TO CALL OBJECT EVENTS...");

    for (const event of dailyParticipantChangeEvents) {
      this.callObject.on(event, this.handleNewParticipantsState);
    }

    this.callObject.on(callObjectLoaded, this.handleCallObjectLoaded);
    this.callObject.on(joinedMeeting, this.handleJoinedMeeting);
    this.callObject.on(cameraError, this.handleCameraError);
    this.callObject.on(fatalError, this.handleFatalError);
    this.callObject.on(trackStarted, this.handleTrackStarted);
    this.callObject.on(trackStopped, this.handleTrackStopped);
    this.callObject.on(activeSpeakerChange, this.handleActiveSpeakerChange);
    this.callObject.on(participantUpdated, this.handleParticipantUpdated);
    this.callObject.on(participantJoined, this.handleParticipantJoined);
    this.callObject.on(participantLeft, this.handleParticipantLeft);
    this.callObject.on(appMessage, this.handleAppMessageReceived);
    runInAction(() => {
      this.listenersAttached = true;
    });
  };

  unsubscribeFromCallObjectEvents = () => {
    if (!this.callObject) {
      console.log("call object is not available for unsubscribe");
      return;
    }
    console.log("UNSUBSCRIBING TO CALL OBJECT EVENTS...");
    if (this.callObject) {
      for (const event of dailyParticipantChangeEvents) {
        this.callObject.off(event, this.handleNewParticipantsState);
      }
    }

    this.callObject.off(callObjectLoaded, this.handleCallObjectLoaded);
    this.callObject.off(joinedMeeting, this.handleJoinedMeeting);
    this.callObject.off(cameraError, this.handleCameraError);
    this.callObject.off(fatalError, this.handleFatalError);
    this.callObject.off(trackStarted, this.handleTrackStarted);
    this.callObject.off(trackStopped, this.handleTrackStopped);
    this.callObject.off(activeSpeakerChange, this.handleActiveSpeakerChange);
    this.callObject.off(participantUpdated, this.handleParticipantUpdated);
    this.callObject.off(participantJoined, this.handleParticipantJoined);
    this.callObject.off(participantLeft, this.handleParticipantLeft);
    this.callObject.off(appMessage, this.handleAppMessageReceived);
    runInAction(() => {
      this.listenersAttached = false;
    });
  };

  handleNewParticipantsState = (event) => {
    try {
      event && logDailyEvent(event);
      const participants = this.callObject.participants();
      this.callStateStore.throttledChangeParticipants(participants);
    } catch (e) {
      console.log("event error", e);
    }
  };

  handleParticipantJoined = (event) => {
    const you_joined_at = this.callObject._participants.local.joined_at;
    const new_participant_joined_at = event.participant.joined_at;
    if (you_joined_at < new_participant_joined_at) {
      if (
        this.rootStores.appStore.officeMode &&
        this.rootStores.callStore.participantList.length < 6
      ) {
        Sounds.playYouJoined();
      }
    }
  };

  handleParticipantLeft = (event) => {
    if (
      this.rootStores.appStore.officeMode &&
      this.rootStores.callStore.participantList.length < 6
    ) {
      Sounds.playLeft();
    }
  };

  handleCallObjectLoaded = (event) => {
    event && logDailyEvent(event);
    this.rootStores.callStore.debouncedShowAllowModal();
  };

  handleJoinedMeeting = (event) => {
    event && logDailyEvent(event);
    if (!this.rootStores.callStore.isCallError) {
      this.rootStores.notificationModalStore.clearNotificationMessage();
    }

    this.rootStores.callStore.configureDeviceStatesAfterJoining();
    this.setCallSettigsStoreDevices();
  };

  handleFatalError = (event) => {
    event && logDailyEvent(event);
    this.rootStores.appStore.setAppState(STATE_ERROR);
    this.callStateStore.setFatalError((event && event.errorMsg) || "Unknown");
  };

  handleTrackStarted = (event) => {
    event && logDailyEvent(event);
    if (event.participant.local === true) {
      if (
        event.participant.videoTrack &&
        event.participant.videoTrack.id &&
        event.track.id === event.participant.videoTrack.id
      ) {
        this.rootStores.callStore.updateVideoFromEvent(event);
      }
    }
  };

  handleTrackStopped = (event) => {
    event && logDailyEvent(event);
    if (event.participant) {
      if (event.participant.local === true) {
        if (
          !event.participant.videoTrack &&
          !event.participant.video &&
          event.track.kind === "video"
        ) {
          this.rootStores.callStore.updateVideoFromEvent(event);
        }
      }
    } else {
      console.log("Daily event had no participant. ??");
    }
  };

  handleActiveSpeakerChange = (event) => {
    this.rootStores.callStore.setActiveSpeakerId(event.activeSpeaker.peerId);
  };

  @action.bound
  handleCameraError(error) {
    if (this.rootStores.triggeredCameraError) {
      return;
    }

    this.rootStores.callStore.triggeredCameraError = true;
    this.rootStores.callStore.isCallError = true;

    if (
      error?.errorMsg?.errorMsg &&
      error?.errorMsg?.errorMsg === "not allowed"
    ) {
      this.rootStores.callStore.showOutputError();
    }

    this.callStateStore.setCamOrMicError(
      (error && error.errorMsg && error.errorMsg.errorMsg) || "Unknown"
    );
  }

  getCallObjectParticipants() {
    return this.callObject.participants();
  }

  getLocalParticipant() {
    return this.getCallObjectParticipants()?.local;
  }

  getLocalVideoTrack() {
    return this.getLocalParticipant?.videoTrack;
  }

  getCallObjectLocalVideo() {
    return this.callObject.localVideo();
  }

  getLocalParticipantId() {
    return this.getLocalParticipant()?.user_id;
  }

  @computed
  get isCallObjectAvailable() {
    return !!this.callObject;
  }

  setCallObjectLocalVideo(value) {
    // console.log("[debug1] setting CallObject  video to: ", value);
    this.callObject.setLocalVideo(value);
  }

  setCallObjectLocalAudio(value) {
    // console.log("[debug1] setting CallObject  audio to: ", value);
    this.callObject.setLocalAudio(value);
  }

  async setCallObjectInputDevices({
    audioDeviceId = null,
    videoDeviceId = null,
  }) {
    let deviceConfig = {};
    if (audioDeviceId) {
      deviceConfig.audioDeviceId = audioDeviceId;
    }
    if (videoDeviceId) {
      deviceConfig.videoDeviceId = videoDeviceId;
    }
    try {
      await this.callObject.setInputDevicesAsync(deviceConfig);
    } catch (error) {
      Honeybadger.notify(error);
    }
  }

  getMeetingState() {
    return this.callObject.meetingState();
  }

  getNetworkStats() {
    return this.callObject.getNetworkStats();
  }

  /**
   * Gets [isCameraMuted, isMicMuted, isSharingScreen].
   * This function is declared outside Tray() so it's not recreated every render
   * (which would require us to declare it as a useEffect dependency).
   */
  getStreamStates() {
    const localParticipant = this.getLocalParticipant();

    let isCameraMuted,
      isMicMuted,
      isSharingScreen = false;

    if (localParticipant) {
      // console.log(
      //   `[debug1 ${localParticipant.session_id}] UPDATING CAM/MIC FROM DAILY! Setting...`
      // );
      // console.log(
      //   `[debug1 ${localParticipant.session_id}] vid: `,
      //   localParticipant.video
      // );
      // console.log(
      //   `[debug1 ${localParticipant.session_id}] mic: `,
      //   localParticipant.audio
      // );

      isCameraMuted = !localParticipant.video;
      isMicMuted = !localParticipant.audio;
      isSharingScreen = localParticipant.screen;
    } else {
      // console.log(
      //   "[debug1] !!!!!!!!!!! No local participant, so defaulting cam/mic to false"
      // );
    }

    return [isCameraMuted, isMicMuted, isSharingScreen];
  }

  handleParticipantUpdated = (event) => {
    if (!event.participant.local) {
      return false;
    }
    const [isCameraMuted, isMicMuted, isSharingScreen] = this.getStreamStates();

    this.directlySetCameraMuted(isCameraMuted);
    this.directlySetMicMuted(isMicMuted);
    this.setSharingScreen(isSharingScreen);
  };

  @action
  directlySetCameraMuted(value) {
    this.isCameraMuted = value;
  }

  @action
  setCamera = (isCameraActive) => {
    this.clearSavedCameraState();
    if (this.isCallError) {
      this.showOutputError();
    } else {
      this.directlySetCameraMuted(!isCameraActive);
      // console.log("[debug1] callObjectStore setCamera to: ", isCameraActive);
      this.setCallObjectLocalVideo(isCameraActive);
    }
  };

  @action
  saveCameraState = () => {
    this.savedCameraState = !this.isCameraMuted;
  };

  restoreCameraState = () => {
    if (this.savedCameraState != null) {
      // console.log(
      //   "[debug1] callobjectstore restoring camera state to: ",
      //   this.savedCameraState
      // );
      this.setCamera(this.savedCameraState);
    }
  };

  @action
  clearSavedCameraState = () => {
    this.savedCameraState = null;
  };

  @action
  directlySetMicMuted(value) {
    this.isMicMuted = value;
  }

  @action
  setMicrophone = (isMicrophoneActive) => {
    this.clearSavedMicState();
    if (this.isCallError) {
      this.showOutputError();
    } else {
      this.directlySetMicMuted(!isMicrophoneActive);
      this.setCallObjectLocalAudio(isMicrophoneActive);
    }
  };

  @action
  toggleMicrophone = () => {
    this.setMicrophone(this.isMicMuted);
  };

  @action
  saveMicState = () => {
    this.savedMicState = !this.isMicMuted;
  };

  restoreMicState = () => {
    if (this.savedMicState != null) {
      this.setMicrophone(this.savedMicState);
    }
  };

  @action
  clearSavedMicState = () => {
    this.savedMicState = null;
  };

  @action
  setSharingScreen(value) {
    this.isSharingScreen = value;
  }

  prepareDevices = () => {
    if (!this.isCallObjectAvailable) return;

    return this.callObject
      .enumerateDevices()
      .then(({ devices }) => {
        let cams = devices.filter((x) => x.kind === "videoinput");

        this.setVideoInputs(
          cams.map((vi) => ({
            value: vi?.deviceId,
            label: vi.label,
          }))
        );

        let mics = devices.filter((x) => x.kind === "audioinput");
        this.setAudioInputs(
          mics.map((vi) => ({
            value: vi?.deviceId,
            label: vi.label,
          }))
        );
      })
      .catch((err) => {
        console.log(err.name + ": " + err.message);
        this.rootStores.errorStore.notify(err);
      });
  };

  lookupDeviceById = (deviceId) => {
    return this.callObject.enumerateDevices().then(({ devices }) => {
      return devices.find((x) => x?.deviceId === deviceId);
    });
  };

  @action
  setAudioInputs(values) {
    this.audioInputs.replace(values);
  }

  @action
  setVideoInputs(values) {
    this.videoInputs.replace(values);
  }

  @action.bound
  resetDevices() {
    this.videoInputs.clear();
    this.audioInputs.clear();
  }

  setCallSettigsStoreDevices() {
    if (!this.isCallObjectAvailable) {
      return;
    }

    this.callObject.getInputDevices().then(({ camera, mic }) => {
      if (!this.rootStores.callSettingsStore.currentCam) {
        if (camera && camera.deviceId) {
          this.rootStores.callSettingsStore.setCurrentCam(camera);
        }
      }
      if (!this.rootStores.callSettingsStore.currentMic) {
        // console.log("setcallsttingstoredevices: ", mic);
        if (mic && mic.deviceId) {
          this.rootStores.callSettingsStore.setCurrentMic(mic);
        }
      }
    });
  }

  toggleScreenSharing() {
    if (this.isSharingScreen) {
      this.callObject.stopScreenShare();
    } else {
      this.callObject.startScreenShare();
    }
  }

  stopScreensharing() {
    this.callObject.stopScreenShare();
  }

  @computed
  get nonEmptyCameras() {
    return this.videoInputs.filter((item) => item.value && item.value !== "");
  }

  @computed
  get nonEmptyMics() {
    return this.audioInputs.filter((item) => item.value && item.value !== "");
  }

  @action
  setIdleTriggered(value) {
    this.isIdleTriggered = value;
  }

  handleAppMessageReceived = (event) => {
    const { message } = event.data;
    if (message === "cursors") {
      this.rootStores.sharedCursorsStore.receiveUserDrawing(event.data);
    } else if (message === "aspectRatio") {
      this.rootStores.sharedCursorsStore.receiveAspectRatio(event.data);
    }
  };
}
