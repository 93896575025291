import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { animated } from "react-spring";
import { useSpringHover } from "hooks/useSpringHover";

function AnimatedButton({
  rotation,
  timing,
  scale,
  goDefaultMode,
  showSlider,
}) {
  const [style, trigger] = useSpringHover({ rotation, timing, scale });

  return (
    <>
      <button
        className="button"
        onClick={goDefaultMode}
        onMouseOver={showSlider}
        onMouseEnter={trigger}
      >
        <animated.span style={style}>
          <FontAwesomeIcon icon={faSquare} />
        </animated.span>
      </button>
    </>
  );
}

export default observer(AnimatedButton);
