import { useEffect, useRef } from "react";

export default function AudioTag(props) {
  const audioEl = useRef(null);

  useEffect(() => {
    if (!audioEl.current) return;
    audioEl.current.srcObject = new MediaStream([props.audioTrack]);
  }, [props.audioTrack]);

  return <audio autoPlay playsInline ref={audioEl} />;
}
