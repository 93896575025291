import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import Honeybadger from "honeybadger-js";
import HBErrorBoundary from "@honeybadger-io/react";
import { Helmet } from "react-helmet";
import LogRocket from "logrocket";
import * as UserAgentUtil from "./utilities/user-agent";
import * as Amplitude from "./utilities/amplitude";
import * as URlUtil from "./utilities/url";
import "./index.scss";
import AppWrapper from "./components/AppWrapper/AppWrapper";
import BrowserUnsupported from "./components/BrowserUnsupported/BrowserUnsupported";

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (process.env.REACT_APP_LOGROCKET_KEY) {
  if (
    window.location.hostname !== "load.sidebar.vc" &&
    window.location.hostname !== "justin.sidebar.vc"
  ) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY, {
      release: process.env.REACT_APP_GIT_SHA,
      rootHostname: "sidebar.vc",
      mergeIframes: true,
    });
  }
}

if (process.env.REACT_APP_HONEYBADGER_ENV) {
  console.log(
    "git revision for honeybadger notify is: ",
    process.env.REACT_APP_GIT_SHA
  );
  Honeybadger.configure({
    apiKey: process.env.REACT_APP_HONEYBADGER_KEY,
    environment: process.env.REACT_APP_HONEYBADGER_ENV,
    revision: process.env.REACT_APP_GIT_SHA,
  });

  LogRocket.getSessionURL((sessionURL) => {
    Honeybadger.beforeNotify((notice) => {
      notice.context.sessionURL = sessionURL;
    });
  });
}
if (process.env.NODE_ENV === "development") {
  Error.stackTraceLimit = Infinity;
}
Amplitude.init();

let oldOnError = window.onerror;
window.onerror = function (message, source, lineno, colno, error) {
  if (oldOnError) oldOnError.apply(this, arguments); // Call any previously assigned handler
  console.log("== CAUGHT IN index.js ==");
  Amplitude.track("ERROR: kicking user out of all due to error.", {
    message,
    source,
    lineno,
    colno,
    error,
  });
  console.log(
    "error!! - printing for Flood.io log, since it doesnt show objects:"
  );
  console.log("name: ", error.name);
  console.log("message: ", error.message);
  Honeybadger.notify(error);

  setTimeout(() => {
    // window.location.reload();
  }, 2000);
};

// window.addEventListener("unhandledrejection", function (event) {
//   console.log("UNHANDLED REJECTION!!!");
//   console.log(event);
//   Honeybadger.notify(event);
// });

// enableLogging();

class Application {
  async init() {
    this.render();
  }

  render() {
    return ReactDOM.render(
      <React.StrictMode>
        <HBErrorBoundary honeybadger={Honeybadger}>
          <>
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
            {UserAgentUtil.isNotCrashing() ? (
              <AppWrapper />
            ) : (
              <BrowserUnsupported />
            )}
          </>
        </HBErrorBoundary>
      </React.StrictMode>,
      document.getElementById("root")
    );
  }
}

const application = new Application();

if (window.location.pathname === "/pricing") {
  window.location.replace("http://get.sidebar.vc/pricing");
} else {
  const sessionId = URlUtil.parseSessionIdFromUrl();
  if (!sessionId) {
    window.location.replace("http://office.sidebar.vc");
  } else {
    application.init();
  }
}
