import { computed, observable, makeObservable, action } from "mobx";

export class CallItemModel {
  @observable.ref audioTrack = null;
  @observable.ref videoTrack = null;
  @observable dailyId;
  @observable isLoading = false;
  @observable isLocal;
  @observable isScreen;
  @observable listKey;
  @observable.ref tracks;
  @observable name = "";

  constructor({ rootStores, data }) {
    this.rootStores = rootStores;
    this.mergeData(data);

    makeObservable(this);
  }

  @computed
  get sidebarParticipant() {
    return this.rootStores.callStore.findParticipantByCallItem(this);
  }

  @action
  mergeData(data) {
    this.audioTrack = data.audioTrack;
    this.videoTrack = data.videoTrack;
    this.dailyId = data.dailyId;
    this.isLoading = data.isLoading;
    this.isLocal = data.isLocal;
    this.isScreen = data.isScreen;
    this.listKey = data.listKey;
    this.tracks = data.tracks;
    this.name = data.name;
  }
}
