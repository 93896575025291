import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";
import { Tooltip } from "react-tippy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import "./Details.scss";

const NetworkStatsDetails = ({ store }) => {
  useEffect(() => {
    store.fetch();
  }, [store]);

  return (
    <div className="network-stats__wrapper">
      <h1>Network info</h1>
      <div className="subtle">Stats refresh every 5s</div>
      {store.connected ? (
        <>
          <div className="network-stats__quality network-stats__row">
            <h2>Overall</h2>
            Your connection is{" "}
            <div
              className={classnames(
                "network-stats__quality-tag",
                `network-stats__quality-tag--${store.connectionStatus}`
              )}
            >
              {store.connectionQualityWord || store.threshold}
            </div>
          </div>
          <div className="network-stats__quality network-stats__row">
            <h2>Packet Loss</h2>
            Your connection is{" "}
            <div
              className={classnames(
                "network-stats__quality-tag",
                `network-stats__quality-tag--${store.connectionStatus}`
              )}
            >
              {store.connectionQualityWord || store.threshold}
            </div>
          </div>
          <div className="network-stats__download network-stats__row">
            <h2>
              Download Data Rate{" "}
              <Tooltip
                animateFill={false}
                theme="light"
                title="Only includes bandwidth used for video and screenshare. Audio data excluded."
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </h2>
            <div className="network-stats__statistic">
              {store.receivedKbps}{" "}
              <span className="network-stats__units">kb/s</span>
            </div>
          </div>
          <div className="network-stats__upload network-stats__row">
            <h2>
              Upload Data Rate{" "}
              <Tooltip
                animateFill={false}
                theme="light"
                title="Only includes bandwidth used for video and screenshare. Audio data excluded."
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </h2>
            <div className="network-stats__statistic">
              {store.sentKbps}{" "}
              <span className="network-stats__units">kb/s</span>
            </div>
          </div>
        </>
      ) : (
        <div className="network-stats__alone">
          Stats will appear after a second person joins.
        </div>
      )}
    </div>
  );
};

export default observer(NetworkStatsDetails);
