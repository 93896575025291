import { observer } from "mobx-react-lite";
import ParticipantCaret from "../ParticipantCaret/ParticipantCaret";
import TinyProfilePhoto from "../ProfilePhoto/TinyProfilePhoto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useStores } from "../../stores/utils";
import classname from "classnames";

import "./ParticipantNametag.scss";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

function ParticipantNametag({
  alreadyInRoom,
  participant,
  parentEl,
  officeMode,
}) {
  const { callStore } = useStores();

  return (
    <ParticipantCaret
      participant={participant}
      alreadyInRoom={alreadyInRoom}
      parentEl={parentEl}
      officeMode={officeMode}
    >
      {(toggleOpen, isOpen) => {
        return (
          <span
            key={participant.id}
            className={classname(
              participant.colorClass,
              "tag",
              "participant-name-tag",
              {
                "currently-focused-tag": isOpen,
                "participant-me": callStore.isMe(participant.id),
                "audio-only-padding": participant.audioOnly,
                "in-auditorium-tag": callStore.currentRoom?.isAuditorium,
              }
            )}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleOpen();
            }}
            aria-haspopup="true"
            aria-controls="dropdown-menu"
          >
            <div className="photo-wrapper">
              {officeMode && (
                <TinyProfilePhoto
                  photoUrl={participant.tinyPhotoUrl}
                  personName={participant.name}
                  placeholderColor={participant.color}
                />
              )}{" "}
              <span className="name-text">{participant.name}</span>
              <div className="nametag-info">
                {callStore.isMe(participant.id) && (
                  <span className="you-label">you</span>
                )}
                {participant.showMicIcon && (
                  <Tippy
                    content="Microphone only, camera off"
                    trigger="mouseenter focus"
                    theme="light"
                  >
                    <div className="audio-only-icon">
                      <FontAwesomeIcon icon={faMicrophone} />
                    </div>
                  </Tippy>
                )}
                <span className="icon is-small">
                  {isOpen ? <BsChevronUp /> : <BsChevronDown />}
                </span>
              </div>
            </div>
          </span>
        );
      }}
    </ParticipantCaret>
  );
}

export default observer(ParticipantNametag);
