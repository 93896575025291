import { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignalAlt } from "@fortawesome/pro-duotone-svg-icons";
import NetworkStatsDetails from "../Details/Details";
import classnames from "classnames";
import "tippy.js/animations/shift-away.css";
import "tippy.js/themes/light.css";

const NetworkStatsButton = ({ store, disabled, theme }) => {
  const interval = useRef(null);

  useEffect(() => {
    if (!store.updateRate) {
      return;
    }

    interval.current = setInterval(() => {
      store.fetch();
    }, store.updateRate);

    return () => {
      clearInterval(interval.current);
    };
  }, [store, store.updateRate]);

  return (
    <Tippy
      animation="shift-away"
      content={<NetworkStatsDetails store={store} />}
      interactive={true}
      onClickOutside={store.hideNetworkPanel}
      theme="light"
      visible={store.isNetworkPanelOpen}
    >
      <Tippy
        content="Network info"
        disabled={store.isNetworkPanelOpen}
        appendTo="parent"
        theme={theme}
      >
        <button
          className={classnames(
            "tray-button",
            "custom-tray-item",
            "networkstats-button",
            `networkstats-button--${store.connectionStatus}`
          )}
          onClick={store.toggleNetworkPanel}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faSignalAlt} />
        </button>
      </Tippy>
    </Tippy>
  );
};

export default observer(NetworkStatsButton);
