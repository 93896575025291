import { useEffect, useState } from "react";
import { useStores, observe } from "stores/utils";
import { ChangePaymentModal } from "./ChangePaymentModal";
import "./shared.scss";

export const Payment = () => {
  const { userSubscriptionStore } = useStores();
  const [showChangePaymentModal, setShowChangePaymentModal] = useState(false);

  useEffect(() => {
    userSubscriptionStore.getPayment();
  }, [userSubscriptionStore]);

  return (
    <div className="billing-card box">
      <div className="billing-card__title">Your payment details</div>

      <div className="billing-card-table">
        {observe(
          userSubscriptionStore.payment,
          () =>
            userSubscriptionStore.payment && (
              <div className="billing-card-table__row">
                <div>
                  {userSubscriptionStore.payment?.card?.brand} ending in{" "}
                  {userSubscriptionStore.payment?.card?.last4}
                </div>
                <div
                  className="link-button"
                  onClick={() => setShowChangePaymentModal(true)}
                >
                  Change payment
                </div>
              </div>
            )
        )}
      </div>
      {showChangePaymentModal && (
        <ChangePaymentModal
          handleCloseModal={() => setShowChangePaymentModal(false)}
        />
      )}
    </div>
  );
};
