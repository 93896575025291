import { useEffect } from "react";
import { useNavigate, useParams } from "@reach/router";
import { useStores } from "stores/utils";

export const SubscriptionPlanSelect = () => {
  const params = useParams();
  const { subscriptionsStore } = useStores();
  const navigate = useNavigate();
  subscriptionsStore.selectPlan(params.planName);

  useEffect(() => {
    navigate("/signup");
  }, [navigate]);

  return <div />;
};
