import PropTypes from "prop-types";
import Select from "react-select";
import ReactModal from "react-modal";
import { MdClose } from "react-icons/md";
import uniqBy from "lodash/uniqBy";
import { formatNumberWithCommas } from "utilities/formatNumberWithCommas";
import { useStores, observe } from "stores/utils";

import "./CapModal.scss";

const PLAN_OPTIONS = [
  {
    value: "month",
    label: "Monthly",
  },
  {
    value: "year",
    label: "Annual",
  },
];

export const ChangePlanModal = ({ handleCloseModal }) => {
  const { subscriptionsStore } = useStores();

  const generateOptions = () => {
    const includedHours =
      subscriptionsStore.currentSubscription?.attributes?.included_hours;

    let options = Array.apply(null, Array(20)).map((_, index) => ({
      value: includedHours + index * 50,
      label: `${includedHours} + ${
        index *
        subscriptionsStore.currentSubscription?.attributes
          ?.overage_hours_lot_size
      } Hours`,
    }));

    options.unshift({ value: null, label: "No cap" });
    return options;
  };

  const getOptions = () => {
    return uniqBy(
      subscriptionsStore.subscriptions.map((subscription) => ({
        value: subscription?.attributes?.name,
        label: subscription?.attributes?.name,
      })),
      "value"
    );
  };

  return (
    <ReactModal
      isOpen
      overlayClassName="ReactModal__Overlay__disabled"
      className="ReactModal__Content__modal"
    >
      <div className="modal-wrapper">
        <div className="modal-close-wrap" onClick={handleCloseModal}>
          <MdClose size={20} />
        </div>
        <div className="modal-header">Change plan details</div>
        <div className="modal-content">
          <div className="subscription-container">
            <div>
              <div className="subscription-item">
                <div className="subscription-item__title">Sidebar plan</div>
                <div className="subscription-item__content">
                  <Select
                    defaultValue={{
                      value:
                        subscriptionsStore.currentSubscription?.attributes
                          ?.name,
                      label:
                        subscriptionsStore.currentSubscription?.attributes
                          ?.name,
                    }}
                    label="Single select"
                    options={getOptions()}
                    onChange={(option) =>
                      subscriptionsStore.changeSubscription(option.value)
                    }
                  />
                </div>
              </div>
              <div className="subscription-item">
                <div className="subscription-item__title">Billing plan</div>
                <div className="subscription-item__content">
                  {observe(subscriptionsStore.currentSubscription, () => (
                    <Select
                      styles={{
                        container: (base) => ({
                          ...base,
                          width: "100%",
                        }),
                      }}
                      defaultValue={PLAN_OPTIONS.find(
                        (option) =>
                          option.value === subscriptionsStore.selectedInterval
                      )}
                      label="Single select"
                      options={PLAN_OPTIONS}
                      onChange={(option) =>
                        subscriptionsStore.changeInterval(option.value)
                      }
                    />
                  ))}
                </div>
              </div>

              <div className="subscription-item">
                <div className="subscription-item__title">Set a cap</div>
                <div className="subscription-item__content">
                  {observe(subscriptionsStore.currentSubscription, () => (
                    <Select
                      value={{
                        value: subscriptionsStore.selectedCap,
                        label:
                          subscriptionsStore.selectedCap === null
                            ? "No cap"
                            : `${subscriptionsStore.selectedCap} Hours`,
                      }}
                      label="Single select"
                      options={generateOptions()}
                      onChange={(option) =>
                        subscriptionsStore.setCap(option.value)
                      }
                    />
                  ))}
                </div>
                <div className="subscription-item__subtitle">
                  You can cap your hours at a preselected amount, or be billed
                  automatically for additional usage. If capped, your events
                  will end when the limit is reached. We'll alert you when
                  you’re nearing your cap.
                </div>
              </div>
            </div>
            <div>
              <div className="subscription-total">
                <div className="subscription-item">
                  <div className="subscription-item__title">Total (USD)</div>
                  <div className="subscription-item__title-price">
                    {observe(subscriptionsStore.currentSubscription, () => (
                      <>
                        $
                        {formatNumberWithCommas(
                          (
                            subscriptionsStore.currentSubscription?.attributes
                              ?.price / 100
                          ).toFixed(2)
                        )}
                        /
                        <div className="small-text">
                          {subscriptionsStore.selectedInterval === "year"
                            ? "yr"
                            : "mo"}
                        </div>
                      </>
                    ))}
                  </div>
                </div>

                <div className="subscription-item">
                  <div className="subscription-item__title">Hours included</div>
                  <div className="subscription-item__title-price">
                    {observe(
                      subscriptionsStore.currentSubscription,
                      () =>
                        `${subscriptionsStore.currentSubscription?.attributes?.included_hours} hours`
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-actions">
          <div
            onClick={handleCloseModal}
            className="subscription__submit-button"
          >
            Confirm
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

ChangePlanModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};

export default ChangePlanModal;
