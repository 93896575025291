import { useEffect } from "react";
import moment from "moment";
import { useStores, observe } from "stores/utils";
import "./shared.scss";

export const Invoices = () => {
  const { userSubscriptionStore } = useStores();

  useEffect(() => {
    userSubscriptionStore.getInvoices();
  }, [userSubscriptionStore]);

  return (
    <div className="billing-card box">
      <div className="billing-card__title">Your invoices</div>

      <div className="billing-card-table">
        {observe(
          userSubscriptionStore.invoices,
          () =>
            userSubscriptionStore.invoices.length > 0 &&
            userSubscriptionStore.invoices.map((invoice) => (
              <div className="billing-card-table__row" key={invoice.id}>
                <div>
                  {moment(invoice.created * 1000).format("MMMM DD, YYYY")}
                </div>
                <div>{invoice.status}</div>
                <div>${invoice.total / 100}</div>
                <a
                  className="link-button"
                  href={invoice.hosted_invoice_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  View invoice
                </a>
              </div>
            ))
        )}
      </div>
    </div>
  );
};
