import asleepWorker from "../webWorkers/asleepWorker.js";
import LogRocket from "logrocket";

export class WebWorkersStore {
  constructor(rootStores) {
    this.rootStores = rootStores;
    this.initiateAsleepWorker();
  }

  initiateAsleepWorker = () => {
    if (localStorage.getItem("AsleepWorker")) {
      console.log(`[Web Worker]: ${localStorage.getItem("AsleepWorker")}`);
      LogRocket.log(
        `[LogRocket][Web Worker]: ${localStorage.getItem("AsleepWorker")}`
      );
      localStorage.removeItem("AsleepWorker");
    }

    asleepWorker.postMessage("Initiate");

    asleepWorker.onmessage = (e) => {
      if (e && e.data === "wakeup") {
        asleepWorker.terminate();
        localStorage.setItem("AsleepWorker", "Computer probably woke up");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    };
  };
}
