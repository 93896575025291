import { observable, action, makeObservable, computed } from "mobx";
import Honeybadger from "honeybadger-js";
import * as Amplitude from "../utilities/amplitude";
import { CometChat } from "@cometchat-pro/chat";
import { USER_CHAT_STATE_LOADED } from "./UserChatStore";

export const CHAT_STATE_LOADING = "CHAT_STATE_LOADING";
export const CHAT_STATE_LOADED = "CHAT_STATE_LOADED";

export class ChatStore {
  @observable chats = [];
  @observable newMessageCount = 0;
  @observable newMessages = [];
  @observable newMessageNotificationList = [];
  @observable currentOpenedGroupChat = null;
  @observable chatState = CHAT_STATE_LOADING;
  @observable initialized = false;
  @observable tokenHash = undefined;
  @observable cometChatListenerId = undefined;
  @observable activeTab = "room";

  constructor(rootStores) {
    this.rootStores = rootStores;
    makeObservable(this);
  }

  @action
  setInitialized(value) {
    this.initialized = true;
  }

  async handleCometChatReady(data, appContext) {
    console.log("[chat] EVENT (comet chat ready)");
    const authToken = data["comet_chat_auth_token"];

    if (!(await this.sameAuthToken(authToken))) {
      this.rememberAuthToken(authToken);
      console.log("[chat] INITIALIZING COMET CHAT!!!");
      var appID = "20468b3ec4c313b";
      var region = "us";
      var appSetting = new CometChat.AppSettingsBuilder()
        .subscribePresenceForAllUsers()
        .setRegion(region)
        .build();

      try {
        await CometChat.init(appID, appSetting);
        this.setInitialized(true);
      } catch (error) {
        console.log("[chat] CometChat init error! ", error);
        Honeybadger.notify(error);
      }
      await this.loginToCometChatWithRetries(authToken, appContext);
    }

    if (appContext === "office") {
      this.rootStores.userChatStore.setupCometChatListener();
      this.rootStores.userChatStore.setChatState(USER_CHAT_STATE_LOADED);
    } else if (appContext === "in-call") {
      this.setupCometChatListener();
      this.setChatState(CHAT_STATE_LOADED);
    }
  }

  @action
  loginToCometChatWithRetries = (authToken, appContext) => {
    if (authToken) {
      if (this.cometChatAttemptsInterval)
        clearInterval(this.cometChatAttemptsInterval);
      this.cometChatAttempts = 0;
      this.cometChatAttemptsInterval = null;
      return this.loginToCometChat(authToken, appContext);
    } else {
      console.log(
        "[chat] skipping cometchat login.... no comet chat auth token"
      );
    }
  };

  @action
  loginToCometChat = (authToken, appContext) => {
    return CometChat.login(authToken).then(
      () => {
        // User logged in successfully.
        console.log("[chat] logged in successfully!");
        clearInterval(this.cometChatAttemptsInterval);
        // this.setupCometChatListener();
      },
      (error) => {
        if (this.cometChatAttempts === 0) {
          this.cometChatAttemptsInterval = setInterval(() => {
            if (this.cometChatAttempts >= 3) {
              // User login failed, check error and take appropriate action.
              clearInterval(this.cometChatAttemptsInterval);
              console.log("[chat] CometChat login failed with exception:", {
                error,
              });
              this.cometChatAttempts = 0;
              Honeybadger.notify(error);
            } else {
              this.loginToCometChat(authToken);
            }
          }, 2000);
        }
        this.cometChatAttempts++;
      }
    );
  };

  @action
  async rememberAuthToken(authToken) {
    this.tokenHash = await this.hashAuthToken(authToken);
  }

  async sameAuthToken(authToken) {
    const newHash = await this.hashAuthToken(authToken);
    return this.tokenHash === newHash;
  }

  toHexString(byteArray) {
    return Array.from(byteArray, function (byte) {
      return ("0" + (byte & 0xff).toString(16)).slice(-2);
    }).join("");
  }

  async hashAuthToken(message) {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    // console.log("[chat]MSG:", msgUint8);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
    // console.log("[chat]HB:", hashBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    // console.log("[chat]HA:", hashArray);
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join(""); // convert bytes to hex string
    // console.log("[chat]HH:", hashHex);
    return hashHex;
  }

  @computed
  get groupChats() {
    return this.chats.filter((item) => item.type === "group");
  }

  @computed
  get directChats() {
    return this.chats.filter((item) => item.type === "user");
  }

  @computed
  get everyoneNewMessages() {
    return this.newMessages.filter(
      (item) =>
        item.conversationId ===
        `group_${this.rootStores.callStore.sessionCometChatGuid}`
    ).length;
  }

  @action
  setChatState(value) {
    this.chatState = value;
  }

  @action
  clearCurrentRoomMessageNotifs() {
    this.newMessages = this.newMessages.filter(
      (item) => item.conversationId !== this.currentOpenedGroupChat
    );
    this.newMessageCount = this.newMessages.length;
  }

  @action
  clearOldRoomMessageNotifs() {
    this.newMessages = this.newMessages.filter(
      (item) =>
        item.conversationId ===
        `group_${this.rootStores.callStore.sessionCometChatGuid}`
    );
    this.newMessageCount = this.newMessages.length;
  }

  // useful when user jumps rooms in THE SAME SPACE. This will remove any message notifs related to previous room.
  // this bug currently exist in producion
  @action
  resetRoomChatStateOnSwitchRoom() {
    this.clearOldRoomMessageNotifs();
    this.setCurrentOpenedGroupChat(undefined);
    this.setActiveTab("room");
  }

  @action
  openChat = (state) => {
    const ifChatIsOpened = this.chats.filter((item) => item.key === state.key)
      .length;

    if (!this.currentOpenedGroupChat && state.type === "group") {
      const currentTabId =
        this.activeTab === "room"
          ? `group_${this.rootStores.callStore.currentRoom.attributes["comet-chat-guid"]}`
          : `group_${this.rootStores.callStore.sessionCometChatGuid}`;

      this.setCurrentOpenedGroupChat(currentTabId);

      if (this.newMessageCount > 0) {
        this.clearCurrentRoomMessageNotifs();
      }
    }

    if (!ifChatIsOpened) {
      Amplitude.track("Opened chat");

      if (state.type === "group") {
        this.setNewMessageNotificationList(
          this.newMessageNotificationList.filter(
            (item) => item.receiverType !== "group"
          )
        );
      }

      this.chats.replace([...this.chats, state]);
    }
  };

  @action
  closeChat = (key) => {
    Amplitude.track("Closed chat");
    if (key) {
      this.chats.replace(this.chats.filter((item) => item.key !== key));
    } else {
      this.chats.replace([]);
    }
    this.setCurrentOpenedGroupChat(undefined);
  };

  @action
  setNewMessages = (messages) => {
    this.newMessages = messages;
    this.newMessageCount = messages.length;
  };

  @action
  setNewMessageNotificationList = (messages) => {
    this.newMessageNotificationList = messages;
  };

  @action
  setCurrentOpenedGroupChat = (uid) => {
    this.currentOpenedGroupChat = uid;
  };

  addMessageNotification = (message) => {
    if (message.receiverType === "group") {
      const isGroupChatOpened = this.chats.filter(
        (item) => item.type === "group"
      ).length;
      const isTargetTabOpened =
        message.conversationId === this.currentOpenedGroupChat;

      if (!isTargetTabOpened) {
        this.setNewMessages([...this.newMessages, message]);
      }

      if (!isGroupChatOpened) {
        this.setNewMessageNotificationList([
          ...this.newMessageNotificationList,
          message,
        ]);

        setTimeout(() => {
          this.setNewMessageNotificationList([
            ...this.newMessageNotificationList.filter(
              (item) => item !== message
            ),
          ]);
        }, 4000);
      }
    } else {
      if (!message.metadata.isUserToUser) {
        this.openChat({
          type: "user",
          key: message.sender.uid,
        });
      }
    }
  };

  @action
  setListenerId(id) {
    this.cometChatListenerId = id;
  }

  @action
  setActiveTab(tab) {
    this.activeTab = tab;
  }

  @action
  isTheSameListenerId(pid) {
    if (!this.cometChatListenerId) {
      // runs once on the first room join after opening the app.
      console.log("NO LISTENER, establish one <<<<<<<<----");
      this.setListenerId(pid);
      return false;
    } else if (this.cometChatListenerId !== pid) {
      // runs every time user jumps spaces.
      console.log(
        "DIFFERENT LISTENER, remove previous and establish a new one <<<<<<<<----"
      );
      this.teardownCometChatListener();
      this.setListenerId(pid);
      return false;
    } else {
      // runs every time user jumps rooms (also happens twice due to CableSocket runs handleCometChatReady twice (BUG?)).
      console.log("SAME LISTENER, skipping <<<<<<<<----");
      return true;
    }
  }

  setupCometChatListener = async () => {
    console.log("[chat] SETTING UP COMET CHAT LISTENERS !!!!");
    const participantId = this.rootStores.callStore.participant?.id;

    if (this.isTheSameListenerId(participantId)) {
      return;
    }

    CometChat.addMessageListener(
      participantId,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage) => {
          console.log("Text message received successfully", textMessage);
          // Handle text message
          this.addMessageNotification(textMessage);
        },
        onMediaMessageReceived: (mediaMessage) => {
          console.log("Media message received successfully", mediaMessage);
          // Handle media message
          this.addMessageNotification(mediaMessage);
        },
        onCustomMessageReceived: (customMessage) => {
          console.log("Custom message received successfully", customMessage);
          // Handle custom message
          this.addMessageNotification(customMessage);
        },
      })
    );
  };

  teardownCometChatListener = () => {
    CometChat.removeMessageListener(this.cometChatListenerId);
    this.setListenerId(undefined);
  };
}
