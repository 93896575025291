import { get } from "lodash/fp";
import * as Amplitude from "../utilities/amplitude";
import api from "../api";

export class ParticipantModelLite {
  id;
  name;
  groupId;
  dailyId;
  userId;
  color;
  videoOn = false;
  chatId;

  constructor({ data, rootStores }) {
    this.rootStores = rootStores;
    this.id = data.id;
    this.name = data.attributes.name;
    this.groupId = data.relationships?.group?.data?.id;
    this.dailyId = data.attributes["daily-participant-id"];
    this.avatar = get("attributes.big-photo-url", data);
    this.tinyPhotoUrl = get("attributes.tiny-photo-url", data);
    this.userId = get("attributes.user-id", data);
    this.color = get("attributes.color", data);
    this.chatId = get("attributes.chat-id", data);
  }

  sendReaction = (reactionName, reactionTitle) => {
    Amplitude.track(`Send Reaction - ${reactionName}`, {
      reaction: reactionName,
      reactionTitle: reactionTitle,
    });

    this.rootStores.userReactionStore.sendReaction(
      reactionName,
      this.id,
      reactionTitle
    );
  };

  banParticipant = () => {
    return api
      .banParticipant(this.id, this.rootStores.userStore.token)
      .catch((e) => this.rootStores.errorStore.handleApiError(e));
  };

  mute = () => {
    Amplitude.track(`Muted user`, {
      participant: this.id,
    });
    this.rootStores.api.muteParticipant(this.id).catch((e) => {
      return this.rootStores.errorStore.handleApiError(e);
    });
  };

  sendInvite = (inviteName, inviteParams = {}) => {
    Amplitude.track(`Send ${inviteName}`, {
      inviteName,
      targetId: this.id,
      inviteParams,
    });

    return this.rootStores.inviteStore.sendInvite(
      inviteName,
      this.id,
      inviteParams
    );
  };

  joinMeInvite = (roomId, clusterId) => {
    const invitedParticipantId = this.id;

    return api.joinMeInvite(roomId, clusterId, invitedParticipantId);
  };

  allowCameraMic = () => {
    Amplitude.track(`Allow Camera/Mic`, {
      targetId: this.id,
    });

    return api
      .createParticipantPermission(
        this.id,
        this.rootStores.callStore.currentRoomId,
        this.rootStores.userStore.token
      )
      .catch((e) => {
        return this.rootStores.errorStore.handleApiError(e);
      });
  };

  disableCameraMic = () => {
    Amplitude.track(`Disable Camera/Mic`, {
      targetId: this.id,
    });

    return api
      .removeParticipantPermission(
        this.id,
        this.rootStores.callStore.currentRoomId,
        this.rootStores.userStore.token
      )
      .catch((e) => {
        return this.rootStores.errorStore.handleApiError(e);
      });
  };
  //
  // get inSameRoom() {
  //   return this.rootStores.callStore.currentRoomId === this.groupId;
  // }

  get colorClass() {
    return this.rootStores.callStore.isMe(this.id)
      ? "is-lightblue"
      : "is-lighterblue";
  }

  get inSameCluster() {
    return this.rootStores.callStore.clusterParticipantIds.includes(this.id);
  }
}
