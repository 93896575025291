import { observer } from "mobx-react-lite";
import Tippy from "@tippyjs/react";
import { ReactComponent as Plus } from "../../images/SVG/plus-light.svg";
import AddRoomButton from "../SideMenu/AddRoomButton";
import { useStores } from "stores/utils";

const EventHostMenu = ({ roomsMenuEl, addRoom, hideCreateAuditorium }) => {
  const { callStore, userStore } = useStores();
  const disabled = callStore.appButtonsDisabled;
  const show = userStore.isAuthenticated && callStore.isEventHost;
  if (!show) {
    return null;
  }

  return (
    <div className="level">
      <div className="level-left">
        <div className="level-item">
          {addRoom && <AddRoomButton />}
          {!hideCreateAuditorium && (
            <div className="add-room-button">
              <Tippy content="Create new auditorium">
                <button
                  className="button is-host is-outlined is-small is-rounded"
                  disabled={disabled}
                  onClick={() => callStore.createAuditorium(roomsMenuEl)}
                >
                  <span className="icon">
                    <Plus />
                  </span>
                  &nbsp;&nbsp;{" "}
                  {callStore.creatingAuditorium ? "Creating..." : "Auditorium"}
                </button>
              </Tippy>
            </div>
          )}
        </div>
      </div>
      <div className="level-right">
        <div className="level-item"></div>
      </div>
    </div>
  );
};

export default observer(EventHostMenu);
